import {
  GET_LEADHISTORYS,
  GET_LEADHISTORYS_SUCCESS,
  GET_LEADHISTORYS_FAIL,
  GET_LEADHISTORY_DETAILS,
  GET_LEADHISTORY_DETAILS_SUCCESS,
  GET_LEADHISTORY_DETAILS_FAIL,
  CREATE_LEADHISTORY,
  CREATE_LEADHISTORY_SUCCESS,
  CREATE_LEADHISTORY_FAIL,
  UPDATE_LEADHISTORY,
  UPDATE_LEADHISTORY_SUCCESS,
  UPDATE_LEADHISTORY_FAIL,
  DELETE_LEADHISTORY_SUCCESS,
  DELETE_LEADHISTORY_FAIL,
  DELETE_LEADHISTORY,
  GET_LEADHISTORY_BY_LEAD,
  GET_LEADHISTORY_BY_LEAD_SUCCESS,
  GET_LEADHISTORY_BY_LEAD_FAIL,
} from "./actionTypes"

// LeadHistory - This line cannot be edited or removed
export const getLeadHistorys = (page, limit, searchText, leadId) => ({
  type: GET_LEADHISTORYS,
  payload: { page, limit, searchText, leadId },
})

export const getLeadHistorysSuccess = leadhistorys => ({
  type: GET_LEADHISTORYS_SUCCESS,
  payload: leadhistorys,
})

export const getLeadHistorysFail = error => ({
  type: GET_LEADHISTORYS_FAIL,
  payload: error,
})

export const getLeadHistoryDetails = leadhistoryId => ({
  type: GET_LEADHISTORY_DETAILS,
  payload: leadhistoryId,
})

export const getLeadHistoryDetailsSuccess = leadhistoryDetails => ({
  type: GET_LEADHISTORY_DETAILS_SUCCESS,
  payload: leadhistoryDetails,
})

export const getLeadHistoryDetailsFail = error => ({
  type: GET_LEADHISTORY_DETAILS_FAIL,
  payload: error,
})

export const createLeadHistory = (leadhistory, history) => ({
  type: CREATE_LEADHISTORY,
  payload: { leadhistory, history },
})

export const createLeadHistorySuccess = leadhistory => ({
  type: CREATE_LEADHISTORY_SUCCESS,
  payload: leadhistory,
})

export const createLeadHistoryFail = error => ({
  type: CREATE_LEADHISTORY_FAIL,
  payload: error,
})

export const getLeadHistoryDetail = leadhistoryId => ({
  type: GET_LEADHISTORY_DETAILS,
  payload: leadhistoryId,
})

export const updateLeadHistory = (leadhistory, leadhistoryId, history) => ({
  type: UPDATE_LEADHISTORY,
  payload: { leadhistory, leadhistoryId, history },
})

export const updateLeadHistorySuccess = leadhistory => ({
  type: UPDATE_LEADHISTORY_SUCCESS,
  payload: leadhistory,
})

export const updateLeadHistoryFail = error => ({
  type: UPDATE_LEADHISTORY_FAIL,
  payload: error,
})

export const deleteLeadHistory = (leadhistoryId, history) => ({
  type: DELETE_LEADHISTORY,
  payload: { leadhistoryId, history },
})

export const deleteLeadHistorySuccess = leadhistory => ({
  type: DELETE_LEADHISTORY_SUCCESS,
  payload: leadhistory,
})

export const deleteLeadHistoryFail = error => ({
  type: DELETE_LEADHISTORY_FAIL,
  payload: error,
})

//
export const getLeadHistoryByLead = (leadId, limit) => ({
  type: GET_LEADHISTORY_BY_LEAD,
  payload: { leadId, limit },
})

export const getLeadHistoryByLeadSuccess = leadhistorysById => ({
  type: GET_LEADHISTORY_BY_LEAD_SUCCESS,
  payload: leadhistorysById,
})

export const getLeadHistoryByLeadFail = error => ({
  type: GET_LEADHISTORY_BY_LEAD_FAIL,
  payload: error,
})
