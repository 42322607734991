import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce, update } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap"

import Select from "react-select"
import MyRating from "components/Common/Rating"
import UseImageUploader from "components/imageUploader/useImageUploader"
import {
  createClient,
  getDistricts,
  getStates,
  updateClient,
} from "store/actions"

const ClientModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const { allDistricts, allState } = useSelector(state => ({
    allState: state?.Client?.states?.States,
    allDistricts: state?.Client?.districts?.districts,
  }))

  const [state, setState] = useState("")
  const [stateId, setStateId] = useState("")
  const [stateSearch, setStateSearch] = useState("")

  const [district, setDistrict] = useState("")
  const [districtId, setDistrictId] = useState("")
  const [districtSearch, setDistrictSearch] = useState("")

  useEffect(() => {
    dispatch(getStates())
  }, [dispatch])

  useEffect(() => {
    setDistrictId(updateData?.address?.district?._id)
    setDistrict(updateData?.address?.district?.name)

    setState(updateData?.address?.state?.name)
    setStateId(updateData?.address?.state?._id)
  }, [updateData])

  console.log(updateData?.countryCode)

  const stateOptions = [
    {
      options: allState?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result._id,
      })),
    },
  ]

  const handleEnteredStates = textEntered => {
    setStateSearch(textEntered)
  }

  function handleFinalValuesStates(event) {
    setState(event.label)
    setStateId(event.value)
  }

  useEffect(() => {
    // if (stateId) {
    dispatch(getDistricts(stateId))
    // }
  }, [dispatch, stateId])

  const districtOptions = [
    {
      options: allDistricts?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result._id,
      })),
    },
  ]

  const handleEnteredDistrict = textEntered => {
    setDistrictSearch(textEntered)
  }

  function handleFinalValuesDistrict(event) {
    setDistrict(event.label)
    setDistrictId(event.value)
  }

  const handleValidSubmit = (e, v) => {
    const countryCode = v?.countryCode
    const countryCodeWithPlus = countryCode ? `+${countryCode}` : null
    const paddedCountryCode = countryCodeWithPlus?.padEnd(12, "")
    const clientUpdate = {
      name: v?.name,
      phone: v?.phone,
      whatsappNumber: v?.whatsappNumber,
      email: v?.email,
      address: {
        addressLine1: v?.addressLine1,
        addressLine2: v?.addressLine2,
        district: districtId,
        state: stateId,
        pincode: v?.pincode,
      },
      countryCode: paddedCountryCode,
    }
    if (updateData?._id) {
      dispatch(updateClient(clientUpdate, updateData?._id, ""))
      onCloseClick()
    } else {
      const clientCreate = {
        ...v,
      }
      dispatch(createClient(clientCreate, onCloseClick))
      onCloseClick()
    }
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>
          {updateData?._id ? "Edit Client" : "Add New Client"}
        </CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <>
              <Col className="col-12 mb-2">
                <label>
                  Name <span className="text-danger"> *</span>
                </label>
                <AvField
                  name="name"
                  type="text"
                  placeholder="Enter Name "
                  value={updateData?.name}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "name is required",
                    },
                  }}
                />
              </Col>
            </>
          </Row>
          <Row>
            <Col className="col-6">
              <label>
                Phone <span className="text-danger"> *</span>
              </label>
              <AvField
                name="phone"
                type="number"
                placeholder="Enter Phone "
                value={updateData?.phone}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "phone number is required",
                  },
                  // minLength: {
                  //   value: 10,
                  //   errorMessage: "phone number must be 10 letter ",
                  // },
                  // maxLength: {
                  //   value: 10,
                  //   errorMessage: "phone number must be 10 letter ",
                  // },
                }}
              />
            </Col>
            <Col className="col-6">
              <AvField
                label="WhatsApp Number"
                name="whatsappNumber"
                type="number"
                placeholder="Enter Whatsapp Number "
                value={updateData?.whatsappNumber}
                validate={{
                  required: {
                    value: false,
                    errorMessage: " what's app number is required",
                  },
                  // minLength: {
                  //   value: 10,
                  //   errorMessage: " number must be 10 letter ",
                  // },
                  // maxLength: {
                  //   value: 10,
                  //   errorMessage: "what's app number must be 10 letter ",
                  // },
                }}
              />
            </Col>
          </Row>

          {updateData?._id ? (
            <>
              <Row>
                <Col className="col-12 py-3">
                  <AvField
                    name="email"
                    label="Email"
                    type="mail"
                    placeholder="Enter Email"
                    value={updateData?.email ?? ""}
                  />
                </Col>
              </Row>
              <Col className="mb-3">
                <AvField
                  label="Address Line 1"
                  name="addressLine1"
                  type="textarea"
                  placeholder="Enter your address"
                  value={updateData?.address?.addressLine1 ?? ""}
                />
              </Col>
              <Col className="">
                <AvField
                  name="addressLine2"
                  label="Address Line 2"
                  type="textarea"
                  placeholder="Enter your address"
                  value={updateData?.address?.addressLine2 ?? ""}
                />
              </Col>
              <Row className="py-2">
                <Col className="col-6">
                  <label>State</label>
                  <Select
                    onInputChange={handleEnteredStates}
                    value={state}
                    placeholder={state}
                    onChange={handleFinalValuesStates}
                    options={stateOptions}
                    classNamePrefix="select2-selection"
                    isLoading={""}
                  />
                </Col>
                <Col className="col-6">
                  <label>District</label>
                  <Select
                    onInputChange={handleEnteredDistrict}
                    value={district}
                    placeholder={district}
                    onChange={handleFinalValuesDistrict}
                    options={districtOptions}
                    classNamePrefix="select2-selection"
                    isLoading={""}
                  />
                </Col>
              </Row>
              <Row className="py-2">
                {/* <Col className="col-6 ">
                  <AvField
                    name="countryCode"
                    label="Country Code"
                    type="text"
                    placeholder="Country Code "
                    value={updateData?.countryCode ?? "+91"}
                  />
                </Col> */}
                <Col className="col-12">
                  <AvField
                    name="pincode"
                    type="number"
                    label="Pincode"
                    placeholder=" Pincode"
                    value={updateData?.address?.pincode ?? ""}
                  />
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}

          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-light ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-warning ms-2">
                {updateData?._id ? "Save" : "Create"}
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

ClientModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default ClientModal
