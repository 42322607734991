import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { CardTitle, Col, Modal, ModalBody, Row } from "reactstrap"
import Select from "react-select"

//actions
import {
  createLeadHistory,
  createLeadNotes,
  getLeads,
  getMembers,
  updateLeadHistory,
  updateLeadNotes,
} from "store/actions"
import PropTypes from "prop-types"

const CreateForm = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [user, setUser] = useState("Select user...")
  const [userId, setUserId] = useState("")
  const [userSearch, setUserSearch] = useState("")

  const [lead, setLead] = useState("Select lead...")
  const [leadId, setLeadId] = useState("")
  const [leadSearch, setLeadSearch] = useState("")

  const { allMembers, loading, allLeads, Leadloading } = useSelector(state => ({
    allLeads: state.Lead.leads?.leads,
    allMembers: state.Members.members?.members,
    loading: state.Members.loading,
    Leadloading: state.Lead.loading,
  }))

  useEffect(() => {
    dispatch(getMembers("", "", userSearch, "", ""))
  }, [dispatch, userSearch])

  const handlerRoleFinalValue = event => {
    setUserId(event?.value)
    setUser(event?.label)
  }
  const handleUserEnters = text => {
    setUserSearch(text)
  }

  const userOptions = [
    {
      options: allMembers?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getLeads("", "", "", leadSearch, "", "", ""))
  }, [dispatch, leadSearch])

  const handlerLeadFinalValue = event => {
    setLeadId(event?.value)
    setLead(event?.label)
  }
  const handleLeadEnters = text => {
    setLeadSearch(text)
  }

  const leadOptions = [
    {
      options: allLeads?.map((result, index) => ({
        key: index,
        label: result?.client?.name,
        value: result?.client?._id,
      })),
    },
  ]

  const leadnotesId = updateData?._id

  function handleValidSubmit(e, v) {
    if (updateData?._id) {
      const updateData = {
        ...v,
        lead: params?.id,

        // user: userId,
        // lead: leadId,
      }
      dispatch(updateLeadNotes(updateData, leadnotesId, onCloseClick))
    } else {
      const createData = {
        ...v,
        lead: params?.id,

        // user: userId,
        // lead: leadId,
      }
      dispatch(createLeadNotes(createData, onCloseClick))
    }
  }

  console.log(updateData)
  useEffect(() => {
    // setUser(updateData?.user?.name)
    // setUserId(updateData?.user?._id)
    // setLead(updateData?.client?.name)
    // setLeadId(updateData?.client?._id)
  }, [updateData])

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>
          {updateData?._id ? "Edit Lead-Notes" : "Add Lead-Notes"}
        </CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            {/* <label>User</label>
            <Select
              onInputChange={handleUserEnters}
              value={user}
              placeholder={user}
              onChange={handlerRoleFinalValue}
              options={userOptions}
              classNamePrefix="select2-selection"
              isLoading={loading}
            /> */}

            {/* <label>Lead</label>
            <Select
              onInputChange={handleLeadEnters}
              value={lead}
              placeholder={lead}
              onChange={handlerLeadFinalValue}
              options={leadOptions}
              classNamePrefix="select2-selection"
              isLoading={Leadloading}
            /> */}
            <>
              <Col className="col-12 py-2">
                <label>Note</label>
                <AvField
                  name="note"
                  type="textarea"
                  value={updateData?.note ?? ""}
                  validate={{
                    required: {
                      value: false,
                      errorMessage: "name is required",
                    },
                  }}
                />
              </Col>
            </>
          </Row>

          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-light ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-warning ms-2">
                {updateData?._id ? "Save" : "Create"}
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export default CreateForm

CreateForm.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}
