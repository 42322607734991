import {
  GET_LEADNOTES,
  GET_LEADNOTES_SUCCESS,
  GET_LEADNOTES_FAIL,
  GET_LEADNOTES_DETAILS,
  GET_LEADNOTES_DETAILS_SUCCESS,
  GET_LEADNOTES_DETAILS_FAIL,
  CREATE_LEADNOTES,
  CREATE_LEADNOTES_SUCCESS,
  CREATE_LEADNOTES_FAIL,
  UPDATE_LEADNOTES,
  UPDATE_LEADNOTES_SUCCESS,
  UPDATE_LEADNOTES_FAIL,
  DELETE_LEADNOTES,
  DELETE_LEADNOTES_SUCCESS,
  DELETE_LEADNOTES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  leadnotes: {
    leadnotes: [],
    total: "",
    page: "",
  },
  leadnotesDetails: {},
  error: {},
  loading: false,
}

const LeadNotes = (state = INIT_STATE, action) => {
  switch (action.type) {
    // LeadNotes - This line cannot be edited or removed
    case GET_LEADNOTES:
    case GET_LEADNOTES_DETAILS:
    case CREATE_LEADNOTES:
    case UPDATE_LEADNOTES:
    case DELETE_LEADNOTES:
      return {
        ...state,
        loading: true,
      }

    case DELETE_LEADNOTES_FAIL:
    case GET_LEADNOTES_FAIL:
    case GET_LEADNOTES_DETAILS_FAIL:
    case UPDATE_LEADNOTES_FAIL:
    case CREATE_LEADNOTES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_LEADNOTES_SUCCESS:
      return {
        ...state,
        leadnotes: action.payload,
        error: {},
        loading: false,
      }

    case GET_LEADNOTES_DETAILS_SUCCESS:
      return {
        ...state,
        leadnotesDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_LEADNOTES_SUCCESS:
      return {
        ...state,
        leadnotes: [action?.payload, ...state.leadnotes],
        // leadnotesDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_LEADNOTES_SUCCESS:
      return {
        ...state,
        leadnotes: state.leadnotes?.map(leadnote =>
          leadnote._id === action.payload._id
            ? { ...leadnote, ...action.payload }
            : leadnote
        ),
        leadnotesDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_LEADNOTES_SUCCESS:
      return {
        ...state,
        leadnotes: state?.leadnotes?.filter(
          leadnotes => leadnotes._id !== action.payload
        ),
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default LeadNotes
