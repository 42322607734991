import {
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  GET_PROFILE,
  RESET_PROFILE_FLAG,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  profile: {},
}

const Profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      state = { ...state }
      break
    case GET_PROFILE_SUCCESS:
      state = { ...state, profile: action.payload }
      break
    case GET_PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Profile
