/* MatchLists - This line cannot be edited or removed */
export const GET_MATCHLISTS = "GET_MATCHLISTS"
export const GET_MATCHLISTS_SUCCESS = "GET_MATCHLISTS_SUCCESS"
export const GET_MATCHLISTS_FAIL = "GET_MATCHLISTS_FAIL"

/* MatchList_DETAILS */
export const GET_MATCHLIST_DETAILS = "GET_MATCHLIST_DETAILS"
export const GET_MATCHLIST_DETAILS_SUCCESS = "GET_MATCHLIST_DETAILS_SUCCESS"
export const GET_MATCHLIST_DETAILS_FAIL = "GET_MATCHLIST_DETAILS_FAIL"

/* CREATE MatchList */
export const CREATE_MATCHLIST = "CREATE_MATCHLIST"
export const CREATE_MATCHLIST_SUCCESS = "CREATE_MATCHLIST_SUCCESS"
export const CREATE_MATCHLIST_FAIL = "CREATE_MATCHLIST_FAIL"

/* Edit MatchList */
export const UPDATE_MATCHLIST = "UPDATE_MATCHLIST"
export const UPDATE_MATCHLIST_SUCCESS = "UPDATE_MATCHLIST_SUCCESS"
export const UPDATE_MATCHLIST_FAIL = "UPDATE_MATCHLIST_FAIL"

/* Delete MatchList */
export const DELETE_MATCHLIST = "DELETE_MATCHLIST"
export const DELETE_MATCHLIST_SUCCESS = "DELETE_MATCHLIST_SUCCESS"
export const DELETE_MATCHLIST_FAIL = "DELETE_MATCHLIST_FAIL"
