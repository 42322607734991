import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import {
  Container,
  Badge,
  Button,
  UncontrolledTooltip,
  CardTitle,
  CardBody,
  Col,
  Card,
  Row,
} from "reactstrap"
import { MdDelete } from "react-icons/md"
import { BsFillFuelPumpFill } from "react-icons/bs"
import { IoSpeedometer } from "react-icons/io5"
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import image from "assets/images/Defualt/noimage.png"
import { TiTick } from "react-icons/ti"
import { FaUserTag } from "react-icons/fa6"
import MetaTag from "components/Common/Meta-tag"
import { BsCalendarDateFill } from "react-icons/bs"
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import DeleteModal from "components/Common/DeleteModal"
import { useDispatch, useSelector } from "react-redux"
import { deleteVehicle, getVehicleDetail } from "store/actions"
import moment from "moment"
import Leads from "pages/Lead/DatatableTables"
import Spinner from "components/Common/Spinner"

const VehicleDetails = props => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const role = sessionStorage?.getItem("role")
  const [isOpen, setIsOpen] = useState({ status: false, id: "" })
  const { vehicleDetails, loading } = useSelector(state => ({
    vehicleDetails: state.Vehicle?.vehicleDetails,
    loading: state.Vehicle?.loading,
  }))

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteVehicle(isOpen?.id, history))
  }

  useEffect(() => {
    dispatch(getVehicleDetail(params.id))
  }, [params?.id])

  const [currentPhoto, setCurrentPhoto] = useState(
    (vehicleDetails?.images && vehicleDetails?.images[0]?.url) || image
  )

  useEffect(() => {
    if (vehicleDetails?.images?.length >= 1) {
      setCurrentPhoto(vehicleDetails?.images[0]?.url)
    }
  }, [vehicleDetails])

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <div className="page-content mb-0 pb-2">
        <MetaTag title="Vehicle Detail" />

        <Container fluid>
          <Breadcrumbs title="User" breadcrumbItem="Vehicle Detail" />

          <Card>
            <CardBody>
              {loading ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "300px" }}
                >
                  <Spinner color="info" className="text-info font-size-20" />
                </div>
              ) : (
                <div className="d-flex position-relative">
                  <div className="d-flex">
                    <div
                      className="me-3 "
                      style={{
                        maxHeight: "400px",
                        width: "100px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {vehicleDetails?.images?.map((item, key) => (
                        <div
                          key={key}
                          className="mb-3 hover-zoom-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setCurrentPhoto(item?.url)}
                        >
                          <img
                            height={50}
                            width={80}
                            alt={`vehicle ${key}`}
                            style={{ borderRadius: "10px" }}
                            src={item?.url}
                          />
                        </div>
                      ))}
                    </div>
                    <img
                      src={currentPhoto ? currentPhoto : image}
                      height={400}
                      width={400}
                      style={{
                        objectFit: "contain",
                        borderRadius: 15,
                        height: "fit-content",
                      }}
                    />
                  </div>
                  <div className="px-5">
                    <div className="d-flex mb-3">
                      {vehicleDetails?.isFeatured && (
                        <Badge
                          className={
                            "font-size-12 badge-soft-warning px-2 py-1 me-2"
                          }
                          color=""
                          pill
                        >
                          Featured
                        </Badge>
                      )}

                      {vehicleDetails?.isBooked && (
                        <Badge
                          className={
                            "font-size-12 badge-soft-warning px-2 py-1 me-2"
                          }
                          color=""
                          pill
                        >
                          Booked
                        </Badge>
                      )}

                      {vehicleDetails?.isSold && (
                        <Badge
                          className={
                            "font-size-12 badge-soft-warning px-2 py-1 me-2"
                          }
                          color=""
                          pill
                        >
                          Sold
                        </Badge>
                      )}
                    </div>
                    <p className="h5 font-size-17">{vehicleDetails?.name}</p>
                    <p className="">{vehicleDetails?.description}</p>
                    <div className="mb-3">
                      <p className="mb-1">General</p>
                      {vehicleDetails?.fuelType && (
                        <>
                          <Badge
                            className={
                              "font-size-12 badge-soft-success p-2 me-2 mb-2"
                            }
                            color=""
                            pill
                            style={{
                              textTransform: "capitalize",
                            }}
                            id="fuelTypeId"
                          >
                            <BsFillFuelPumpFill className="font-size-20  text-success me-2" />
                            {vehicleDetails?.fuelType}
                          </Badge>
                          <UncontrolledTooltip target={"fuelTypeId"}>
                            Fuel Type
                          </UncontrolledTooltip>
                        </>
                      )}

                      {vehicleDetails?.kiloMeter && (
                        <>
                          <Badge
                            className={
                              "font-size-12 badge-soft-success p-2 me-2 mb-2"
                            }
                            color=""
                            pill
                            style={{
                              textTransform: "capitalize",
                            }}
                            id="kilometerId"
                          >
                            <IoSpeedometer className="font-size-20  text-success me-2" />
                            {vehicleDetails?.kiloMeter}
                          </Badge>

                          <UncontrolledTooltip target={"kilometerId"}>
                            Kilometer
                          </UncontrolledTooltip>
                        </>
                      )}
                      {vehicleDetails?.ownershipStatus ? (
                        <>
                          <Badge
                            className={
                              "font-size-12 badge-soft-success p-2 me-2 mb-2"
                            }
                            color=""
                            pill
                            style={{
                              textTransform: "capitalize",
                            }}
                            id="ownershipStatusId"
                          >
                            <FaUserTag className="font-size-20  text-success me-2" />
                            {vehicleDetails?.ownershipStatus?.replace(
                              /_/g,
                              " "
                            )}
                          </Badge>
                          <UncontrolledTooltip target={"ownershipStatusId"}>
                            Ownership Status
                          </UncontrolledTooltip>
                        </>
                      ) : (
                        ""
                      )}
                      {vehicleDetails?.makeYear ? (
                        <Badge
                          className={
                            "font-size-12 badge-soft-success p-2 me-2 mb-2"
                          }
                          color=""
                          pill
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          <BsCalendarDateFill className="font-size-20  text-success me-2" />
                          Year {vehicleDetails?.makeYear}
                        </Badge>
                      ) : (
                        ""
                      )}
                      {/* {vehicleDetails?.initialPurchaseYear ? (
                        <Badge
                          className={
                            "font-size-12 badge-soft-success p-2 me-2 mb-2"
                          }
                          color=""
                          pill
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          <BsCalendarDateFill className="font-size-20  text-success me-2" />
                          Paraphrase Year {vehicleDetails?.initialPurchaseYear}
                        </Badge>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <div>
                      {vehicleDetails?.brand?.name && (
                        <div className="d-flex">
                          <TiTick className="text-success font-size-20 me-2" />
                          <p>
                            Brand&nbsp;
                            <span
                              className="text-black"
                              style={{ fontWeight: 900 }}
                            >
                              {vehicleDetails?.brand?.name}
                            </span>
                          </p>
                        </div>
                      )}
                      {vehicleDetails?.model?.name && (
                        <div className="d-flex">
                          <TiTick className="text-success font-size-20 me-2" />
                          <p>
                            Model&nbsp;
                            <span
                              className="text-black"
                              style={{ fontWeight: 900 }}
                            >
                              {vehicleDetails?.model?.name}
                            </span>
                          </p>
                        </div>
                      )}
                      {vehicleDetails?.variant?.name && (
                        <div className="d-flex">
                          <TiTick className="text-success font-size-20 me-2" />
                          <p>
                            Variant&nbsp;
                            <span
                              className="text-black"
                              style={{ fontWeight: 900 }}
                            >
                              {vehicleDetails?.variant?.name}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>

                    {role === "admin" ||
                    role === "generalManager" ||
                    role === "managingDirector" ||
                    role === "purchaseManager" ? (
                      <div className="py-3 d-flex align-items-center">
                        <MdDelete
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "10px",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                          className="text-danger"
                          onClick={() => {
                            handleDelete(vehicleDetails?._id, history)
                          }}
                          id="deleteVehicleId"
                        />
                        <UncontrolledTooltip target={"deleteVehicleId"}>
                          Delete Vehicle
                        </UncontrolledTooltip>
                        <Link
                          to={{
                            pathname: `/vehicle/update/${vehicleDetails?._id}`,
                            state: "vehicle",
                          }}
                        >
                          <Button className="px-5" color="light">
                            Update
                          </Button>{" "}
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}

                    {role === "branchManager" ? (
                      <div className="py-3 d-flex align-items-center">
                        {/* <MdDelete
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "10px",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                          className="text-danger"
                          onClick={() => {
                            handleDelete(vehicleDetails?._id, history)
                          }}
                          id="deleteVehicleId"
                        />
                        <UncontrolledTooltip target={"deleteVehicleId"}>
                          Delete Vehicle
                        </UncontrolledTooltip> */}
                        <Link
                          to={{
                            pathname: `/vehicle/update/${vehicleDetails?._id}`,
                            state: "vehicle",
                          }}
                        >
                          <Button className="px-5" color="light">
                            Update
                          </Button>{" "}
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
          <Row>
            <Col sm="6">
              <Card>
                <CardBody>
                  <p>Booking Details</p>
                  <div className="d-flex align-items-end justify-content-between">
                    <div>
                      {vehicleDetails?.isBooked ? (
                        <>
                          <div className="d-flex p-1 px-0">
                            <p className="pb-0 mb-0">Booked Date</p>
                            <div className="px-2">
                              <p className="h5">
                                {moment(vehicleDetails?.bookedDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex p-1 px-0">
                            <p className="pb-0 mb-0">Booked Client</p>
                            <div className="px-2">
                              <p className="h5">
                                {vehicleDetails?.bookedClient?.name}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex p-1 px-0">
                            <p className="pb-0 mb-0">Booked By</p>
                            <div className="px-2">
                              <p className="h5">
                                {vehicleDetails?.bookedBy?.name}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <p>The booking details have not been generated yet.</p>
                      )}
                    </div>
                    {role === "admin" ||
                    role === "generalManager" ||
                    role === "branchManager" ||
                    role === "managingDirector" ? (
                      <Link
                        to={{
                          pathname: `/vehicle/update/${vehicleDetails?._id}`,
                          state: "booking",
                        }}
                      >
                        <Button className="px-5 btn-outline-warning" color="">
                          Update
                        </Button>{" "}
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card>
                <CardBody>
                  <p>Sold Details</p>

                  <div className="d-flex align-items-end justify-content-between">
                    <div>
                      {vehicleDetails?.isSold ? (
                        <>
                          <div className="d-flex p-1">
                            <div>Sold Date</div>
                            <div className="px-2">
                              <p className="h5">
                                {" "}
                                {moment(vehicleDetails?.soldDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex p-1">
                            <div>Sold Client</div>
                            <div className="px-2">
                              <p className="h5">
                                {vehicleDetails?.soldClient?.name}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex p-1">
                            <div>Sold By</div>
                            <div className="px-2">
                              <p className="h5">
                                {vehicleDetails?.soldBy?.name}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <p>The sold details have not been generated yet</p>
                      )}
                    </div>
                    {role === "admin" ||
                    role === "generalManager" ||
                    role === "branchManager" ||
                    role === "managingDirector" ? (
                      <Link
                        to={{
                          pathname: `/vehicle/update/${vehicleDetails?._id}`,
                          state: "sold",
                        }}
                      >
                        <Button className="px-5 btn-outline-warning" color="">
                          Update
                        </Button>{" "}
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {role !== "purchaseManager" && (
        <div className="px-3 mb-4 pb-4">
          <CardTitle className="mx-2">Leads</CardTitle>
          <Leads vehicleDetailsId={params?.id} />
        </div>
      )}
    </React.Fragment>
  )
}

VehicleDetails.propTypes = {
  user: PropTypes.object,
  match: PropTypes.any,
  onGetUserDetail: PropTypes.func,
}

export default VehicleDetails
