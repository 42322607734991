import {
  GET_EXELDOWNLOAD_SALE,
  GET_EXELDOWNLOAD_VEHICLE,
  GET_EXELDOWNLOAD_LEAD,
  GET_EXELDOWNLOAD_SALE_FAIL,
  GET_EXELDOWNLOAD_LEAD_FAIL,
  GET_EXELDOWNLOAD_VEHICLE_FAIL,
  GET_EXELDOWNLOAD_SALE_SUCCESS,
  GET_EXELDOWNLOAD_VEHICLE_SUCCESS,
  GET_EXELDOWNLOAD_LEAD_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  exeldownloadLead: [],
  exeldownloadSale: [],
  exeldownloadVehicle: [],
  error: {},
  loading: false,
}

const ExelDownload = (state = INIT_STATE, action) => {
  switch (action.type) {
    // ExelDownload - This line cannot be edited or removed
    case GET_EXELDOWNLOAD_SALE:
    case GET_EXELDOWNLOAD_VEHICLE:
    case GET_EXELDOWNLOAD_LEAD:
      return {
        ...state,
        loading: true,
      }

    case GET_EXELDOWNLOAD_SALE_FAIL:
    case GET_EXELDOWNLOAD_LEAD_FAIL:
    case GET_EXELDOWNLOAD_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_EXELDOWNLOAD_SALE_SUCCESS:
      return {
        ...state,
        exeldownloadSale: action.payload,
        error: {},
        loading: false,
      }

    case GET_EXELDOWNLOAD_VEHICLE_SUCCESS:
      return {
        ...state,
        exeldownloadVehicle: action.payload,
        error: {},
        loading: false,
      }

    case GET_EXELDOWNLOAD_LEAD_SUCCESS:
      return {
        ...state,
        exeldownloadLead: action.payload,
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default ExelDownload
