import {
  GET_MODELS,
  GET_MODELS_SUCCESS,
  GET_MODELS_FAIL,
  GET_MODEL_DETAILS,
  GET_MODEL_DETAILS_SUCCESS,
  GET_MODEL_DETAILS_FAIL,
  CREATE_MODEL,
  CREATE_MODEL_SUCCESS,
  CREATE_MODEL_FAIL,
  UPDATE_MODEL,
  UPDATE_MODEL_SUCCESS,
  UPDATE_MODEL_FAIL,
  DELETE_MODEL_SUCCESS,
  DELETE_MODEL_FAIL,
  DELETE_MODEL,
} from "./actionTypes"

// Model - This line cannot be edited or removed
export const getModels = (page, limit, searchText, brandId) => (
  console.log(brandId),
  {
    type: GET_MODELS,
    payload: { page, limit, searchText, brandId },
  }
)

export const getModelsSuccess = models => ({
  type: GET_MODELS_SUCCESS,
  payload: models,
})

export const getModelsFail = error => ({
  type: GET_MODELS_FAIL,
  payload: error,
})

export const getModelDetails = modelId => ({
  type: GET_MODEL_DETAILS,
  payload: modelId,
})

export const getModelDetailsSuccess = modelDetails => ({
  type: GET_MODEL_DETAILS_SUCCESS,
  payload: modelDetails,
})

export const getModelDetailsFail = error => ({
  type: GET_MODEL_DETAILS_FAIL,
  payload: error,
})

export const createModel = (model, onCloseClick) => ({
  type: CREATE_MODEL,
  payload: { model, onCloseClick },
})

export const createModelSuccess = model => ({
  type: CREATE_MODEL_SUCCESS,
  payload: model,
})

export const createModelFail = error => ({
  type: CREATE_MODEL_FAIL,
  payload: error,
})

export const getModelDetail = modelId => ({
  type: GET_MODEL_DETAILS,
  payload: modelId,
})

export const updateModel = (model, modelId, onCloseClick) => ({
  type: UPDATE_MODEL,
  payload: { model, modelId, onCloseClick },
})

export const updateModelSuccess = model => ({
  type: UPDATE_MODEL_SUCCESS,
  payload: model,
})

export const updateModelFail = error => ({
  type: UPDATE_MODEL_FAIL,
  payload: error,
})

export const deleteModel = (modelId, history) => ({
  type: DELETE_MODEL,
  payload: { modelId, history },
})

export const deleteModelSuccess = model => ({
  type: DELETE_MODEL_SUCCESS,
  payload: model,
})

export const deleteModelFail = error => ({
  type: DELETE_MODEL_FAIL,
  payload: error,
})
