import {
  GET_ALL_FOLLOWUP,
  GET_ALL_FOLLOWUP_SUCCESS,
  GET_ALL_FOLLOWUP_FAIL,
  GET_FOLLOWUP_DETAILS,
  GET_FOLLOWUP_DETAILS_SUCCESS,
  GET_FOLLOWUP_DETAILS_FAIL,
  CREATE_FOLLOWUP,
  CREATE_FOLLOWUP_SUCCESS,
  CREATE_FOLLOWUP_FAIL,
  UPDATE_FOLLOWUP,
  UPDATE_FOLLOWUP_SUCCESS,
  UPDATE_FOLLOWUP_FAIL,
  DELETE_FOLLOWUP,
  DELETE_FOLLOWUP_SUCCESS,
  DELETE_FOLLOWUP_FAIL,
} from "./actionTypes"

// Client - This line cannot be edited or removed
export const getAllFollowUp = (
  page,
  sort,
  limit,
  typeValue,
  StaffId,
  finalDateFormated,
  leadId,
  clientId
) => ({
  type: GET_ALL_FOLLOWUP,
  payload: {
    page,
    sort,
    limit,
    typeValue,
    StaffId,
    finalDateFormated,
    leadId,
    clientId,
  },
})

export const getAllFollowUpSuccess = followUps => ({
  type: GET_ALL_FOLLOWUP_SUCCESS,
  payload: followUps,
})

export const getAllFollowUpFail = error => ({
  type: GET_ALL_FOLLOWUP_FAIL,
  payload: error,
})

export const getFollowUpDetails = followUpId => ({
  type: GET_FOLLOWUP_DETAILS,
  payload: followUpId,
})

export const getFollowUpDetailsSuccess = followUpDetails => ({
  type: GET_FOLLOWUP_DETAILS_SUCCESS,
  payload: followUpDetails,
})

export const getFollowUpDetailsFail = error => ({
  type: GET_FOLLOWUP_DETAILS_FAIL,
  payload: error,
})

export const createFollowUp = (followUps, onCloseClick) => (
  console.log(followUps),
  {
    type: CREATE_FOLLOWUP,
    payload: { followUps, onCloseClick },
  }
)

export const createFollowUpSuccess = followUps => ({
  type: CREATE_FOLLOWUP_SUCCESS,
  payload: followUps,
})

export const createFollowUpFail = error => ({
  type: CREATE_FOLLOWUP_FAIL,
  payload: error,
})

export const updateFollowUp = (
  followUps,
  followUpId,
  onCloseClick,
  setSort
) => ({
  type: UPDATE_FOLLOWUP,
  payload: { followUps, followUpId, onCloseClick, setSort },
})

export const updateFollowUpSuccess = followUps => ({
  type: UPDATE_FOLLOWUP_SUCCESS,
  payload: followUps,
})

export const updateFollowUpFail = error => ({
  type: UPDATE_FOLLOWUP_FAIL,
  payload: error,
})

export const deleteFollowUp = followUpId => ({
  type: DELETE_FOLLOWUP,
  payload: { followUpId },
})

export const deleteFollowUpSuccess = followUps => ({
  type: DELETE_FOLLOWUP_SUCCESS,
  payload: followUps,
})

export const deleteFollowUpFail = error => ({
  type: DELETE_FOLLOWUP_FAIL,
  payload: error,
})
