import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

// client
import Client from "./client/reducer"
// lead
import Lead from "./lead/reducer"
// brand
import Brand from "./brand/reducer"
// model
import Model from "./model/reducer"
// vehicle
import Vehicle from "./vehicle/reducer"
// leadhistory
import LeadHistory from "./leadhistory/reducer"
// varient
import Varient from "./varient/reducer"
// sale
import Sale from "./sale/reducer"
// users
import Members from "./users/reducer"
// lead notes
import LeadNotes from "./leadnotes/reducer"

import ExelDownload from "./exeldownload/reducer"

import Categories from "./categories/reducer"

import FollowUp from "./followUp/reducer"

import MatchList from "./matchlist/reducer"

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  Client,
  Lead,
  Brand,
  Model,
  Vehicle,
  LeadHistory,
  Varient,
  Sale,
  Members,
  LeadNotes,
  ExelDownload,
  Categories,
  FollowUp,
  MatchList,
})

export default rootReducer
