import PropTypes from "prop-types"
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, Spinner } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, userForgetPassword } from "../../store/actions"

// import images
import MetaTag from "components/Common/Meta-tag"
import { LocalLogo, profile } from "assets/images"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"

const UserPasswordPage = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const [show, setShow] = useState(false)
  const [password, setPassword] = useState({
    pass: "",
    confirmPass: "",
  })

  // const { forgetError, forgetSuccessMsg, settings } = useSelector(state => ({
  //   forgetError: state.ForgetPassword.forgetError,
  //   forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  //   settings: state.Settings.settings,
  // }))

  function handleValidSubmit(event, values) {
    console.log(values)
    const user = {
      ...values,
    }
    dispatch(registerUser(user, params?.id, history))
  }
  const loading = false

  const settings = []

  const forgetError = "hello"
  const forgetSuccessMsg = "hello"

  return (
    // <React.Fragment>
    //   <MetaTag title="Forget Password" />
    //   <div className="home-btn d-none d-sm-block">
    //     <Link to="/" className="text-dark">
    //       <i className="fas fa-home h2" />
    //     </Link>
    //   </div>
    //   <div className="account-pages my-5 pt-sm-5">
    //     <Container>
    //       <Row className="justify-content-center">
    //         <Col md={8} lg={6} xl={5}>
    //           <Card className="overflow-hidden">
    //             <div className="bg-primary bg-softbg-soft-primary">
    //               <Row>
    //                 <Col xs={7}>
    //                   <div className="text-primary p-4">
    //                     <h5 className="text-primary">Welcome Back !</h5>
    //                     <p>Sign in to continue to Skote.</p>
    //                   </div>
    //                 </Col>
    //                 <Col className="col-5 align-self-end">
    //                   <img src={profile} alt="" className="img-fluid" />
    //                 </Col>
    //               </Row>
    //             </div>
    //             <CardBody className="pt-0">
    //               <div>
    //                 <Link to="/">
    //                   <div className="avatar-md profile-user-wid mb-4">
    //                     <span className="avatar-title rounded-circle bg-light">
    //                       <img
    //                         src={settings?.company?.logo[0]?.url ?? LocalLogo}
    //                         alt=""
    //                         className="rounded-circle"
    //                         height="70"
    //                       />
    //                     </span>
    //                   </div>
    //                 </Link>
    //               </div>
    //               <div className="p-2">
    //                 {forgetError && forgetError ? (
    //                   <Alert color="danger" style={{ marginTop: "13px" }}>
    //                     {forgetError}
    //                   </Alert>
    //                 ) : null}
    //                 {forgetSuccessMsg ? (
    //                   <Alert color="success" style={{ marginTop: "13px" }}>
    //                     {forgetSuccessMsg}
    //                   </Alert>
    //                 ) : null}

    //                 <AvForm
    //                   className="form-horizontal"
    //                   onValidSubmit={(e, v) => handleValidSubmit(e, v)}
    //                 >
    //                   <div className="mb-3">
    //                     <AvField
    //                       name="email"
    //                       label="Email"
    //                       className="form-control"
    //                       placeholder="Enter email"
    //                       type="email"
    //                       required
    //                     />
    //                   </div>
    //                   <Row className="mb-3">
    //                     <Col className="text-end">
    //                       <button
    //                         className="btn btn-primary w-md "
    //                         type="submit"
    //                       >
    //                         Reset
    //                       </button>
    //                     </Col>
    //                   </Row>
    //                 </AvForm>
    //               </div>
    //             </CardBody>
    //           </Card>
    //           <div className="mt-5 text-center">
    //             <p>
    //               Go back to{" "}
    //               <Link to="login" className="font-weight-medium text-primary">
    //                 Login
    //               </Link>{" "}
    //             </p>
    //             <p>
    //               © {new Date().getFullYear()} {settings?.company?.name}
    //               <i className="mdi mdi-heart text-danger" />
    //             </p>
    //           </div>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    // </React.Fragment>

    <React.Fragment>
      <MetaTag title="Login" />

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            {/* <Col md={8} lg={6} xl={8}> */}
            {/* <Card
            className="overflow-hidden new-login-screen px-0 w-75"
            style={{ height: "70vh", borderRadius: "5px" }}
          > */}
            <Card className="login-old">
              <CardBody className="p-0 d-flex w-100 align-items-center justify-content-center">
                {/* <div
                className="w-50 h-100 login-background"
                style={{ backgroundColor: "#41655B", position: "relative" }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={loginBg}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    alt="login-bg"
                  />
                </div>
              </div> */}
                <div className="p-4 w-100 sm:w-100">
                  <Link to="/" className="auth-logo-light">
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="avatar-title py-3"
                        style={{
                          backgroundColor: "transparent",
                          justifyContent: "flex-start",
                          marginLeft: "",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <img
                          src={settings?.company?.logo[0]?.url ?? LocalLogo}
                          alt="logo"
                          height="80"
                        />
                      </span>
                    </div>
                  </Link>
                  <p>Create New Passowrd to login ...</p>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {/* <Card
                    className={`border card border-info ${
                      isStoreAdminLogin ? "bg-info text-white" : ""
                    }`}
                  >
                    <CardBody className="d-flex align-items-center p-2 justify-content-between">
                      <div>
                        <label
                          className="mb-0 pb-0"
                          htmlFor="storeAdminRadio"
                        >
                          <MdOutlineStorefront
                            className="me-1 color-info"
                            size={"15px"}
                          />
                          Log in as a store administrator
                        </label>
                      </div>

                      <AvField
                        id="storeAdminRadio"
                        name="isStoreAdmin"
                        label=""
                        placeholder=""
                        type="radio"
                        style={{
                          height: "17px",
                          width: "17px",
                        }}
                        className="m-0 "
                        onChange={e => setIsStoreAdminLogin(e.target.checked)}
                        checked={isStoreAdminLogin}
                      />
                    </CardBody>
                  </Card> */}

                    {/* {error?.message ? (
                      <Alert color="danger">{error.message}</Alert>
                    ) : null} */}

                    <div className="mb-3">
                      <AvField
                        name="newPassword"
                        label="Password"
                        className="form-control"
                        placeholder="Enter password"
                        type={show ? "text" : "password"}
                        onChange={e =>
                          setPassword({
                            ...password,
                            pass: e.target.value,
                          })
                        }
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Password is Required",
                          },
                          minLength: {
                            value: 4,
                            errorMessage: "Minimum four characters",
                          },
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="confirmPassword"
                        label="Confirm                   Password"
                        type={show ? "text" : "password"}
                        placeholder="Confirm Password"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Confirm Password is Required",
                          },
                          pattern: {
                            value:
                              password?.pass !== password?.confirmPass
                                ? "^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,10}$"
                                : "",
                            errorMessage: "Password does not match",
                          },
                        }}
                        onChange={e =>
                          setPassword({
                            ...password,
                            confirmPass: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                        onChange={e => setShow(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Show Password
                      </label>
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                        style={{
                          backgroundColor: "#f8971d",
                          color: "white",
                        }}
                        className="btn  btn-block"
                        type="submit"
                      >
                        {loading ? (
                          <>
                            <Spinner color="white" />
                          </>
                        ) : (
                          "Create Passoword"
                        )}
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <Link to="/login" className="text-primary">
                        <i className="mdi mdi-lock me-1" />
                        Login
                      </Link>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            {/* </Card> */}
            <div className="mt-5 text-center">
              <p>
                © {new Date().getFullYear()} {}. Crafted with{" "}
                <i className="mdi mdi-heart text-danger" /> by
                <a target="blank" href="https://osperb.com">
                  {` osperb`}
                </a>
              </p>
            </div>
            {/* </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default UserPasswordPage
