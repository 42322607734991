import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"
import { CSVLink } from "react-csv"

function ExcelDownload({ headers, excelDownloadData, title }) {
  // const headers = [
  //   { label: "_id", key: "_id" },
  //   { label: "itemCode", key: "itemCode" },
  //   { label: "name", key: "name" },
  //   { label: "cost", key: "cost" },
  //   { label: "price", key: "price" },
  //   { label: "offerPrice", key: "offerPrice" },
  //   { label: "stock", key: "stock" },
  // ]

  // const excelDownloadProducts = map(
  //   productsForDownload?.products,
  //   (item, key) => ({
  //     ...item,
  //     _id: item._id,
  //     itemCode: item.itemCode,
  //     name: item?.primaryLang?.name,
  //     cost: item.cost,
  //     price: item.price,
  //     offerPrice: item.offerPrice,
  //     stock: item.stock,
  //   })
  // )

  return (
    <Button
      data-tip
      data-for="downloadTip"
      style={{ width: "100%", border: "none" }}
      className="w-md btn mx-4 btn-warning mt-4"
    >
      <CSVLink
        data={excelDownloadData || []}
        headers={headers}
        filename={`${title}.csv`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <i
          className="bx bx-download mx-1"
          style={{
            fontSize: "18px",
            color: "white",
          }}
        />{" "}
        <span className="text-white"> Download Excel</span>
      </CSVLink>
    </Button>
  )
}

export default ExcelDownload

ExcelDownload.propTypes = {
  headers: PropTypes.any,
  excelDownloadData: PropTypes.any,
  title: PropTypes.string,
}
