import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce, update } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  CardTitle,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import {
  createClient,
  createMember,
  updateClient,
  updateMember,
} from "store/actions"
import Select from "react-select"

const UserModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()
  const [role, setRole] = useState("select role")
  const [memberRole, setMemberRole] = useState("Select Member Roles...")
  const [memberRoleValue, setMemberValue] = useState("Select Member Roles...")

  const handleValidSubmit = (e, v) => {
    const userUpdate = {
      ...v,
      role: role ? role : "",
      isPrime: false,
      memberRole: memberRoleValue ? memberRoleValue : "",
    }
    if (updateData?._id) {
      dispatch(updateMember(userUpdate, updateData?._id, ""))
      onCloseClick()
    } else {
      const createUser = {
        ...v,
        role: role ? role : "",
        memberRole: memberRoleValue ? memberRoleValue : "",
      }
      dispatch(createMember(createUser, onCloseClick))
      onCloseClick()
    }
  }

  useEffect(() => {
    setRole(updateData?.role)
    setMemberRole(updateData?.memberRole)
  }, [updateData?.role])

  console.log(role)

  console.log(updateData)

  const handlerRoleFinalValue = event => {
    setRole(event?.value)
  }

  const handlerRoleFinalValues = event => {
    setMemberRole(event?.label)
    setMemberValue(event?.value)
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>{updateData?._id ? "Edit User" : "Add New User"}</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <>
              <Col className="col-6 mb-2">
                <AvField
                  name="name"
                  type="text"
                  placeholder="Name *"
                  value={updateData?.name}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "name is required",
                    },
                  }}
                />
              </Col>
              <Col className="col-6 mb-2">
                <AvField
                  name="phone"
                  type="text"
                  placeholder="Phone *"
                  value={updateData?.phone}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "phone is required",
                    },
                  }}
                />
              </Col>
              <Col className="col-12 mb-2">
                <AvField
                  name="email"
                  type="mail"
                  placeholder="Email *"
                  value={updateData?.email}
                  validate={{
                    required: {
                      value: false,
                      errorMessage: "mail is required",
                    },
                  }}
                />
              </Col>
            </>
          </Row>
          <Row>
            <Col md="12">
              <label>Role</label>
              <Select
                // onInputChange={handleProductEnters}
                value={role}
                placeholder={role}
                onChange={handlerRoleFinalValue}
                options={optionsRole}
                classNamePrefix="select2-selection"
                // isLoading={prodLoading}
              />
            </Col>
            {role === "member" && (
              <div className="py-2">
                <label>Member Role</label>
                <Select
                  // onInputChange={handleProductEnters}
                  value={memberRole}
                  placeholder={memberRole}
                  onChange={handlerRoleFinalValues}
                  options={optionsRoleMember}
                  classNamePrefix="select2-selection"
                  // isLoading={prodLoading}
                />
              </div>
            )}
          </Row>

          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              {role || memberRole ? (
                <Button type="submit" className="btn ms-2" color="success">
                  {updateData?._id ? "Save" : "Create"}
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success ms-2" disabled>
                  {updateData?._id ? "Save" : "Create"}
                </Button>
              )}
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

UserModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default UserModal

const optionsRole = [
  { label: "Admin", value: "admin" },
  { label: "Client", value: "client" },
  { label: "Member", value: "member" },
]

const optionsRoleMember = [
  { label: "Managing Director", value: "managingDirector" },
  { label: "General Manager", value: "generalManager" },
  { label: "Enquiry Manager", value: "enquiryManager" },
  { label: "Enquiry Analyst", value: "enquiryAnalyst" },
  { label: "Sales Manager", value: "salesManager" },
  { label: "Sales Executive", value: "salesExecutive" },
]
