import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_LEADS,
  GET_LEAD_DETAILS,
  CREATE_LEAD,
  UPDATE_LEAD,
  DELETE_LEAD,
  GET_LEAD_STATUS_COUNT,
} from "./actionTypes"
import {
  getLeadsFail,
  getLeadsSuccess,
  getLeadDetailsSuccess,
  getLeadDetailsFail,
  createLeadFail,
  createLeadSuccess,
  updateLeadSuccess,
  updateLeadFail,
  deleteLeadSuccess,
  deleteLeadFail,
  getLeadStatusCountSuccess,
  getLeadStatusCountFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"
import {
  getLeadHistoryByLead,
  getLeadHistoryByLeadFail,
  getLeadHistoryByLeadSuccess,
  getLeadHistorys,
} from "store/actions"

// Lead - This line cannot be edited or removed
function getLeadsAPi({
  page,
  sort,
  limit,
  searchText,
  vehicleId,
  typeValue,
  clientId,
  medium,
  sortByDate,
  StaffId,
}) {
  console.log("page", page, "saga")

  return get(
    `/lead/admin/all/?sort=${sort ? sort : ""}&leadType=${
      typeValue ? typeValue : ""
    }&vehicle=${vehicleId ? vehicleId : ""}&page=${page}&limit=${
      limit ? limit : 10
    }&search=${searchText ? searchText : ""}&client=${
      clientId ? clientId : ""
    }&medium=${medium ? medium : ""}&from=${
      sortByDate.from ? sortByDate?.from : ""
    }&to=${sortByDate.to ? sortByDate.to : ""}&assignee=${
      StaffId ? StaffId : ""
    }`
  )
}

const getLeadDetailsAPi = leadId => {
  return get(`/lead/admin/${leadId}`)
}

const createLeadApi = ({ lead }) => {
  return post("/lead/admin/new", lead)
}

const updateLeadApi = ({ lead, leadId, isStatus }) => {
  if (isStatus) {
    return ApiPut(`/lead/admin/status/${leadId}`, lead)
  }
  return ApiPut(`/lead/admin/${leadId}`, lead)
}

const deleteLeadApi = leadId => {
  console.log("leadId =>", leadId)
  return del(`/lead/admin/${leadId}`)
}

const getLeadStatusCountAPi = () => {
  return get(`/lead/status-count`)
}

// const getLeadHistoryByLeadAPi = ({ leadId, limit }) => {
//   return get(`/leadhistory/admin/${leadId}?limit=${limit ? limit : 10}`)
// }

function* fetchLeads({ payload }) {
  try {
    const response = yield call(getLeadsAPi, payload)
    yield put(getLeadsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getLeadsFail(error))
  }
}

function* fetchLeadDetails({ payload: leadId }) {
  try {
    const response = yield call(getLeadDetailsAPi, leadId)
    yield put(getLeadDetailsSuccess(response))
  } catch (error) {
    yield put(getLeadDetailsFail(error))
  }
}

function* onCreateLead({ payload }) {
  console.log(payload)
  try {
    const response = yield call(createLeadApi, payload)
    yield put(createLeadSuccess(response))
    payload?.history?.push(`/lead-details/${response?._id}`)

    doneNotification("Lead Created Successfully!")
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createLeadFail(error))
  }
}

function* onUpdateLead({ payload }) {
  try {
    const response = yield call(updateLeadApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Lead Updated Successfully!",
        title: "",
      })
      yield put(updateLeadSuccess(response))
      // try {
      //   const response2 = yield call(getLeadHistoryByLeadAPi, response?._id)
      //   yield put(getLeadHistoryByLeadSuccess(response2))
      // } catch (error) {
      //   yield put(getLeadHistoryByLeadFail(error))
      // }
      if (payload.history) {
        payload.history.goBack()
      }
      if (payload.onClose) {
        payload.onClose()
      }
    }
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateLeadFail(error?.response?.data))
  }
}

function* onDeleteLead({ payload }) {
  console.log("payload =>", payload)
  try {
    const response = yield call(deleteLeadApi, payload?.leadId)
    yield put(deleteLeadSuccess(response))
    Notification({
      type: "success",
      message: "Lead Deleted Successfully!",
      title: "",
    })
    payload.history.push("/lead/all")
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteLeadFail(error?.response?.data))
  }
}

function* fetchLeadStatusCount({ payload: leadId }) {
  try {
    const response = yield call(getLeadStatusCountAPi, leadId)
    yield put(getLeadStatusCountSuccess(response))
  } catch (error) {
    yield put(getLeadStatusCountFail(error))
  }
}

function* leadSaga() {
  yield takeEvery(GET_LEADS, fetchLeads)
  yield takeEvery(GET_LEAD_DETAILS, fetchLeadDetails)
  yield takeEvery(CREATE_LEAD, onCreateLead)
  yield takeEvery(UPDATE_LEAD, onUpdateLead)
  yield takeEvery(DELETE_LEAD, onDeleteLead)
  yield takeEvery(GET_LEAD_STATUS_COUNT, fetchLeadStatusCount)
}

export default leadSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
