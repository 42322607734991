import PropTypes from "prop-types"
import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Spinner,
  Button,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import MetaTag from "components/Common/Meta-tag"
import { LocalLogo, profile } from "assets/images"

const ForgetPasswordPage = props => {
  const dispatch = useDispatch()

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  function handleValidSubmit(v) {
    console.log("v=>", v)
    dispatch(userForgetPassword(v, props.history))
  }
  const error = []
  const loading = false

  return (
    <React.Fragment>
      <MetaTag title="Login" />

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            {/* <Col md={8} lg={6} xl={8}> */}
            {/* <Card
            className="overflow-hidden new-login-screen px-0 w-75"
            style={{ height: "70vh", borderRadius: "5px" }}
          > */}
            <Card className="login-old">
              <CardBody className="p-0 d-flex w-100 align-items-center justify-content-center">
                {/* <div
                className="w-50 h-100 login-background"
                style={{ backgroundColor: "#41655B", position: "relative" }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={loginBg}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    alt="login-bg"
                  />
                </div>
              </div> */}
                <div className="p-4 w-100 sm:w-100">
                  <Link to="/" className="auth-logo-light">
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="avatar-title py-3"
                        style={{
                          backgroundColor: "transparent",
                          justifyContent: "flex-start",
                          marginLeft: "",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <img src={LocalLogo} alt="logo" height="70" />
                      </span>
                    </div>
                  </Link>
                  {/* <h5>Welcome Back !</h5> */}
                  <p>Reset your password and continue...!</p>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(v)
                    }}
                  >
                    {/* <Card
                    className={`border card border-info ${
                      isStoreAdminLogin ? "bg-info text-white" : ""
                    }`}
                  >
                    <CardBody className="d-flex align-items-center p-2 justify-content-between">
                      <div>
                        <label
                          className="mb-0 pb-0"
                          htmlFor="storeAdminRadio"
                        >
                          <MdOutlineStorefront
                            className="me-1 color-info"
                            size={"15px"}
                          />
                          Log in as a store administrator
                        </label>
                      </div>

                      <AvField
                        id="storeAdminRadio"
                        name="isStoreAdmin"
                        label=""
                        placeholder=""
                        type="radio"
                        style={{
                          height: "17px",
                          width: "17px",
                        }}
                        className="m-0 "
                        onChange={e => setIsStoreAdminLogin(e.target.checked)}
                        checked={isStoreAdminLogin}
                      />
                    </CardBody>
                  </Card> */}

                    {error?.message ? (
                      <Alert color="danger">{error.message}</Alert>
                    ) : null}

                    <div className="mb-3 p-0">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter Email"
                        type="text"
                        required
                      />
                    </div>

                    <div className="mt-3 d-flex align-items-center justify-content-end">
                      <Button
                        className="px-4"
                        style={{
                          backgroundColor: "#f8971d",
                          color: "white",
                          border: "none",
                        }}
                        size="md"
                        type="submit"
                      >
                        {loading ? (
                          <>
                            <Spinner color="white" />
                          </>
                        ) : (
                          "Reset"
                        )}
                      </Button>
                    </div>

                    <div className="mt-4 text-center">
                      <i className="mdi mdi-lock me-1" />
                      Allready have an account..! &nbsp;
                      <Link to="/login" className="text-primary">
                        Login..
                      </Link>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            {/* </Card> */}
            <div className="mt-5 text-center">
              <p>
                © {new Date().getFullYear()} {}. Crafted with{" "}
                <i className="mdi mdi-heart text-danger" /> by
                <a target="blank" href="https://osperb.com">
                  {` osperb`}
                </a>
              </p>
            </div>
            {/* </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
