import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { Button, CardTitle, Col, Modal, ModalBody, Row } from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import { createModel, getBrands, updateModel } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { Label } from "recharts"

function FormModal({ show, onCloseClick, updateData }) {
  const dispatch = useDispatch()
  const [brand, setBrand] = useState("Select Brands...")
  const [brandId, setBrandId] = useState("")
  const [brandSearch, setBrandSearch] = useState("")

  const { loading, allBrands } = useSelector(state => ({
    allBrands: state?.Brand?.brands?.brands,
    loading: state?.Brand?.loading,
  }))

  const handleEnter = textEntered => {
    setBrandSearch(textEntered)
  }

  function handlerFinalValues(event) {
    setBrand(event.label)
    setBrandId(event.value)
  }
  const brandOptions = [
    {
      options: allBrands?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getBrands("", "", "", brandSearch))
  }, [dispatch, brandSearch])

  const handleValidSubmit = (e, v) => {
    if (updateData?._id) {
      const updateDatas = {
        ...v,
        brand: brandId,
      }
      dispatch(updateModel(updateDatas, updateData?._id, onCloseClick))
    } else {
      const createDatas = {
        ...v,
        brand: brandId,
      }
      dispatch(createModel(createDatas, onCloseClick))
    }
  }

  console.log(updateData)

  useEffect(() => {
    setBrand(updateData?.brand?.name)
    setBrandId(updateData?.brand?._id)
  }, [updateData])

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={{ minWidth: "500px" }}
    >
      <ModalBody className="p-4">
        <CardTitle>{updateData?._id ? "Edit Model" : "Add Model"}</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col className=" mb-2">
              <label>
                Brand <span className="text-danger">*</span>
              </label>
              <Select
                onInputChange={handleEnter}
                value={brand}
                placeholder={brand}
                onChange={handlerFinalValues}
                options={brandOptions}
                classNamePrefix="select2-selection"
                isLoading={loading}
              />
            </Col>
            <Col className="col-12  mb-2">
              <lebel>
                Name
                <span className="text-danger">*</span>
              </lebel>
              <AvField
                name="name"
                type="text"
                value={updateData?.name ?? ""}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter a name",
                  },
                }}
              />
            </Col>{" "}
            <Col className="col-12 mb-2">
              <AvField
                label="Description"
                name="description"
                type="textarea"
                value={updateData?.description ?? ""}
              />
            </Col>{" "}
          </Row>

          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-light ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              {brandId ? (
                <Button type="submit" color="warning" className="btn ms-2">
                  {updateData?._id ? "Save" : "Create"}
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="btn ms-2"
                  color="warning"
                  disabled
                >
                  {updateData?._id ? "Save" : "Create"}
                </Button>
              )}
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export default FormModal

FormModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

const options = [
  { label: "Rashid", value: "rashid" },
  { label: "Rashid", value: "rashid" },
  { label: "Rashid", value: "rashid" },
  { label: "Rashid", value: "rashid" },
  { label: "Rashid", value: "rashid" },
  { label: "Rashid", value: "rashid" },
]
