import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_VEHICLES,
  GET_VEHICLE_DETAILS,
  CREATE_VEHICLE,
  UPDATE_VEHICLE,
  DELETE_VEHICLE,
  CHECK_VEHICLE_NUMBER,
} from "./actionTypes"
import {
  getVehiclesFail,
  getVehiclesSuccess,
  getVehicleDetailsSuccess,
  getVehicleDetailsFail,
  createVehicleFail,
  createVehicleSuccess,
  updateVehicleSuccess,
  updateVehicleFail,
  deleteVehicleSuccess,
  deleteVehicleFail,
  checkVehicleNumberSuccess,
  checkVehicleNumberFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Vehicle - This line cannot be edited or removed
function getVehiclesAPi({
  page,
  limit,
  searchText,
  brandId,
  modelId,
  varientId,
  oilTypeValue,
  ownerShipStatusValue,
  sort,
  soldClient,
  sortByDate,
  bool,
}) {
  console.log(searchText)
  return get(
    `/vehicle/all/?brand=${brandId ? brandId : ""}&variant=${
      varientId && varientId
    }&model=${modelId ? modelId : ""}&fuelType=${
      oilTypeValue ? oilTypeValue : ""
    }&ownershipStatus=${
      ownerShipStatusValue ? ownerShipStatusValue : ""
    }&page=${page ? page : 1}&limit=${limit ? limit : 10}&search=${
      searchText && searchText
    }&sort=${sort ? sort : ""}&soldClient=${
      soldClient ? soldClient : ""
    }&from=${sortByDate.from ? sortByDate?.from : ""}&to=${
      sortByDate.to ? sortByDate?.to : ""
    }&isSold=${bool === false ? bool : ""}`
  )
}

const getVehicleDetailsAPi = vehicleId => {
  return get(`/vehicle/${vehicleId}`)
}

const createVehicleApi = ({ vehicle }) => {
  return post("/vehicle/admin/new", vehicle)
}

const updateVehicleApi = ({ vehicle, vehicleId }) => {
  return ApiPut(`/vehicle/admin/${vehicleId}`, vehicle)
}

const deleteVehicleApi = ({ vehicleId }) => {
  return del(`/vehicle/admin/${vehicleId}`)
}

const checkVehicleNumberApi = ({ vehicleNumber, vehicleId }) => {
  console.log(vehicleNumber)
  if (vehicleId) {
    return post(`/vehicle/check/name/${vehicleId}`, vehicleNumber)
  } else {
    return post(`/vehicle/check/name`, vehicleNumber)
  }
}

function* fetchVehicles({ payload }) {
  try {
    const response = yield call(getVehiclesAPi, payload)
    yield put(getVehiclesSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getVehiclesFail(error))
  }
}

function* fetchVehicleDetails({ payload: vehicleId }) {
  try {
    const response = yield call(getVehicleDetailsAPi, vehicleId)
    yield put(getVehicleDetailsSuccess(response))
  } catch (error) {
    yield put(getVehicleDetailsFail(error))
  }
}

function* onCreateVehicle({ payload }) {
  try {
    const response = yield call(createVehicleApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createVehicleSuccess(response))
      payload?.history?.push("/vehicle/all")
      doneNotification("Vehicle Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createVehicleFail(error))
  }
}

function* onUpdateVehicle({ payload }) {
  try {
    const response = yield call(updateVehicleApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Vehicle Updated Successfully!",
        title: "",
      })
      yield put(updateVehicleSuccess(response))
      if (payload.history) {
        payload.history.goBack()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateVehicleFail(error.response.data))
  }
}

function* onDeleteVehicle({ payload }) {
  try {
    const response = yield call(deleteVehicleApi, payload)
    yield put(deleteVehicleSuccess(response))
    Notification({
      type: "success",
      message: "Vehicle Deleted Successfully!",
      title: "",
    })
    payload.history.push("/vehicle/all")
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteVehicleFail(error?.response?.data))
  }
}

function* onCheckVehicleNumber({ payload }) {
  console.log(payload)
  try {
    const response = yield call(checkVehicleNumberApi, payload)
    yield put(checkVehicleNumberSuccess(response))
  } catch (error) {
    yield put(checkVehicleNumberFail(error))
  }
}

function* vehicleSaga() {
  yield takeEvery(GET_VEHICLES, fetchVehicles)
  yield takeEvery(GET_VEHICLE_DETAILS, fetchVehicleDetails)
  yield takeEvery(CREATE_VEHICLE, onCreateVehicle)
  yield takeEvery(UPDATE_VEHICLE, onUpdateVehicle)
  yield takeEvery(DELETE_VEHICLE, onDeleteVehicle)
  yield takeEvery(CHECK_VEHICLE_NUMBER, onCheckVehicleNumber)
}

export default vehicleSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
