import {
  GET_VEHICLES,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  GET_VEHICLE_DETAILS,
  GET_VEHICLE_DETAILS_SUCCESS,
  GET_VEHICLE_DETAILS_FAIL,
  CREATE_VEHICLE,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAIL,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  DELETE_VEHICLE,
  RESET_STATE,
  CHECK_VEHICLE_NUMBER,
  CHECK_VEHICLE_NUMBER_SUCCESS,
  CHECK_VEHICLE_NUMBER_FAIL,
} from "./actionTypes"

// Vehicle - This line cannot be edited or removed
export const getVehicles = (
  page,
  limit,
  searchText,
  brandId,
  modelId,
  varientId,
  oilTypeValue,
  ownerShipStatusValue,
  sort,
  soldClient,
  sortByDate,
  bool
) => (
  console.log(bool),
  {
    type: GET_VEHICLES,
    payload: {
      page,
      limit,
      searchText,
      brandId,
      modelId,
      varientId,
      oilTypeValue,
      ownerShipStatusValue,
      sort,
      soldClient,
      sortByDate,
      bool,
    },
  }
)

export const getVehiclesSuccess = vehicles => ({
  type: GET_VEHICLES_SUCCESS,
  payload: vehicles,
})

export const getVehiclesFail = error => ({
  type: GET_VEHICLES_FAIL,
  payload: error,
})

export const getVehicleDetails = vehicleId => ({
  type: GET_VEHICLE_DETAILS,
  payload: vehicleId,
})

export const getVehicleDetailsSuccess = vehicleDetails => ({
  type: GET_VEHICLE_DETAILS_SUCCESS,
  payload: vehicleDetails,
})

export const getVehicleDetailsFail = error => ({
  type: GET_VEHICLE_DETAILS_FAIL,
  payload: error,
})

export const createVehicle = (vehicle, history) => ({
  type: CREATE_VEHICLE,
  payload: { vehicle, history },
})

export const createVehicleSuccess = vehicle => ({
  type: CREATE_VEHICLE_SUCCESS,
  payload: vehicle,
})

export const createVehicleFail = error => ({
  type: CREATE_VEHICLE_FAIL,
  payload: error,
})

export const getVehicleDetail = vehicleId => ({
  type: GET_VEHICLE_DETAILS,
  payload: vehicleId,
})

export const updateVehicle = (vehicle, vehicleId, history) => ({
  type: UPDATE_VEHICLE,
  payload: { vehicle, vehicleId, history },
})

export const updateVehicleSuccess = vehicle => ({
  type: UPDATE_VEHICLE_SUCCESS,
  payload: vehicle,
})

export const updateVehicleFail = error => ({
  type: UPDATE_VEHICLE_FAIL,
  payload: error,
})

export const deleteVehicle = (vehicleId, history) => ({
  type: DELETE_VEHICLE,
  payload: { vehicleId, history },
})

export const deleteVehicleSuccess = vehicle => ({
  type: DELETE_VEHICLE_SUCCESS,
  payload: vehicle,
})

export const deleteVehicleFail = error => ({
  type: DELETE_VEHICLE_FAIL,
  payload: error,
})

export const checkVehicleNumber = (vehicleNumber, vehicleId) => ({
  type: CHECK_VEHICLE_NUMBER,
  payload: { vehicleNumber, vehicleId },
})

export const checkVehicleNumberSuccess = vehicleNumber => ({
  type: CHECK_VEHICLE_NUMBER_SUCCESS,
  payload: { vehicleNumber },
})

export const checkVehicleNumberFail = error => ({
  type: CHECK_VEHICLE_NUMBER_FAIL,
  payload: error,
})
export const resetState = () => ({
  type: RESET_STATE,
})
