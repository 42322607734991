import moment from "moment"
import React, { useEffect } from "react"
import { FaRegUser } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { getMatchListDetails } from "store/actions"

function Details() {
  const dispatch = useDispatch()
  const params = useParams()
  const { Details } = useSelector(state => ({
    Details: state.MatchList.matchlistDetails,
  }))

  useEffect(() => {
    dispatch(getMatchListDetails(params?.id))
  }, [dispatch, params?.id])

  const handleOwnershipStatus = ownership => {
    switch (ownership) {
      case "first_owner":
        return "First Owner"
      case "second_owner":
        return "Second Owner"
      case "third_owner":
        return "Third Owner"
      default:
        return ""
    }
  }

  const handleChange = type => {
    if (type === "vehicleMatch") {
      return "Vehicle Match"
    } else {
      return "Sell Match"
    }
  }

  const handleChangeMatch = type => {
    if (type === "dream_car") {
      return "Dream Car"
    } else if (type === "sell") {
      return "Sell"
    } else {
      return "Buy"
    }
  }

  console.log(Details)

  return (
    <Row>
      <Col md={8}>
        <Card>
          <CardBody>
            <div className="d-flex align-items-start justify-content-between">
              <div className="w-50">
                <p className="m-0 mb-1">
                  {moment(Details?.createdAt).format("DD-MM-YYYY h:mm a")}
                </p>
                <Badge
                  className={"my-1 font-size-13 badge-soft-success"}
                  color="black"
                  pill
                >
                  {handleChangeMatch(Details?.lead?.leadType)}
                </Badge>
                <h3 className="d-flex align-items-center">
                  <p className="font-size-16 mb-0">
                    {Details?.lead?.client?.name}{" "}
                  </p>
                </h3>
                {Details?.lead?.client?.email && (
                  <p className="mb-1 d-flex align-items-center">
                    <i className="bx bx-mail-send text-primary pe-2" />
                    {Details?.lead?.client?.email}
                  </p>
                )}
                {Details?.lead?.client?.phone && (
                  <p className="d-flex align-items-center mb-1">
                    <i className="bx bxs-phone text-secondary pe-2" />
                    {Details?.lead?.client?.phone}
                  </p>
                )}
                {Details?.lead?.client?.whatsappNumber && (
                  <p className="d-flex align-items-center mb-1">
                    <i className="bx bxl-whatsapp text-success pe-2" />

                    {Details?.lead?.client?.whatsappNumber}
                  </p>
                )}
                {/* <div>
                  <p>
                    Created :{" "}
                    {Details?.createdBy && (
                      <span>
                        {Details?.createdBy?.name} (
                        {Details?.createdBy?.memberRole
                          ? Details?.createdBy?.memberRole
                          : Details?.createdBy?.role}
                        )
                      </span>
                    )}
                  </p>
                </div> */}
              </div>

              <div className="w-50">
                {Details?.lead?.message && (
                  <p className="mb-1 pt-2">
                    Message :&nbsp; {Details?.message}
                  </p>
                )}
                {Details?.lead?.remarks && (
                  <p className="m-0">Remarks :&nbsp; {Details?.remarks}</p>
                )}
              </div>
            </div>
            <hr />
          </CardBody>
        </Card>
      </Col>
      <Col md={4}>
        <Card className="m-0 mb-4">
          {Details?.vehicle ? (
            <CardBody>
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <h5>Vehicle Details :</h5>
                  <p className="mb-2 ">{Details?.vehicle?.name}</p>
                  <p className="mb-2">{Details?.vehicle?.brand?.name}</p>
                  <p className="mb-2">{Details?.vehicle?.model?.name}</p>
                  <p className="mb-2">{Details?.vehicle?.oilType}</p>
                  <p className="mb-2">{Details?.vehicle?.variant?.name}</p>
                  <p className="mb-2">
                    {handleOwnershipStatus(Details?.vehicle?.ownershipStatus)}
                  </p>
                </div>

                {Details?.vehicle?.images ? (
                  <div className="mb-4">
                    <img
                      height={150}
                      width={150}
                      src={Details?.vehicle?.images[0]?.url}
                      style={{
                        objectFit: "contain",
                        borderRadius: 5,
                        height: "auto",
                      }}
                      alt="Vehicle Image"
                    />
                  </div>
                ) : (
                  <div className="m-0 p-0">No Image Available</div>
                )}
              </div>
            </CardBody>
          ) : (
            <div style={{ height: "100%" }}>
              <CardBody>
                <div className="d-flex justify-content-between align-items-start">
                  <div>
                    <h5>Vehicle Details :</h5>
                    {/* <p className="mb-2 ">{Details?.vehicle?.name}</p> */}
                    <p className="mb-2">{Details?.brand?.name}</p>
                    <p className="mb-2">{Details?.model?.name}</p>
                    <p className="mb-2">{Details?.variant?.name}</p>
                  </div>

                  {Details?.vehicle?.images ? (
                    <div className="mb-4">
                      <img
                        height={150}
                        width={150}
                        src={Details?.vehicle?.images[0]?.url}
                        style={{
                          objectFit: "contain",
                          borderRadius: 5,
                          height: "auto",
                        }}
                        alt="Vehicle Image"
                      />
                    </div>
                  ) : (
                    <div className="m-0 p-0">No Image Available</div>
                  )}
                </div>
              </CardBody>
            </div>
          )}
        </Card>
      </Col>
      <span className="fs-5 p-3">Matching Lists</span>
      <div className="d-flex flex-wrap">
        {Details?.matches?.map(item => (
          <>
            {/* <Row>
              <Col md={12}> */}
            <Card key={item.id} className="mx-2">
              <CardBody>
                <Badge
                  className="font-size-12 badge-soft-success mx-5 my-2"
                  color=""
                  pill
                  style={{ textTransform: "capitalize" }}
                >
                  {handleChange(item?.type)}
                </Badge>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <FaRegUser size={20} className="mx-3" />
                    <div>
                      <Link to={`/lead-details/${item?.lead?._id}`}>
                        <h5>{item?.lead?.client?.name}</h5>
                      </Link>
                      <p className="mb-1">{item?.lead?.client?.phone}</p>
                      <Link to={`/vehicle/details/${item?.vehicle?._id}`}>
                        <p className="mb-1">{item?.vehicle?.name}</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            {/* </Col>
            </Row> */}
          </>
        ))}
      </div>
    </Row>
  )
}

export default Details
