import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import "toastr/build/toastr.min.css"

import { Row, CardBody, Card, Container, Spinner } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser } from "../../store/actions"

// import images
import MetaTag from "components/Common/Meta-tag"
import { LocalLogo } from "assets/images"

const Login = ({ history }) => {
  const dispatch = useDispatch()

  const { error, user, loading } = useSelector(state => ({
    error: state.Login.error,
    user: state.Login.user,
    loading: state.Login.loading,
    // settings: state.Settings.settings,
  }))

  const settings = []
  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(loginUser(values, history))
  }

  const token = sessionStorage.getItem("token")

  useEffect(() => {
    if (token) {
      return history.push("/dashboard")
    }
  }, [])

  const [show, setShow] = useState(false)

  return (
    <React.Fragment>
      <MetaTag title="Login" />

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Card className="login-old">
              <CardBody className="p-0 d-flex w-100 align-items-center justify-content-center">
                <div className="p-4 w-100 sm:w-100">
                  <Link to="/" className="auth-logo-light">
                    <div className="d-flex align-items-center justify-content-center">
                      <span
                        className="avatar-title py-3"
                        style={{
                          backgroundColor: "transparent",
                          justifyContent: "flex-start",
                          marginLeft: "",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <img
                          src={settings?.company?.logo[0]?.url ?? LocalLogo}
                          alt="logo"
                          height="40"
                        />
                      </span>
                    </div>
                  </Link>
                  <h5>Welcome Back !</h5>
                  <p>Sign in to continue</p>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(v)
                    }}
                  >
                    <div className="mb-3">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter username"
                        type="text"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="password"
                        label="Password"
                        type={show ? "text" : "password"}
                        required
                        placeholder="Enter Password"
                      />
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                        onChange={e => setShow(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Show Password
                      </label>
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                        style={{
                          backgroundColor: "#f8971d",
                          color: "white",
                        }}
                        className="btn  btn-block"
                        type="submit"
                      >
                        {loading ? (
                          <>
                            <Spinner color="white" />
                          </>
                        ) : (
                          "Log In"
                        )}
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-primary">
                        <i className="mdi mdi-lock me-1" />
                        Forgot your password?
                      </Link>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            {/* </Card> */}
            <div className="mt-5 text-center">
              <p>
                © {new Date().getFullYear()} {}. Crafted with{" "}
                <i className="mdi mdi-heart text-danger" /> by
                <a target="blank" href="https://osperb.com">
                  {` osperb`}
                </a>
              </p>
            </div>
            {/* </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
