import {
  GET_EXELDOWNLOAD_LEAD,
  GET_EXELDOWNLOAD_LEAD_FAIL,
  GET_EXELDOWNLOAD_LEAD_SUCCESS,
  GET_EXELDOWNLOAD_SALE,
  GET_EXELDOWNLOAD_SALE_FAIL,
  GET_EXELDOWNLOAD_SALE_SUCCESS,
  GET_EXELDOWNLOAD_VEHICLE,
  GET_EXELDOWNLOAD_VEHICLE_FAIL,
  GET_EXELDOWNLOAD_VEHICLE_SUCCESS,
} from "./actionTypes"

// ExelDownload - This line cannot be edited or removed
export const getExelDownloadSale = (
  page,
  totalItemLimit,
  SearchText,
  clientId,
  vehicleId,
  sortByDate
) => ({
  type: GET_EXELDOWNLOAD_SALE,
  payload: {
    page,
    totalItemLimit,
    SearchText,
    clientId,
    vehicleId,
    sortByDate,
  },
})

export const getExelDownloadSaleSuccess = exeldownloadSale => ({
  type: GET_EXELDOWNLOAD_SALE_SUCCESS,
  payload: exeldownloadSale,
})

export const getExelDownloadSaleFail = error => ({
  type: GET_EXELDOWNLOAD_SALE_FAIL,
  payload: error,
})

export const getExelDownloadVehicle = (
  pageVehicle,
  totalItemLimit,
  vehicleSearchText,
  brandId,
  modelId,
  varientId,
  oilTypeValue,
  ownerShipStatusValue,
  sort,

  dateBySort
) => ({
  type: GET_EXELDOWNLOAD_VEHICLE,
  payload: {
    pageVehicle,
    totalItemLimit,
    vehicleSearchText,
    brandId,
    modelId,
    varientId,
    oilTypeValue,
    ownerShipStatusValue,
    sort,
    dateBySort,
  },
})

export const getExelDownloadVehicleSuccess = exeldownloadVehicle => ({
  type: GET_EXELDOWNLOAD_VEHICLE_SUCCESS,
  payload: exeldownloadVehicle,
})

export const getExelDownloadVehicleFail = error => ({
  type: GET_EXELDOWNLOAD_VEHICLE_FAIL,
  payload: error,
})

export const getExelDownloadLead = (
  page,
  sort,
  totalItemLimit,
  searchText,
  vehicleId,
  typeValue,
  clientId,
  medium,
  sortByDate
) => ({
  type: GET_EXELDOWNLOAD_LEAD,
  payload: {
    page,
    sort,
    totalItemLimit,
    searchText,
    vehicleId,
    typeValue,
    clientId,
    medium,
    sortByDate,
  },
})

export const getExelDownloadLeadSuccess = exeldownloadLead => ({
  type: GET_EXELDOWNLOAD_LEAD_SUCCESS,
  payload: exeldownloadLead,
})

export const getExelDownloadLeadFail = error => ({
  type: GET_EXELDOWNLOAD_LEAD_FAIL,
  payload: error,
})
