module.exports = {
  primaryColor: "#22b3be",
  secondaryColor: "#ed475f",
  companyName: "Focus Motors",
  shortName: "Focus",
  siteMetadata: {
    websiteTitle: "Focus",
    description: "Focus motors",
    keywords: "",
    faviconUrl: "",
    logoUrl: "",
    secondaryLogo: "",
  },
  apiEndpointDev: "http://192.168.1.70:4000/",
  apiEndpointProd: "https://api.focusmotors.in/",
  apiEndpointLocal: "http://192.168.1.37:4005/",

  frontEndUrl: "",
  tokenPrefix: "focus_token",
}
