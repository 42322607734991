import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"
import { Notification } from "components/Common/Notification"
import { get, post } from "helpers/api_helper"

// Is user register successfull then direct plot user in redux.

function setUsersAPi({ user, token }) {
  return post(`/password/reset/${token}`, user)
}
function* registerUser({ payload }) {
  try {
    const response = yield call(setUsersAPi, payload)
    yield put(registerUserSuccessful(response))
    payload?.history?.push("/login")
    Notification({
      type: "success",
      message: "Users Created Successfully!",
      title: "",
    })
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
