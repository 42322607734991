import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes"
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from "./actions"
import { post } from "helpers/api_helper"
import { Notification, errorNotification } from "components/Common/Notification"

//If user is send successfully send mail link then dispatch redux action's are directly from here.

const forgetPasswordApi = ({ user }) => {
  console.log("user =>", user?.email)
  return post("/forgot", user)
}

const resetPasswordApi = ({ user, resetId }) => {
  return post(`password/reset/${resetId}`, user)
}

function* forgetPasword({ payload }) {
  try {
    const response = yield call(forgetPasswordApi, payload)

    yield put(userForgetPasswordSuccess(response))
    if (payload?.history) {
      payload.history?.push("/login")
    }
    Notification({
      type: "success",
      message: "mail send successfully!",
      title: "",
    })
  } catch (error) {
    console.log(error)
    yield put(userForgetPasswordError(error?.response?.data))
    errorNotification()
  }
}

function* resetPasword({ payload }) {
  try {
    const response = yield call(resetPasswordApi, payload)

    yield put(resetPasswordSuccess(response))
    if (payload?.history) {
      payload?.history?.push("/login")
    }
    Notification({
      type: "success",
      message: "password changed successfully!",
      title: "",
    })
  } catch (error) {
    console.log(error)
    yield put(resetPasswordError(error?.response?.data))
    errorNotification()
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetPasword)
  yield takeEvery(RESET_PASSWORD, resetPasword)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
