import MetaTag from "components/Common/Meta-tag"
import React, { useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Container } from "reactstrap"
import Form from "./Form"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { getCategoriesDetails } from "store/actions"

function UpdateVehicle() {
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(getCategoriesDetails(params.id))
  }, [params.id])

  return (
    <div>
      <MetaTag title={"Update Category"} />

      <div className="page-content">
        <Breadcrumbs
          title="Dashboard / Vehicles"
          breadcrumbItem="Update Category"
        />
        <Container fluid>
          <Form updateData={true} />
        </Container>
      </div>
    </div>
  )
}

export default UpdateVehicle
