import MetaTag from "components/Common/Meta-tag"
import React from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Container } from "reactstrap"
import Form from "./Form"

function CreateCategories() {
  return (
    <div>
      <MetaTag title={"Create Category"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Create Category" />
        <Container fluid>
          <div className="container-fluid">
            <Form updateData={false} history={history} />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default CreateCategories
