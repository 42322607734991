import React from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"

const SaleAnalyticsChart = ({ saleCharts }) => {
  const filteredArray = Object.values(saleCharts).filter(
    element => typeof element === "number"
  )
  const maxValue = Math.max(...filteredArray)

  console.log(maxValue + 5)

  const series = [
    {
      name: "Sales",
      data: Object?.values(saleCharts),
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: true,
      },
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    colors: ["#f8971d"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    yaxis: { title: { text: "" } },
  }

  return (
    <>
      <div id="line-chart" dir="ltr">
        <ReactApexChart
          series={series}
          options={options}
          type="line"
          height={320}
          className="apex-charts"
        />
      </div>
    </>
  )
}

export default SaleAnalyticsChart

SaleAnalyticsChart.propTypes = {
  saleCharts: PropTypes?.array,
}
