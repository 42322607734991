import MetaTag from "components/Common/Meta-tag"
import React from "react"
import LeadDetails from "./Details"

function LeadDetail() {
  return (
    <>
      <MetaTag title={"Lead Details"} />
      <LeadDetails />
    </>
  )
}

export default LeadDetail
