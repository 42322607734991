import React, { useEffect, useState } from "react"
import { Badge, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTag from "components/Common/Meta-tag"
import AllVarientTables from "pages/Varient/DatatableTables"
import AllModels from "pages/Model/DatatableTables"
import AllBrands from "pages/Brand/DatatableTables"
import { map } from "lodash"
import { useHistory, useLocation } from "react-router-dom"

function Category() {
  const location = useLocation()
  const history = useHistory()

  const [selectedItem, setSelectedItem] = useState("brands")

  const headerItems = [
    { label: "Brands", value: "brands" },
    { label: "Models", value: "models" },
    { label: "Variants", value: "variants" },
  ]

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set("p", 1)

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
      state: {
        ...location.state,
        page: 1,
      },
    })
  }, [selectedItem])

  return (
    <>
      <MetaTag title={"All Category"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Category" />
        <Container fluid>
          <Row className="pt-1 px-3">
            <Col className="col-12">
              {map(headerItems, (head, key) => (
                <>
                  <input
                    type="radio"
                    className="btn-check"
                    name={`btnradio${key}`}
                    id={head?.value}
                    autoComplete="off"
                    checked={selectedItem === head.value}
                    onChange={() => setSelectedItem(head?.value)}
                  />
                  <label
                    className={`btn  ${
                      selectedItem === head.value ? "btn-success" : "btn-light"
                    }`}
                    htmlFor={head?.value}
                    style={{
                      borderRadius: 3,
                      marginRight: 1.5,
                    }}
                  >
                    {head?.label}
                  </label>
                </>
              ))}
            </Col>
          </Row>
          <div className="container-fluid row">
            {selectedItem === "brands" && (
              <Col md="12" className="mb-4">
                <AllBrands />
              </Col>
            )}
            {selectedItem === "models" && (
              <Col md="12">
                <AllModels />
              </Col>
            )}
            {selectedItem === "variants" && (
              <Col md="12">
                <AllVarientTables />
              </Col>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default Category
