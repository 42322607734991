/* Sales - This line cannot be edited or removed */
export const GET_SALES = "GET_SALES"
export const GET_SALES_SUCCESS = "GET_SALES_SUCCESS"
export const GET_SALES_FAIL = "GET_SALES_FAIL"

/* Sale_DETAILS */
export const GET_SALE_DETAILS = "GET_SALE_DETAILS"
export const GET_SALE_DETAILS_SUCCESS = "GET_SALE_DETAILS_SUCCESS"
export const GET_SALE_DETAILS_FAIL = "GET_SALE_DETAILS_FAIL"

/* CREATE Sale */
export const CREATE_SALE = "CREATE_SALE"
export const CREATE_SALE_SUCCESS = "CREATE_SALE_SUCCESS"
export const CREATE_SALE_FAIL = "CREATE_SALE_FAIL"

/* Edit Sale */
export const UPDATE_SALE = "UPDATE_SALE"
export const UPDATE_SALE_SUCCESS = "UPDATE_SALE_SUCCESS"
export const UPDATE_SALE_FAIL = "UPDATE_SALE_FAIL"

/* Delete Sale */
export const DELETE_SALE = "DELETE_SALE"
export const DELETE_SALE_SUCCESS = "DELETE_SALE_SUCCESS"
export const DELETE_SALE_FAIL = "DELETE_SALE_FAIL"
