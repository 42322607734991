import React, { useState } from "react"
import Form from "./Form"
import MetaTag from "components/Common/Meta-tag"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Button, Card, CardBody, Container } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { createLead } from "store/actions"
import Spinner from "components/Common/Spinner"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import ClientModal from "pages/Client/Crud/Modal"

function CreateLeads() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [clientModal, setClientModal] = useState({ status: false, data: {} })

  const { loading } = useSelector(state => ({
    loading: state?.Client?.loading,
  }))
  const [vehicle, setVehicle] = useState("Select Vehicle...")
  const [vehicleId, setVehicleId] = useState("")
  const [vehicleSearch, setVehicleSearch] = useState("")
  const [status, setStatus] = useState("Select Status...")
  const [statusValue, setStatusValue] = useState("")
  const [client, setClient] = useState("Select Client...")
  const [clientId, setClientId] = useState("")
  const [clientSearch, setClientSearch] = useState("")
  const [type, setType] = useState("Select...")
  const [typeValue, setTypeValue] = useState("")
  const [medium, setMedium] = useState("Select...")
  const [mediumValue, setMediumValue] = useState("")
  const [member, setMember] = useState("Select member...")
  const [memberId, setMemberId] = useState("")
  const [memberSearch, setMemberSearch] = useState("")
  const [country, setCountry] = useState("+91")

  const [brandValue, setBrandValue] = useState("Select Brand...")
  const [brandId, setBrandId] = useState("")
  const [brandSearch, setBrandSearch] = useState("")

  const [modelValue, setModelValue] = useState("Select Model...")
  const [modelId, setModelValueId] = useState("")
  const [modelSearch, setModelSearch] = useState("")

  const [varientValue, setVarientValue] = useState("Select Variant...")
  const [varientId, setVarientId] = useState("")
  const [varientSearch, setVarientSearch] = useState("")

  const allStates = {
    vehicle,
    setVehicle,
    vehicleId,
    setVehicleId,
    vehicleSearch,
    setVehicleSearch,
    status,
    setStatus,
    statusValue,
    setStatusValue,
    client,
    setClient,
    clientId,
    setClientId,
    clientSearch,
    setClientSearch,
    type,
    setType,
    typeValue,
    setTypeValue,
    medium,
    setMedium,
    mediumValue,
    setMediumValue,
    member,
    setMember,
    memberId,
    setMemberId,
    memberSearch,
    setMemberSearch,
    country,
    setCountry,
    brandValue,
    setBrandValue,
    brandId,
    setBrandId,
    brandSearch,
    setBrandSearch,
    modelValue,
    setModelValue,
    modelId,
    setModelValueId,
    modelSearch,
    setModelSearch,
    varientValue,
    setVarientValue,
    varientId,
    setVarientId,
    varientSearch,
    setVarientSearch,
  }

  const handleValidSubmit = (e, v) => {
    const finalDatas = {
      ...v,
      client: clientId ? clientId : "",
      medium: mediumValue,
      leadType: typeValue,
      vehicle: vehicleId,
      status: statusValue,
      assignee: memberId,
      countryCode: country,
    }

    if (typeValue === "dream_car" || typeValue === "sell") {
      finalDatas.requestedVehicle = {
        model: String(modelId),
        varient: String(varientId),
        brand: String(brandId),
      }
    }

    dispatch(createLead(finalDatas, history))
    console.log(finalDatas, history, "lead hisrory")
  }

  return (
    <div>
      <div>
        <div>
          <MetaTag title="Create Lead" />

          <div className="page-content">
            <Breadcrumbs title="Dashboard" breadcrumbItem=" Create Lead" />
            <Container fluid>
              <div className="container-fluid">
                <ClientModal
                  onCloseClick={() =>
                    setClientModal({ status: false, data: {} })
                  }
                  show={clientModal?.status}
                  updateData={clientModal?.data}
                />
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                >
                  <Card className="w-75">
                    <CardBody>
                      <Form
                        states={allStates}
                        clientModal={clientModal}
                        setClientModal={setClientModal}
                      />
                      <div className="py-3 d-flex aling-items-center justify-content-end">
                        {clientId ? (
                          <Button
                            color="warning"
                            type="submit"
                            className="px-5"
                          >
                            {loading && <Spinner />} Create
                          </Button>
                        ) : (
                          <Button
                            color="warning"
                            type="submit"
                            className="px-5"
                            disabled
                          >
                            {loading && <Spinner />} Create
                          </Button>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </AvForm>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateLeads
