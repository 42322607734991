import React from "react"
import ReactEcharts from "echarts-for-react"
import PropTypes from "prop-types"

const LeadToSale = ({ chartData }) => {
  console.log(chartData)
  const option = {
    series: [
      {
        name: "Sale To Lead Conversion",
        type: "gauge",
        detail: {
          formatter: "{value}%",
          textStyle: {
            fontSize: 20,
          },
        },

        axisLine: {
          lineStyle: {
            color: [
              [0.2, "#fcce95"],
              [0.8, "#fab967"],
              [1, "#c89452"],
            ],
            width: 20,
          },
        },
        title: {
          show: true,
          text: "Custom Title",
          textStyle: {
            color: "#FF9900",
            fontSize: 12,
          },
        },
        data: [
          {
            value: chartData || 0,
            name: "Sale Ratio",
          },
        ],
      },
    ],
  }

  return (
    <React.Fragment>
      <ReactEcharts
        style={{ height: "300px", marginTop: -20 }}
        option={option}
      />
    </React.Fragment>
  )
}

export default LeadToSale

LeadToSale.propTypes = {
  chartData: PropTypes?.any,
}
