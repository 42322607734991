import { AvField } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  getBrands,
  getClients,
  getModels,
  getUsers,
  getVariants,
  getVehicles,
} from "store/actions"
import { map } from "lodash"
import CountrySelect from "react-phone-number-input"
import PhoneInput from "react-phone-number-input"
import { countryCodes } from "components/Common/countryCodes"

function Form({ isUpdate, states, clientModal, setClientModal }) {
  const dispatch = useDispatch()
  const role = sessionStorage.getItem("role")

  // const [country, setCountry] = useState()

  const OpenModal = () => {
    setClientModal({ status: true, data: "" })
  }
  const {
    vehicle,
    setVehicle,
    vehicleId,
    setVehicleId,
    vehicleSearch,
    setVehicleSearch,
    status,
    setStatus,
    statusValue,
    setStatusValue,
    client,
    setClient,
    clientId,
    setClientId,
    clientSearch,
    setClientSearch,
    type,
    setType,
    typeValue,
    setTypeValue,
    medium,
    setMedium,
    mediumValue,
    setMediumValue,
    member,
    setMember,
    memberId,
    setMemberId,
    memberSearch,
    setMemberSearch,
    country,
    setCountry,
    brandValue,
    setBrandValue,
    brandId,
    setBrandId,
    brandSearch,
    setBrandSearch,
    modelValue,
    setModelValue,
    modelId,
    setModelValueId,
    modelSearch,
    setModelSearch,
    varientValue,
    setVarientValue,
    varientId,
    setVarientId,
    varientSearch,
    setVarientSearch,
  } = states

  const { allClients, loading, allVehicle, members, brand, model, varient } =
    useSelector(state => ({
      allClients: state.Client.clients?.clients,
      allVehicle: state.Vehicle.vehicles?.vehicles,
      loading: state?.Client?.loading,
      members: state?.Members.users?.users,
      brand: state.Brand.brands?.brands,
      model: state.Model.models?.models,
      varient: state.Varient.variants?.variants,
    }))

  const handleRoles = () => {
    if (role === "enquiryManager") {
      return "enquiryAnalyst"
    } else if (role === "salesManager") {
      return "salesExecutive"
    } else if (role === "salesExecutive") {
      return "branchManager"
    } else if (role === "admin") {
      return "member"
    } else if (role === "enquiryAnalyst") {
      return "salesManager"
    }
  }

  console.log("handleRoles() =>", handleRoles())

  console.log(memberSearch)

  useEffect(() => {
    // if (role !== "salesExecutive") {
    dispatch(getUsers("", "", memberSearch, handleRoles(), ""))
    // }
  }, [dispatch, handleRoles(), memberSearch])

  console.log(members)

  const memberOptions = [
    {
      options: members?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  function handlerMemberFinalValue(event) {
    setMember(event.label)
    setMemberId(event.value)
  }

  const handleEntersMember = text => {
    setMemberSearch(text)
  }

  useEffect(() => {
    setMemberId(isUpdate?.assignee?._id)
    setMember(isUpdate?.assignee?.name)
  }, [isUpdate])

  // clients

  useEffect(() => {
    dispatch(getClients("", "", clientSearch, "", "", ""))
  }, [dispatch, clientSearch])

  function handlerClientFinalValue(event) {
    setClient(event.label)
    setClientId(event.value)
  }

  const handleEnters = text => {
    setClientSearch(text)
  }

  const clientOptions = [
    // {
    //   label: "+ Create Client",
    //   value: "",
    //   link: "/update",
    // },
    {
      options: allClients?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  useEffect(() => {
    setClientId(isUpdate?.client?._id)
    setClient(isUpdate?.client?.name)
  }, [isUpdate])

  // medium

  const handlerMeduimFinalValue = event => {
    setMedium(event?.label)
    setMediumValue(event?.value)
  }
  useEffect(() => {
    setMedium(isUpdate?.medium)
    setMediumValue(isUpdate?.medium)
  }, [isUpdate])

  //type

  const handlerTypeFinalValue = event => {
    setType(event?.label)
    setTypeValue(event?.value)
  }

  useEffect(() => {
    setType(isUpdate?.leadType)
    setTypeValue(isUpdate?.leadType)
  }, [isUpdate])

  // vehicles

  const bool = false

  useEffect(() => {
    dispatch(
      getVehicles("", "", vehicleSearch, "", "", "", "", "", "", "", "", bool)
    )
  }, [dispatch, vehicleSearch, bool])

  const handlerVehicleFinalValue = event => {
    setVehicle(event?.label)
    setVehicleId(event?.value)
  }

  const vehicleOptions = [
    {
      options: allVehicle?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersVehicle = text => {
    setVehicleSearch(text)
  }

  useEffect(() => {
    setVehicle(isUpdate?.vehicle?.name)
    setVehicleId(isUpdate?.vehicle?._id)
  }, [isUpdate])

  // status

  const handlerStatusFinalValue = event => {
    setStatus(event?.label)
    setStatusValue(event?.value)
  }

  useEffect(() => {
    setStatus(isUpdate?.status)
    setStatusValue(isUpdate?.status)
  }, [isUpdate])

  console.log(role)

  const statusOptions = [
    { label: "New", value: "new" },
    { label: "Qualified", value: "qualified" },
    { label: "Processing", value: "processing" },
    { label: "Confirmed", value: "confirmed" },
    { label: " New Buyer", value: "newBuyer" },
    { label: "Follow Up Done", value: "followupDone" },
    { label: "Price Quoted", value: "priceQuoted" },
    { label: "Negotiation", value: "negotiation" },
    { label: "Car Booked", value: "carBooked" },
    { label: "Paper Work", value: "paperWork" },
    { label: "Sold", value: "carSold" },
    { label: "Lost", value: "lost" },
  ]

  const handleStatusValue = () => {
    switch (role) {
      case "admin":
      case "managingDirecter":
      case "generalManager":
      case "enquiryAnalyst":
        const statusValues = [
          // { label: "All", value: "all" },
          { label: "New", value: "new" },
          {
            label: "Qualified",
            value: "qualified",
          },
          {
            label: "Processing",
            value: "processing",
          },
          {
            label: "Confirmed",
            value: "confirmed",
          },
          {
            label: " New Buyer",
            value: "newBuyer",
          },
          {
            label: "Follow Up Done",
            value: "followupDone",
          },
          {
            label: "Price Quoted",
            value: "priceQuoted",
          },
          {
            label: "Negotiation",
            value: "negotiation",
          },
          {
            label: "Car Booked",
            value: "carBooked",
          },
          {
            label: "Paper Work",
            value: "paperWork",
          },
          {
            label: "Sold",
            value: "carSold",
          },
          { label: "Lost", value: "lost" },
        ]
        return statusValues

      case "enquiryManager":
        const salesStatusValues = [
          // { label: "All", value: "all" },
          { label: "New", value: "new" },
          {
            label: "Qualified",
            value: "qualified",
          },
          {
            label: "Processing",
            value: "processing",
          },
          {
            label: "Confirmed",
            value: "confirmed",
          },
        ]
        return salesStatusValues
        // case "enquiryAnalyst":
        const enquiryAnalystValues = [
          { label: "New", value: "new" },
          {
            label: "Qualified",
            value: "qualified",
          },
          {
            label: "Processing",
            value: "processing",
          },
          {
            label: "Confirmed",
            value: "confirmed",
          },
        ]
        return enquiryAnalystValues

      case "salesManager":
        const salesManagerStatus = [
          {
            label: "Confirmed",
            value: "confirmed",
          },
          {
            label: " New Buyer",
            value: "newBuyer",
          },
          {
            label: "Follow Up Done",
            value: "followupDone",
          },
          {
            label: "Price Quoted",
            value: "priceQuoted",
          },
          {
            label: "Negotiation",
            value: "negotiation",
          },
          {
            label: "Car Booked",
            value: "carBooked",
          },
          {
            label: "Paper Work",
            value: "paperWork",
          },
          {
            label: "Sold",
            value: "carSold",
          },
          { label: "Lost", value: "lost" },
        ]

        return salesManagerStatus

      case "salesExecutive":
        const salesExecutiveStatus = [
          {
            label: " New Buyer",
            value: "newBuyer",
          },
          {
            label: "Follow Up Done",
            value: "followupDone",
          },
          {
            label: "Price Quoted",
            value: "priceQuoted",
          },
          {
            label: "Negotiation",
            value: "negotiation",
          },
          {
            label: "Car Booked",
            value: "carBooked",
          },
          {
            label: "Paper Work",
            value: "paperWork",
          },
          {
            label: "Sold",
            value: "carSold",
          },
          { label: "Lost", value: "lost" },
        ]
        return salesExecutiveStatus
      default:
        break
    }
  }

  useEffect(() => {
    dispatch(getBrands("", "", "", brandSearch))
  }, [dispatch, brandSearch])

  useEffect(() => {
    if (isUpdate?.brand) {
      setBrandId(isUpdate?.brand?.id)
      setBrandValue(isUpdate?.brand?.name)
      setModelValueId(isUpdate?.model?.id)
      setModelValue(isUpdate?.model?.name)
      setVarientId(isUpdate?.varient?.id)
      setVarientValue(isUpdate?.varient?.name)
    }
  }, [isUpdate])

  const handleEnter = textEntered => {
    setBrandSearch(textEntered)
  }

  function handlerFinalValuesBrand(event) {
    setBrandValue(event.label)
    setBrandId(event.value)
  }

  const brandOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (brand?.length >= 1 ? brand : [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getModels("", "", modelSearch, brandId))
  }, [dispatch, modelSearch, brandId])

  const handlModeleEnters = textEntered => {
    setModelSearch(textEntered)
  }

  function handlerFinalValueModel(event) {
    setModelValue(event.label)
    setModelValueId(event.value)
  }

  const modelOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (model?.length >= 1 ? model : [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results._id,
      })),
    },
  ]

  const handleEntered = textEntered => {
    setVarientSearch(textEntered)
  }

  function handleFinalValuesVarient(event) {
    setVarientValue(event.label)
    setVarientId(event.value)
  }

  const varientOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (varient?.length >= 1 ? varient : [])?.map((results, index) => ({
        key: index,
        label: results?.name,
        value: results?._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getVariants("", "", varientSearch, ""))
  }, [dispatch, varientSearch])

  console.log(allClients?.length)

  console.log(typeValue)

  return (
    <div>
      {/* <ClientModal
        onCloseClick={() => setClientModal({ status: false, data: {} })}
        show={clientModal?.status}
        updateData={clientModal?.data}
      /> */}
      <Row>
        {isUpdate?._id ? (
          <>
            <Col md={4}>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <label>
                    Client <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  {allClients?.length === 0 ? (
                    <div className="p-1">
                      <Button
                        className="px-3 mx-1 py-1"
                        color="warning"
                        size="sm"
                        onClick={() => OpenModal()}
                      >
                        create client
                        <i className="bx bx-plus" style={{ fontSize: 10 }} />
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <Select
                onInputChange={handleEnters}
                value={client}
                placeholder={client}
                onChange={handlerClientFinalValue}
                options={clientOptions}
                classNamePrefix="select2-selection"
                isLoading={loading}
              />
            </Col>
            <Col md={4}>
              <label>Vehicle</label>
              <Select
                onInputChange={handleEntersVehicle}
                value={vehicle}
                placeholder={vehicle}
                onChange={handlerVehicleFinalValue}
                options={vehicleOptions}
                classNamePrefix="select2-selection"
                isLoading={loading}
              />
            </Col>
            <Col md={4}>
              <label>Country </label>
              <AvField
                name="countryCode"
                type="select"
                value={isUpdate?.countryCode}
                onChange={e => setCountry(e.target.value)}
              >
                <option value="">Select Country</option>
                {countryCodes?.map((item, key) => (
                  <option key={key} value={item?.code}>
                    {item?.name}
                  </option>
                ))}
              </AvField>
            </Col>
          </>
        ) : (
          <>
            <Col md={4}>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <label>
                    Client <span className="text-danger">*</span>
                  </label>
                </div>
                <div>
                  {allClients?.length === 0 ? (
                    <div className="p-1">
                      <Button
                        className="px-3 mx-1 py-1"
                        color="warning"
                        size="sm"
                        onClick={() => OpenModal()}
                      >
                        create client
                        <i className="bx bx-plus" style={{ fontSize: 10 }} />
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <Select
                onInputChange={handleEnters}
                value={client}
                placeholder={client}
                onChange={handlerClientFinalValue}
                options={clientOptions}
                classNamePrefix="select2-selection"
                isLoading={loading}
              />
            </Col>
            {/* <Col md={3}>
              <div className="d-flex align-items-center">
                <Button>Create Client</Button>
              </div>
            </Col> */}
            <Col md={4}>
              <label>Vehicle</label>
              <Select
                onInputChange={handleEntersVehicle}
                value={vehicle}
                placeholder={vehicle}
                onChange={handlerVehicleFinalValue}
                options={vehicleOptions}
                classNamePrefix="select2-selection"
                isLoading={loading}
              />
            </Col>
            {role === "salesExecutive" ? (
              ""
            ) : (
              <Col md="4">
                {isUpdate?._id ? (
                  ""
                ) : (
                  <Col md={12}>
                    <label>Assignee</label>
                    <Select
                      onInputChange={handleEntersMember}
                      value={member}
                      placeholder={member}
                      onChange={handlerMemberFinalValue}
                      options={memberOptions}
                      classNamePrefix="select2-selection"
                      isLoading={loading}
                    />
                  </Col>
                )}
              </Col>
            )}
          </>
        )}
      </Row>

      <Row className="py-3">
        {isUpdate?._id ? (
          <>
            <Col md={4}>
              {/* <label>Medium</label>
              <Select
                // onInputChange={handleEntersMedium}
                value={medium}
                placeholder={medium}
                onChange={handlerMeduimFinalValue}
                options={MediumOptions}
                classNamePrefix="select2-selection"
                isLoading={loading}
              /> */}

              <label>Medium</label>
              <div
                className="btn-group mt-0 mt-xl-0 d-flex align-items-center justify-content-start"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                {map(MediumOptions, (item, key) => (
                  <div key={key} className="me-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio2"
                      id={item.value}
                      autoComplete="off"
                      checked={mediumValue === item.value}
                      value={item.value}
                      onChange={e => setMediumValue(e.target.value)}
                    />
                    <label
                      className="btn btn-outline-warning"
                      htmlFor={item.value}
                    >
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={4}>
              <label>Type</label>
              <div
                className="btn-group mt-0 mt-xl-0 d-flex align-items-center justify-content-start"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                {map(typeOptions, (item, key) => (
                  <div key={key} className="me-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio1"
                      id={item.value + "type"}
                      autoComplete="off"
                      checked={typeValue === item?.value}
                      value={item.value}
                      onChange={e => setTypeValue(e.target.value)}
                    />
                    <label
                      className="btn btn-outline-warning"
                      htmlFor={item.value + "type"}
                    >
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col md={4} className="">
              <label>Medium</label>
              <div
                className="btn-group mt-0 mt-xl-0 d-flex align-items-center justify-content-start"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                {map(MediumOptions, (item, key) => (
                  <div key={key} className="me-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio-medium"
                      id={item.value + "medium"}
                      autoComplete="off"
                      checked={mediumValue === item.value}
                      value={item.value}
                      onChange={e => setMediumValue(e.target.value)}
                    />
                    <label
                      className="btn btn-outline-warning"
                      htmlFor={item.value + "medium"}
                    >
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>
            </Col>

            <Col md={4}>
              <label>Type</label>
              <div
                className="btn-group mt-0 mt-xl-0 d-flex align-items-center justify-content-start"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                {map(typeOptions, (item, key) => (
                  <div key={key} className="me-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio-type"
                      id={item.value + "type"}
                      autoComplete="off"
                      checked={typeValue === item.value}
                      value={item.value}
                      onChange={e => setTypeValue(e.target.value)}
                    />
                    <label
                      className="btn btn-outline-warning"
                      htmlFor={item.value + "type"}
                    >
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={4}>
              <label>Country </label>

              <AvField
                name="countryCode"
                type="select"
                value={country}
                onChange={e => setCountry(e.target.value)}
              >
                <option value="">Select Country</option>
                {countryCodes?.map((item, key) => (
                  <option key={key} value={item?.code}>
                    {item?.name}
                  </option>
                ))}
              </AvField>
            </Col>
          </>
        )}

        {isUpdate?._id && (
          <Col md={4}>
            <label>Status</label>
            <Select
              onInputChange={handleEntersVehicle}
              value={status}
              placeholder={status}
              onChange={handlerStatusFinalValue}
              options={handleStatusValue()}
              classNamePrefix="select2-selection"
              isLoading={loading}
            />
          </Col>
        )}
      </Row>

      {typeValue === "dream_car" || typeValue === "sell" ? (
        <>
          <Row className="mb-2">
            <Col md="4">
              {" "}
              <label>Brand</label>
              <Select
                onInputChange={handleEnter}
                value={brandValue}
                placeholder={brandValue}
                onChange={handlerFinalValuesBrand}
                options={brandOptions}
                classNamePrefix="select2-selection"
                isLoading={loading}
              />{" "}
            </Col>
            <Col md="4">
              <label>Model</label>
              <Select
                onInputChange={handlModeleEnters}
                value={modelValue}
                placeholder={modelValue}
                onChange={handlerFinalValueModel}
                options={modelOptions}
                classNamePrefix="select2-selection"
                isLoading={loading}
              />{" "}
            </Col>
            <Col md="4">
              <label>Variant</label>
              <Select
                onInputChange={handleEntered}
                value={varientValue}
                placeholder={varientValue}
                onChange={handleFinalValuesVarient}
                options={varientOptions}
                classNamePrefix="select2-selection"
                isLoading={loading}
              />{" "}
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}

      <Row>
        {isUpdate?._id ? (
          <>
            <Col md={6}>
              <AvField
                style={{ minHeight: 100 }}
                name="message"
                className="form-control"
                type="textarea"
                label="Message"
                value={isUpdate?.message ?? ""}
              />
            </Col>
            <Col md={6}>
              <AvField
                style={{ minHeight: 100 }}
                name="remarks"
                className="form-control"
                type="textarea"
                label="Remarks"
                value={isUpdate?.remarks ?? ""}
              />
            </Col>
          </>
        ) : (
          <>
            <Col md={12}>
              <AvField
                style={{ minHeight: 100 }}
                name="message"
                className="form-control"
                type="textarea"
                label="Message"
                value={isUpdate?.message ?? ""}
              />
            </Col>
            {/* <Col md={6}>
          <AvField
            style={{ minHeight: 100 }}
            name="remarks"
            className="form-control"
            type="textarea"
            label="Remarks"
            value={isUpdate?.remarks ?? ""}
          />
        </Col> */}
          </>
        )}
      </Row>
    </div>
  )
}

export default Form

Form.propTypes = {
  isUpdate: PropTypes.obj,
  states: PropTypes.obj,
  clientModal: PropTypes.any,
  setClientModal: PropTypes.any,
}

const MediumOptions = [
  { label: "Social", value: "social" },
  { label: "Direct", value: "direct" },
  { label: "Call", value: "call" },
]

const typeOptions = [
  { label: "Sell", value: "sell" },
  { label: "Buy", value: "buy" },
  { label: "Dream Car", value: "dream_car" },
]
