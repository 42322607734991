import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Modal,
  UncontrolledTooltip,
} from "reactstrap"
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { FaRegCircleUser } from "react-icons/fa6"
import SimpleBar from "simplebar-react"

//actions
import { FiEdit2 } from "react-icons/fi"
//redux
import { useSelector, useDispatch } from "react-redux"
import { map } from "lodash"
import { CurrencySwitch } from "hooks/Currency"
import MetaTag from "components/Common/Meta-tag"
import DeleteModal from "components/Common/DeleteModal"
import {
  deleteLead,
  deleteLeadNotes,
  getLeadDetails,
  getLeadNotes,
} from "store/actions"
import moment from "moment"
import LeadHistory from "pages/Lead-History"
import CreateForm from "pages/Lead-Notes/Crud/Update/Update"
import Select from "react-select"
import LeadModal from "./Modal"
import { FaEdit, FaRegUser, FaUser, FaUserCircle } from "react-icons/fa"
import { MdDelete, MdEdit } from "react-icons/md"
import StatusUpdate from "./StatusUpdate"
import FollowUpDataTables from "pages/followUp/DataTables"

const LeadDetails = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const role = sessionStorage.getItem("role")

  const [formModal, setFormModal] = useState({ status: false, data: "" })
  const [statusModal, setStatusModal] = useState({ status: false, data: "" })

  const { leadDetails, AllLeadNotes, loading, members } = useSelector(
    state => ({
      leadDetails: state.Lead.leadDetails,
      loading: state.LeadNotes.loading,
      AllLeadNotes: state.LeadNotes.leadnotes,
      members: state?.Members.members?.members,
    })
  )

  //delete
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteLead(params?.id, history))
  }
  const handleDelete = (id, history) => {
    setIsOpen({ stauts: true, id: id })
  }

  useEffect(() => {
    dispatch(getLeadDetails(params.id))
  }, [dispatch, params?.id])

  const memberOptions = [
    {
      options: members?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  function handlerMemberFinalValue(event) {
    setMember(event.label)
    setMemberId(event.value)
  }

  const handleEntersMember = text => {
    setMemberSearch(text)
  }

  useEffect(() => {
    dispatch(getLeadNotes("", "", "", params?.id))
  }, [dispatch, params?.id])

  const onCloseClick = () => {
    setFormModal({ status: false, data: "" })
  }

  const [leadModal, setLeadModal] = useState(false)

  const handleOwnershipStatus = ownership => {
    switch (ownership) {
      case "first_owner":
        return "First Owner"
      case "second_owner":
        return "Second Owner"
      case "third_owner":
        return "Third Owner"
      default:
        return ""
    }
  }

  const handleDeleteNotes = noteItemId => {
    dispatch(deleteLeadNotes(noteItemId, ""))
  }

  const handleStatusColor = status => {
    switch (status) {
      case "new":
        return "primary"
      case "qualified":
        return "info"
      case "processing":
        return "warning"
      case "confirmed":
        return "success"
      case "newBuyer":
        return "primary"
      case "followupDone":
        return "warning"
      case "priceQuoted":
        return "info"
      case "negotiation":
        return "warning"
      case "carBooked":
        return "success"
      case "paperWork":
        return "warning"
      case "carSold":
        return "success"
      case "lost":
        return "danger"
      default:
        return "primary"
    }
  }

  const handleCheckStatus = status => {
    switch (status) {
      case "new":
        return "new"
      case "qualified":
        return "qualified"
      case "processing":
        return "processing"
      case "confirmed":
        return "confirmed"
      case "newBuyer":
        return "New Buyer"
      case "followupDone":
        return "Follow Up Done"
      case "priceQuoted":
        return "Price Quoted"
      case "negotiation":
        return "negotiation"
      case "carBooked":
        return "Car Booked"
      case "paperWork":
        return "Paper Work"
      case "carSold":
        return "Car Sold"
      case "lost":
        return "lost"
      default:
        return "new"
    }
  }

  const handleShowDeleteIcon = () => {
    switch (role) {
      case "branchManager":
      case "enquiryAnalyst":
        return false
      case "admin":
      case "generalManager":
      case "enquiryManager":
      case "managingDirector":
        return true

      default:
        return false
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />

      {formModal?.status && (
        <CreateForm
          show={formModal?.status}
          onCloseClick={onCloseClick}
          updateData={formModal?.data}
        />
      )}
      <StatusUpdate
        show={statusModal?.status}
        onCloseClick={() => setStatusModal({ ...statusModal, status: false })}
        updateData={leadDetails?.status}
      />
      <div className="page-content">
        <MetaTag title="Lead Details" />

        <Container fluid>
          <Breadcrumbs title="Lead" breadcrumbItem="Lead Details" />
          <Row>
            <Col md={8}>
              <Col md="12">
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-start justify-content-between">
                      <div className="w-50">
                        <p className="m-0 mb-1">
                          {moment(leadDetails?.createdAt).format("lll")}
                        </p>
                        <h3 className="d-flex align-items-center">
                          <p className="font-size-16 mb-0">
                            {leadDetails?.client?.name}{" "}
                          </p>
                          <Badge
                            className={"mx-2 font-size-13 badge-soft-success"}
                            color="black"
                            pill
                          >
                            {leadDetails?.type}
                          </Badge>
                        </h3>
                        {leadDetails?.client?.email && (
                          <p className="mb-1 d-flex align-items-center">
                            <i className="bx bx-mail-send text-primary pe-2" />
                            {leadDetails?.client?.email}
                          </p>
                        )}
                        {leadDetails?.client?.phone && (
                          <p className="d-flex align-items-center mb-1">
                            <i className="bx bxs-phone text-secondary pe-2" />
                            {leadDetails?.client?.phone}
                          </p>
                        )}
                        {leadDetails?.client?.whatsappNumber && (
                          <p className="d-flex align-items-center mb-1">
                            <i className="bx bxl-whatsapp text-success pe-2" />

                            {leadDetails?.client?.whatsappNumber}
                          </p>
                        )}
                        <div>
                          <p>
                            Created :{" "}
                            {leadDetails?.createdBy && (
                              <span>
                                {leadDetails?.createdBy?.name} (
                                {leadDetails?.createdBy?.memberRole
                                  ? leadDetails?.createdBy?.memberRole
                                  : leadDetails?.createdBy?.role}
                                )
                              </span>
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="w-50">
                        {leadDetails?.message && (
                          <p className="mb-1 pt-2">
                            Message :&nbsp; {leadDetails?.message}
                          </p>
                        )}
                        {leadDetails?.remarks && (
                          <p className="m-0">
                            Remarks :&nbsp; {leadDetails?.remarks}
                          </p>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex align-items-center justify-content-between">
                      <div
                        style={{ cursor: "pointer" }}
                        className="d-flex align-items-center p-1"
                        onClick={() => setStatusModal({ status: true })}
                      >
                        <Badge
                          id="leadStatusId"
                          title="edit"
                          style={{ textTransform: "capitalize" }}
                          className={
                            "px-2 py-1 font-size-14 badge-soft-" +
                            `${handleStatusColor(leadDetails?.status)}`
                          }
                        >
                          {" "}
                          {handleCheckStatus(leadDetails?.status)}{" "}
                        </Badge>
                        &nbsp;
                        <i
                          title="Edit"
                          className="bx bx-edit me-2 text-warning"
                          style={{ fontSize: "16px" }}
                        />
                        <UncontrolledTooltip target={"leadStatusId"}>
                          Lead Status
                        </UncontrolledTooltip>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <div>
                          {handleShowDeleteIcon() && (
                            <div className="mx-2">
                              <MdDelete
                                onClick={() => {
                                  handleDelete(params?.id)
                                }}
                                size={20}
                                className="text-danger cursor-pointer"
                              />
                            </div>
                          )}
                        </div>
                        {role === "admin" ||
                        role === "enquiryAnalyst" ||
                        role === "generalManager" ||
                        role === "managingDirector" ||
                        role === "enquiryManager" ||
                        role === "branchManager" ? (
                          <Link to={`/lead/update/${leadDetails?._id}`}>
                            <Button
                              className="cursor-pointer d-flex align-items-center"
                              color="warning"
                              size="sm"
                            >
                              <i className="bx bx-edit pe-1" />
                              Update
                            </Button>
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Col>
            <Col md={4}>
              <Card className="m-0 mb-4">
                {leadDetails?.vehicle ? (
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <h5>Vehicle Details :</h5>
                        <p className="mb-2 ">{leadDetails?.vehicle?.name}</p>
                        <p className="mb-2">
                          {leadDetails?.vehicle?.brand?.name}
                        </p>
                        <p className="mb-2">
                          {leadDetails?.vehicle?.model?.name}
                        </p>
                        <p className="mb-2">{leadDetails?.vehicle?.oilType}</p>
                        <p className="mb-2">
                          {leadDetails?.vehicle?.variant?.name}
                        </p>
                        <p className="mb-2">
                          {handleOwnershipStatus(
                            leadDetails?.vehicle?.ownershipStatus
                          )}
                        </p>
                      </div>

                      {leadDetails?.vehicle?.images ? (
                        <div className="mb-4">
                          <img
                            height={150}
                            width={150}
                            src={leadDetails?.vehicle?.images[0]?.url}
                            style={{
                              objectFit: "contain",
                              borderRadius: 5,
                              height: "auto",
                            }}
                            alt="Vehicle Image"
                          />
                        </div>
                      ) : (
                        <div className="m-0 p-0">No Image Available</div>
                      )}
                    </div>
                  </CardBody>
                ) : (
                  <div style={{ height: "100%" }}>
                    <CardBody>
                      <p>No Product Selected...!</p>
                    </CardBody>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <div>
                <p>Follow Ups</p>
                <FollowUpDataTables />
              </div>
            </Col>
            <Col md={4} style={{ marginTop: 37 }}>
              <Card>
                <CardBody>
                  <label>Assignee</label>
                  <>
                    {leadModal ? (
                      <LeadModal
                        onClose={() => setLeadModal(false)}
                        assignee={leadDetails?.assignee}
                      />
                    ) : (
                      <div className="d-flex align-items-start ">
                        <p className="m-0 p-0 mt-1">
                          {leadDetails.assignee?.name
                            ? leadDetails?.assignee?.name
                            : "No Assignee Selected..!"}
                        </p>

                        {role === "salesExecutive" ||
                        role === "salesManager" ||
                        role === "admin" ||
                        role === "enquiryManager" ||
                        role !== "branchManager" ||
                        role === "enquiryAnalyst" ? (
                          <>
                            <Link
                              to={"#"}
                              className="ms-3"
                              onClick={() => setLeadModal(!leadModal)}
                              id="assigneeChangeId"
                            >
                              <i
                                title="Edit"
                                className="bx bx-edit me-2 text-warning"
                                style={{ fontSize: "22px" }}
                              />
                            </Link>
                            <UncontrolledTooltip target={"assigneeChangeId"}>
                              Assignee to new member
                            </UncontrolledTooltip>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </>

                  {/* <Link
                          to={"#"}
                          className="ms-3"
                          onClick={() => setLeadModal(leadModal)}
                          id="assigneeChangeId"
                        >
                          <i
                            title="Edit"
                            className="bx bx-edit me-2"
                            style={{ color: "green", fontSize: "22px" }}
                          />
                        </Link> */}

                  <hr />
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <p
                      id="scrollToViewAllNoteId"
                      className="p-0 m-0 font-size-16 text-black"
                    >
                      Notes
                    </p>
                    <UncontrolledTooltip target={"scrollToViewAllNoteId"}>
                      Scroll to view all notes
                    </UncontrolledTooltip>

                    <div className="py-2">
                      <Button
                        size="sm"
                        color="warning"
                        className="px-2 "
                        id="createNewNoteId"
                        onClick={() => setFormModal({ status: true, data: "" })}
                      >
                        <i className="mdi mdi-plus font-size-16" />
                      </Button>
                      <UncontrolledTooltip target={"createNewNoteId"}>
                        Create new notes
                      </UncontrolledTooltip>
                    </div>
                  </div>
                  <SimpleBar style={{ maxHeight: "460px" }}>
                    {AllLeadNotes && AllLeadNotes?.length >= 1 ? (
                      <>
                        {map(AllLeadNotes, item => (
                          <Card
                            className="bg-gray mb-2"
                            style={{ borderRadius: "12px" }}
                          >
                            <CardBody>
                              <div>
                                <div className="d-flex align-items-center justify-content-end">
                                  <Button
                                    className="mb-2 "
                                    style={{
                                      border: "1px solid #e4e4e4",
                                      padding: "10px",
                                      padding: "6px",
                                      borderRadius: "10px",
                                    }}
                                    type="button"
                                    color=""
                                    onClick={() =>
                                      setFormModal({
                                        status: true,
                                        data: item,
                                      })
                                    }
                                  >
                                    <FiEdit2
                                      size={15}
                                      className="text-warning cursor-pointer"
                                    />
                                  </Button>
                                  <Button
                                    type="button"
                                    className="mb-2 mx-1"
                                    style={{
                                      border: "1px solid #e4e4e4",
                                      padding: "10px",
                                      padding: "6px",
                                      borderRadius: "10px",
                                    }}
                                    onClick={() => handleDeleteNotes(item?._id)}
                                    color=""
                                  >
                                    <MdDelete
                                      size={18}
                                      className="text-danger cursor-pointer"
                                    />
                                  </Button>
                                </div>
                                <p
                                  className="pt-1 text-black "
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    maxWidth: "220px",
                                  }}
                                >
                                  {item?.note}
                                </p>
                                <div className="d-flex align-items-end justify-content-between">
                                  <div className="d-flex">
                                    <FaRegUser
                                      size={20}
                                      className="me-2"
                                      style={{ marginTop: "0px" }}
                                    />
                                    <p className="m-0 font-size-16 text-muted">
                                      {item?.user?.name}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-end">
                                    <p className="m-0 font-size-12 ">
                                      {moment(item?.createdAt).format(
                                        "d-m-y h:m a"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <p
                        style={{ cursor: "pointer" }}
                        className="d-flex align-items-center justify-content-center py-2 text-warning"
                        onClick={() => setFormModal({ status: true, data: "" })}
                      >
                        {" "}
                        Create New Note..
                      </p>
                    )}
                  </SimpleBar>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <div>
                <p className="h6 p-2 text-muted">Lead History</p>{" "}
                <LeadHistory />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

LeadDetails.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
}

export default LeadDetails
