// import {
//   GET_PROMOTER_DASHBOARD_DATA,
//   GET_PROMOTER_DASHBOARD_DATA_FAIL,
//   GET_PROMOTER_DASHBOARD_DATA_SUCCESS,
// } from "store/promoter/actionTypes"
import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_PROMOTER_PROFILE_DETAILS,
  GET_PROMOTER_PROFILE_DETAILS_SUCCESS,
  GET_PROMOTER_PROFILE_DETAILS_FAIL,
  GET_PROMOTER_DASHBOARD_DATA,
  GET_PROMOTER_DASHBOARD_DATA_SUCCESS,
  GET_PROMOTER_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_LEAD_CHART_DATA,
  GET_DASHBOARD_LEAD_CHART_DATA_SUCCESS,
  GET_DASHBOARD_LEAD_CHART_DATA_FAIL,
  GET_DASHBOARD_VEHICLE_CHART_DATA,
  GET_DASHBOARD_VEHICLE_CHART_DATA_SUCCESS,
  GET_DASHBOARD_VEHICLE_CHART_DATA_FAIL,
  GET_DASHBOARD_SALE_CHART_DATA,
  GET_DASHBOARD_SALE_CHART_DATA_SUCCESS,
  GET_DASHBOARD_SALE_CHART_DATA_FAIL,
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
})

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
})

// charts data
export const getChartsData = periodType => ({
  type: GET_CHARTS_DATA,
  payload: periodType,
})

//Dashboard Data

export const getDashboardData = isStoreAdmin => ({
  type: GET_DASHBOARD_DATA,
  payload: isStoreAdmin,
})

export const getDashboardDataSuccess = dashboardData => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload: dashboardData,
})

export const getDashboardDataFail = error => ({
  type: GET_DASHBOARD_DATA_FAIL,
  payload: error,
})

// promoter profile data

export const getPromoterProfileDetails = () => ({
  type: GET_PROMOTER_PROFILE_DETAILS,
  payload: "",
})

export const getPromoterProfileDetailsSuccess = promoterProfile => ({
  type: GET_PROMOTER_PROFILE_DETAILS_SUCCESS,
  payload: promoterProfile,
})

export const getPromoterProfileDetailsFail = error => ({
  type: GET_PROMOTER_PROFILE_DETAILS_FAIL,
  payload: error,
})

// promoter dashboard data

export const getPromoterDashboardData = () => ({
  type: GET_PROMOTER_DASHBOARD_DATA,
  payload: "",
})

export const getPromoterDashboardDataSuccess = promoterDashboardData => ({
  type: GET_PROMOTER_DASHBOARD_DATA_SUCCESS,
  payload: promoterDashboardData,
})

export const getPromoterDashboardDataFail = error => ({
  type: GET_PROMOTER_DASHBOARD_DATA_FAIL,
  payload: error,
})

//  -----------/ charts  /----
//lead

export const getDashboardLeadChartData = () => ({
  type: GET_DASHBOARD_LEAD_CHART_DATA,
  payload: "",
})

export const getDashboardLeadChartDataSuccess = leadChartData => ({
  type: GET_DASHBOARD_LEAD_CHART_DATA_SUCCESS,
  payload: leadChartData,
})

export const getDashboardLeadChartDataFail = error => ({
  type: GET_DASHBOARD_LEAD_CHART_DATA_FAIL,
  payload: error,
})

// vehicle

export const getDashboardVehicleChartData = () => ({
  type: GET_DASHBOARD_VEHICLE_CHART_DATA,
  payload: "",
})

export const getDashboardVehicleChartDataSuccess = vehicleChartData => ({
  type: GET_DASHBOARD_VEHICLE_CHART_DATA_SUCCESS,
  payload: vehicleChartData,
})

export const getDashboardVehicleChartDataFail = error => ({
  type: GET_DASHBOARD_VEHICLE_CHART_DATA_FAIL,
  payload: error,
})

//sale

export const getDashboardSaleChartData = () => ({
  type: GET_DASHBOARD_SALE_CHART_DATA,
  payload: "",
})

export const getDashboardSaleChartDataSuccess = saleChartData => ({
  type: GET_DASHBOARD_SALE_CHART_DATA_SUCCESS,
  payload: saleChartData,
})

export const getDashboardSaleChartDataFail = error => ({
  type: GET_DASHBOARD_SALE_CHART_DATA_FAIL,
  payload: error,
})
