import {
  GET_MATCHLISTS,
  GET_MATCHLISTS_SUCCESS,
  GET_MATCHLISTS_FAIL,
  GET_MATCHLIST_DETAILS,
  GET_MATCHLIST_DETAILS_SUCCESS,
  GET_MATCHLIST_DETAILS_FAIL,
  CREATE_MATCHLIST,
  CREATE_MATCHLIST_SUCCESS,
  CREATE_MATCHLIST_FAIL,
  UPDATE_MATCHLIST,
  UPDATE_MATCHLIST_SUCCESS,
  UPDATE_MATCHLIST_FAIL,
  DELETE_MATCHLIST,
  DELETE_MATCHLIST_SUCCESS,
  DELETE_MATCHLIST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  matchingLists: {
    matchingLists: [],
    total: "",
    page: "",
  },
  matchlistDetails: {},
  error: {},
  loading: false,
}

const MatchList = (state = INIT_STATE, action) => {
  switch (action.type) {
    // MatchList - This line cannot be edited or removed
    case GET_MATCHLISTS:
    case GET_MATCHLIST_DETAILS:
    case CREATE_MATCHLIST:
    case UPDATE_MATCHLIST:
    case DELETE_MATCHLIST:
      return {
        ...state,
        loading: true,
      }

    case DELETE_MATCHLIST_FAIL:
    case GET_MATCHLISTS_FAIL:
    case GET_MATCHLIST_DETAILS_FAIL:
    case UPDATE_MATCHLIST_FAIL:
    case CREATE_MATCHLIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MATCHLISTS_SUCCESS:
      return {
        ...state,
        matchingLists: action.payload,
        error: {},
        loading: false,
      }

    case GET_MATCHLIST_DETAILS_SUCCESS:
      return {
        ...state,
        matchlistDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_MATCHLIST_SUCCESS:
      return {
        ...state,
        matchlists: {
          ...state.matchlists,
          matchlists: [...state.matchlists.matchlists, action.payload],
          total: state.matchlists.total + 1,
        },
        matchlistDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_MATCHLIST_SUCCESS:
      console.log(state?.matchingLists)
      console.log(action.payload)
      return {
        ...state,
        matchingLists: {
          ...state.matchingLists,
          matchingLists: state?.matchingLists?.matchingLists?.filter(
            matchlist => matchlist._id !== action.payload._id
          ),
        },
        matchlistDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_MATCHLIST_SUCCESS:
      return {
        ...state,
        matchlists: {
          ...state.matchlists,
          matchlists: state.matchlists.matchlists.filter(
            matchlist => matchlist._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default MatchList
