import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"
import moment from "moment"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"
import Select from "react-select"

//actions

import "../../assets/scss/datatables.scss"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useSiteVersion } from "hooks/useSiteVersion"
import DeleteModal from "components/Common/DeleteModal"
import { defaultImg } from "assets/images"
import {
  deleteLeadNotes,
  deleteSale,
  getClients,
  getExelDownloadSale,
  getLeadHistorys,
  getLeadNotes,
  getSales,
  getVehicles,
} from "store/actions"
import CreateForm from "./Crud/Update/Update"
import ExcelDownload from "hooks/useExcelDownloadButton"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const AllSale = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [SearchText, setSearchText] = useState("")

  const [client, setClient] = useState("Select Client...")
  const [clientId, setClientId] = useState("")
  const [clientSearch, setClientSearch] = useState("")

  const [vehicle, setVehicle] = useState("Select Vehicle...")
  const [vehicleId, setVehicleId] = useState("")
  const [vehicleSearch, setVehicleSearch] = useState("")

  const role = sessionStorage.getItem("role")

  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  const { AllSales, total, loading, AllSaleExel, allClients, allVehicle } =
    useSelector(state => ({
      AllSales: state.Sale.sales?.sales,
      total: state.Sale.sales?.total,
      loading: state.Sale.loading,
      AllSaleExel: state?.ExelDownload?.exeldownloadSale?.sales,
      allClients: state?.Client?.clients?.clients,
      allVehicle: state.Vehicle.vehicles?.vehicles,
    }))

  useEffect(() => {
    if (
      role === "admin" ||
      role === "generalManager" ||
      role === "managingDirector"
    ) {
      dispatch(
        getExelDownloadSale(
          page,
          totalItemLimit,
          SearchText,
          clientId,
          vehicleId,
          handleDateSearch()
        )
      )
    }
  }, [
    dispatch,
    page,
    totalItemLimit,
    SearchText,
    clientId,
    vehicleId,
    finalDateFormated,
  ])

  const handleDateSearch = () => {
    if (
      finalDateFormated?.from?.length >= 1 &&
      finalDateFormated?.to?.length >= 1
    ) {
      return finalDateFormated
    } else {
      return {
        from: "",
        to: "",
      }
    }
  }

  //delete
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })
  const [formModal, setFormModal] = useState({ status: false, data: "" })

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteSale(isOpen?.id, history))
  }
  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }
  const updatedLocation = {
    ...location,
    state: {
      ...location.state,
      page: 1,
    },
  }

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getSales(
        page,
        totalItemLimit,
        SearchText,
        clientId,
        vehicleId,
        handleDateSearch()
      )
    )
  }, [
    dispatch,
    page,
    totalItemLimit,
    SearchText,
    clientId,
    vehicleId,
    finalDateFormated,
  ])

  const columns = [
    {
      dataField: "no",
      text: "#",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },

    {
      dataField: "client",
      text: "Client",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "vehicle_image",
      text: "image",
      sort: true,
    },
    {
      dataField: "vehicle",
      text: "Vehicle",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const headers = [
    { label: "No", key: "no" },
    { label: "Date", key: "createdAt" },
    { label: "Vehicle", key: "vehicle_name" },
    { label: "Client Name", key: "client_name" },
    { label: "Client Email", key: "client_email" },
    { label: "Client Whatsapp Number", key: "client_whatsappNumber" },
  ]

  const formattedCreateDates = item => {
    return moment(item?.createdAt).format("DD/MM/YYYY h:m:a")
  }

  const excelDownloadSales = map(AllSaleExel, (item, key) => ({
    // change this allLeads to corresponding sate
    ...item,
    no: key + 1,
    createdAt: formattedCreateDates(item),
    client_name: item?.client?.name,
    client_phone: item?.client?.phone,
    client_email: item?.client?.email,
    client_whatsappNumber: item?.client?.whatsappNumber,
    vehicle_name: item.vehicle?.name,
  }))

  console.log(AllSales)
  const saleData = map(
    AllSales?.length >= 1 ? AllSales : [],
    (item, index) => ({
      ...item,
      key: index,
      no: index + 1,
      phone: <span>{item?.client?.phone}</span>,
      createdAt: moment(item?.createdAt).format("DD/MM/YYYY - h:mm A"),
      vehicle_image: (
        <img
          className=" header-profile-user"
          style={{ width: "70px", height: "70px" }}
          src={item?.vehicle?.images[0]?.url || defaultImg}
        />
      ),
      vehicle: (
        <p>
          <Link to={`/vehicle/details/${item?.vehicle?._id}`}>
            {item?.vehicle?.name}
          </Link>
        </p>
      ),
      client: (
        <p>
          <Link to={`/client/details/${item?.client?._id}`}>
            {item?.client?.name}
          </Link>
        </p>
      ),
      action: (
        <div>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
            onClick={() => setFormModal({ status: true, data: item })}
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>

          {/* <Button
            id={`delete${index}`}
            type="button"
            color="white"
            className="ms-1 btn "
            onClick={() => {
              handleDelete(item?._id)
            }}
          >
            <i
              className="bx bx-trash me-2"
              style={{ color: "red", fontSize: "20px" }}
            />
          </Button> */}
        </div>
      ),
    })
  )

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  const debounceUserSearch = debounce(value => setSearchText(value), 600)

  // const handleTableColumn = () => {
  //   if (sort === "store-admin" || sort === "admin") {
  //     return storeAdminColums
  //   } else if (sort === "all") {
  //     return columns
  //   } else {
  //     return columns?.filter(i => i.dataField !== "roleData")
  //   }
  // }

  useEffect(() => {
    const currentLimit = localStorage.getItem("userLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("userLimit", e.target.value)
    }
  }

  const onCloseClick = () => {
    setFormModal({ status: false, data: "" })
  }

  useEffect(() => {
    dispatch(getClients("", "", clientSearch, "", ""))
  }, [dispatch, clientSearch])

  const clientOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allClients?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersClients = text => {
    setClientSearch(text)
  }

  const handlerClientFinalValue = event => {
    setClient(event?.label)
    setClientId(event?.value)
  }

  const bool = false
  useEffect(() => {
    dispatch(
      getVehicles("", "", vehicleSearch, "", "", "", "", "", "", "", "", "")
    )
  }, [dispatch, vehicleSearch])

  const handlerVehicleFinalValue = event => {
    setVehicle(event?.label)
    setVehicleId(event?.value)
    history.push(updatedLocation)
  }

  const vehicleOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allVehicle?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersVehicle = text => {
    setVehicleSearch(text)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <CreateForm
        show={formModal?.status}
        onCloseClick={onCloseClick}
        updateData={formModal?.data}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={saleData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="d-flex align-items-center justify-content-between py-3 px-2">
                        <div className="d-flex w-50">
                          {/* <div className="w-100">
                            <div className="search-box mb-2 d-flex ">
                              <div className="position-relative ">
                                <AvForm>
                                  <AvField
                                    name="searchText"
                                    placeholder="Search"
                                    type="text"
                                    onChange={e =>
                                      debounceUserSearch(e.target.value)
                                    }
                                  />
                                </AvForm>
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="w-75">
                            <Select
                              onInputChange={handleEntersClients}
                              value={client}
                              placeholder={client}
                              onChange={handlerClientFinalValue}
                              options={clientOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div> */}
                          <div className="w-75 px-1">
                            <Select
                              onInputChange={handleEntersVehicle}
                              value={vehicle}
                              placeholder={vehicle}
                              onChange={handlerVehicleFinalValue}
                              options={vehicleOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                          <div>
                            <AvForm className="form-horizontal mx-2 mb-0 d-flex align-items-center">
                              <div className="mx-1">
                                <span className="">
                                  Show
                                  <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                                </span>
                              </div>
                              <div className="" style={{ width: "50px" }}>
                                <AvField
                                  name="pageLimit"
                                  className="form-control"
                                  value={totalItemLimit}
                                  onChange={e => handlePageLimit(e)}
                                  type="text"
                                  required
                                />
                              </div>
                            </AvForm>
                          </div>
                        </div>
                        <AvForm style={{ marginTop: "-20px" }}>
                          <span className="mt-1 ">Sort By Date</span>
                          <div className={`d-flex date_wrapper  `}>
                            <div className="d-flex align-items-start position-relative">
                              <AvField
                                name="dateRange"
                                type="date"
                                validate={{
                                  dateRange: {
                                    start: { value: -5, units: "years" },
                                    end: { value: 5, units: "years" },
                                  },
                                }}
                                onChange={e => {
                                  setFinalDateFormated({
                                    ...finalDateFormated,
                                    from: e.target.value,
                                  })
                                  history.push(updatedLocation)
                                }}
                                value={finalDateFormated?.from}
                              />
                              <button
                                onClick={() => {
                                  setFinalDateFormated({
                                    ...finalDateFormated,
                                    from: "",
                                  })
                                  history.push(updatedLocation)
                                }}
                                type="button"
                                className="btn btn-white editable-cancel date_close btn-sm"
                              >
                                <i className="mdi mdi-close text-danger "></i>
                              </button>
                            </div>
                            <div className="d-flex align-items-start position-relative">
                              <AvField
                                name="dateRange"
                                className="mx-1"
                                type="date"
                                validate={{
                                  dateRange: {
                                    start: { value: -5, units: "years" },
                                    end: { value: 5, units: "years" },
                                  },
                                }}
                                onChange={e =>
                                  setFinalDateFormated({
                                    ...finalDateFormated,
                                    to: e.target.value,
                                  })
                                }
                                value={finalDateFormated?.to}
                              />
                              <button
                                onClick={() =>
                                  setFinalDateFormated({
                                    ...finalDateFormated,
                                    to: "",
                                  })
                                }
                                type="button"
                                className="btn btn-white editable-cancel date_close btn-sm"
                              >
                                <i className="mdi mdi-close text-danger "></i>
                              </button>
                            </div>
                          </div>
                        </AvForm>

                        <div className="pb-0 d-flex align-items-center justify-content-end">
                          <div>
                            <Button
                              color="warning"
                              className="px-4"
                              onClick={() =>
                                setFormModal({ status: true, data: "" })
                              }
                            >
                              <i className="mdi mdi-plus me-1" />
                              New Sale
                            </Button>
                          </div>
                        </div>
                      </div>

                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {AllSales?.length ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p
                              className="my-4 mx-3 "
                              style={{ textTransform: "capitalize" }}
                            >
                              No Sales!
                            </p>
                          )}
                          <div className="d-flex align-items-center justify-content-between px-4">
                            <div className="w-75">
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            </div>
                            {role === "admin" ||
                            role === "generalManager" ||
                            role === "managingDirector" ? (
                              <div>
                                {AllSales?.length >= 0 ? (
                                  <ExcelDownload
                                    title="Sale Reports"
                                    headers={headers}
                                    excelDownloadData={excelDownloadSales}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllSale
