import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_CATEGORIES,
  GET_CATEGORIES_DETAILS,
  CREATE_CATEGORIES,
  UPDATE_CATEGORIES,
  DELETE_CATEGORIES,
} from "./actionTypes"
import {
  getCategoriesSuccess,
  getCategoriesFail,
  getCategoriesDetailsSuccess,
  getCategoriesDetailsFail,
  createCategoriesSuccess,
  createCategoriesFail,
  updateCategoriesSuccess,
  updateCategoriesFail,
  deleteCategoriesFail,
  deleteCategoriesSuccess,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const getCategoriesApi = ({ page, limit, searchText }) => {
  return get(
    `/category/admin/all?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}`
  )
}

const categoriesDetailsApi = categoriesId => {
  return get(`/category/admin/${categoriesId}`)
}

const createCategoriesApi = ({ categories }) => {
  return post("/category/admin/new", categories)
}

const updateCategoriesApi = ({ categories, categoryId }) => {
  return ApiPut(`/category/admin/${categoryId}`, categories)
}

const deleteCategoriesApi = ({ categoriesId }) => {
  return del(`/category/admin/${categoriesId}`)
}

function* fetchCategories({ payload }) {
  try {
    const response = yield call(getCategoriesApi, payload)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* fetchCategoriesDetails({ payload: categoriesId }) {
  try {
    const response = yield call(categoriesDetailsApi, categoriesId)
    yield put(getCategoriesDetailsSuccess(response))
  } catch (error) {
    yield put(getCategoriesDetailsFail(error))
  }
}

function* onCreateCategories({ payload }) {
  console.log(payload, "payload")
  try {
    const response = yield call(createCategoriesApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Category Created Successfully",
        title: "",
      })
      yield put(createCategoriesSuccess(response))
      if (payload.history) {
        payload?.history.push("/categories")
      }
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "File Size is too Large",
        title: "",
      })
    }
    yield put(createCategoriesFail(error))
  }
}

function* onUpdateCategories({ payload }) {
  console.log(payload, "update payload")
  try {
    const response = yield call(updateCategoriesApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Category Updated Successfully",
        title: "",
      })
      yield put(updateCategoriesSuccess(response))
      console.log(history, "history")
      if (payload.history) {
        payload?.history.push("/categories")
      }
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is too large",
        title: "",
      })
    }
    yield put(updateCategoriesFail(error))
  }
}

function* onDeleteCategories({ payload: categoriesId }) {
  try {
    const response = yield call(deleteCategoriesApi, categoriesId)
    yield put(deleteCategoriesSuccess(response))
    Notification({
      type: "success",
      message: "Categories Deleted Successfully",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteCategoriesFail(error?.response?.data?.message))
  }
}

function* categoriesSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(GET_CATEGORIES_DETAILS, fetchCategoriesDetails)
  yield takeEvery(CREATE_CATEGORIES, onCreateCategories)
  yield takeEvery(UPDATE_CATEGORIES, onUpdateCategories)
  yield takeEvery(DELETE_CATEGORIES, onDeleteCategories)
}

export default categoriesSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
