import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
  Badge,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"
import { PiNotepad } from "react-icons/pi"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import ClientModal from "./Crud/Modal"
import { GiTeamIdea } from "react-icons/gi"
import { GrTask } from "react-icons/gr"
import { BiSolidPhoneCall } from "react-icons/bi"
import { MdAddCall } from "react-icons/md"
import { MdNoteAdd } from "react-icons/md"
import { MdAddTask } from "react-icons/md"
import { IoIosAdd } from "react-icons/io"
import { IoCarSportOutline } from "react-icons/io5"
import { MdOutlineSell } from "react-icons/md"

import {
  deleteClient,
  getBrands,
  getClients,
  getLeads,
  getMatchLists,
  getModels,
  getUsers,
  getVehicles,
  updateMatchList,
} from "store/actions"
import { getallMatchList, updateFollowUp } from "store/followUp/actions"
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import FollowUpModal from "./Crud/Modal"
import { useCopyText } from "hooks/useCopyText"

const MatchingListTable = () => {
  const role = sessionStorage.getItem("role")
  const dispatch = useDispatch()
  const history = useHistory()
  const { copyText } = useCopyText()

  const location = useLocation()
  const params = useParams()
  const [page, setPage] = useState(1)
  const [followUpModal, setFollowUpModal] = useState({
    status: false,
    data: {},
    value: "",
  })

  const [checkbox, setCheckbox] = useState("")

  const [searchText, setSearchText] = useState("")
  const [totalListLimit, setTotalListLimit] = useState(10)

  const [vehicle, setVehicle] = useState("Select Vehicle...")
  const [vehicleId, setVehicleId] = useState("")
  const [vehicleSearch, setVehicleSearch] = useState("")

  const [sort, setSort] = useState("active")
  const [typeValue, setTypeValue] = useState("all")
  const [staffRole, setStaffRole] = useState("member")

  const [modelValue, setModelValue] = useState("Select Model...")
  const [modelId, setModelValueId] = useState("")
  const [modelSearch, setModelSearch] = useState("")

  const [leadValue, setLeadValue] = useState("Select Lead...")
  const [leadId, setLeadId] = useState("")
  const [leadSearch, setLeadSearch] = useState("")

  const [brandValue, setBrandValue] = useState("Select Brand...")
  const [brandId, setBrandId] = useState("")
  const [brandSearch, setBrandSearch] = useState("")

  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  const { loading, allMatchList, brand, model, allVehicle, allLeads } =
    useSelector(state => ({
      loading: state.MatchList.loading,
      allMatchList: state?.MatchList?.matchingLists,
      brand: state.Brand.brands?.brands,
      model: state.Model.models?.models,
      allVehicle: state.Vehicle.vehicles?.vehicles,
      allLeads: state?.Lead?.leads?.leads,
    }))

  const totalPages = Math.ceil(allMatchList?.total / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getMatchLists(
        page,
        sort,
        totalListLimit,
        // typeValue,
        vehicleId,
        brandId,
        modelId,
        leadId
      )
    )
  }, [
    dispatch,
    page,
    sort,
    totalListLimit,
    // typeValue,
    vehicleId,
    brandId,
    modelId,
    leadId,
  ])

  console.log(finalDateFormated)

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteClient(isOpen?.id))
  }

  // const handleFollowUpStatus = status => {
  //   switch (status) {
  //     case "scheduled":
  //       return "SCHEDULED"
  //     case "pending":
  //       return "PENDING"
  //     case "done":
  //       return "DONE"
  //   }
  // }
  const columns = [
    {
      dataField: "id",
      text: "Mark as Done",
      sort: true,
    },
    {
      dataField: "lead",
      text: "Lead",
      sort: true,
    },
    {
      dataField: "vehicle",
      text: "Vehicle",
      sort: true,
    },
    {
      dataField: "brand.name",
      text: "Brand",
      sort: true,
    },
    {
      dataField: "model.name",
      text: "Model",
    },
    {
      dataField: "variant",
      text: "Variant",
      sort: true,
    },

    // {
    //   dataField: "isActive",
    //   text: "Status",
    //   sort: true,
    // },

    {
      dataField: "action",
      text: "Action",
    },
  ]

  const handleCopy = text => {
    copyText(text)
  }
  const bool = false

  useEffect(() => {
    dispatch(
      getVehicles("", "", vehicleSearch, "", "", "", "", "", "", "", "", bool)
    )
  }, [dispatch, vehicleSearch, bool])

  const handlerVehicleFinalValue = event => {
    setVehicle(event?.label)
    setVehicleId(event?.value)
  }

  const vehicleOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allVehicle?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersVehicle = text => {
    setVehicleSearch(text)
  }

  useEffect(() => {
    dispatch(getBrands("", "", "", brandSearch))
  }, [dispatch, brandSearch])

  const handleEnter = textEntered => {
    setBrandSearch(textEntered)
  }

  function handlerFinalValuesBrand(event) {
    setBrandValue(event.label)
    setBrandId(event.value)
  }

  const brandOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (brand?.length >= 1 ? brand : [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getModels("", "", modelSearch, brandId))
  }, [dispatch, modelSearch, brandId])

  const handleEnters = textEntered => {
    setModelSearch(textEntered)
  }

  function handlerFinalValueModel(event) {
    setModelValue(event.label)
    setModelValueId(event.value)
  }

  const modelOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (model?.length >= 1 ? model : [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getLeads("", "", "", leadSearch, "", "", "", "", "", ""))
  }, [dispatch, leadSearch])

  const handleEntersLead = textEntered => {
    setLeadSearch(textEntered)
  }

  function handlerFinalValueLead(event) {
    setLeadValue(event.label)
    setLeadId(event.value)
  }

  const leadOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (allLeads?.length >= 1 ? allLeads : [])?.map(
        (results, index) => ({
          key: index,
          label: `${results.client?.name} ${results.vehicle?.name ? "-" : ""} ${
            results.vehicle?.name || ""
          }`,
          value: results._id,
        })
      ),
    },
  ]

  // const handleFollowUpStatusColor = color => {
  //   switch (color) {
  //     case "scheduled":
  //       return "info"
  //     case "pending":
  //       return "warning"
  //     case "done":
  //       return "success"

  //     default:
  //       return "info"
  //   }
  // }

  const handleFollowUpType = type => {
    switch (type) {
      case "call":
        return <BiSolidPhoneCall className="fs-3" />
      case "meeting":
        return <GiTeamIdea className="fs-3" />
      case "task":
        return <PiNotepad className="fs-3" />
      case "other":
        return ""

      default:
        return ""
    }
  }

  console.log(checkbox)

  const handleCheckbox = matchingId => {
    setCheckbox(matchingId)
    const updateStatus = {
      isActive: false,
    }
    dispatch(updateMatchList(updateStatus, matchingId, ""))
  }

  const handleColumns = () => {
    if (role === "salesManager") {
      return columns?.filter(i => i.dataField !== "action")
    } else return columns
  }

  console.log(allMatchList)

  const tableData = map(allMatchList?.matchingLists, (item, index) => ({
    ...item,
    id:
      item?.isActive === true ? (
        <AvForm>
          <UncontrolledTooltip placement="bottom" target="check">
            Mark as Done
          </UncontrolledTooltip>
          <AvField
            id="check"
            name="checkbox"
            type="checkbox"
            defaultChecked={false}
            onChange={e => handleCheckbox(item?._id)}
            className="checkbox-custom"
          />
        </AvForm>
      ) : (
        ""
      ),
    vehicle: item?.vehicle?.name ? <span> {item?.vehicle?.name} </span> : "---",
    variant: item?.variant?.name ? <span> {item?.variant?.name} </span> : "---",
    lead: item?.lead?.client.name ? (
      <span> {item?.lead?.client.name} </span>
    ) : (
      "---"
    ),
    action: (
      <Link to={`/matching-list/details/${item?._id}`}>
        <Button
          title="edit"
          id={`view${index}`}
          type="button"
          color="white"
          className="ms-1 btn"
          // onClick={() =>
          //   setFollowUpModal({
          //     status: true,
          //     value: item?.type,
          //     data: item,
          //   })
          // }
        >
          <i
            className="bx bx-show-alt me-2"
            style={{ color: "green", fontSize: "24px" }}
          />
        </Button>
        {/* {role === "admin" ||
        role === "generalManager" ||
        role === "managingDirecter" ? (
          <>
            <Button
              id={`Delete${index}`}
              type="button"
              color="white"
              className="ms-1 btn "
              onClick={() => {
                handleDelete(item._id)
              }}
            >
              <i
                className="bx bx-trash me-2"
                style={{
                  color: "red",
                  marginBottom: "10px",
                  fontSize: "1.3rem",
                }}
              />
            </Button>
          </>
        ) : (
          ""
        )} */}
      </Link>
    ),
  }))

  const debounceClientSearch = debounce(value => setSearchText(value), 600)

  const handlePageLimit = count => {
    setTotalListLimit(count)
  }

  const statusValues = [
    { label: "Matching List", value: "active" },
    { label: "History", value: "inActive" },
  ]

  const handleStatusController = value => {
    switch (role) {
      case "admin":
        return value
      case "generalManager":
        return value
      case "managingDirecter":
        return value
      case "branchManager":
        return ""
    }
  }

  const listChangeFollowup = sortValue => {
    setSort(sortValue)
    console.log(sortValue)
    if (location?.pathname === "/dashboard") {
      return ""
    } else {
      history?.push({
        pathname: location.pathname,
        search: `p=${page}&s=${sortValue}`,
        state: {
          page: page,
          sort: handleStatusController(sortValue),
        },
      })
    }
  }

  // useEffect(() => {
  //   if (params?.id) {
  //     setSort("")
  //   } else {
  //     setSort("scheduled")
  //   }
  // }, [params?.id])

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <FollowUpModal
          onCloseClick={() => setFollowUpModal({ status: false, data: {} })}
          show={followUpModal?.status}
          updateData={followUpModal?.data}
          type={followUpModal?.value}
        />
        <Col className="col-12 ">
          <Card style={{ minHeight: "245px" }}>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={handleColumns()}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {!params?.id && (
                      <>
                        <Row>
                          <Col className="mb-0 ">
                            {map(
                              statusValues,
                              (head, key) => (
                                console.log(head),
                                (
                                  <>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name={`btnradio${key}`}
                                      id={key + "1"}
                                      autoComplete="off"
                                      checked={sort === head?.value}
                                      onChange={() =>
                                        listChangeFollowup(head?.value, key)
                                      }
                                    />
                                    <label
                                      className="btn btn-warning "
                                      htmlFor={key + "1"}
                                      style={{
                                        borderRadius: 1,
                                      }}
                                    >
                                      {head?.label}
                                      <Badge
                                        className={`font-size-12 ms-2 ${
                                          sort === head.value
                                            ? "badge-soft-light"
                                            : "badge-soft-light"
                                        }`}
                                        pill
                                      >
                                        {head?.count}
                                      </Badge>
                                    </label>
                                  </>
                                )
                              )
                            )}
                          </Col>
                        </Row>

                        {/* <div
                          className="btn-group mt-0 mt-xl-0 d-flex align-items-center justify-content-start"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {map(typeOptions, (item, key) => (
                            <div
                              key={key}
                              className="me-3 d-flex align-items-center"
                            >
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio2"
                                id={key + "value"}
                                autoComplete="off"
                                checked={typeValue === item.value}
                                value={item.value}
                                onChange={e => setTypeValue(e.target.value)}
                              />
                              <label
                                className="btn btn-outline-warning d-flex align-items-center justify-content-center"
                                htmlFor={key + "value"}
                              >
                                {item?.icon}
                                &nbsp;
                                {item.label}
                              </label>
                            </div>
                          ))}
                        </div> */}

                        <div className="d-flex  align-items-center">
                          <div className="mt-2">
                            <label></label>

                            <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center w-75">
                              <div className="mx-1">
                                <span className="">
                                  Show
                                  <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                                </span>
                              </div>
                              <div className="" style={{ width: "50px" }}>
                                <AvField
                                  name="pageLimit"
                                  className="form-control"
                                  value={totalListLimit}
                                  onChange={e =>
                                    handlePageLimit(e.target.value)
                                  }
                                  type="text"
                                  required
                                />
                              </div>
                            </AvForm>
                          </div>
                          {role === "admin" && (
                            <>
                              <div
                                className="w-20 text-align-center me-1"
                                style={{ width: "21%" }}
                              >
                                <label>Vehicle</label>
                                <Select
                                  onInputChange={handleEntersVehicle}
                                  value={vehicle}
                                  placeholder={vehicle}
                                  onChange={handlerVehicleFinalValue}
                                  options={vehicleOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={loading}
                                />
                              </div>
                            </>
                          )}

                          <div
                            className="w-20 text-align-center"
                            style={{ width: "21%" }}
                          >
                            <label>Lead</label>
                            <Select
                              onInputChange={handleEntersLead}
                              value={leadValue}
                              placeholder={leadValue}
                              onChange={handlerFinalValueLead}
                              options={leadOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />{" "}
                          </div>
                          {/* <div className="mx-1">
                            <AvForm style={{ marginTop: 8 }}>
                              <span className="mt-1">Sort By Date</span>
                              <div className={`d-flex date_wrapper `}>
                                <div className="d-flex align-items-start position-relative">
                                  <AvField
                                    name="dateRange"
                                    type="date"
                                    validate={{
                                      dateRange: {
                                        start: { value: -5, units: "years" },
                                        end: { value: 5, units: "years" },
                                      },
                                    }}
                                    onChange={e =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        from: e.target.value,
                                      })
                                    }
                                    value={finalDateFormated?.from}
                                  />
                                  <button
                                    onClick={() =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        from: "",
                                      })
                                    }
                                    type="button"
                                    className="btn btn-white editable-cancel date_close btn-sm"
                                  >
                                    <i className="mdi mdi-close text-danger "></i>
                                  </button>
                                </div>
                                <div className="d-flex align-items-start position-relative">
                                  <AvField
                                    name="dateRange"
                                    className="mx-1"
                                    type="date"
                                    validate={{
                                      dateRange: {
                                        start: { value: -5, units: "years" },
                                        end: { value: 5, units: "years" },
                                      },
                                    }}
                                    onChange={e =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        to: e.target.value,
                                      })
                                    }
                                    value={finalDateFormated?.to}
                                  />
                                  <button
                                    onClick={() =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        to: "",
                                      })
                                    }
                                    type="button"
                                    className="btn btn-white editable-cancel date_close btn-sm"
                                  >
                                    <i className="mdi mdi-close text-danger "></i>
                                  </button>
                                </div>
                              </div>
                            </AvForm>
                          </div> */}
                          <div
                            className="w-20 text-align-center mx-1"
                            style={{ width: "21%" }}
                          >
                            <label>Brand</label>
                            <Select
                              onInputChange={handleEnter}
                              value={brandValue}
                              placeholder={brandValue}
                              onChange={handlerFinalValuesBrand}
                              options={brandOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />{" "}
                          </div>
                          <div
                            className="w-20 text-align-center mx-1"
                            style={{ width: "21%" }}
                          >
                            <label>Model</label>
                            <Select
                              onInputChange={handleEnters}
                              value={modelValue}
                              placeholder={modelValue}
                              onChange={handlerFinalValueModel}
                              options={modelOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />{" "}
                          </div>
                        </div>
                      </>
                    )}

                    {/* <div className="d-flex align-items-center justify-content-end py-3">

                      <div>
                        {location?.pathname !== "/followUp/all" &&
                          (location?.pathname !== "/dashboard" ? (
                            <>
                              {typeOptionsCreate?.map((item, key) => (
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded me-2 text-align-center "
                                  onClick={() =>
                                    setFollowUpModal({
                                      status: true,
                                      value: item?.value,
                                    })
                                  }
                                  key={key}
                                >
                                  <span> {item?.icon}</span>
                                  <span className="text-align-center">
                                    {" "}
                                    {item?.label}
                                  </span>
                                </Button>
                              ))}
                            </>
                          ) : null)}
                      </div>
                    </div> */}

                    {loading ? (
                      <div className="p-2">
                        {" "}
                        <Spinner color="secondary" className="d-block m-auto" />
                      </div>
                    ) : (
                      <>
                        {allMatchList?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive my-3">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No MatchingList !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default MatchingListTable

const typeOptions = [
  { label: "All", value: "all", icon: "" },
  {
    label: "Vehicle Match",
    value: "vehicle_match",
    icon: <IoCarSportOutline style={{ fontSize: 14 }} />,
  },
  {
    label: "Sell Match",
    value: "sell_match",
    icon: <MdOutlineSell style={{ fontSize: 14 }} />,
  },
]

const typeOptionsCreate = [
  { label: "Call", value: "call", icon: <MdAddCall className="fs-5" /> },
  { label: "Meeting", value: "meeting", icon: <MdNoteAdd className="fs-5" /> },
  { label: "Task", value: "task", icon: <MdAddTask className="fs-5" /> },
  { label: "Other", value: "other", icon: <IoIosAdd className="fs-5" /> },
]
