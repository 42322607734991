import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_VARIANTS,
  GET_VARIANT_DETAILS,
  CREATE_VARIANT,
  UPDATE_VARIANT,
  DELETE_VARIANT,
} from "./actionTypes"
import {
  getVariantsFail,
  getVariantsSuccess,
  getVariantDetailsSuccess,
  getVariantDetailsFail,
  createVariantFail,
  createVariantSuccess,
  updateVariantSuccess,
  updateVariantFail,
  deleteVariantSuccess,
  deleteVariantFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Variant - This line cannot be edited or removed
function getVariantsAPi({ page, sort, limit, searchText, modelValueId }) {
  return get(
    `/variant/admin/all?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}&model=${modelValueId ? modelValueId : ""}`
  )
}

const getVariantDetailsAPi = variantId => {
  return get(`/variant/admin/single/${variantId}`)
}

const createVariantApi = ({ variant }) => {
  return post("/variant/admin/new", variant)
}

const updateVariantApi = ({ variant, variantId }) => {
  return ApiPut(`/variant/admin/${variantId}`, variant)
}

const deleteVariantApi = ({ variantId }) => {
  return del(`/variant/admin/${variantId}`)
}

function* fetchVariants({ payload }) {
  try {
    const response = yield call(getVariantsAPi, payload)
    yield put(getVariantsSuccess(response))
  } catch (error) {
    yield put(getVariantsFail(error))
  }
}

function* fetchVariantDetails({ payload: variantId }) {
  try {
    const response = yield call(getVariantDetailsAPi, variantId)
    yield put(getVariantDetailsSuccess(response))
  } catch (error) {
    yield put(getVariantDetailsFail(error))
  }
}

function* onCreateVariant({ payload }) {
  try {
    const response = yield call(createVariantApi, payload)

    yield put(createVariantSuccess(response))
    doneNotification("Variant Created Successfully!")
    payload?.onCloseClick()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createVariantFail(error))
  }
}

function* onUpdateVariant({ payload }) {
  try {
    const response = yield call(updateVariantApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Variant Updated Successfully!",
        title: "",
      })
      yield put(updateVariantSuccess(response))
      if (payload.onCloseClick) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateVariantFail(error.response.data))
  }
}

function* onDeleteVariant({ payload: variantId }) {
  console.log(variantId)
  try {
    const response = yield call(deleteVariantApi, variantId)
    yield put(deleteVariantSuccess(response))
    Notification({
      type: "success",
      message: "Variant Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteVariantFail(error?.response?.data))
  }
}

function* varientSaga() {
  yield takeEvery(GET_VARIANTS, fetchVariants)
  yield takeEvery(GET_VARIANT_DETAILS, fetchVariantDetails)
  yield takeEvery(CREATE_VARIANT, onCreateVariant)
  yield takeEvery(UPDATE_VARIANT, onUpdateVariant)
  yield takeEvery(DELETE_VARIANT, onDeleteVariant)
}

export default varientSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
