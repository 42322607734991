/* LeadNotess - This line cannot be edited or removed */
export const GET_LEADNOTES = "GET_LEADNOTES"
export const GET_LEADNOTES_SUCCESS = "GET_LEADNOTES_SUCCESS"
export const GET_LEADNOTES_FAIL = "GET_LEADNOTES_FAIL"

/* LeadNotes_DETAILS */
export const GET_LEADNOTES_DETAILS = "GET_LEADNOTES_DETAILS"
export const GET_LEADNOTES_DETAILS_SUCCESS = "GET_LEADNOTES_DETAILS_SUCCESS"
export const GET_LEADNOTES_DETAILS_FAIL = "GET_LEADNOTES_DETAILS_FAIL"

/* CREATE LeadNotes */
export const CREATE_LEADNOTES = "CREATE_LEADNOTES"
export const CREATE_LEADNOTES_SUCCESS = "CREATE_LEADNOTES_SUCCESS"
export const CREATE_LEADNOTES_FAIL = "CREATE_LEADNOTES_FAIL"

/* Edit LeadNotes */
export const UPDATE_LEADNOTES = "UPDATE_LEADNOTES"
export const UPDATE_LEADNOTES_SUCCESS = "UPDATE_LEADNOTES_SUCCESS"
export const UPDATE_LEADNOTES_FAIL = "UPDATE_LEADNOTES_FAIL"

/* Delete LeadNotes */
export const DELETE_LEADNOTES = "DELETE_LEADNOTES"
export const DELETE_LEADNOTES_SUCCESS = "DELETE_LEADNOTES_SUCCESS"
export const DELETE_LEADNOTES_FAIL = "DELETE_LEADNOTES_FAIL"
