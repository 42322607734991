import {
  GET_MODELS,
  GET_MODELS_SUCCESS,
  GET_MODELS_FAIL,
  GET_MODEL_DETAILS,
  GET_MODEL_DETAILS_SUCCESS,
  GET_MODEL_DETAILS_FAIL,
  CREATE_MODEL,
  CREATE_MODEL_SUCCESS,
  CREATE_MODEL_FAIL,
  UPDATE_MODEL,
  UPDATE_MODEL_SUCCESS,
  UPDATE_MODEL_FAIL,
  DELETE_MODEL,
  DELETE_MODEL_SUCCESS,
  DELETE_MODEL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  models: {
    models: [],
    total: "",
    page: "",
  },
  modelDetails: {},
  error: {},
  loading: false,
}

const Model = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Model - This line cannot be edited or removed
    case GET_MODELS:
    case GET_MODEL_DETAILS:
    case CREATE_MODEL:
    case UPDATE_MODEL:
    case DELETE_MODEL:
      return {
        ...state,
        loading: true,
      }

    case DELETE_MODEL_FAIL:
    case GET_MODELS_FAIL:
    case GET_MODEL_DETAILS_FAIL:
    case UPDATE_MODEL_FAIL:
    case CREATE_MODEL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MODELS_SUCCESS:
      return {
        ...state,
        models: action.payload,
        error: {},
        loading: false,
      }

    case GET_MODEL_DETAILS_SUCCESS:
      return {
        ...state,
        modelDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_MODEL_SUCCESS:
      return {
        ...state,
        models: {
          ...state.models,
          models: [...state.models.models, action.payload],
          total: state.models.total + 1,
        },
        modelDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_MODEL_SUCCESS:
      return {
        ...state,
        models: {
          ...state.models,
          models: state.models.models.map(model =>
            model._id === action.payload._id
              ? { ...model, ...action.payload }
              : model
          ),
        },
        modelDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_MODEL_SUCCESS:
      return {
        ...state,
        models: {
          ...state.models,
          models: state.models.models.filter(
            model => model._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Model
