import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { defaultImg } from "assets/images"
import moment from "moment"
import {
  deleteCategories,
  deleteClient,
  deleteMember,
  getCategories,
} from "store/actions"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const Categories = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [totalListLimit, setTotalListLimit] = useState("10")
  const [searchText, setSearchText] = useState("")
  const [memberRole, setMemberRole] = useState("Select Roles...")
  const [sortValues, setSortValues] = useState("member")

  const { Categories, loading, total } = useSelector(state => ({
    Categories: state?.Categories?.categories?.categories,
    total: state?.Categories?.categories?.total,
    loading: state.Categories.loading,
  }))

  console.log(Categories, "categories")

  const totalPages = Math.ceil(total / totalListLimit)
  const pages = range(1, totalPages + 1)

  const sort = sortValues

  console.log(sort)

  useEffect(() => {
    dispatch(getCategories(page, totalListLimit, searchText, "", sort))
  }, [dispatch, page, totalListLimit, searchText, sort])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })
  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteCategories(isOpen?.id))
  }

  console.log(isOpen)

  const columns = [
    {
      dataField: "id",
      text: "#",
    },

    {
      dataField: "createdAt",
      text: "Joined on",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "webBanner",
      text: "Web Banner",
      sort: true,
    },
    {
      dataField: "appBanner",
      text: "App Banner",
      sort: true,
    },
    {
      dataField: "logoImages",
      text: "Logo Image",
      sort: true,
    },
    // {
    //   dataField: "description",
    //   text: "Description",
    //   sort: true,
    // },

    {
      dataField: "action",
      text: "Action",
    },
  ]

  const debounceUserSearch = debounce(value => setSearchText(value), 600)

  const handlePageLimit = e => {
    console.log("count =>", e)
    setTotalListLimit(e)
  }

  const listChange = sortValue => {
    setSortValues(sortValue)
    console.log(sortValue)
    history?.push({
      pathname: location.pathname,
      search: `p=${page}&s=${sortValue}`,
      state: {
        page: page,
        sort: sortValue,
      },
    })
  }

  const handleRoleFormatted = role => {
    switch (role) {
      case "enquiryManager":
        return "Enquiry Manager"
      case "enquiryAnalyst":
        return "Enquiry Analyst"
      case "salesManager":
        return "Sales Manager"
      case "salesExecutive":
        return "Sales Executive"
      case "managingDirector":
        return "Managing Director"
      case "generalManager":
        return "General Manager"
    }
  }

  const tableData = map(
    Categories,
    (item, index) => (
      console.log(item, "item"),
      {
        ...item,
        id: index + 1,
        createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
        webBanner: (
          <img
            className="d-block header-profile-user"
            style={{ width: "300px", height: "200px" }}
            src={
              item?.webBanners && item?.webBanners[0]
                ? item?.webBanners[0]?.url
                : defaultImg
            }
          />
        ),
        appBanner: (
          <img
            className="d-block header-profile-user"
            style={{ width: "150px", height: "100px" }}
            src={
              item?.appBanners && item?.appBanners[0]
                ? item?.appBanners[0]?.url
                : defaultImg
            }
          />
        ),
        logoImages: (
          <img
            className="d-block header-profile-user"
            style={{ width: "70px", height: "70px", borderRadius: "100%" }}
            src={
              item?.logoImages && item?.logoImages[0]
                ? item?.logoImages[0]?.url
                : defaultImg
            }
          />
        ),
        action: (
          <div>
            <Link to={`/categories/update/${item._id}`} className="btn-md">
              <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
            </Link>
            <Button
              id={`Delete${index}`}
              type="button"
              color="white"
              className="ms-1 btn"
              onClick={() => {
                handleDelete(item?._id)
              }}
            >
              <i
                className="bx bx-trash me-2"
                style={{
                  color: "red",
                  marginBottom: "10px",
                  fontSize: "1.3rem",
                }}
              />
            </Button>
          </div>
        ),
      }
    )
  )

  const optionsRoleMember = [
    { label: "All", value: "member" },
    // { label: "Client", value: "client" },
    // { label: "Member", value: "member" },
    { label: "Enquiry Manager", value: "enquiryManager" },
    { label: "Enquiry Analyst", value: "enquiryAnalyst" },
    { label: "Sales Manager", value: "salesManager" },
    { label: "Sales Executive", value: "salesExecutive" },
    { label: "Managing Director", value: "managingDirector" },
    { label: "General Manager", value: "generalManager" },
  ]

  // const handlerRoleFinalValues = event => {
  //   console.log(event)
  //   setMemberRole(event?.label)
  //   setMemberValue(event?.value)
  // }

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {/* <div className="py-2">
                        {map(optionsRoleMember, (head, key) => (
                          <>
                            <input
                              type="radio"
                              className="btn-check"
                              name="btnradio"
                              id={key + 1}
                              autoComplete="off"
                              checked={sortValues === head.value}
                              onChange={() => listChange(head?.value)}
                            />
                            <label
                              className="btn btn-warning"
                              htmlFor={key + 1}
                              style={{
                                borderRadius: 1,
                              }}
                            >
                              {head?.label}
                            </label>
                          </>
                        ))}
                      </div> */}
                    <div className="d-flex justify-content-between flex-row-reverse">
                      <div>
                        <Link
                          to={{
                            pathname: "/categories/create",
                            state: "create",
                          }}
                        >
                          <Button
                            type="button"
                            color="warning"
                            className="btn-rounded  mb-2 me-2"
                          >
                            <i className="mdi mdi-plus me-1" />
                            Add Category
                          </Button>
                        </Link>
                      </div>

                      <div className="d-flex">
                        <div>
                          {" "}
                          <div className="search-box mb-2 d-inline-block">
                            <div className="position-relative">
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e =>
                                    debounceUserSearch(e.target.value)
                                  }
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
                            <div className="mx-1">
                              <span className="">
                                Show
                                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                              </span>
                            </div>
                            <div className="" style={{ width: "50px" }}>
                              <AvField
                                name="pageLimit"
                                className="form-control"
                                value={totalListLimit}
                                onChange={e => handlePageLimit(e.target.value)}
                                type="text"
                                required
                              />
                            </div>
                          </AvForm>
                        </div>
                        {/* <div className="w-25">
                        <Select
                          // onInputChange={handleProductEnters}
                          value={memberRole}
                          placeholder={memberRole}
                          onChange={handlerRoleFinalValues}
                          options={optionsRoleMember}
                          classNamePrefix="select2-selection"
                          // isLoading={prodLoading}
                        />
                      </div> */}
                      </div>
                    </div>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Categories !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Categories
