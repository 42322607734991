/* Models - This line cannot be edited or removed */
export const GET_MODELS = "GET_MODELS"
export const GET_MODELS_SUCCESS = "GET_MODELS_SUCCESS"
export const GET_MODELS_FAIL = "GET_MODELS_FAIL"

/* Model_DETAILS */
export const GET_MODEL_DETAILS = "GET_MODEL_DETAILS"
export const GET_MODEL_DETAILS_SUCCESS = "GET_MODEL_DETAILS_SUCCESS"
export const GET_MODEL_DETAILS_FAIL = "GET_MODEL_DETAILS_FAIL"

/* CREATE Model */
export const CREATE_MODEL = "CREATE_MODEL"
export const CREATE_MODEL_SUCCESS = "CREATE_MODEL_SUCCESS"
export const CREATE_MODEL_FAIL = "CREATE_MODEL_FAIL"

/* Edit Model */
export const UPDATE_MODEL = "UPDATE_MODEL"
export const UPDATE_MODEL_SUCCESS = "UPDATE_MODEL_SUCCESS"
export const UPDATE_MODEL_FAIL = "UPDATE_MODEL_FAIL"

/* Delete Model */
export const DELETE_MODEL = "DELETE_MODEL"
export const DELETE_MODEL_SUCCESS = "DELETE_MODEL_SUCCESS"
export const DELETE_MODEL_FAIL = "DELETE_MODEL_FAIL"
