import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DatatableTables from "./DatatableTables"
import MetaTag from "components/Common/Meta-tag"
import PropTypes from "prop-types"

function LeadHistory() {
  return (
    <>
      <MetaTag title={"All Lead History"} />

      <div className="my-1">
        <DatatableTables />
      </div>
    </>
  )
}

export default LeadHistory

LeadHistory.propTypes = {
  leadDetails: PropTypes.obj,
}
