import {
  GET_VARIANTS,
  GET_VARIANTS_SUCCESS,
  GET_VARIANTS_FAIL,
  GET_VARIANT_DETAILS,
  GET_VARIANT_DETAILS_SUCCESS,
  GET_VARIANT_DETAILS_FAIL,
  CREATE_VARIANT,
  CREATE_VARIANT_SUCCESS,
  CREATE_VARIANT_FAIL,
  UPDATE_VARIANT,
  UPDATE_VARIANT_SUCCESS,
  UPDATE_VARIANT_FAIL,
  DELETE_VARIANT,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  variants: {
    variants: [],
    total: "",
    page: "",
  },
  varientDetails: {},
  error: {},
  loading: false,
}

const Varient = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Varient - This line cannot be edited or removed
    case GET_VARIANTS:
    case GET_VARIANT_DETAILS:
    case CREATE_VARIANT:
    case UPDATE_VARIANT:
    case DELETE_VARIANT:
      return {
        ...state,
        loading: true,
      }

    case DELETE_VARIANT_FAIL:
    case GET_VARIANTS_FAIL:
    case GET_VARIANT_DETAILS_FAIL:
    case UPDATE_VARIANT_FAIL:
    case CREATE_VARIANT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_VARIANTS_SUCCESS:
      return {
        ...state,
        variants: action.payload,
        error: {},
        loading: false,
      }

    case GET_VARIANT_DETAILS_SUCCESS:
      return {
        ...state,
        varientDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_VARIANT_SUCCESS:
      return {
        ...state,
        variants: {
          ...state.variants,
          variants: [...state.variants.variants, action.payload],
          total: state.variants.total + 1,
        },
        varientDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_VARIANT_SUCCESS:
      return {
        ...state,
        variants: {
          ...state.variants,
          variants: state.variants.variants.map(varient =>
            varient._id === action.payload._id
              ? { ...varient, ...action.payload }
              : varient
          ),
        },
        varientDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_VARIANT_SUCCESS:
      return {
        ...state,
        variants: {
          ...state.variants,
          variants: state.variants.variants.filter(
            varient => varient._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Varient
