import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_BRANDS,
  GET_BRAND_DETAILS,
  CREATE_BRAND,
  UPDATE_BRAND,
  DELETE_BRAND,
} from "./actionTypes"
import {
  getBrandsFail,
  getBrandsSuccess,
  getBrandDetailsSuccess,
  getBrandDetailsFail,
  createBrandFail,
  createBrandSuccess,
  updateBrandSuccess,
  updateBrandFail,
  deleteBrandSuccess,
  deleteBrandFail,
  getBrandSearchSuccess,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Brand - This line cannot be edited or removed
function getBrandsAPi({ page, sort, limit, searchText }) {
  return get(
    `/brand/admin/all/?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}`
  )
}

const getBrandDetailsAPi = brandId => {
  return get(`/brand/admin/single/${brandId}`)
}

const createBrandApi = ({ brand }) => {
  return post("/brand/admin/new", brand)
}

const updateBrandApi = ({ brand, brandId }) => {
  return ApiPut(`/brand/admin/${brandId}`, brand)
}

const deleteBrandApi = ({ brandId }) => {
  return del(`/brand/admin/${brandId}`)
}

function* fetchBrands({ payload }) {
  try {
    const response = yield call(getBrandsAPi, payload)
    if (payload?.searchText) {
      yield put(getBrandSearchSuccess(response))
    } else {
      yield put(getBrandsSuccess(response))
    }
  } catch (error) {
    yield put(getBrandsFail(error))
  }
}

function* fetchBrandDetails({ payload: brandId }) {
  try {
    const response = yield call(getBrandDetailsAPi, brandId)
    yield put(getBrandDetailsSuccess(response))
  } catch (error) {
    yield put(getBrandDetailsFail(error))
  }
}

function* onCreateBrand({ payload }) {
  try {
    const response = yield call(createBrandApi, payload)
    yield put(createBrandSuccess(response))
    payload?.onCloseClick()
    doneNotification("Brand Created Successfully!")
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createBrandFail(error))
  }
}

function* onUpdateBrand({ payload }) {
  try {
    const response = yield call(updateBrandApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Brand Updated Successfully!",
        title: "",
      })
      yield put(updateBrandSuccess(response))
      if (payload.history) {
        payload.history.push("/brands")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateBrandFail(error.response.data))
  }
}

function* onDeleteBrand({ payload: BrandId }) {
  try {
    const response = yield call(deleteBrandApi, BrandId)
    yield put(deleteBrandSuccess(response))
    Notification({
      type: "success",
      message: "Brand Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteBrandFail(error?.response?.data))
  }
}

function* brandSaga() {
  yield takeEvery(GET_BRANDS, fetchBrands)
  yield takeEvery(GET_BRAND_DETAILS, fetchBrandDetails)
  yield takeEvery(CREATE_BRAND, onCreateBrand)
  yield takeEvery(UPDATE_BRAND, onUpdateBrand)
  yield takeEvery(DELETE_BRAND, onDeleteBrand)
}

export default brandSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
