import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTag from "components/Common/Meta-tag"
import FollowUpDataTables from "./DataTables"

function FollowUp() {
  return (
    <>
      <MetaTag title={"All FollowUp"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All FollowUp" />
        <Container fluid>
          <div className="container-fluid">
            <FollowUpDataTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default FollowUp
