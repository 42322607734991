import React from "react"
import Details from "./Details"

function ClientDetails() {
  return (
    <div>
      <Details />
    </div>
  )
}

export default ClientDetails
