import {
  GET_VEHICLES,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  GET_VEHICLE_DETAILS,
  GET_VEHICLE_DETAILS_SUCCESS,
  GET_VEHICLE_DETAILS_FAIL,
  CREATE_VEHICLE,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAIL,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAIL,
  DELETE_VEHICLE,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAIL,
  RESET_STATE,
  CHECK_VEHICLE_NUMBER,
  CHECK_VEHICLE_NUMBER_FAIL,
  CHECK_VEHICLE_NUMBER_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  vehicles: {
    vehicles: [],
    total: "",
    page: "",
  },
  vehicleDetails: {},
  createVehicleData: {},
  error: {},
  loading: false,
  vehicleNumber: {},
}

const Vehicle = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Vehicle - This line cannot be edited or removed
    case GET_VEHICLES:
    case GET_VEHICLE_DETAILS:
    case CREATE_VEHICLE:
    case UPDATE_VEHICLE:
    case DELETE_VEHICLE:
    case CHECK_VEHICLE_NUMBER:
      return {
        ...state,
        loading: true,
      }

    case DELETE_VEHICLE_FAIL:
    case GET_VEHICLES_FAIL:
    case GET_VEHICLE_DETAILS_FAIL:
    case UPDATE_VEHICLE_FAIL:
    case CREATE_VEHICLE_FAIL:
    case CHECK_VEHICLE_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: action.payload,
        error: {},
        loading: false,
      }

    case GET_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        vehicleDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          vehicles: [...state.vehicles.vehicles, action.payload],
          total: state.vehicles.total + 1,
        },
        vehicleDetails: action.payload,
        createVehicleData: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        // vehicles: {
        //   ...state.vehicles,
        //   vehicles: state.vehicles.vehicles.map(vehicle =>
        //     vehicle._id === action.payload._id
        //       ? { ...vehicle, ...action.payload }
        //       : vehicle
        //   ),
        // },
        // vehicleDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          vehicles: state.vehicles.vehicles.filter(
            vehicle => vehicle._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }

    case CHECK_VEHICLE_NUMBER_SUCCESS:
      return {
        ...state,
        vehicleNumber: action.payload,
        error: {},
        loading: false,
      }
    case RESET_STATE:
      return {
        ...state,
        createVehicleData: {},
        vehicleDetails: {},
      }
    default:
      return state
  }
}

export default Vehicle
