import {
  GET_SALES,
  GET_SALES_SUCCESS,
  GET_SALES_FAIL,
  GET_SALE_DETAILS,
  GET_SALE_DETAILS_SUCCESS,
  GET_SALE_DETAILS_FAIL,
  CREATE_SALE,
  CREATE_SALE_SUCCESS,
  CREATE_SALE_FAIL,
  UPDATE_SALE,
  UPDATE_SALE_SUCCESS,
  UPDATE_SALE_FAIL,
  DELETE_SALE,
  DELETE_SALE_SUCCESS,
  DELETE_SALE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  sales: {
    sales: [],
    total: "",
    page: "",
  },
  saleDetails: {},
  error: {},
  loading: false,
}

const Sale = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Sale - This line cannot be edited or removed
    case GET_SALES:
    case GET_SALE_DETAILS:
    case CREATE_SALE:
    case UPDATE_SALE:
    case DELETE_SALE:
      return {
        ...state,
        loading: true,
      }

    case DELETE_SALE_FAIL:
    case GET_SALES_FAIL:
    case GET_SALE_DETAILS_FAIL:
    case UPDATE_SALE_FAIL:
    case CREATE_SALE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SALES_SUCCESS:
      return {
        ...state,
        sales: action.payload,
        error: {},
        loading: false,
      }

    case GET_SALE_DETAILS_SUCCESS:
      return {
        ...state,
        saleDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_SALE_SUCCESS:
      return {
        ...state,
        sales: {
          ...state.sales,
          sales: [...state.sales.sales, action.payload],
          total: state.sales.total + 1,
        },
        saleDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_SALE_SUCCESS:
      return {
        ...state,
        sales: {
          ...state.sales,
          sales: state.sales.sales.map(sale =>
            sale._id === action.payload._id
              ? { ...sale, ...action.payload }
              : sale
          ),
        },
        saleDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_SALE_SUCCESS:
      return {
        ...state,
        sales: {
          ...state.sales,
          sales: state.sales.sales.filter(
            sale => sale._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Sale
