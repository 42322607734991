import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Button, CardTitle, Col, Modal, ModalBody, Row } from "reactstrap"
import Select from "react-select"

//actions
import {
  createLeadHistory,
  createLeadNotes,
  createSale,
  getClients,
  getLeads,
  getMembers,
  getVehicles,
  updateLeadHistory,
  updateLeadNotes,
  updateSale,
} from "store/actions"
import PropTypes from "prop-types"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import ClientModal from "pages/Client/Crud/Modal"

const CreateForm = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const [clientModal, setClientModal] = useState({ status: false, data: {} })

  const [client, setClient] = useState("Select Client...")
  const [clientId, setClientId] = useState("")
  const [clientSearch, setClientSearch] = useState("")

  const [vehicle, setVehicle] = useState("Select vehicle...")
  const [vehicleId, setVehicleId] = useState("")
  const [vehicleSearch, setVehicleSearch] = useState("")

  const { allVehicle, VehicleLoading, allClient, clientLoading } = useSelector(
    state => ({
      allClient: state.Client.clients?.clients,
      allVehicle: state.Vehicle.vehicles?.vehicles,
      VehicleLoadingloading: state.Vehicle.loading,
      clientLoading: state.Client.loading,
    })
  )

  console.log(allClient?.length)

  useEffect(() => {
    dispatch(getClients("", "", clientSearch, "", ""))
  }, [dispatch, clientSearch])

  const handlerClientFinalValue = event => {
    setClientId(event?.value)
    setClient(event?.label)
  }
  const handleClientEnters = text => {
    setClientSearch(text)
  }

  const clientOptions = [
    {
      options: allClient?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]
  const bool = false

  useEffect(() => {
    dispatch(
      getVehicles(
        "",
        "",
        vehicleSearch,
        "",
        "",
        "",
        "",
        "",
        "booked",
        "",
        "",
        bool
      )
    )
  }, [dispatch, vehicleSearch, "booked", bool])

  const handlerVehicleFinalValue = event => {
    setVehicleId(event?.value)
    setVehicle(event?.label)
  }
  const handleVehicleEnters = text => {
    setVehicleSearch(text)
  }

  const vehicleOptions = [
    {
      options: allVehicle?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const saleId = updateData?._id

  function handleValidSubmit(e, v) {
    if (updateData?._id) {
      const updateData = {
        ...v,
        client: clientId,
        vehicle: vehicleId,
      }
      dispatch(updateSale(updateData, saleId, onCloseClick))
    } else {
      const createData = {
        ...v,
        client: clientId,
        vehicle: vehicleId,
      }
      dispatch(createSale(createData, onCloseClick))
    }
  }

  console.log(updateData)
  useEffect(() => {
    setClient(updateData?.client?.name)
    setClientId(updateData?.client?._id)
    setVehicle(updateData?.vehicle?.name)
    setVehicleId(updateData?.vehicle?._id)
  }, [updateData])

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ClientModal
        onCloseClick={() => setClientModal({ status: false, data: {} })}
        show={clientModal?.status}
        updateData={clientModal?.data}
      />
      <ModalBody className="p-4">
        <CardTitle>{updateData?._id ? "Edit Sale" : "Add Sale"}</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row className="mt-1">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <label>
                  vehicle <span className="text-danger">*</span>
                </label>
              </div>
              <div className="py-1">
                {allVehicle?.length === 0 ? (
                  <div className="d-flex align-items-start justify-content-between">
                    <Link to={`/vehicle/create`}>
                      <Button className="px-3 mx-1" color="success" size="sm">
                        create vehicle
                        <i className="bx bx-plus" style={{ fontSize: 10 }} />
                      </Button>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Select
              onInputChange={handleVehicleEnters}
              value={vehicle}
              placeholder={vehicle}
              onChange={handlerVehicleFinalValue}
              options={vehicleOptions}
              classNamePrefix="select2-selection"
              isLoading={VehicleLoading}
              required
            />
          </Row>
          <Row className="py-2">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <label>
                  Client <span className="text-danger">*</span>
                </label>
              </div>
              <div>
                {allClient?.length === 0 ? (
                  <div className="p-1">
                    <Button
                      className="px-3 mx-1 py-1"
                      color="success"
                      size="sm"
                      onClick={() => setClientModal({ status: true, data: "" })}
                    >
                      create client
                      <i className="bx bx-plus" style={{ fontSize: 10 }} />
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Select
              onInputChange={handleClientEnters}
              value={client}
              placeholder={client}
              onChange={handlerClientFinalValue}
              options={clientOptions}
              classNamePrefix="select2-selection"
              isLoading={clientLoading}
            />
          </Row>

          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-light ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              {clientId && vehicleId ? (
                <Button type="submit" className={`btn  ms-2`} color="warning">
                  {updateData?._id ? "Save" : "Create"}
                </Button>
              ) : (
                <Button
                  type="submit"
                  color="warning"
                  disabled
                  className={`btn   ms-2`}
                >
                  {updateData?._id ? "Save" : "Create"}
                </Button>
              )}
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export default CreateForm

CreateForm.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}
