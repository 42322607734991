import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import ClientModal from "./Crud/Modal"
import {
  deleteClient,
  getClients,
  getDistricts,
  getStates,
} from "store/actions"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const Clients = () => {
  const role = sessionStorage.getItem("role")
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [clientModal, setClientModal] = useState({ status: false, data: {} })
  const [searchText, setSearchText] = useState("")
  const [totalListLimit, setTotalListLimit] = useState(10)

  const [client, setClient] = useState("Select Client...")
  const [clientId, setClientId] = useState("")
  const [clientSearch, setClientSearch] = useState("")

  const [state, setState] = useState("Select State...")
  const [stateId, setStateId] = useState("")
  const [stateSearch, setStateSearch] = useState("")

  const [district, setDistrict] = useState("Select District...")
  const [districtId, setDistrictId] = useState(null)
  const [districtSearch, setDistrictSearch] = useState("")

  const { loading, Clients, allClients, allState, allDistricts } = useSelector(
    state => ({
      loading: state.Client.loading,
      Clients: state.Client.clients,
      allClients: state?.Client?.clients?.clients,
      allState: state?.Client?.states?.States,
      allDistricts: state?.Client?.districts?.districts,
    })
  )

  console.log(Clients)
  const totalPages = Math.ceil(Clients?.total / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getClients(
        page,
        totalListLimit,
        searchText,
        clientId,
        stateId,
        districtId
      )
    )
  }, [
    dispatch,
    page,
    totalListLimit,
    searchText,
    clientId,
    stateId,
    districtId,
  ])

  const updatedLocation = {
    ...location,
    state: {
      ...location.state,
      page: 1,
    },
  }

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteClient(isOpen?.id))
  }

  const columns = [
    {
      dataField: "no",
      text: "#",
      sort: true,
    },
    {
      dataField: "name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const handleColumns = () => {
    if (role === "salesManager") {
      return columns?.filter(i => i.dataField !== "action")
    } else return columns
  }

  const tableData = map(Clients?.clients, (item, index) => ({
    ...item,
    no: index + 1,
    message: (
      <div style={{ whiteSpace: "break-spaces" }}>
        {item?.message?.slice(0, 110)}
        {item?.message?.length > 110 ? "..." : ""}
      </div>
    ),
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
    action: (
      <div>
        <Link to={`/client/details/${item?._id}`}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>
        </Link>
        {role === "admin" ||
        role === "generalManager" ||
        role === "managingDirecter" ? (
          <>
            <Button
              id={`Delete${index}`}
              type="button"
              color="white"
              className="ms-1 btn "
              onClick={() => {
                handleDelete(item._id)
              }}
            >
              <i
                className="bx bx-trash me-2"
                style={{
                  color: "red",
                  marginBottom: "10px",
                  fontSize: "1.3rem",
                }}
              />
            </Button>
          </>
        ) : (
          ""
        )}
      </div>
    ),
  }))

  const debounceClientSearch = debounce(value => {
    setSearchText(value)
    history.push(updatedLocation)
  }, 600)

  const handlePageLimit = count => {
    setTotalListLimit(count)
  }

  const clientOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allClients?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersClients = text => {
    setClientSearch(text)
  }

  const handlerClientFinalValue = event => {
    setClient(event?.label)
    setClientId(event?.value)
  }

  // states

  useEffect(() => {
    dispatch(getStates())
  }, [dispatch])

  const stateOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allState?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result._id,
      })),
    },
  ]

  const handleEnteredStates = textEntered => {
    setStateSearch(textEntered)
  }

  function handleFinalValuesStates(event) {
    setState(event.label)
    setStateId(event.value)
    history.push(updatedLocation)
  }

  useEffect(() => {
    dispatch(getDistricts(stateId))
  }, [dispatch, stateId])

  const districtOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allDistricts?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result._id,
      })),
    },
  ]

  const handleEnteredDistrict = textEntered => {
    setDistrictSearch(textEntered)
  }

  function handleFinalValuesDistrict(event) {
    setDistrict(event.label)
    setDistrictId(event.value)
    history.push(updatedLocation)
  }

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <ClientModal
          onCloseClick={() => setClientModal({ status: false, data: {} })}
          show={clientModal?.status}
          updateData={clientModal?.data}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={handleColumns()}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <div className="d-flex align-items-center justify-content-between p-1">
                      {/* <div className="d-flex w-100"> */}
                      <div className="">
                        <div className="search-box mb-2 d-inline-block w-100">
                          <div className="position-relative mx-0">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                value={searchText}
                                onChange={e =>
                                  debounceClientSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>{" "}
                      </div>
                      <div>
                        <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center w-75">
                          <div className="mx-1">
                            <span className="">
                              Show
                              <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                            </span>
                          </div>
                          <div className="" style={{ width: "50px" }}>
                            <AvField
                              name="pageLimit"
                              className="form-control"
                              value={totalListLimit}
                              onChange={e => handlePageLimit(e.target.value)}
                              type="text"
                              required
                            />
                          </div>
                        </AvForm>
                      </div>
                      {/* <div className="w-25">
                          <Select
                            onInputChange={handleEntersClients}
                            value={client}
                            placeholder={client}
                            onChange={handlerClientFinalValue}
                            options={clientOptions}
                            classNamePrefix="select2-selection"
                            isLoading={loading}
                          />
                        </div> */}
                      <div className="w-25 mx-1 mb-1">
                        <Select
                          onInputChange={handleEnteredStates}
                          value={state}
                          placeholder={state}
                          onChange={handleFinalValuesStates}
                          options={stateOptions}
                          classNamePrefix="select2-selection"
                          isLoading={""}
                        />
                      </div>
                      <div className="w-25 mb-1">
                        <Select
                          onInputChange={handleEnteredDistrict}
                          value={district}
                          placeholder={district}
                          onChange={handleFinalValuesDistrict}
                          options={districtOptions}
                          classNamePrefix="select2-selection"
                          isLoading={""}
                        />
                      </div>
                      {/* </div> */}
                      <Button
                        type="button"
                        color="warning"
                        className="btn-rounded  mb-2 me-2 "
                        onClick={() => setClientModal({ status: true })}
                      >
                        <i className="mdi mdi-plus me-1" />
                        New Client
                      </Button>
                    </div>

                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {Clients?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Clients !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Clients
