import {
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  GET_PROFILE,
  RESET_PROFILE_FLAG,
} from "./actionTypes"

export const getProfile = () => {
  return {
    type: GET_PROFILE,
    payload: "",
  }
}

export const getProfileSuccess = profile => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: profile,
  }
}

export const getProfileError = error => {
  return {
    type: GET_PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
