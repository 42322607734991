import {
  GET_BRANDS,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAIL,
  GET_BRAND_DETAILS,
  GET_BRAND_DETAILS_SUCCESS,
  GET_BRAND_DETAILS_FAIL,
  CREATE_BRAND,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAIL,
  UPDATE_BRAND,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
  GET_BRAND_SEARCH_SUCCESS,
  GET_BRAND_SEARCH_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  brands: {
    brands: [],
    total: "",
    page: "",
  },
  brandDetails: {},
  error: {},
  loading: false,
  searchLoading: false,
}

const Brand = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Brand - This line cannot be edited or removed
    case GET_BRAND_SEARCH_SUCCESS:
      return {
        ...state,
        searchLoading: false,
        brands: action.payload,
      }

    case GET_BRAND_SEARCH_FAIL:
      return {
        ...state,
        searchLoading: false,
      }

    // case GET_BRANDS:
    case GET_BRAND_DETAILS:
    case CREATE_BRAND:
    case UPDATE_BRAND:
    case DELETE_BRAND:
      return {
        ...state,
        loading: true,
      }

    case DELETE_BRAND_FAIL:
    case GET_BRANDS_FAIL:
    case GET_BRAND_DETAILS_FAIL:
    case UPDATE_BRAND_FAIL:
    case CREATE_BRAND_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.payload,
        error: {},
        loading: false,
      }

    case GET_BRAND_DETAILS_SUCCESS:
      return {
        ...state,
        brandDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_BRAND_SUCCESS:
      return {
        ...state,
        brands: {
          ...state.brands,
          brands: [...state.brands.brands, action.payload],
          total: state.brands.total + 1,
        },
        brandDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        brands: {
          ...state.brands,
          brands: state.brands.brands.map(brand =>
            brand._id === action.payload._id
              ? { ...brand, ...action.payload }
              : brand
          ),
        },
        brandDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        brands: {
          ...state.brands,
          brands: state.brands.brands.filter(
            brand => brand._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Brand
