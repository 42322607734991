import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import ClientModal from "./Crud/Modal"
import { deleteClient, deleteMember, getUsers } from "store/actions"
import UserModal from "./Crud/Modal"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const Members = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const history = useHistory()
  const [userModal, setUserModal] = useState({ status: false, data: {} })
  const [totalListLimit, setTotalListLimit] = useState("10")
  const [searchText, setSearchText] = useState("")
  const [sortValues, setSortValues] = useState("all")

  const [memberRoleValue, setMemberValue] = useState("Select Member Roles...")

  const { loading, allUser: allUsers } = useSelector(state => ({
    loading: state.Members?.loading,
    allUser: state.Members.users,
  }))

  const totalPages = Math.ceil(allUsers?.total / 10)
  const pages = range(1, totalPages + 1)

  const sort = sortValues

  console.log(sort)

  useEffect(() => {
    dispatch(getUsers(page, totalListLimit, searchText, "", sort))
  }, [dispatch, page, totalListLimit, searchText, sort])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  // const handleDelete = id => {
  //   setIsOpen({ status: true, id: id })
  // }

  // const handleDeleteEvent = () => {
  //   setIsOpen({ ...isOpen, status: false })
  //   // dispatch(deleteMember(isOpen?.id))
  // }

  console.log(isOpen)

  const columns = [
    { dataField: "id", text: "#" },
    {
      dataField: "role",
      text: "Role",
      sort: true,
    },
    {
      dataField: "name",
      text: "UserName",
      sort: true,
    },
    {
      dataField: "email",
      text: "E-mail",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
    },
    // {
    //   dataField: "message",
    //   text: "Message",
    // },
    // {
    //   dataField: "action",
    //   text: "Action",
    // },
  ]

  const debounceUserSearch = debounce(value => setSearchText(value), 600)

  const handlePageLimit = e => {
    console.log("count =>", e)
    setTotalListLimit(e)
  }

  console.log(allUsers)

  const tableData = map(allUsers?.users, (item, index) => ({
    ...item,
    id: index + 1,
    email: <div style={{ whiteSpace: "break-spaces" }}>{item?.email}</div>,
    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
    // action: (
    //   <div>
    //     <Link
    //       to={`#`}
    //       className="btn-md"
    //       onClick={() => setUserModal({ status: true, data: item })}
    //     >
    //       <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
    //     </Link>
    //     <Button
    //       id={`Delete${index}`}
    //       type="button"
    //       color="white"
    //       className="ms-1 btn "
    //       onClick={() => {
    //         handleDelete(item._id)
    //       }}
    //     >
    //       <i
    //         className="bx bx-trash me-2"
    //         style={{ color: "red", marginBottom: "10px", fontSize: "1.3rem" }}
    //       />
    //     </Button>
    //   </div>
    // ),
  }))

  const optionsRoleMember = [
    { label: "All", value: "all" },
    { label: "Admin", value: "admin" },
    // { label: "Client", value: "client" },
    // { label: "Member", value: "member" },
    { label: "Member", value: "member" },
  ]

  const handlerRoleFinalValues = event => {
    console.log(event)
    setMemberRole(event?.label)
    setMemberValue(event?.value)
  }

  console.log(memberRoleValue)

  const listChange = sortValue => {
    setSortValues(sortValue)
    console.log(sortValue)
    history?.push({
      pathname: location.pathname,
      search: `p=${page}&s=${sortValue}`,
      state: {
        page: page,
        sort: sortValue,
      },
    })
  }

  return (
    <React.Fragment>
      <Row>
        {/* <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        /> */}
        <UserModal
          onCloseClick={() => setUserModal({ status: false, data: {} })}
          show={userModal?.status}
          updateData={userModal?.data}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {/* <div className="d-flex aling-items-center justify-content-end">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                        onClick={() => setUserModal({ status: true })}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create Member
                      </Button>
                    </div> */}

                    {map(optionsRoleMember, (head, key) => (
                      <>
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id={key + 1}
                          autoComplete="off"
                          checked={sortValues === head.value}
                          onChange={() => listChange(head?.value)}
                        />
                        <label
                          className="btn btn-warning"
                          htmlFor={key + 1}
                          style={{
                            borderRadius: 1,
                          }}
                        >
                          {head?.label}
                        </label>
                      </>
                    ))}
                    <div className="d-flex">
                      <div>
                        {" "}
                        <div className="search-box mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceUserSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
                          <div className="mx-1">
                            <span className="">
                              Show
                              <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                            </span>
                          </div>
                          <div className="" style={{ width: "50px" }}>
                            <AvField
                              name="pageLimit"
                              className="form-control"
                              value={totalListLimit}
                              onChange={e => handlePageLimit(e.target.value)}
                              type="text"
                              required
                            />
                          </div>
                        </AvForm>
                      </div>
                      {/* <div className="w-25">
                        <Select
                          // onInputChange={handleProductEnters}
                          value={memberRole}
                          placeholder={memberRole}
                          onChange={handlerRoleFinalValues}
                          options={optionsRoleMember}
                          classNamePrefix="select2-selection"
                          // isLoading={prodLoading}
                        />
                      </div> */}
                    </div>

                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {allUsers?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No users !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Members
