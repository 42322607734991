import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce, update } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, CardTitle, Col, Modal, ModalBody, Row } from "reactstrap"
import { createMember, updateMember } from "store/actions"

const UserModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()
  const [memberRole, setMemberRole] = useState("")

  console.log(updateData)

  const handleValidSubmit = (e, v) => {
    const memberData = {
      ...v,
      role: updateData?.role ? updateData?.role : "member",
      isPrime: false,
    }
    if (updateData?._id) {
      dispatch(updateMember(memberData, updateData?._id, onCloseClick))
      onCloseClick()
    } else {
      dispatch(createMember(memberData, onCloseClick))
      onCloseClick()
    }
  }
  useEffect(() => {
    setMemberRole(updateData?.user?.memberRole)
  }, [updateData])

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>
          {updateData?._id ? "Edit Member" : "Add New Member"}
        </CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <>
              <Col className="col-6 mb-2">
                <AvField
                  name="name"
                  type="text"
                  label="Name"
                  placeholder="Name *"
                  value={updateData?.name}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "name is required",
                    },
                  }}
                />
              </Col>
              <Col className="col-6 mb-2">
                <AvField
                  name="phone"
                  type="number"
                  label="Phone"
                  placeholder="Phone *"
                  value={updateData?.phone}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "phone is required",
                    },
                    // minLength: {
                    //   value: 10,
                    //   errorMessage: "phone number must be 10 letter ",
                    // },
                    // maxLength: {
                    //   value: 10,
                    //   errorMessage: "phone number must be 10 letter ",
                    // },
                  }}
                />
              </Col>
              <Col className="col-12 mb-2">
                <AvField
                  name="email"
                  type="email"
                  label="E-mail"
                  placeholder="Email *"
                  value={updateData?.email}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "mail is required",
                    },
                  }}
                />
              </Col>
            </>
          </Row>
          <Row>
            {/* <Col md="12">
              <label>Role</label>
              <Select
                // onInputChange={handleProductEnters}
                value={"member"}
                placeholder={role}
                onChange={handlerRoleFinalValue}
                // options={"optionsRole"}
                classNamePrefix="select2-selection"
                // isLoading={prodLoading}
              />
            </Col> */}
            {/* {!role === "member" && ( */}
            <div className="py-2">
              <Col className="col-12 mb-2">
                <AvField
                  name="memberRole"
                  type="select"
                  label="Designation"
                  value={updateData?.memberRole}
                  onChange={e => setMemberRole(e.target.value)}
                >
                  <option value="">Select role</option>
                  {optionsRoleMember?.map((item, key) => (
                    <option key={key} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </AvField>
              </Col>
            </div>
            {/* )} */}
          </Row>

          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-light ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <Button
                type="submit"
                className="btn ms-2"
                color="warning"
                // disabled={memberRole}
              >
                {updateData?._id ? "Save" : "Create"}
              </Button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

UserModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default UserModal

// const optionsRole = [
//   { label: "Admin", value: "admin" },
//   { label: "Client", value: "client" },
//   { label: "Member", value: "member" },
// ]

const optionsRoleMember = [
  { label: "Managing Director", value: "managingDirector" },
  { label: "General Manager", value: "generalManager" },
  { label: "Enquiry Manager", value: "enquiryManager" },
  { label: "Enquiry Analyst", value: "enquiryAnalyst" },
  { label: "Sales Manager", value: "salesManager" },
  { label: "Sales Executive", value: "salesExecutive" },
  { label: "Branch Manager", value: "branchManager" },
  { label: "Purchase Manager", value: "purchaseManager" },
]
