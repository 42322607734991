export const adminSidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },

  {
    routeLink: "/lead/all",
    icon: "bx bx-bar-chart",
    title: "Leads",
    isStore: true,
  },
  {
    routeLink: "/followUp/all",
    icon: "bx bxs-bell-ring",
    title: "FollowUp",
    isStore: true,
  },
  {
    routeLink: "/matching-list/all",
    icon: "bx bx-list-check",
    title: "Matching List",
    isStore: true,
  },
  {
    routeLink: "/sale/all",
    icon: "bx bx-cart",
    title: "Sales",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Garage",
    isSeparator: true,
  },

  {
    routeLink: "/vehicle/all",
    icon: "bx bx-car",
    title: "Vehicles",
    isStore: true,
  },
  {
    routeLink: "/category",
    icon: "bx bx-detail ",
    title: "Specifications",
  },
  {
    routeLink: "/categories",
    icon: "bx bxs-color-fill",
    title: "Categories",
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Administration",
    isSeparator: true,
  },
  {
    routeLink: "/client/all",
    icon: "bx bx-user",
    title: "Clients",
    isStore: true,
  },
  {
    routeLink: "/member/all",
    icon: "bx bxs-user",
    title: "Staffs",
  },
  // {
  //   routeLink: "/#",
  //   icon: "",
  //   title: "Extras",
  //   isSeparator: true,
  // },

  // {
  //   routeLink: "/user/all",
  //   icon: " bx bx-group",
  //   title: "Users",
  // },
]

export const managingDirectorSidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },
  {
    routeLink: "/lead/all",
    icon: "bx bx-bar-chart",
    title: "Lead",
    isStore: true,
  },
  {
    routeLink: "/followUp/all",
    icon: "bx bxs-bell-ring",
    title: "FollowUp",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Garage",
    isSeparator: true,
  },

  {
    routeLink: "/vehicle/all",
    icon: "bx bx-car",
    title: "Vehicles",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Administration",
    isSeparator: true,
  },
  {
    routeLink: "/client/all",
    icon: "bx bx-user",
    title: "Clients",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Extras",
    isSeparator: true,
  },

  // {
  //   routeLink: "/user/all",
  //   icon: "bx bxs-user",
  //   title: "Users",
  // },
]
export const generalManagerSidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },

  {
    routeLink: "/lead/all",
    icon: "bx bx-bar-chart",
    title: "Lead",
    isStore: true,
  },
  {
    routeLink: "/followUp/all",
    icon: "bx bxs-bell-ring",
    title: "FollowUp",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Garage",
    isSeparator: true,
  },
  {
    routeLink: "/vehicle/all",
    icon: "bx bx-car",
    title: "Vehicles",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Administration",
    isSeparator: true,
  },
  {
    routeLink: "/client/all",
    icon: "bx bx-user",
    title: "Clients",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Extras",
    isSeparator: true,
  },
  // {
  //   routeLink: "/user/all",
  //   icon: "bx bxs-user",
  //   title: "Users",
  // },
]
export const enquiryManagerSidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },

  {
    routeLink: "/lead/all",
    icon: "bx bx-bar-chart",
    title: "Lead",
    isStore: true,
  },
  {
    routeLink: "/followUp/all",
    icon: "bx bxs-bell-ring",
    title: "FollowUp",
    isStore: true,
  },
  { routeLink: "/#", icon: "", title: "Garage", isSeparator: true },

  {
    routeLink: "/vehicle/all",
    icon: "bx bx-car",
    title: "Vehicles",
    isStore: true,
  },
  { routeLink: "/#", icon: "", title: "Administration", isSeparator: true },
  {
    routeLink: "/client/all",
    icon: "bx bx-user",
    title: "Clients",
    isStore: true,
  },
]
export const enquiryAnalystSidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },

  {
    routeLink: "/lead/all",
    icon: "bx bx-bar-chart",
    title: "Lead",
    isStore: true,
  },
  {
    routeLink: "/followUp/all",
    icon: "bx bxs-bell-ring",
    title: "FollowUp",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Garage",
    isSeparator: true,
  },
  {
    routeLink: "/vehicle/all",
    icon: "bx bx-car",
    title: "Vehicles",
    isStore: true,
  },
]
export const salesExecutiveSidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },

  {
    routeLink: "/lead/all",
    icon: "bx bx-bar-chart",
    title: "Lead",
    isStore: true,
  },
  {
    routeLink: "/followUp/all",
    icon: "bx bxs-bell-ring",
    title: "FollowUp",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Garage",
    isSeparator: true,
  },

  {
    routeLink: "/vehicle/all",
    icon: "bx bx-car",
    title: "Vehicles",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Administration",
    isSeparator: true,
  },
  {
    routeLink: "/client/all",
    icon: "bx bx-user",
    title: "Clients",
    isStore: true,
  },
]

export const salesManagerSidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },

  {
    routeLink: "/lead/all",
    icon: "bx bx-bar-chart",
    title: "Lead",
    isStore: true,
  },
  {
    routeLink: "/followUp/all",
    icon: "bx bxs-bell-ring",
    title: "FollowUp",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Garage",
    isSeparator: true,
  },
  {
    routeLink: "/vehicle/all",
    icon: "bx bx-car",
    title: "Vehicles",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Administration",
    isSeparator: true,
  },
  {
    routeLink: "/client/all",
    icon: "bx bx-user",
    title: "Clients",
    isStore: true,
  },
]

export const branchManagerSidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },
  {
    routeLink: "/lead/all",
    icon: "bx bx-bar-chart",
    title: "Lead",
    isStore: true,
  },
  {
    routeLink: "/followUp/all",
    icon: "bx bxs-bell-ring",
    title: "FollowUp",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Garage",
    isSeparator: true,
  },
  {
    routeLink: "/vehicle/all",
    icon: "bx bx-car",
    title: "Vehicles",
    isStore: true,
  },
]

export const purchaseManagerSidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },
  {
    routeLink: "/vehicle/all",
    icon: "bx bx-car",
    title: "Vehicles",
    isStore: true,
  },
]
