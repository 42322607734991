import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_MATCHLISTS,
  GET_MATCHLIST_DETAILS,
  CREATE_MATCHLIST,
  UPDATE_MATCHLIST,
  DELETE_MATCHLIST,
} from "./actionTypes"
import {
  getMatchListsFail,
  getMatchListsSuccess,
  getMatchListDetailsSuccess,
  getMatchListDetailsFail,
  createMatchListFail,
  createMatchListSuccess,
  updateMatchListSuccess,
  updateMatchListFail,
  deleteMatchListSuccess,
  deleteMatchListFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// MatchList - This line cannot be edited or removed
function getMatchListsAPi({
  page,
  sort,
  limit,
  vehicleId,
  brandId,
  modelId,
  leadId,
}) {
  return get(
    `/matchingList/admin/all/?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }
    
    &vehicle=${vehicleId ? vehicleId : ""}&brand=${
      brandId ? brandId : ""
    }&model=${modelId ? modelId : ""}&lead=${leadId ? leadId : ""}`
    // &from=${
    //   finalDateFormated?.from && finalDateFormated?.from
    // }&to=${finalDateFormated?.to && finalDateFormated?.to}
  )
}

const getMatchListDetailsAPi = matchlistId => {
  return get(`/matchingList/admin/${matchlistId}`)
}

const createMatchListApi = ({ matchlist }) => {
  return post("/matchlist/admin/new", matchlist)
}

const updateMatchListApi = ({ matchlist, matchlistId }) => {
  return ApiPut(`/matchingList/admin/${matchlistId}`, matchlist)
}

const deleteMatchListApi = ({ matchlistId }) => {
  return del(`/matchlist/admin/${matchlistId}`)
}

function* fetchMatchLists({ payload }) {
  try {
    const response = yield call(getMatchListsAPi, payload)
    yield put(getMatchListsSuccess(response))
  } catch (error) {
    yield put(getMatchListsFail(error))
  }
}

function* fetchMatchListDetails({ payload: matchlistId }) {
  try {
    const response = yield call(getMatchListDetailsAPi, matchlistId)
    yield put(getMatchListDetailsSuccess(response))
  } catch (error) {
    yield put(getMatchListDetailsFail(error))
  }
}

function* onCreateMatchList({ payload }) {
  try {
    const response = yield call(createMatchListApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createMatchListSuccess(response))
      payload?.history?.push("/matchlists")
      doneNotification("MatchList Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createMatchListFail(error))
  }
}

function* onUpdateMatchList({ payload }) {
  try {
    const response = yield call(updateMatchListApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "MatchList Updated Successfully!",
        title: "",
      })
      yield put(updateMatchListSuccess(response))
      if (payload.history) {
        payload.history.push("/matchlists")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateMatchListFail(error?.response?.data))
  }
}

function* onDeleteMatchList({ payload }) {
  try {
    const response = yield call(deleteMatchListApi, MatchListId)
    yield put(deleteMatchListSuccess(response))
    Notification({
      type: "success",
      message: "MatchList Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteMatchListFail(error?.response?.data))
  }
}

function* matchlistSaga() {
  yield takeEvery(GET_MATCHLISTS, fetchMatchLists)
  yield takeEvery(GET_MATCHLIST_DETAILS, fetchMatchListDetails)
  yield takeEvery(CREATE_MATCHLIST, onCreateMatchList)
  yield takeEvery(UPDATE_MATCHLIST, onUpdateMatchList)
  yield takeEvery(DELETE_MATCHLIST, onDeleteMatchList)
}

export default matchlistSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
