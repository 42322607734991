/* Vehicles - This line cannot be edited or removed */
export const GET_VEHICLES = "GET_VEHICLES"
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS"
export const GET_VEHICLES_FAIL = "GET_VEHICLES_FAIL"

/* Vehicle_DETAILS */
export const GET_VEHICLE_DETAILS = "GET_VEHICLE_DETAILS"
export const GET_VEHICLE_DETAILS_SUCCESS = "GET_VEHICLE_DETAILS_SUCCESS"
export const GET_VEHICLE_DETAILS_FAIL = "GET_VEHICLE_DETAILS_FAIL"

/* CREATE Vehicle */
export const CREATE_VEHICLE = "CREATE_VEHICLE"
export const CREATE_VEHICLE_SUCCESS = "CREATE_VEHICLE_SUCCESS"
export const CREATE_VEHICLE_FAIL = "CREATE_VEHICLE_FAIL"

/* Edit Vehicle */
export const UPDATE_VEHICLE = "UPDATE_VEHICLE"
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS"
export const UPDATE_VEHICLE_FAIL = "UPDATE_VEHICLE_FAIL"

/* Delete Vehicle */
export const DELETE_VEHICLE = "DELETE_VEHICLE"
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS"
export const DELETE_VEHICLE_FAIL = "DELETE_VEHICLE_FAIL"

export const CHECK_VEHICLE_NUMBER = "CHECK_VEHICLE_NUMBER"
export const CHECK_VEHICLE_NUMBER_SUCCESS = "CHECK_VEHICLE_NUMBER_SUCCESS"
export const CHECK_VEHICLE_NUMBER_FAIL = "CHECK_VEHICLE_NUMBER_FAIL"

export const RESET_STATE = "RESET_STATE"
