import MetaTag from "components/Common/Meta-tag"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Container } from "reactstrap"
import Form from "./Form"
import { useDispatch } from "react-redux"
import {
  getBrands,
  getCategories,
  getClients,
  getModels,
  getVariants,
  getVarients,
} from "store/actions"

function Create() {
  const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(getModels(1, "", "", ""))
  // }, [])

  // useEffect(() => {
  //   dispatch(getBrands(1, "", "", ""))
  // }, [])

  // useEffect(() => {
  //   dispatch(getVariants(1, "", "", "", ""))
  // }, [])

  // useEffect(() => {
  //   dispatch(getClients("", "", "", "", ""))
  // }, [dispatch])

  // useEffect(()=>{
  //   dispatch(getCategories("","",""))
  // })

  return (
    <div>
      <MetaTag title={"Create Vehicle"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard / Vehicle" breadcrumbItem="Create" />
        <Container fluid>
          <div className="container-fluid">
            <Form updateData={false} />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Create
