import {
  GET_VARIANTS,
  GET_VARIANTS_SUCCESS,
  GET_VARIANTS_FAIL,
  GET_VARIANT_DETAILS,
  GET_VARIANT_DETAILS_SUCCESS,
  GET_VARIANT_DETAILS_FAIL,
  CREATE_VARIANT,
  CREATE_VARIANT_SUCCESS,
  CREATE_VARIANT_FAIL,
  UPDATE_VARIANT,
  UPDATE_VARIANT_SUCCESS,
  UPDATE_VARIANT_FAIL,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT,
  DELETE_VARIANT_FAIL,
} from "./actionTypes"

// Varient - This line cannot be edited or removed
export const getVariants = (page, limit, searchText, modelValueId) => ({
  type: GET_VARIANTS,
  payload: { page, limit, searchText, modelValueId },
})

export const getVariantsSuccess = variants => ({
  type: GET_VARIANTS_SUCCESS,
  payload: variants,
})

export const getVariantsFail = error => ({
  type: GET_VARIANTS_FAIL,
  payload: error,
})

export const getVariantDetails = variantId => ({
  type: GET_VARIANT_DETAILS,
  payload: variantId,
})

export const getVariantDetailsSuccess = variantDetails => ({
  type: GET_VARIANT_DETAILS_SUCCESS,
  payload: variantDetails,
})

export const getVariantDetailsFail = error => ({
  type: GET_VARIANT_DETAILS_FAIL,
  payload: error,
})

export const createVariant = (variant, onCloseClick) => ({
  type: CREATE_VARIANT,
  payload: { variant, onCloseClick },
})

export const createVariantSuccess = variant => ({
  type: CREATE_VARIANT_SUCCESS,
  payload: variant,
})

export const createVariantFail = error => ({
  type: CREATE_VARIANT_FAIL,
  payload: error,
})

export const getVariantDetail = variantId => ({
  type: GET_VARIANT_DETAILS,
  payload: variantId,
})

export const updateVariant = (variant, variantId, onCloseClick) => (
  console.log("variantId =>", variantId),
  {
    type: UPDATE_VARIANT,
    payload: { variant, variantId, onCloseClick },
  }
)

export const updateVariantSuccess = variant => ({
  type: UPDATE_VARIANT_SUCCESS,
  payload: variant,
})

export const updateVariantFail = error => ({
  type: UPDATE_VARIANT_FAIL,
  payload: error,
})

export const deleteVariant = (variantId, history) => ({
  type: DELETE_VARIANT,
  payload: { variantId, history },
})

export const deleteVariantSuccess = variant => ({
  type: DELETE_VARIANT_SUCCESS,
  payload: variant,
})

export const deleteVariantFail = error => ({
  type: DELETE_VARIANT_FAIL,
  payload: error,
})
