import React from "react"
import Details from "./Details"
import MetaTag from "components/Common/Meta-tag"
import { Container } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

function MatchingListDetails() {
  return (
    <div>
      <div className="page-content mb-0 pb-2">
        <MetaTag title="Matching Detail" />
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Matching Detail" />
          <div className="container-fluid">
            <Details />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default MatchingListDetails
