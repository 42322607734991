import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"
import moment from "moment"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

//actions

import "../../assets/scss/datatables.scss"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useSiteVersion } from "hooks/useSiteVersion"
import DeleteModal from "components/Common/DeleteModal"
import { defaultImg } from "assets/images"
import { deleteLeadNotes, getLeadHistorys, getLeadNotes } from "store/actions"
import CreateForm from "./Crud/Update/Update"

const AllLeadNotes = () => {
  const dispatch = useDispatch()

  const { AllLeadNotes, total, loading } = useSelector(state => ({
    AllLeadNotes: state.LeadNotes.leadnotes?.leadNotes,
    loading: state.LeadNotes.loading,
  }))

  console.log(AllLeadNotes)
  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [SearchText, setSearchText] = useState("")

  //delete
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })
  const [formModal, setFormModal] = useState({ status: false, data: "" })

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteLeadNotes(isOpen?.id, history))
  }
  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getLeadNotes(page, totalItemLimit, SearchText))
  }, [dispatch, page, totalItemLimit, SearchText])

  const columns = [
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "user.name",
      text: "User",
      sort: true,
    },
    {
      dataField: "note",
      text: "Note",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const LeadNoteData = map(
    AllLeadNotes && AllLeadNotes?.length >= 1 && AllLeadNotes,
    (item, index) => ({
      ...item,
      key: index,
      createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm A"),
      note: (
        <p>
          {item?.note?.length >= 20
            ? item?.note?.slice(0, 20 - 1) + "..."
            : item?.note}
        </p>
      ),
      action: (
        <div>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
            onClick={() => setFormModal({ status: true, data: item })}
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>

          <Button
            id={`delete${index}`}
            type="button"
            color="white"
            className="ms-1 btn "
            onClick={() => {
              handleDelete(item?._id)
            }}
          >
            <i
              className="bx bx-trash me-2"
              style={{ color: "red", fontSize: "20px" }}
            />
          </Button>
        </div>
      ),
    })
  )

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  const debounceUserSearch = debounce(value => setSearchText(value), 600)

  // const handleTableColumn = () => {
  //   if (sort === "store-admin" || sort === "admin") {
  //     return storeAdminColums
  //   } else if (sort === "all") {
  //     return columns
  //   } else {
  //     return columns?.filter(i => i.dataField !== "roleData")
  //   }
  // }

  useEffect(() => {
    const currentLimit = localStorage.getItem("userLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("userLimit", e.target.value)
    }
  }

  const onCloseClick = () => {
    setFormModal({ status: false, data: "" })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <CreateForm
        show={formModal?.status}
        onCloseClick={onCloseClick}
        updateData={formModal?.data}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={LeadNoteData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex">
                          <div>
                            <div className="search-box mb-2 d-flex">
                              <div className="position-relative">
                                <AvForm>
                                  <AvField
                                    name="searchText"
                                    placeholder="Search"
                                    type="text"
                                    onChange={e =>
                                      debounceUserSearch(e.target.value)
                                    }
                                  />
                                </AvForm>
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                          <div>
                            <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
                              <div className="mx-1">
                                <span className="">
                                  Show
                                  <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                                </span>
                              </div>
                              <div className="" style={{ width: "50px" }}>
                                <AvField
                                  name="pageLimit"
                                  className="form-control"
                                  value={totalItemLimit}
                                  onChange={e => handlePageLimit(e)}
                                  type="text"
                                  required
                                />
                              </div>
                            </AvForm>
                          </div>
                        </div>
                        <div>
                          <div>
                            <Button
                              color="success"
                              className="px-4"
                              onClick={() =>
                                setFormModal({ status: true, data: "" })
                              }
                            >
                              <i className="mdi mdi-plus me-1" />
                              New Lead Notes
                            </Button>
                          </div>
                        </div>
                      </div>

                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {AllLeadNotes?.length ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p
                              className="my-4 mx-3 "
                              style={{ textTransform: "capitalize" }}
                            >
                              No Datas!
                            </p>
                          )}
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllLeadNotes
