import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { Button, CardTitle, Col, Modal, ModalBody, Row } from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  createVariant,
  getBrands,
  getModels,
  updateVariant,
} from "store/actions"

function FormModal({ show, onCloseClick, updateData }) {
  const dispatch = useDispatch()
  // const [brandId, setBrandId] = useState("")
  // const [brand, setBrand] = useState("select brand...")
  // const [brandSearch, setBrandSearch] = useState("")

  const [modelId, setModelId] = useState("")
  const [model, setModel] = useState("select model...")
  const [modelSearch, setModelSearch] = useState("")

  const { loading, allModels } = useSelector(state => ({
    allModels: state?.Model?.models?.models,
    loading: state?.Brand?.loading,
  }))

  // useEffect(() => {
  //   dispatch(getBrands("", "", "", brandSearch))
  // }, [dispatch, brandSearch])

  useEffect(() => {
    dispatch(getModels("", "", "", modelSearch))
  }, [dispatch, modelSearch])

  // const handleEnter = textEntered => {
  //   setBrandSearch(textEntered)
  // }

  // const brandOptions = [
  //   {
  //     options: allBrands?.map((result, index) => ({
  //       key: index,
  //       label: result?.name,
  //       value: result?._id,
  //     })),
  //   },
  // ]

  // function handlerFinalValues(event) {
  //   setBrand(event.label)
  //   setBrandId(event.value)
  // }

  const handleEnterModel = textEntered => {
    setModelSearch(textEntered)
  }

  const modelOptions = [
    {
      options: allModels?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  function handlerModelFinalValues(event) {
    setModel(event.label)
    setModelId(event.value)
  }

  useEffect(() => {
    setModel(updateData?.model?.name)
    setModelId(updateData?.model?._id)
  }, [updateData])

  console.log(updateData)
  const variantId = updateData?._id

  const handleValidSubmit = (e, v) => {
    if (updateData?._id) {
      const updateData = {
        ...v,
        model: modelId,
      }
      dispatch(updateVariant(updateData, variantId, onCloseClick))
    } else {
      const createData = {
        ...v,
        model: modelId,
      }
      dispatch(createVariant(createData, onCloseClick))
    }
  }

  const modelLoading = false

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>
          {updateData?._id ? "Edit Variant" : "Add Variant"}
        </CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <>
              <Col>
                <label>Model</label>
                <Select
                  onInputChange={handleEnterModel}
                  value={model}
                  placeholder={model}
                  onChange={handlerModelFinalValues}
                  options={modelOptions}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />
              </Col>
              <Col className="col-12 my-3">
                <AvField
                  name="name"
                  type="text"
                  label="Name *"
                  value={updateData?.name ?? ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a name",
                    },
                  }}
                />
              </Col>{" "}
              <Col className="col-12 mb-2">
                <AvField
                  label="Description"
                  name="description"
                  type="textarea"
                  value={updateData?.description ?? ""}
                />
              </Col>
              {/* <Col>
                <label>Brand</label>
                <Select
                  onInputChange={handleEnter}
                  value={brand}
                  placeholder={brand}
                  onChange={handlerFinalValues}
                  options={brandOptions}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />
              </Col> */}
            </>
          </Row>

          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-light ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              {modelId ? (
                <Button type="submit" color="warning" className="btn  ms-2">
                  {updateData?._id ? "Save" : "Create"}
                </Button>
              ) : (
                <Button
                  type="submit"
                  color="warning"
                  className="btn  ms-2"
                  disabled
                >
                  {updateData?._id ? "Save" : "Create"}
                </Button>
              )}
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export default FormModal

FormModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}
