import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Button, CardTitle, Col, Modal, ModalBody, Row } from "reactstrap"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { updateLead } from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

function StatusUpdate({ show, onCloseClick, updateData }) {
  const [status, setStatus] = useState("Select Status...")
  const [statusValue, setStatusValue] = useState("")
  const role = sessionStorage.getItem("role")
  const dispatch = useDispatch()
  const params = useParams()

  const handlerStatusFinalValue = event => {
    setStatus(event?.label)
    setStatusValue(event?.value)
  }

  useEffect(() => {
    setStatus(updateData)
    setStatusValue(updateData)
  }, [updateData])

  const handleStatusValue = () => {
    switch (role) {
      case "admin":
      case "managingDirecter":
      case "generalManager":
      case "enquiryAnalyst":
        const statusValues = [
          { label: "All", value: "all" },
          { label: "New", value: "new" },
          {
            label: "Qualified",
            value: "qualified",
          },
          {
            label: "Processing",
            value: "processing",
          },
          {
            label: "Confirmed",
            value: "confirmed",
          },
          {
            label: " New Buyer",
            value: "newBuyer",
          },
          {
            label: "Follow Up Done",
            value: "followupDone",
          },
          {
            label: "Price Quoted",
            value: "priceQuoted",
          },
          {
            label: "Negotiation",
            value: "negotiation",
          },
          {
            label: "Car Booked",
            value: "carBooked",
          },
          {
            label: "Paper Work",
            value: "paperWork",
          },
          {
            label: "Sold",
            value: "carSold",
          },
          { label: "Lost", value: "lost" },
        ]
        return statusValues

      case "enquiryManager":
        const salesStatusValues = [
          { label: "All", value: "all" },
          { label: "New", value: "new" },
          {
            label: "Qualified",
            value: "qualified",
          },
          {
            label: "Processing",
            value: "processing",
          },
          {
            label: "Confirmed",
            value: "confirmed",
          },
        ]
        return salesStatusValues
        // case "enquiryAnalyst":
        const enquiryAnalystValues = [
          { label: "New", value: "new" },
          {
            label: "Qualified",
            value: "qualified",
          },
          {
            label: "Processing",
            value: "processing",
          },
          {
            label: "Confirmed",
            value: "confirmed",
          },
        ]
        return enquiryAnalystValues

      case "salesManager":
        const salesManagerStatus = [
          {
            label: "Confirmed",
            value: "confirmed",
          },
          {
            label: " New Buyer",
            value: "newBuyer",
          },
          {
            label: "Follow Up Done",
            value: "followupDone",
          },
          {
            label: "Price Quoted",
            value: "priceQuoted",
          },
          {
            label: "Negotiation",
            value: "negotiation",
          },
          {
            label: "Car Booked",
            value: "carBooked",
          },
          {
            label: "Paper Work",
            value: "paperWork",
          },
          {
            label: "Sold",
            value: "carSold",
          },
          { label: "Lost", value: "lost" },
        ]

        return salesManagerStatus

      case "salesExecutive":
        const salesExecutiveStatus = [
          {
            label: " New Buyer",
            value: "newBuyer",
          },
          {
            label: "Follow Up Done",
            value: "followupDone",
          },
          {
            label: "Price Quoted",
            value: "priceQuoted",
          },
          {
            label: "Negotiation",
            value: "negotiation",
          },
          {
            label: "Car Booked",
            value: "carBooked",
          },
          {
            label: "Paper Work",
            value: "paperWork",
          },
          {
            label: "Sold",
            value: "carSold",
          },
          { label: "Lost", value: "lost" },
        ]
        return salesExecutiveStatus
      default:
        break
    }
  }

  const handleValidSubmit = () => {
    const lead = {
      status: statusValue,
    }
    dispatch(updateLead(lead, params?.id, "", onCloseClick, true))
  }

  return (
    <div>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="p-4">
          <CardTitle>Edit Status</CardTitle>
          <Row>
            <Col md={12}>
              <label>Status</label>
              <Select
                value={status}
                placeholder={status}
                onChange={handlerStatusFinalValue}
                options={handleStatusValue()}
                classNamePrefix="select2-selection"
              />
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-end py-2">
            <Button
              color="warning"
              type="button"
              onClick={() => handleValidSubmit()}
              className="px-4"
            >
              Update
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default StatusUpdate

StatusUpdate.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}
