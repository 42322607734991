import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { GET_PROFILE } from "./actionTypes"
import {
  profileSuccess,
  profileError,
  getProfileSuccess,
  getProfileError,
} from "./actions"
import { get } from "helpers/api_helper"

function getProfileApi(token) {
  return get(`/user`)
}

function* fetchProfiles({ payload }) {
  try {
    const response = yield call(getProfileApi, payload)
    yield put(getProfileSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getProfileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(GET_PROFILE, fetchProfiles)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
