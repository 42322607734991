import {
  GET_LEADNOTES,
  GET_LEADNOTES_SUCCESS,
  GET_LEADNOTES_FAIL,
  GET_LEADNOTES_DETAILS,
  GET_LEADNOTES_DETAILS_SUCCESS,
  GET_LEADNOTES_DETAILS_FAIL,
  CREATE_LEADNOTES,
  CREATE_LEADNOTES_SUCCESS,
  CREATE_LEADNOTES_FAIL,
  UPDATE_LEADNOTES,
  UPDATE_LEADNOTES_SUCCESS,
  UPDATE_LEADNOTES_FAIL,
  DELETE_LEADNOTES_SUCCESS,
  DELETE_LEADNOTES_FAIL,
  DELETE_LEADNOTES,
} from "./actionTypes"

// LeadNotes - This line cannot be edited or removed
export const getLeadNotes = (page, limit, searchText, leadId) => ({
  type: GET_LEADNOTES,
  payload: { page, limit, searchText, leadId },
})

export const getLeadNotesSuccess = leadnotess => ({
  type: GET_LEADNOTES_SUCCESS,
  payload: leadnotess,
})

export const getLeadNotesFail = error => ({
  type: GET_LEADNOTES_FAIL,
  payload: error,
})

export const getLeadNotesDetails = leadnotesId => ({
  type: GET_LEADNOTES_DETAILS,
  payload: leadnotesId,
})

export const getLeadNotesDetailsSuccess = leadnotesDetails => ({
  type: GET_LEADNOTES_DETAILS_SUCCESS,
  payload: leadnotesDetails,
})

export const getLeadNotesDetailsFail = error => ({
  type: GET_LEADNOTES_DETAILS_FAIL,
  payload: error,
})

export const createLeadNotes = (leadnotes, onCloseClick) => ({
  type: CREATE_LEADNOTES,
  payload: { leadnotes, onCloseClick },
})

export const createLeadNotesSuccess = leadnotes => ({
  type: CREATE_LEADNOTES_SUCCESS,
  payload: leadnotes,
})

export const createLeadNotesFail = error => ({
  type: CREATE_LEADNOTES_FAIL,
  payload: error,
})

export const getLeadNotesDetail = leadnotesId => ({
  type: GET_LEADNOTES_DETAILS,
  payload: leadnotesId,
})

export const updateLeadNotes = (leadnotes, leadnotesId, onCloseClick) => ({
  type: UPDATE_LEADNOTES,
  payload: { leadnotes, leadnotesId, onCloseClick },
})

export const updateLeadNotesSuccess = leadnotes => ({
  type: UPDATE_LEADNOTES_SUCCESS,
  payload: leadnotes,
})

export const updateLeadNotesFail = error => ({
  type: UPDATE_LEADNOTES_FAIL,
  payload: error,
})

export const deleteLeadNotes = (leadnotesId, history) => ({
  type: DELETE_LEADNOTES,
  payload: { leadnotesId, history },
})

export const deleteLeadNotesSuccess = leadNoteId => ({
  type: DELETE_LEADNOTES_SUCCESS,
  payload: leadNoteId,
})

export const deleteLeadNotesFail = error => ({
  type: DELETE_LEADNOTES_FAIL,
  payload: error,
})
