import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ALL_FOLLOWUP,
  GET_FOLLOWUP_DETAILS,
  CREATE_FOLLOWUP,
  UPDATE_FOLLOWUP,
  DELETE_FOLLOWUP,
} from "./actionTypes"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"
import {
  createFollowUpFail,
  createFollowUpSuccess,
  deleteFollowUpFail,
  deleteFollowUpSuccess,
  getAllFollowUp,
  getAllFollowUpFail,
  getAllFollowUpSuccess,
  getFollowUpDetailsFail,
  getFollowUpDetailsSuccess,
  updateFollowUpFail,
  updateFollowUpSuccess,
} from "./actions"

//   - This line cannot be edited or removed
function getFollowUpApi({
  page,
  sort,
  limit,
  typeValue,
  StaffId,
  finalDateFormated,
  leadId,
  clientId,
}) {
  return get(
    `/followUp/admin/all/?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&sort=${sort ? sort : ""}&type=${typeValue ? typeValue : ""}&assignee=${
      StaffId ? StaffId : ""
    }&from=${finalDateFormated?.from ? finalDateFormated?.from : ""}&to=${
      finalDateFormated?.to ? finalDateFormated?.to : ""
    }&lead=${leadId ? leadId : ""}&client=${clientId ? clientId : ""}`
  )
}

const getFollowUpDetailsAPi = clientId => {
  return get(`/client/admin/${clientId}`)
}

const createFollowUpApi = ({ followUps }) => {
  console.log("followUpDatas =>", followUps)
  return post("/followUp/admin/new", followUps)
}

const updateFollowUpApi = ({ followUps, followUpId }) => {
  return ApiPut(`/followUp/admin/${followUpId}`, followUps)
}

const deleteFollowUpApi = ({ clientId }) => {
  return del(`/followUp/admin/${clientId}`)
}

function* fetchFollowUp({ payload }) {
  try {
    const response = yield call(getFollowUpApi, payload)
    yield put(getAllFollowUpSuccess(response))
  } catch (error) {
    yield put(getAllFollowUpFail(error))
  }
}

function* fetchFollowUpDetails({ payload: clientId }) {
  try {
    const response = yield call(getFollowUpDetailsAPi, clientId)
    yield put(getFollowUpDetailsSuccess(response))
  } catch (error) {
    yield put(getFollowUpDetailsFail(error))
  }
}

function* onCreateFollowUp({ payload }) {
  console.log(payload)
  try {
    const response = yield call(createFollowUpApi, payload)

    yield put(createFollowUpSuccess(response))
    payload?.onCloseClick()
    doneNotification("FollowUp Created Successfully!")
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createFollowUpFail(error))
  }
}

function* onUpdateFollowUp({ payload }) {
  try {
    const response = yield call(updateFollowUpApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "FollowUp Updated Successfully!",
        title: "",
      })
      yield put(updateFollowUpSuccess(response))
      if (payload.history) {
        payload.history.push("/followUp/all")
      }
      if (payload?.leadId) {
        yield put(getAllFollowUp("", "", "", "", "", "", payload?.leadId, ""))
      } else if (response?._id) {
        yield put(getAllFollowUp("", response?.status, "", "", "", "", ""))
        if (payload?.setSort) {
          payload?.setSort(response?.status)
        }
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateFollowUpFail(error.response.data))
  }
}

function* onDeleteFollowUp({ payload }) {
  try {
    const response = yield call(deleteFollowUpApi, payload)
    yield put(deleteFollowUpSuccess(response))
    doneNotification("Client Deleted Successfully!")
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteFollowUpFail(error?.response?.data))
  }
}

function* FollowUpSaga() {
  yield takeEvery(GET_ALL_FOLLOWUP, fetchFollowUp)
  yield takeEvery(GET_FOLLOWUP_DETAILS, fetchFollowUpDetails)
  yield takeEvery(CREATE_FOLLOWUP, onCreateFollowUp)
  yield takeEvery(UPDATE_FOLLOWUP, onUpdateFollowUp)
  yield takeEvery(DELETE_FOLLOWUP, onDeleteFollowUp)
}

export default FollowUpSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
