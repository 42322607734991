import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_MEMBERS,
  GET_MEMBERS_DETAILS,
  CREATE_MEMBERS,
  UPDATE_MEMBERS,
  DELETE_USERS,
  DELETE_MEMBERS,
  GET_USERS,
  CREATE_USERS,
  UPDATE_USERS,
} from "./actionTypes"
import {
  getMembersFail,
  getMembersSuccess,
  getMemberDetailsSuccess,
  getMemberDetailsFail,
  createMemberFail,
  createMemberSuccess,
  updateMemberSuccess,
  updateMemberFail,
  deleteMemberSuccess,
  deleteMemberFail,
  getUsersSuccess,
  getUsersFail,
  createUsersSuccess,
  createUsersFail,
  updateUsersSuccess,
  updateUsersFail,
  deleteUsersSuccess,
  deleteUsersFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Users - This line cannot be edited or removed
function getMembersAPi({ page, limit, searchText, handleRoles, sort }) {
  console.log(handleRoles)
  // return get(
  //   `/member/admin/all/?page=${page && page}&limit=${
  //     limit ? limit : ""
  //   }&search=${searchText ? searchText : ""}&sort=${
  //     handleRoles ? handleRoles : sort
  //   }`
  // )
  // return get(
  //   `/member/admin/all/?page=${page ? page : 1}&limit=${
  //     limit ? limit : 10
  //   }&search=${searchText}&sort=${handleRoles ? handleRoles : ""}`
  // )
}

const getMemberDetailsAPi = usersId => {
  return get(`/member/admin/single/${usersId}`)
}

const createMemberApi = ({ users }) => {
  return post("/member/admin/new", users)
}

const updateMemberApi = ({ users, usersId }) => {
  console.log(usersId)
  return ApiPut(`/user/admin/${usersId}`, users)
}

const deleteMemberApi = ({ usersId }) => {
  console.log(usersId)
  return del(`/member/admin/${usersId}`)
}
const role = sessionStorage?.getItem("role")
function getUsersAPi({ page, limit, searchText, handleRoles, sort }) {
  return get(
    `/user/all/?page=${page && page}&limit=${limit ? limit : ""}&search=${
      searchText ? searchText : ""
    }&sort=${handleRoles ? handleRoles : sort}`
  )
}

const createUsersApi = ({ users }) => {
  return post("/user/new", users)
}

const updateUserApi = ({ users, usersId }) => {
  return ApiPut(`/user/${usersId}`, users)
}

const deleteUserApi = ({ userId }) => {
  console.log(userId)
  return del(`/user/${userId}`)
}

function* fetchMembers({ payload }) {
  try {
    const response = yield call(getMembersAPi, payload)
    yield put(getMembersSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getMembersFail(error))
  }
}

function* fetchUsersDetails({ payload: usersId }) {
  try {
    const response = yield call(getMemberDetailsAPi, usersId)
    yield put(getMemberDetailsSuccess(response))
  } catch (error) {
    yield put(getMemberDetailsFail(error))
  }
}

function* onCreateMembers({ payload }) {
  try {
    const response = yield call(createMemberApi, payload)
    yield put(createMemberSuccess(response))
    if (payload.onCloseClick) {
      payload.onCloseClick()
    }
    Notification({
      type: "success",
      message: "Member Created Successfully!",
      title: "",
    })
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createMemberFail(error))
  }
}

function* onUpdateMembers({ payload }) {
  try {
    const response = yield call(updateMemberApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Member Updated Successfully!",
        title: "",
      })
      yield put(updateMemberSuccess(response))
      if (payload.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateMemberFail(error.response.data))
  }
}

function* onDeleteMembers({ payload }) {
  try {
    const response = yield call(deleteMemberApi, payload)
    yield put(deleteMemberSuccess(response))
    console.log(response)
    Notification({
      type: "success",
      message: "Users Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteMemberFail(error?.response?.data))
  }
}

// users

function* fetchUsers({ payload }) {
  try {
    const response = yield call(getUsersAPi, payload)
    yield put(getUsersSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getUsersFail(error))
  }
}

function* onCreateUsers({ payload }) {
  try {
    const response = yield call(createUsersApi, payload)
    yield put(createUsersSuccess(response))
    payload?.onCloseClick()
    Notification({
      type: "success",
      message: "Users Created Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createUsersFail(error))
  }
}

function* onUpdateUsers({ payload }) {
  try {
    const response = yield call(updateUserApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Users Updated Successfully!",
        title: "",
      })
      yield put(updateUsersSuccess(response))
      if (payload.history) {
        payload.history.push("/userss")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateUsersFail(error.response.data))
  }
}

function* onDeleteUsers({ payload }) {
  try {
    const response = yield call(deleteUserApi, payload)
    yield put(deleteUsersSuccess(response))
    console.log(response)
    Notification({
      type: "success",
      message: "Users Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteUsersFail(error?.response?.data))
  }
}

function* usersSaga() {
  yield takeEvery(GET_MEMBERS, fetchMembers)
  yield takeEvery(GET_MEMBERS_DETAILS, fetchUsersDetails)
  yield takeEvery(CREATE_MEMBERS, onCreateMembers)
  yield takeEvery(UPDATE_MEMBERS, onUpdateMembers)
  yield takeEvery(DELETE_MEMBERS, onDeleteMembers)

  // users
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(CREATE_USERS, onCreateUsers)
  yield takeEvery(UPDATE_USERS, onUpdateUsers)
  yield takeEvery(DELETE_USERS, onDeleteUsers)
}
export default usersSaga
