import MetaTag from "components/Common/Meta-tag"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { defaultImg } from "assets/images"

import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Media,
  Row,
  Spinner,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getClientDetail, getLeads, getVehicles } from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import MyPagination from "components/Common/MyPagination"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { map, range } from "lodash"
import moment from "moment"
import img from "assets/images/other/people.jpeg"
import ClientModal from "../Crud/Modal"
import { FaRegUser } from "react-icons/fa"

function Details() {
  const {
    vehicle,
    loading,
    vehicleCount,
    allLeads,
    allLeadsCount,
    clientDetail,
  } = useSelector(state => ({
    vehicleCount: state.Vehicle.vehicles?.total,
    vehicle: state.Vehicle.vehicles?.vehicles,
    loading: state?.Client?.loading,
    allLeads: state?.Lead?.leads?.leads,
    allLeadsCount: state?.Lead?.leads?.total,
    clientDetail: state?.Client?.clientDetails,
  }))
  const dispatch = useDispatch()
  const params = useParams()
  const [pageVehicle, setPageVehicle] = useState(1)
  const [pageLead, setPageLead] = useState(1)
  const [clientModal, setClientModal] = useState({ status: false, data: {} })

  const totalPage = Math.ceil(allLeadsCount / 10)
  const leadPages = range(1, totalPage + 1)

  const totalPages = Math.ceil(vehicleCount / 10)
  const vehiclePages = range(1, totalPages + 1)

  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  useEffect(() => {
    dispatch(getClientDetail(params?.id))
  }, [dispatch, params?.id])

  useEffect(() => {
    dispatch(getLeads(pageLead, "", "", "", "", "", clientDetail?._id, "", ""))
  }, [dispatch, pageLead, clientDetail?._id])

  const bool = false

  useEffect(() => {
    dispatch(
      getVehicles(
        pageVehicle,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        clientDetail?._id,
        "",
        " "
      )
    )
  }, [dispatch, pageVehicle, clientDetail?._id])

  const handleOrderStatus = status => {
    switch (status) {
      case "new":
        return "primary"
      case "qualified":
        return "info"
      case "processing":
        return "warning"
      case "confirmed":
        return "success"
      case "newBuyer":
        return "primary"
      case "followupDone":
        return "warning"
      case "priceQuoted":
        return "info"
      case "negotiation":
        return "warning"
      case "carBooked":
        return "success"
      case "paperWork":
        return "warning"
      case "carSold":
        return "danger"
      case "lost":
        return "danger"
      default:
        return "primary"
    }
  }

  const columnsLead = [
    {
      dataField: "createdAt",
      filterText: "createdAt",
      text: "Date",
    },

    {
      dataField: "vehicle.name",
      filterText: "vehicle",
      text: "Vehicle",
    },
    {
      dataField: "leadType",
      filterText: "leadType",
      text: "Lead Type",
      sort: true,
    },

    {
      dataField: "assignee.name",
      filterText: "assignee",
      text: "Assignee",
    },
    {
      dataField: "status",
      filterText: "status",
      text: "Status",
      sort: true,
    },
  ]

  const columns = [
    {
      dataField: "image",
      text: "Image",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "soldClient.name",
      text: "Sold Client",
      sort: true,
    },
    {
      dataField: "soldDate",
      text: "Sold Date",
      sort: true,
    },
    {
      dataField: "soldBy.name",
      text: "Sold By",
      sort: true,
    },
  ]

  const userData = map(
    vehicle,
    (item, index) => (
      console.log(item),
      {
        ...item,
        soldDate: <span>{moment(item?.soldDate).format("DD/MM/YYYY")}</span>,
        image: (
          <>
            <img
              className="rounded-circle header-profile-user"
              style={{ width: "70px", height: "70px" }}
              src={
                item?.images && item?.images[0]
                  ? item?.images[0]?.url
                  : defaultImg
              }
            />
          </>
        ),
      }
    )
  )

  const allLeadData = map(allLeads, (item, index) => ({
    ...item,
    createdAt: <span>{moment(item?.createdAt).format("DD/MM/YYYY")}</span>,
    status: (
      <Badge
        style={{ textTransform: "capitalize" }}
        className={
          "px-2 py-2 badge-soft-" + `${handleOrderStatus(item?.status)}`
        }
      >
        {" "}
        {item?.status}{" "}
      </Badge>
    ),
  }))

  return (
    <div>
      <ClientModal
        onCloseClick={() => setClientModal({ status: false, data: {} })}
        show={clientModal?.status}
        updateData={clientModal?.data}
      />
      <MetaTag title={"Client Detail"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Client Detail" />
        <Container fluid>
          <div className="container-fluid">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <FaRegUser size={20} className="mx-3" />
                    <div>
                      <h5>{clientDetail?.name}</h5>
                      <p className="mb-1">
                        {clientDetail?.address?.addressLine1}
                      </p>
                      <p className="mb-1">
                        {clientDetail?.address?.addressLine2}
                      </p>
                      <p className="mb-0">
                        {clientDetail?.address?.district?.name}
                        {clientDetail?.address?.state?.name}
                      </p>
                    </div>
                    <div className="mx-5">
                      {clientDetail?.email && (
                        <p className="d-flex align-items-center fs-6 mb-1">
                          <i className="bx bx-mail-send text-primary pe-2" />
                          {clientDetail?.email}
                        </p>
                      )}
                      {clientDetail?.phone && (
                        <p className="d-flex align-items-center fs-6 mb-1">
                          <i className="bx bx-phone text-secondary pe-2" />
                          {clientDetail?.phone}
                        </p>
                      )}
                      {clientDetail?.whatsappNumber && (
                        <p className="d-flex align-items-center fs-6 m-0">
                          <i className="bx bxl-whatsapp text-success pe-2" />
                          {clientDetail?.whatsappNumber}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <Button
                      color="warning"
                      type="button"
                      className="d-flex align-items-center"
                      onClick={() =>
                        setClientModal({
                          status: true,
                          data: clientDetail,
                        })
                      }
                    >
                      <i className="bx bx-edit me-1" />
                      Update
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className="py-4">
              <Row>
                <Col md="6">
                  <span></span>
                  {loading ? (
                    <Spinner color="secondary" className="d-block m-auto" />
                  ) : (
                    <>
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={userData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <h5>Vehicle Details :</h5>
                            <Card>
                              <CardBody>
                                {vehicle?.length >= 1 ? (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            // keyField={"id"}
                                            key={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            // defaultSorted={defaultSorted}
                                            // selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            onTableChange={handleTableChange}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <MyPagination
                                      totalPages={vehiclePages}
                                      page={pageVehicle}
                                      setPage={setPageVehicle}
                                      title={"vhcl"}
                                    />
                                  </>
                                ) : (
                                  <p
                                    className="my-4 mx-3"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    No vehicle
                                  </p>
                                )}
                              </CardBody>
                            </Card>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </>
                  )}
                </Col>
                <Col md="6">
                  <ToolkitProvider
                    keyField="_id"
                    columns={columnsLead}
                    data={allLeadData}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        {loading ? (
                          <Spinner
                            color="secondary"
                            className="d-block m-auto"
                          />
                        ) : (
                          <>
                            <h5>Lead Details :</h5>
                            <Card>
                              <CardBody>
                                {allLeads?.length >= 1 ? (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            key={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            // defaultSorted={defaultSorted}
                                            // selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            // onTableChange={handleTableChange}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="">
                                      <div className="w-100">
                                        <MyPagination
                                          totalPages={leadPages}
                                          page={pageLead}
                                          setPage={setPageLead}
                                          title={"lead"}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <p className="my-4 mx-3 ">No Lead Data !</p>
                                )}
                              </CardBody>
                            </Card>
                          </>
                        )}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Details
