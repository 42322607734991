import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import dashboardSaga from "./dashboard/saga"
import ClientSaga from "./client/saga"

// lead
import LeadSaga from "./lead/saga"

// brand
import BrandSaga from "./brand/saga"

import ModelSaga from "./model/saga"

import VehicleSaga from "./vehicle/saga"

import LeadHistorySaga from "./leadhistory/saga"

import LeadNotesSaga from "./leadnotes/saga"

import VarientSaga from "./varient/saga"

import SaleSaga from "./sale/saga"

import UsersSaga from "./users/saga"

import CategoriesSaga from "./categories/saga"

import ExelDownloadSaga from "./exeldownload/saga"

import FollowUpSaga from "./followUp/saga"

import MatchListSaga from "./matchlist/saga"

export default function* rootSaga() {
  yield all([
    fork(MatchListSaga),
    fork(ExelDownloadSaga),
    fork(UsersSaga),
    fork(SaleSaga),
    fork(VarientSaga),
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(BrandSaga),
    fork(LeadSaga),
    fork(ClientSaga),
    fork(ModelSaga),
    fork(LeadNotesSaga),
    fork(LeadHistorySaga),
    fork(VehicleSaga),
    fork(CategoriesSaga),
    fork(FollowUpSaga),
  ])
}
