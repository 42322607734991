import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_EXELDOWNLOAD_LEAD,
  GET_EXELDOWNLOAD_SALE,
  GET_EXELDOWNLOAD_VEHICLE,
} from "./actionTypes"
import {
  getExelDownloadSaleSuccess,
  getExelDownloadSaleFail,
  getExelDownloadVehicleSuccess,
  getExelDownloadVehicleFail,
  getExelDownloadLeadFail,
  getExelDownloadLeadSuccess,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// ExelDownload - This line cannot be edited or removed
function getExelDownloadSaleAPi({
  page,
  limit,
  searchText,
  clientId,
  vehicleId,
  sortByDate,
}) {
  return get(
    `/sale/download/all/?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText ? searchText : ""}&client=${
      clientId ? clientId : ""
    }&vehicle=${vehicleId ? vehicleId : ""}&from=${
      sortByDate?.from ? sortByDate?.from : ""
    }&to=${sortByDate?.to ? sortByDate?.to : ""}`
  )
}

const getExelDownloadVehicleApi = ({
  pageVehicle,
  totalItemLimit,
  vehicleSearchText,
  brandId,
  modelId,
  varientId,
  oilTypeValue,
  ownerShipStatusValue,
  sort,
  dateBySort,
}) => {
  return get(
    `/vehicle/download/all/?page=${pageVehicle ? pageVehicle : 1}&limit=${
      totalItemLimit ? totalItemLimit : 10
    }&search=${vehicleSearchText ? vehicleSearchText : ""}&brand=${
      brandId ? brandId : ""
    }&model=${modelId ? modelId : ""}&variant=${
      varientId ? varientId : ""
    }&ownershipStatus=${
      ownerShipStatusValue ? ownerShipStatusValue : ""
    }&sort=${sort ? sort : ""}&fuelType=${
      oilTypeValue ? oilTypeValue : ""
    }&from=${dateBySort?.from ? dateBySort?.from : ""}&to=${
      dateBySort?.to ? dateBySort?.to : ""
    }`
  )
}

const getExelDownloadLeadApi = ({
  page,
  sort,
  limit,
  searchText,
  vehicleId,
  typeValue,
  clientId,
  medium,
  sortByDate,
}) => {
  return get(
    `/lead/download/all?sort=${sort ? sort : ""}&leadType=${
      typeValue ? typeValue : ""
    }&vehicle=${vehicleId ? vehicleId : ""}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText ? searchText : ""}&client=${
      clientId && clientId
    }&medium=${medium ? medium : ""}&from=${sortByDate.from}&to=${
      sortByDate.to
    }`
  )
}

function* fetchExelDownloadSales({ payload }) {
  try {
    const response = yield call(getExelDownloadSaleAPi, payload)
    yield put(getExelDownloadSaleSuccess(response))
  } catch (error) {
    yield put(getExelDownloadSaleFail(error))
  }
}

function* fetchExelDownloadVehicles({ payload }) {
  try {
    const response = yield call(getExelDownloadVehicleApi, payload)
    yield put(getExelDownloadVehicleSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getExelDownloadVehicleFail(error))
  }
}

function* fetchExelDownloadLeads({ payload }) {
  try {
    const response = yield call(getExelDownloadLeadApi, payload)
    yield put(getExelDownloadLeadSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getExelDownloadLeadFail(error))
  }
}

function* exeldownloadSaga() {
  yield takeEvery(GET_EXELDOWNLOAD_SALE, fetchExelDownloadSales)
  yield takeEvery(GET_EXELDOWNLOAD_VEHICLE, fetchExelDownloadVehicles)
  yield takeEvery(GET_EXELDOWNLOAD_LEAD, fetchExelDownloadLeads)
}

export default exeldownloadSaga
