import { AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardBody } from "reactstrap"
import { getUsers, updateLead } from "store/actions"
import Select from "react-select"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { debounce } from "lodash"

const LeadModal = ({ assignee, onClose }) => {
  const params = useParams()
  const dispatch = useDispatch()
  const { members, assigneeLoading } = useSelector(state => ({
    members: state?.Members?.users?.users,
    assigneeLoading: state?.Members?.loading,
  }))

  const role = sessionStorage.getItem("role")
  const [assigneeValue, setAssigneeValue] = useState("")
  const [assigneeId, setAssigneeId] = useState("")
  const [assigneeSearch, setAssigneeSearch] = useState("")

  // const handleEntered = textEntered => {
  //   setAssigneeSearch(textEntered)
  // }

  const handleEntered = textEntered => {
    debounceProviderSearch(textEntered)
  }
  const debounceProviderSearch = debounce(
    value => setAssigneeSearch(value),
    600
  )

  function handleFinalValuesAssignee(event) {
    console.log("event =>", event)
    setAssigneeValue(event.label)
    setAssigneeId(event.value)
  }

  const handleRoles = () => {
    if (role === "enquiryManager") {
      return "enquiryAnalyst"
    } else if (role === "salesManager") {
      return "salesExecutive"
    } else if (role === "salesExecutive") {
      return "branchManager"
    } else if (role === "admin") {
      return "member"
    } else if (role === "enquiryAnalyst") {
      return "salesManager"
    }
  }
  useEffect(() => {
    // if (role !== "salesExecutive" && role !== "enquiryManager") {
    dispatch(getUsers("", "", assigneeSearch, handleRoles(), ""))
    // }
  }, [dispatch, role, assigneeSearch])

  const handleRoleText = text => {
    // let spacedString = text?.replace(/([A-Z])/g, " $1")
    // return spacedString.charAt(0).toUpperCase() + text?.slice(1).trim()
    if (text) {
      const addSpace = text?.replace(/([a-z])([A-Z])/g, "$1 $2")
      return addSpace?.charAt(0).toUpperCase() + addSpace?.slice(1).trim()
    } else return ""
  }

  const assigneeOptions = [
    {
      options: (members?.length >= 1 ? members : [])?.map((result, index) => ({
        key: index,
        // label: result?.name,
        label: `${result?.name} (${
          result?.role
            ? handleRoleText(result?.memberRole)
            : handleRoleText(result?.role)
        })`,
        value: result?._id,
      })),
    },
  ]
  // label: `${result?.full_name} (${
  //   result?.mahall_custom_id ? result?.mahall_custom_id : result?.custom_id
  // })`,
  useEffect(() => {
    setAssigneeValue(assignee ? assignee?.name : "Select assignee")
    setAssigneeId(assignee ? assignee?._id : "")
  }, [assignee])

  const handleValidSubmit = (e, v) => {
    const finalValues = {
      assignee: assigneeId,
    }
    dispatch(updateLead(finalValues, params?.id, "", onClose))
  }

  return (
    <CardBody className="p-0">
      <AvForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v)
        }}
      >
        <Select
          onInputChange={handleEntered}
          // value={assigneeValue}
          placeholder={assigneeValue}
          onChange={handleFinalValuesAssignee}
          options={assigneeOptions}
          classNamePrefix="select2-selection"
          isLoading={assigneeLoading}
        />
        {role === "enquiryAnalyst" ? (
          ""
        ) : (
          <>
            <div className="d-flex justify-content-end align-items-center">
              <div className="text-center mt-3">
                <button
                  type="button"
                  className="btn btn-light ms-2"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-warning ms-2">
                  Update
                </button>
              </div>
            </div>
          </>
        )}
      </AvForm>
    </CardBody>
  )
}

LeadModal.propTypes = {
  assignee: PropTypes.any,
  onClose: PropTypes.any,
}

export default LeadModal
