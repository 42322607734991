import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Spinner,
  CardTitle,
  Badge,
} from "reactstrap"

//actions
import MetaTag from "components/Common/Meta-tag"
import { map } from "lodash"
import { Link } from "react-router-dom"
import { useSiteVersion } from "hooks/useSiteVersion"
import { orderImg, productImg, users } from "assets/images"
import useGetStore from "hooks/useGetStore"
import {
  getDashboardData,
  getDashboardLeadChartData,
  getDashboardSaleChartData,
  getDashboardVehicleChartData,
  getMatchLists,
} from "store/actions"
import LeadAnalyticsChart from "./Charts/leadChart"
import VehicleChart from "./Charts/vehicleChart"
import Lead from "pages/Lead"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import SaleAnalyticsChart from "./Charts/saleChart"
import LeadToSale from "./Charts/leadToSale"
import { useWindowSize } from "components/Common/Hooks/useWindowSize"
import FollowUpDataTables from "pages/followUp/DataTables"
import Leads from "pages/Lead/DatatableTables"

const Dashboard = () => {
  const dispatch = useDispatch()
  const role = sessionStorage?.getItem("role")
  const history = useHistory()
  const { isPremium, isBasic } = useSiteVersion()
  const {
    dashboardData,
    loading,
    saleChart,
    vehicleChart,
    leadChart,
    allMatchLit,
  } = useSelector(state => ({
    dashboardData: state.Dashboard.dashboardData,
    loading: state.Dashboard.loading,
    leadChart: state?.Dashboard?.leadChartData,
    vehicleChart: state?.Dashboard?.vehicleChartData,
    saleChart: state?.Dashboard?.saleChartData,
    allMatchLit: state?.MatchList?.matchingLists,
  }))
  const wSize = useWindowSize()
  console.log(wSize)

  const userPercentage = (
    ((dashboardData?.thisMonthUsersCount - dashboardData?.lastMonthUsersCount) /
      dashboardData?.lastMonthUsersCount) *
    100
  ).toFixed(0)
  const isUsersHigher =
    dashboardData?.thisMonthUsersCount > dashboardData?.lastMonthUsersCount

  const TopCards = [
    {
      iconClass: users,
      title: "New Leads",
      description: dashboardData?.newLeadsCount || 0,
      title3: "Today",
      description3: dashboardData?.todayLeadsCount || 0,
      title2: "Total",
      description2: dashboardData?.totalLeadsCount || 0,

      bgColor: "bg-light-orange",
      subList: true,
      list: [
        {
          sbadges: "Direct",
          sbadgesRate: `${dashboardData?.directLeadsCount || 0}`,
          sbadgesPercentage: `${(userPercentage || "0") + `%`}`,
          sbadgesClass: "dark",
          sbadgesPercentageColor: `${isUsersHigher ? "success" : "danger"}`,
        },
        {
          sbadges: "Social Media",
          sbadgesRate: dashboardData?.socialLeadsCount,
          sbadgesClass: "dark",
        },
        {
          sbadges: "Call",
          sbadgesRate: dashboardData?.callLeadsCount,
          sbadgesClass: "dark",
        },
      ],
      Link: "/lead/all",
      col: "col-12 col-sm-12 col-xs-12 col-md-12 col-lg-4 col-xl-4",
    },
    {
      iconClass: orderImg,
      title: "Booked",
      description: dashboardData?.bookedVehiclesCount || 0,
      title2: "Sold",
      description2: dashboardData?.totalVehiclesSoldCount || 0,
      title3: "Available",
      description3: dashboardData?.currentVehicleCount || 0,

      bgColor: "bg-light-orange-color",
      subList: true,
      list: [
        {
          sbadges: "Today Booked",
          sbadgesRate: `${dashboardData?.vehiclesBookedTodayCount || 0} `,
          sbadgesClass: "dark",
        },
        {
          sbadges: "Today Sold",
          sbadgesRate: `${dashboardData?.vehiclesSoldTodayCount || 0}`,
          sbadgesClass: "dark",
        },
      ],
      Link: "/vehicle/all",
      col: "col-12 col-sm-12 col-xs-12 col-md-12 col-lg-4 col-xl-4",
    },
    {
      title: "New Clients",
      description: dashboardData?.newClientsTodayCount || 0,
      title2: "Today",
      description2: dashboardData?.newClientsTodayCount || 0,
      title3: "Total",
      description3: dashboardData?.totalClientsCount || 0,
      iconClass: productImg,
      bgColor: "bg-lighter-orange",
      subList: true,
      list: [
        // {
        //   sbadges: "Today Clients",
        //   sbadgesRate: dashboardData?.todayPurchasedClientsCount,
        //   sbadgesClass: "dark",
        // },
      ],
      Link: "/client/all",
      col: "col-12  col-xs-6 col-sm-6 col-md-12 col-lg-4 col-xl-4",
    },

    // {
    //   title: "Stores",
    //   iconClass: storeImg,
    //   description: dashboardData?.totalStores || 0,
    //   bgColor: "bg-whiteSmook",
    //   subList: false,
    //   list: [],
    //   Link: "/stores",
    //   col: "col-12  col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
    // },
  ]

  useEffect(() => {
    if (
      role === "admin" ||
      role === "generalManager" ||
      role === "managingDirector"
    ) {
      dispatch(getDashboardData())
    }
  }, [dispatch, role])

  useEffect(() => {
    if (
      role === "enquiryManager" ||
      role === "enquiryAnalyst" ||
      role === "salesManager" ||
      role === "salesExecutive"
    ) {
      history?.push("/lead/all")
    }
  }, [role])

  const reports = [
    {
      title: "Managing Directors",
      iconClass: "fas fa-user-tie",
      description: dashboardData?.managingDirectorCounts || 0,
      wbadges: "",
      wbadgesRate: "",
      col: { md: 4 },
    },
    {
      title: "General Managers",
      iconClass: "fas fa-user-tie",
      description: dashboardData?.generalManagerCounts || 0,
      wbadges: "",
      wbadgesRate: "",
      col: { md: 4 },
    },
    {
      title: "Enquiry Managers",
      iconClass: "fas fa-user-tie",
      description: dashboardData?.enquiryManagerCounts || 0,
      wbadges: "",
      wbadgesRate: "",
      col: { md: 4 },
    },
    {
      title: "Enquiry Analysts",
      iconClass: "fas fa-user-tie",
      description: dashboardData?.enquiryAnalystCounts || 0,
      wbadges: "",
      wbadgesRate: "",
      col: { md: 4 },
    },
    {
      title: "Sales Managers",
      iconClass: "fas fa-user-tie",
      description: dashboardData?.salesManagerCounts || 0,
      wbadges: "",
      wbadgesRate: "",
      col: { md: 4 },
    },
    {
      title: "Sales Executives",
      iconClass: "fas fa-user-tie",
      description: dashboardData?.salesExecutiveCounts || 0,
      wbadges: "",
      wbadgesRate: "",
      col: { md: 4 },
    },
  ]

  const logisticsData = [
    {
      title: "Shiprocket Wallet",
      iconClass: "fas fa-ticket-alt",
      description: `₹${dashboardData?.shiprocketWalletBalance || 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
    {
      title: "New Orders",
      iconClass: "fas fa-ticket-alt",
      description: `${dashboardData?.shiprocketNewOrders || 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
    {
      title: "PickUps",
      iconClass: "fas fa-ticket-alt",
      description: `${dashboardData?.shiprocketPickUps || 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
    {
      title: "In Transit",
      iconClass: "fas fa-ticket-alt",
      description: `${dashboardData?.shiprocketInTransit || 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
    {
      title: "Delivered",
      iconClass: "fas fa-ticket-alt",
      description: `${dashboardData?.shiprocketPickUps || 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
    {
      title: "NDR",
      iconClass: "fas fa-ticket-alt",
      description: `${dashboardData?.shiprocketPickUps || 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
  ]
  const classification = []

  const ids = []
  const logistics = []

  useEffect(() => {
    dispatch(getMatchLists("", "", 4, "", "", "", ""))
  }, [dispatch])

  const handleReport = () => {
    return reports
  }

  useEffect(() => {
    if (
      role === "admin" ||
      role === "generalManager" ||
      role === "managingDirector"
    ) {
      dispatch(getDashboardLeadChartData())
      dispatch(getDashboardVehicleChartData())
      dispatch(getDashboardSaleChartData())
    }
  }, [dispatch])

  useEffect(() => {
    if (role === "purchaseManager") {
      history?.push("/vehicle/all")
    }
  }, [role])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="Dashboard" />
        <Container fluid>
          {/* <CardTitle>Dashboard</CardTitle> */}
          {loading ? (
            <Spinner color="secondary" className="d-block m-auto" />
          ) : (
            <>
              {role === "admin" ||
              role === "managingDirector" ||
              role === "generalManager" ? (
                <>
                  <Row className="d-flex flex-wrap">
                    {map(TopCards, (item, key) => (
                      <Col className={item.col} key={key}>
                        <Card
                          className={"mega-stats-wid " + item.bgColor}
                          style={{ minHeight: 150 }}
                        >
                          <Link to={item.Link}>
                            <CardBody
                              className={
                                "" + item.subList
                                  ? "align-items-center"
                                  : "align-items-center"
                              }
                            >
                              <div
                                className={`d-flex flex-wrap align-items-center mb-3 w-100 ${
                                  item?.subList === false && "flex-column"
                                }`}
                              >
                                <div className="d-flex w-100">
                                  <Row className="d-flex justify-content-center align-items-center w-100">
                                    <Col
                                      className={`p-0 ${
                                        item?.subList === false &&
                                        "mt-2 text-center"
                                      }`}
                                      sm={4}
                                    >
                                      <p className="mb-2 text_md_black font-size-14 text-dark text-center">
                                        {item?.title}
                                      </p>

                                      <h3 className="text_deals_green text-center">
                                        {item?.description || 0}
                                      </h3>
                                    </Col>
                                    {item?.title2 && (
                                      <Col
                                        sm={4}
                                        className={`p-0 ${
                                          item?.subList === false &&
                                          "mt-2 text-center"
                                        }`}
                                      >
                                        <p className="mb-2 text_md_black font-size-14 text-center">
                                          {item?.title2}
                                        </p>
                                        <h3 className="text_deals_green text-center">
                                          {item?.description2 || 0}
                                        </h3>
                                      </Col>
                                    )}
                                    {item?.title3 && (
                                      <Col
                                        sm={4}
                                        className={`p-0 ${
                                          item?.subList === false &&
                                          "mt-2 text-center"
                                        }`}
                                      >
                                        <p className="mb-2 text_md_black font-size-14 text-center">
                                          {item?.title3}
                                        </p>
                                        <h3 className="text_deals_green text-center">
                                          {item?.description3 || 0}
                                        </h3>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              </div>

                              <div className="d-flex flex-wrap">
                                {map(item?.list, (subItem, subKey) => (
                                  <span
                                    className="d-flex align-items-center mb-2 me-1 text_md_black text-center"
                                    key={subKey}
                                  >
                                    <p
                                      className={
                                        "mb-0 mx-0 text-white fw-medium rounded-pill badge bg-back"
                                      }
                                      style={{
                                        fontSize: "13px",
                                        backgroundColor: "#EED481",
                                        color: "#000",
                                      }}
                                    >
                                      {subItem.sbadges}{" "}
                                      <span className="text-dark">
                                        &nbsp;
                                        {subItem?.sbadgesRate}
                                      </span>
                                    </p>
                                  </span>
                                ))}
                              </div>
                            </CardBody>
                          </Link>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <Col sm="6">
                      <Row className="d-flex p-2">
                        {handleReport()?.map((report, key) => (
                          <Col md={6} key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                              <CardBody>
                                <Link to={report.Link}>
                                  <Media>
                                    <Media body>
                                      <p
                                        className="text-muted fw-medium d-flex align-items-center"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <i
                                          className={`font-size-16 text-orange ${report.iconClass}`}
                                        ></i>
                                        <span className="mx-2 me-2">
                                          {report.title}
                                        </span>
                                      </p>
                                      <h4 className="mb-0  ">
                                        {report.description}
                                      </h4>
                                    </Media>

                                    {/* <Media body className="text-center">
                              <div className="flex-grow-1">
                                <p
                                  className={
                                    "text-white fw-medium rounded-pill badge bg-" +
                                    report?.sbadgesClass
                                  }
                                >
                                  {report?.sbadges}
                                </p>
                                <h4 className="mb-0">{report?.sbadgesRate}</h4>
                              </div>
                            </Media> */}

                                    <Media body className="text-center">
                                      <div className="flex-grow-1">
                                        <p
                                          className={
                                            "text-white fw-medium rounded-pill badge bg-" +
                                            report?.sbadgesClass
                                          }
                                        >
                                          {report.wbadges}
                                        </p>
                                        <h4 className="mb-0">
                                          {report.wbadgesRate}
                                        </h4>
                                      </div>
                                    </Media>
                                  </Media>
                                </Link>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                        {classification?.map((report, key) => (
                          <Col md="4" key={"_col_" + key}>
                            {ids?.includes(report?.value) ? (
                              <Card className="mini-stats-wid">
                                <CardBody>
                                  <Media>
                                    <Media body>
                                      <p className="text-muted fw-medium">
                                        {report.title}
                                      </p>
                                      <h4 className="mb-0">
                                        {report.description}
                                      </h4>
                                    </Media>

                                    {/* badges with success */}
                                    <Media body className="text-center">
                                      <div className="flex-grow-1">
                                        <p className="text-white fw-medium rounded-pill bg-success badge">
                                          {report.sbadges}
                                        </p>
                                        <h4 className="mb-0">
                                          {report.sbadgesRate}
                                        </h4>
                                      </div>
                                    </Media>

                                    {/* badges with warning */}
                                    <Media body className="text-center">
                                      <div className="flex-grow-1">
                                        <p className="text-white fw-medium rounded-pill bg-warning badge">
                                          {report.wbadges}
                                        </p>
                                        <h4 className="mb-0">
                                          {report.wbadgesRate}
                                        </h4>
                                      </div>
                                    </Media>
                                  </Media>
                                </CardBody>
                              </Card>
                            ) : (
                              ""
                            )}
                          </Col>
                        ))}
                      </Row>
                    </Col>

                    <Col sm="3" style={{ marginTop: 8 }}>
                      <Card className="p-2 " style={{ borderRadius: 10 }}>
                        <div className="d-flex align-items-center justify-content-between my-2 ">
                          <span className="px-2 py-">Matching List</span>
                          <div className="d-flex align-items-center  mx-2">
                            <Link to="/matching-list/all">View more...</Link>
                          </div>
                        </div>
                        {allMatchLit?.matchingLists?.map((item, key) => (
                          <>
                            <div
                              style={{
                                backgroundColor: "#ececec",
                                padding: 15,
                                borderRadius: 10,
                              }}
                              key={key}
                              className=" mx-2 my-1"
                            >
                              <span>{item?.lead?.client?.name}</span> &nbsp;
                              <span>{item?.vehicle?.name}</span>&nbsp;&nbsp;
                              <span>{item?.brand?.name}</span>&nbsp;&nbsp;
                              <span>{item?.model?.name}</span>&nbsp;&nbsp;
                              <span>{item?.variant?.name}</span>&nbsp;
                              <span>{item?.matches?.length}</span>
                              <span className="px-3">
                                {item?.isActive !== true ? (
                                  <Badge
                                    className={"px-1 py-1 badge-soft-danger"}
                                  >
                                    {" "}
                                    {"Not Active"}
                                  </Badge>
                                ) : (
                                  <Badge
                                    className={"px-1 py-1 badge-soft-success"}
                                  >
                                    {"Active"}
                                  </Badge>
                                )}
                              </span>
                            </div>
                            {/* <hr /> */}
                          </>
                        ))}
                      </Card>
                    </Col>
                    <Col sm="3">
                      <LeadToSale
                        chartData={dashboardData?.leadToSaleRatio || 0}
                      />
                    </Col>
                  </Row>
                  {logistics?.isShiprocketConnected && (
                    <>
                      <p>Logistics</p>
                      <hr />
                      <Row>
                        {logisticsData?.map((report, key) => (
                          <Col md={report.col.md} key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                              <CardBody>
                                <Link to={report.Link}>
                                  <Media>
                                    <Media body>
                                      <p
                                        className="text-muted fw-medium d-flex align-items-center"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <i
                                          className={`font-size-16 text-success ${report.iconClass}`}
                                        ></i>
                                        <span className="mx-2">
                                          {report.title}
                                        </span>
                                      </p>
                                      <h4 className="mb-0  ">
                                        {report.description}
                                      </h4>
                                    </Media>

                                    <Media body className="text-center">
                                      <div className="flex-grow-1">
                                        <p
                                          className={
                                            "text-white fw-medium rounded-pill badge bg-" +
                                            report?.sbadgesClass
                                          }
                                        >
                                          {report.wbadges}
                                        </p>
                                        <h4 className="mb-0">
                                          {report.wbadgesRate}
                                        </h4>
                                      </div>
                                    </Media>
                                  </Media>
                                </Link>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  <Row style={{ marginTop: -20 }}>
                    <Col className="col-6">
                      <Card style={{ borderRadius: 5, height: 400 }}>
                        <CardBody>
                          Lead Analytics
                          <LeadAnalyticsChart leadChart={leadChart} />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="col-6">
                      <Card style={{ borderRadius: 5, height: 400 }}>
                        <CardBody>
                          Sale Analytics
                          <SaleAnalyticsChart saleCharts={saleChart} />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="col-12">
                      <Card style={{ borderRadius: 5 }}>
                        <CardBody>
                          Vehicle Analytics
                          <VehicleChart vehicleChart={vehicleChart} />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : (
                <div>
                  <FollowUpDataTables />
                  <Leads />
                </div>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
