import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CHARTS_DATA,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_LEAD_CHART_DATA,
  GET_DASHBOARD_SALE_CHART_DATA,
  GET_DASHBOARD_VEHICLE_CHART_DATA,
  GET_PROMOTER_DASHBOARD_DATA,
  GET_PROMOTER_PROFILE_DETAILS,
} from "./actionTypes"
import {
  apiSuccess,
  apiFail,
  getDashboardDataSuccess,
  getDashboardDataFail,
  getPromoterProfileDetailsSuccess,
  getPromoterProfileDetailsFail,
  getDashboardLeadChartDataSuccess,
  getDashboardLeadChartDataFail,
  getDashboardVehicleChartDataSuccess,
  getDashboardVehicleChartDataFail,
  getDashboardSaleChartDataSuccess,
  getDashboardSaleChartDataFail,
} from "./actions"

import { get } from "helpers/api_helper"
import {
  getPromoterDashboardDataFail,
  getPromoterDashboardDataSuccess,
} from "store/actions"

const role = sessionStorage?.getItem("role")

function getDashboardDataAPi() {
  return get("/static/dashboard/data")
}

// promoter profile api
function promoterProfileApi() {
  return get(`/promoter`)
}

// promoter dashboard api

function promoterDashboardDataApi() {
  return get(`/static/promoter/dashboard/data`)
}

function getDashboardLeadChartDataAPi() {
  return get("/lead/graph/monthly")
}
function getDashboardVehicleChartDataAPi() {
  return get("/vehicle/graph/monthly")
}
function getDashboardSaleChartDataAPi() {
  return get("/sale/graph/monthly")
}

function* getChartsData({ payload: periodType }) {
  try {
    var response
    yield put(apiSuccess(GET_CHARTS_DATA, response))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

function* fetchDashboardData({ payload }) {
  try {
    const response = yield call(getDashboardDataAPi, payload)
    yield put(getDashboardDataSuccess(response))
  } catch (error) {
    yield put(getDashboardDataFail(error))
  }
}
function* promoterProfile({ payload }) {
  try {
    const response = yield call(promoterProfileApi, payload)
    yield put(getPromoterProfileDetailsSuccess(response))
  } catch (error) {
    yield put(getPromoterProfileDetailsFail(error))
  }
}

//promoter dashboard data

function* promoterDashboardData({ payload }) {
  try {
    const response = yield call(promoterDashboardDataApi, payload)
    yield put(getPromoterDashboardDataSuccess(response))
  } catch (error) {
    yield put(getPromoterDashboardDataFail(error))
  }
}

// --------------------------|| Charts  ||----------------------------------//

// lead

function* fetchDashboardLeadChartData({ payload }) {
  try {
    const response = yield call(getDashboardLeadChartDataAPi, payload)
    yield put(getDashboardLeadChartDataSuccess(response))
  } catch (error) {
    yield put(getDashboardLeadChartDataFail(error))
  }
}

// vehicle

function* fetchDashboardVehicleChartData({ payload }) {
  try {
    const response = yield call(getDashboardVehicleChartDataAPi, payload)
    yield put(getDashboardVehicleChartDataSuccess(response))
  } catch (error) {
    yield put(getDashboardVehicleChartDataFail(error))
  }
}
// sale

function* fetchDashboardSaleChartData({ payload }) {
  try {
    const response = yield call(getDashboardSaleChartDataAPi, payload)
    yield put(getDashboardSaleChartDataSuccess(response))
  } catch (error) {
    yield put(getDashboardSaleChartDataFail(error))
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData)
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)])

  yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData)
  yield takeEvery(GET_PROMOTER_PROFILE_DETAILS, promoterProfile)
  yield takeEvery(GET_PROMOTER_DASHBOARD_DATA, promoterDashboardData)

  yield takeEvery(GET_DASHBOARD_LEAD_CHART_DATA, fetchDashboardLeadChartData)
  yield takeEvery(
    GET_DASHBOARD_VEHICLE_CHART_DATA,
    fetchDashboardVehicleChartData
  )
  yield takeEvery(GET_DASHBOARD_SALE_CHART_DATA, fetchDashboardSaleChartData)
}

export default dashboardSaga
