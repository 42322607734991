import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { createCategories, updateCategories } from "store/actions"
import { useParams } from "react-router-dom"
import moment from "moment"
import UseImageUploader from "components/imageUploader/useImageUploader"
import { WithContext as ReactTags } from "react-tag-input"
import Switch from "react-switch"
import { useLocation } from "react-router-dom"
import { map } from "lodash"
import { useHistory } from "react-router-dom"

function Form({ updateData }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const history = useHistory()

  const { categoriesDetails } = useSelector(state => ({
    categoriesDetails: state.Categories?.categoriesDetails,
  }))

  const [webBanners, setWebBanners] = useState([])
  const [appBanners, setAppBanners] = useState([])
  const [logoImages, setLogoImages] = useState([])

  useEffect(() => {
    if (categoriesDetails) {
      setLogoImages(categoriesDetails?.logoImages || [])
      setAppBanners(categoriesDetails?.appBanners || [])
      setWebBanners(categoriesDetails?.webBanners || [])
    }
  }, [categoriesDetails])

  console.log(webBanners, "web banner")

  const handleSubmit = (e, values) => {
    const categoryData = {
      ...values,
      name: values.name,
      description: values.description,
      webBanners: webBanners,
      appBanners: appBanners,
      logoImages: logoImages,
    }
    if (params?.id) {
      dispatch(updateCategories(categoryData, categoriesDetails?._id, history))
    } else {
      dispatch(createCategories(categoryData, history))
    }
  }

  return (
    <div>
      <AvForm
        onValidSubmit={(e, v) => {
          handleSubmit(e, v)
        }}
      >
        <Row>
          {/* <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col
                    md={6}
                    style={{
                      marginTop: "10px",
                    }}
                  ></Col>
                </Row>
              </CardBody>
            </Card>
          </Col> */}

          <>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col
                      md={6}
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <div className="mb-4 d-flex align-items-end m-2 mt-4">
                        <div className="w-100">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <AvField
                            name="name"
                            type="text"
                            className="form-control"
                            value={categoriesDetails?.name ?? ""}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter a name",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <Col md={12} className="p-2 ">
                        <AvField
                          name="description"
                          type="textarea"
                          className="form-control"
                          label="Description"
                          value={categoriesDetails?.description ?? ""}
                          validate={{
                            required: {
                              value: false,
                              errorMessage: "Please enter a name",
                            },
                          }}
                          style={{ height: 120 }}
                        />
                      </Col>

                      <label className="mt-5">Logo</label>
                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={logoImages}
                        setImagesPreview={setLogoImages}
                        uploadUrl={"/focusmotors"}
                        isMultiple
                      />

                      <label className="mt-5">Web Banner</label>
                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={webBanners}
                        setImagesPreview={setWebBanners}
                        uploadUrl={"/focusmotors"}
                        isMultiple
                      />
                      <label className="mt-5">App Banner</label>
                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={appBanners}
                        setImagesPreview={setAppBanners}
                        uploadUrl={"/focusmotors"}
                        isMultiple
                      />
                    </Col>
                  </Row>
                  <Col sm="create" className="px-3">
                    <div className="d-flex align-items-center justify-content-end py-2 mb-4">
                      <Button className="px-5" color="warning" type="submit">
                        {updateData ? "Update" : "Create"}
                      </Button>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </>
        </Row>
      </AvForm>
    </div>
  )
}

export default Form

Form.propTypes = {
  updateData: PropTypes.any,
}
