/* FOLLOWUPs - This line cannot be edited or removed */
export const GET_ALL_FOLLOWUP = "GET_ALL_FOLLOWUP"
export const GET_ALL_FOLLOWUP_SUCCESS = "GET_ALL_FOLLOWUP_SUCCESS"
export const GET_ALL_FOLLOWUP_FAIL = "GET_ALL_FOLLOWUP_FAIL"

/* Client_DETAILS */
export const GET_FOLLOWUP_DETAILS = "GET_FOLLOWUP_DETAILS"
export const GET_FOLLOWUP_DETAILS_SUCCESS = "GET_FOLLOWUP_DETAILS_SUCCESS"
export const GET_FOLLOWUP_DETAILS_FAIL = "GET_FOLLOWUP_DETAILS_FAIL"

/* CREATE FOLLOWUP */
export const CREATE_FOLLOWUP = "CREATE_FOLLOWUP"
export const CREATE_FOLLOWUP_SUCCESS = "CREATE_FOLLOWUP_SUCCESS"
export const CREATE_FOLLOWUP_FAIL = "CREATE_FOLLOWUP_FAIL"

/* Edit FOLLOWUP */
export const UPDATE_FOLLOWUP = "UPDATE_FOLLOWUP"
export const UPDATE_FOLLOWUP_SUCCESS = "UPDATE_FOLLOWUP_SUCCESS"
export const UPDATE_FOLLOWUP_FAIL = "UPDATE_FOLLOWUP_FAIL"

/* Delete FOLLOWUP */
export const DELETE_FOLLOWUP = "DELETE_FOLLOWUP"
export const DELETE_FOLLOWUP_SUCCESS = "DELETE_FOLLOWUP_SUCCESS"
export const DELETE_FOLLOWUP_FAIL = "DELETE_FOLLOWUP_FAIL"
