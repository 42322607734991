import {
  GET_MATCHLISTS,
  GET_MATCHLISTS_SUCCESS,
  GET_MATCHLISTS_FAIL,
  GET_MATCHLIST_DETAILS,
  GET_MATCHLIST_DETAILS_SUCCESS,
  GET_MATCHLIST_DETAILS_FAIL,
  CREATE_MATCHLIST,
  CREATE_MATCHLIST_SUCCESS,
  CREATE_MATCHLIST_FAIL,
  UPDATE_MATCHLIST,
  UPDATE_MATCHLIST_SUCCESS,
  UPDATE_MATCHLIST_FAIL,
  DELETE_MATCHLIST_SUCCESS,
  DELETE_MATCHLIST_FAIL,
  DELETE_MATCHLIST,
} from "./actionTypes"

// MatchList - This line cannot be edited or removed
export const getMatchLists = (
  page,
  sort,
  limit,
  // typeValue,
  vehicleId,
  brandId,
  modelId,
  leadId
) => ({
  type: GET_MATCHLISTS,
  payload: {
    page,
    sort,
    limit,
    // typeValue,
    vehicleId,
    brandId,
    modelId,
    leadId,
  },
})

export const getMatchListsSuccess = matchlists => ({
  type: GET_MATCHLISTS_SUCCESS,
  payload: matchlists,
})

export const getMatchListsFail = error => ({
  type: GET_MATCHLISTS_FAIL,
  payload: error,
})

export const getMatchListDetails = matchlistId => ({
  type: GET_MATCHLIST_DETAILS,
  payload: matchlistId,
})

export const getMatchListDetailsSuccess = matchlistDetails => ({
  type: GET_MATCHLIST_DETAILS_SUCCESS,
  payload: matchlistDetails,
})

export const getMatchListDetailsFail = error => ({
  type: GET_MATCHLIST_DETAILS_FAIL,
  payload: error,
})

export const createMatchList = (matchlist, history) => ({
  type: CREATE_MATCHLIST,
  payload: { matchlist, history },
})

export const createMatchListSuccess = matchlist => ({
  type: CREATE_MATCHLIST_SUCCESS,
  payload: matchlist,
})

export const createMatchListFail = error => ({
  type: CREATE_MATCHLIST_FAIL,
  payload: error,
})

export const getMatchListDetail = matchlistId => ({
  type: GET_MATCHLIST_DETAILS,
  payload: matchlistId,
})

export const updateMatchList = (matchlist, matchlistId, history) => ({
  type: UPDATE_MATCHLIST,
  payload: { matchlist, matchlistId, history },
})

export const updateMatchListSuccess = matchlist => ({
  type: UPDATE_MATCHLIST_SUCCESS,
  payload: matchlist,
})

export const updateMatchListFail = error => ({
  type: UPDATE_MATCHLIST_FAIL,
  payload: error,
})

export const deleteMatchList = (matchlistId, history) => ({
  type: DELETE_MATCHLIST,
  payload: { matchlistId, history },
})

export const deleteMatchListSuccess = matchlist => ({
  type: DELETE_MATCHLIST_SUCCESS,
  payload: matchlist,
})

export const deleteMatchListFail = error => ({
  type: DELETE_MATCHLIST_FAIL,
  payload: error,
})
