import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DatatableTables from "./DatatableTables"
import MetaTag from "components/Common/Meta-tag"

function Lead() {
  const role = sessionStorage?.getItem("role")
  return (
    <>
      <MetaTag title="All Leads" />

      <div className="page-content">
        {role === "admin" ? (
          <Breadcrumbs title="Dashboard" breadcrumbItem=" All Leads" />
        ) : (
          ""
        )}
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Lead
