import React, { Fragment, useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Badge, Spinner } from "reactstrap"
import { UncontrolledTooltip } from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import SimpleBar from "simplebar-react"
import { TbClockExclamation } from "react-icons/tb"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

//actions

import "../../assets/scss/datatables.scss"

import DeleteModal from "components/Common/DeleteModal"

import {
  deleteLead,
  getClients,
  getExelDownloadLead,
  getLeadStatusCount,
  getLeads,
  getUsers,
  getVehicles,
} from "store/actions"
import ReactDrawer from "react-drawer"
import { FaList } from "react-icons/fa"
import { PiTableFill } from "react-icons/pi"
import ExcelDownload from "hooks/useExcelDownloadButton"
import moment from "moment"
import PropTypes from "prop-types"

const Leads = ({ vehicleDetailsId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  console.log(location)

  const {
    allLeads,
    count,
    loading,
    allVehicle,
    allLeadsExel,
    allClients,
    statusCount,
    allStaffs,
  } = useSelector(state => ({
    allLeads: state?.Lead?.leads?.leads,
    loading: state?.Lead?.loading,
    count: state?.Lead?.leads?.total,
    allVehicle: state.Vehicle.vehicles?.vehicles,
    allLeadsExel: state.ExelDownload?.exeldownloadLead.leads,
    allClients: state?.Client?.clients?.clients,
    statusCount: state?.Lead?.leads?.statusCounts,
    allStaffs: state?.Members?.users?.users,
  }))

  const total = count

  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [page, setPage] = useState(null)

  const [sort, setSort] = useState("all")

  const [vehicle, setVehicle] = useState("Select Vehicle...")
  const [vehicleId, setVehicleId] = useState("")
  const [vehicleSearch, setVehicleSearch] = useState("")

  const [client, setClient] = useState("Select Client...")
  const [clientId, setClientId] = useState("")
  const [clientSearch, setClientSearch] = useState("")

  const [staff, setStaff] = useState("Select Staff...")
  const [staffId, setStaffId] = useState("")
  const [staffSearch, setStaffSearch] = useState("")

  const [typeValue, setTypeValue] = useState("")

  const [layout, setLayout] = useState("table")
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState("right")

  const [medium, setMedium] = useState("")
  const [finalDateFormatted, setFinalDateFormatted] = useState({
    from: "",
    to: "",
  })

  const updateURLParams = (key, value, extraState = {}) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set("p", 1)

    if (location?.pathname !== "/dashboard") {
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: {
          ...location.state,
          page: 1,
          ...extraState,
          [key]: value,
        },
      })
    }
  }

  const handleDateSearch = () => {
    if (
      finalDateFormatted?.from?.length >= 1 &&
      finalDateFormatted?.to?.length >= 1
    ) {
      return finalDateFormatted
    } else {
      return {
        from: "",
        to: "",
      }
    }
  }

  const role = sessionStorage.getItem("role")

  useEffect(() => {
    if (vehicleDetailsId) {
      setVehicleId(vehicleDetailsId)
    }
  }, [vehicleDetailsId])

  //delete
  const [isOpen, setIsOpen] = useState({ status: false, id: "" })
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteLead(isOpen?.id, ""))
  }

  useEffect(() => {
    setPage(1)
  }, [])

  useEffect(() => {
    if (page) {
      dispatch(
        getLeads(
          page,
          sort,
          totalItemLimit,
          "",
          vehicleId,
          typeValue,
          clientId,
          medium,
          handleDateSearch(),
          staffId
        )
      )
    }
  }, [
    dispatch,
    page,
    sort,
    totalItemLimit,
    vehicleId,
    typeValue,
    clientId,
    medium,
    finalDateFormatted,
    staffId,
  ])

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getExelDownloadLead(
        page,
        sort,
        totalItemLimit,
        "",
        vehicleId,
        typeValue,
        clientId,
        medium,
        handleDateSearch()
      )
    )
  }, [
    dispatch,
    page,
    sort,
    totalItemLimit,
    vehicleId,
    typeValue,
    clientId,
    medium,
    finalDateFormatted,
  ])

  const columns = [
    {
      dataField: "list",
      filterText: "",
      text: "#",
      sort: true,
    },
    {
      dataField: "isFollowupCreated",
      filterText: "",
      text: "",
      sort: true,
    },
    {
      dataField: "createdAt",
      filterText: "createdAt",
      text: "Date",
    },
    {
      dataField: "client",
      filterText: "client",
      text: "Client",
    },
    {
      dataField: "medium",
      filterText: "medium",
      text: "Medium",
      sort: true,
    },
    {
      dataField: "vehicle",
      filterText: "vehicle",
      text: "Vehicle",
    },
    {
      dataField: "leadType",
      filterText: "leadType",
      text: "Lead Type",
      sort: true,
    },

    {
      dataField: "assignee",
      filterText: "assignee",
      text: "Assignee",
    },
    {
      dataField: "status",
      filterText: "status",
      text: "Status",
      sort: true,
    },

    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const handleColumns = () => {
    if (vehicleDetailsId) {
      return columns?.filter(i => i.dataField !== "vehicle")
    } else return columns
  }

  const handleLeadType = leadType => {
    switch (leadType) {
      case "dream_car":
        return "info"
      case "buy":
        return "success"
      case "sell":
        return "danger"
      default:
        return "info"
    }
  }
  const handleLeadTypes = leadType => {
    switch (leadType) {
      case "dream_car":
        return "Dream Car"
      case "buy":
        return "buy"
      case "sell":
        return "sell"
      default:
        return ""
    }
  }

  const handleOrderStatus = status => {
    switch (status) {
      case "new":
        return "primary"
      case "qualified":
        return "info"
      case "processing":
        return "warning"
      case "confirmed":
        return "success"
      case "newBuyer":
        return "primary"
      case "followupDone":
        return "warning"
      case "priceQuoted":
        return "info"
      case "negotiation":
        return "warning"
      case "carBooked":
        return "success"
      case "paperWork":
        return "warning"
      case "carSold":
        return "success"
      case "lost":
        return "danger"
      default:
        return "primary"
    }
  }

  const handleCheckStatus = status => {
    switch (status) {
      case "new":
        return "new"
      case "qualified":
        return "qualified"
      case "processing":
        return "processing"
      case "confirmed":
        return "confirmed"
      case "newBuyer":
        return "New Buyer"
      case "followupDone":
        return "Follow Up Done"
      case "priceQuoted":
        return "Price Quoted"
      case "negotiation":
        return "negotiation"
      case "carBooked":
        return "Car Booked"
      case "paperWork":
        return "Paper Work"
      case "carSold":
        return "Car Sold"
      case "lost":
        return "lost"
      default:
        return "new"
    }
  }

  //all
  const allLeadData = map(allLeads, (item, index) => {
    return {
      ...item,
      key: item._id,
      createdAt: <span>{moment(item?.createdAt).format("DD-MM-YYYY")}</span>,
      list: <span>{index + 1 + (page - 1) * totalItemLimit}</span>,

      isFollowupCreated:
        item?.status === "new" &&
        item?.createdAt &&
        item?.createdAt >= moment().startOf("day").fromNow() &&
        item?.isFollowupCreated === false ? (
          <span>
            <TbClockExclamation style={{ color: "red", fontSize: 20 }} />
          </span>
        ) : (
          ""
        ),

      assignee: (
        <Link to={`/client/details/${item?.assignee?._id}`}>
          <p>{item?.assignee?.name}</p>
        </Link>
      ),
      message: (
        <p>
          {item?.message?.length >= 30
            ? item?.message?.slice(0, 30 - 1) + "..."
            : item?.message}
        </p>
      ),
      medium: item?.medium && (
        <Badge
          style={{ textTransform: "capitalize" }}
          className={"px-2 py-2 badge-soft-info"}
        >
          {" "}
          {item?.medium}{" "}
        </Badge>
      ),

      client: (
        <p>
          <Link to={`/client/details/${item?.client?._id}`}>
            {item?.client?.name}
          </Link>
        </p>
      ),
      vehicle: (
        <p>
          <Link to={`/vehicle/details/${item?.vehicle?._id}`}>
            {item?.vehicle?.name}
          </Link>
        </p>
      ),
      leadType: item?.leadType && (
        <Badge
          style={{ textTransform: "capitalize" }}
          className={
            "px-2 py-2 badge-soft-" + `${handleLeadType(item?.leadType)}`
          }
        >
          {" "}
          {handleLeadTypes(item?.leadType)}{" "}
        </Badge>
      ),
      status: item?.status && (
        <Badge
          style={{ textTransform: "capitalize" }}
          className={
            "px-2 py-2 badge-soft-" + `${handleOrderStatus(item?.status)}`
          }
        >
          {" "}
          {handleCheckStatus(item?.status)}{" "}
        </Badge>
      ),
      action: (
        <div>
          <Link to={`/lead-details/${item?._id}`}>
            <Button
              id={`view${index}`}
              type="button"
              color="white"
              className="ms-1 btn"
            >
              <i
                className="bx bx-show-alt me-2"
                style={{ color: "green", fontSize: "24px" }}
              />
            </Button>
          </Link>
        </div>
      ),
    }
  })

  const listChange = sortValue => {
    setSort(sortValue)
    updateURLParams("sort", sortValue)
  }

  const handlePageLimit = e => {
    const newLimit = parseInt(e.target.value)
    if (e.target.value >= 1) {
      setTotalItemLimit(newLimit)
      updateURLParams("limit", newLimit)
    }
  }

  const bool = false

  useEffect(() => {
    dispatch(
      getVehicles("", "", vehicleSearch, "", "", "", "", "", "", "", "", bool)
    )
  }, [dispatch, vehicleSearch, bool])

  const handlerVehicleFinalValue = event => {
    setVehicle(event?.label)
    setVehicleId(event?.value)

    updateURLParams("vehicle", event?.value, { vehicleName: event?.label })
  }

  const vehicleOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allVehicle?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersVehicle = text => {
    setVehicleSearch(text)
  }

  useEffect(() => {
    dispatch(getClients("", "", clientSearch, "", ""))
  }, [dispatch, clientSearch])

  const clientOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allClients?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersClients = text => {
    setClientSearch(text)
  }

  const handlerClientFinalValue = event => {
    setClient(event?.label)
    if (event?.value) {
      setClientId(event?.value)
      updateURLParams("client", event?.value, { clientName: event?.label })
    }
  }

  useEffect(() => {
    const searchText1 = staffSearch
    if (
      role === "admin" ||
      role === "enquiryManager" ||
      role === "enquiryAnalyst"
    ) {
      dispatch(getUsers("", "", searchText1, "", "member"))
    }
  }, [dispatch, staffSearch])

  const staffOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allStaffs?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersStaffs = text => {
    setStaffSearch(text)
  }

  const handlerStaffsFinalValue = event => {
    setStaff(event?.label)
    setStaffId(event?.value)
    updateURLParams("staff", event?.value, { staffName: event?.label })
  }

  const handlerDateFrom = e => {
    setFinalDateFormatted({
      ...finalDateFormatted,
      from: e.target.value,
    })
    updateURLParams("dateFrom", e.target.value)
  }

  const handlerDateTo = e => {
    setFinalDateFormatted({
      ...finalDateFormatted,
      to: e.target.value,
    })
    updateURLParams("dateTo", e.target.value)
  }

  const handlerClearDateFrom = () => {
    setFinalDateFormatted({
      ...finalDateFormatted,
      from: "",
    })
    updateURLParams("dateFrom", "")
  }

  const handlerClearDateTo = () => {
    setFinalDateFormatted({
      ...finalDateFormatted,
      to: "",
    })
    updateURLParams("dateTo", "")
  }

  const headerOptions = (
    <>
      {layout && (
        <div className={`headerOptionWrap w-100`}>
          <div className="headerOptionSubWrap  align-items-center flex-wrap ">
            <div className={`${layout === "list" ? "" : "d-flex"}`}>
              <div className={`${layout === "list" ? " " : "py-2"}`}></div>
              <div className={`${layout === "list" ? " " : "py-2"}`}>
                <label></label>
                <AvForm className="form-horizontal mx-3 d-flex align-items-center">
                  <div className="mb-3 mx-1">
                    <span className="">
                      Show
                      <i className="fas fa-sort-numeric-down-alt mx-0"></i>
                    </span>
                  </div>
                  <div className="mb-3" style={{ width: "50px" }}>
                    <AvField
                      name="pageLimit"
                      className="form-control"
                      value={totalItemLimit}
                      onChange={e => handlePageLimit(e)}
                      type="text"
                      required
                    />
                  </div>
                </AvForm>
              </div>
              <div className={layout === "list" ? "my-2" : "w-25 mx-1"}>
                {" "}
                <label>Client</label>
                <Select
                  onInputChange={handleEntersClients}
                  value={client}
                  placeholder={client}
                  onChange={handlerClientFinalValue}
                  options={clientOptions}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />
              </div>
              {!vehicleDetailsId && (
                <div className={layout === "list" ? "my-2" : "w-25 mx-1"}>
                  <label>Vehicle</label>
                  <Select
                    onInputChange={handleEntersVehicle}
                    value={vehicle}
                    placeholder={vehicle}
                    onChange={handlerVehicleFinalValue}
                    options={vehicleOptions}
                    classNamePrefix="select2-selection"
                    isLoading={loading}
                  />
                </div>
              )}

              {role === "admin" || role === "enquiryManager" ? (
                <div className={layout === "list" ? "my-2" : "w-25 mx-1"}>
                  <label>Staff</label>
                  <Select
                    onInputChange={handleEntersStaffs}
                    value={staff}
                    placeholder={staff}
                    onChange={handlerStaffsFinalValue}
                    options={staffOptions}
                    classNamePrefix="select2-selection"
                    isLoading={loading}
                  />
                </div>
              ) : (
                ""
              )}

              <AvForm style={{ marginTop: 8 }}>
                <span className="mt-1">Sort By Date</span>
                <div className={`d-flex date_wrapper `}>
                  <div className="d-flex align-items-start position-relative">
                    <AvField
                      name="dateRange"
                      type="date"
                      validate={{
                        dateRange: {
                          start: { value: -5, units: "years" },
                          end: { value: 5, units: "years" },
                        },
                      }}
                      onChange={e => handlerDateFrom(e)}
                      value={finalDateFormatted?.from}
                    />
                    <button
                      onClick={handlerClearDateFrom}
                      type="button"
                      className="btn btn-white editable-cancel date_close btn-sm"
                    >
                      <i className="mdi mdi-close text-danger "></i>
                    </button>
                  </div>
                  <div className="d-flex align-items-start position-relative">
                    <AvField
                      name="dateRange"
                      className="mx-1"
                      type="date"
                      validate={{
                        dateRange: {
                          start: { value: -5, units: "years" },
                          end: { value: 5, units: "years" },
                        },
                      }}
                      onChange={e => handlerDateTo(e)}
                      value={finalDateFormatted?.to}
                    />
                    <button
                      onClick={handlerClearDateTo}
                      type="button"
                      className="btn btn-white editable-cancel date_close btn-sm"
                    >
                      <i className="mdi mdi-close text-danger "></i>
                    </button>
                  </div>
                </div>
              </AvForm>
            </div>
          </div>
        </div>
      )}
    </>
  )

  const toggleTopDrawer = () => {
    setPosition("right")
    setOpen(!open)
    setLayout("list")
    localStorage.setItem("layout", "list")
  }

  const onDrawerClose = () => {
    setOpen(false)
  }

  const handleStatusValue = () => {
    switch (role) {
      case "admin":
      case "managingDirector":
      case "generalManager":
      case "enquiryAnalyst":
      case "branchManager":
        const statusValues = [
          { label: "All", value: "all", count: statusCount?.all },
          { label: "New", value: "new", count: statusCount?.new },
          {
            label: "Qualified",
            value: "qualified",
            count: statusCount?.qualified,
          },
          {
            label: "Processing",
            value: "processing",
            count: statusCount?.processing,
          },
          {
            label: "Confirmed",
            value: "confirmed",
            count: statusCount?.confirmed,
          },
          {
            label: " New Buyer",
            value: "newBuyer",
            count: statusCount?.newBuyer,
          },
          {
            label: "Follow Up Done",
            value: "followupDone",
            count: statusCount?.followupDone,
          },
          {
            label: "Price Quoted",
            value: "priceQuoted",
            count: statusCount?.priceQuoted,
          },
          {
            label: "Negotiation",
            value: "negotiation",
            count: statusCount?.negotiation,
          },
          {
            label: "Car Booked",
            value: "carBooked",
            count: statusCount?.carBooked,
          },
          {
            label: "Paper Work",
            value: "paperWork",
            count: statusCount?.paperWork,
          },
          {
            label: "Sold",
            value: "carSold",
            count: statusCount?.carSold,
          },
          { label: "Lost", value: "lost", count: statusCount?.lost },
        ]
        return statusValues
      case "enquiryManager":
        const salesStatusValues = [
          { label: "All", value: "all", count: statusCount?.all },
          { label: "New", value: "new", count: statusCount?.new },
          {
            label: "Qualified",
            value: "qualified",
            count: statusCount?.qualified,
          },
          {
            label: "Processing",
            value: "processing",
            count: statusCount?.processing,
          },
          {
            label: "Confirmed",
            value: "confirmed",
            count: statusCount?.confirmed,
          },
        ]
        return salesStatusValues

      case "salesManager":
        const salesManagerStatus = [
          { label: "All", value: "all", count: statusCount?.all },

          {
            label: "Confirmed",
            value: "confirmed",
            count: statusCount?.confirmed,
          },
          {
            label: " New Buyer",
            value: "newBuyer",
            count: statusCount?.newBuyer,
          },
          {
            label: "Follow Up Done",
            value: "followupDone",
            count: statusCount?.followupDone,
          },
          {
            label: "Price Quoted",
            value: "priceQuoted",
            count: statusCount?.priceQuoted,
          },
          {
            label: "Negotiation",
            value: "negotiation",
            count: statusCount?.negotiation,
          },
          {
            label: "Car Booked",
            value: "carBooked",
            count: statusCount?.carBooked,
          },
          {
            label: "Paper Work",
            value: "paperWork",
            count: statusCount?.paperWork,
          },
          {
            label: "Sold",
            value: "carSold",
            count: statusCount?.carSold,
          },
          { label: "Lost", value: "lost", count: statusCount?.lost },
        ]

        return salesManagerStatus

      case "salesExecutive":
        const salesExecutiveStatus = [
          { label: "All", value: "all", count: statusCount?.all },

          {
            label: " New Buyer",
            value: "newBuyer",
            count: statusCount?.newBuyer,
          },
          {
            label: "Follow Up Done",
            value: "followupDone",
            count: statusCount?.followupDone,
          },
          {
            label: "Price Quoted",
            value: "priceQuoted",
            count: statusCount?.priceQuoted,
          },
          {
            label: "Negotiation",
            value: "negotiation",
            count: statusCount?.negotiation,
          },
          {
            label: "Car Booked",
            value: "carBooked",
            count: statusCount?.carBooked,
          },
          {
            label: "Paper Work",
            value: "paperWork",
            count: statusCount?.paperWork,
          },
          {
            label: "Sold",
            value: "carSold",
            count: statusCount?.carSold,
          },
          { label: "Lost", value: "lost", count: statusCount?.lost },
        ]
        return salesExecutiveStatus

      default:
        break
    }
  }

  const headers = [
    { label: "No", key: "no" },
    { label: "Date", key: "createdAt" },
    { label: "Client Name", key: "client_name" },
    { label: "Client Phone", key: "client_phone" },
    { label: "Client WhatsApp Number", key: "client_whatsappNumber" },
    { label: "Medium", key: "medium" },
    { label: "Type", key: "type" },
    { label: "Vehicle", key: "vehicle_name" },
    { label: "Vehicle Ownership Status", key: "ownership_status" },
    { label: "Variant Name", key: "variant_name" },
    { label: "Model Name", key: "model_name" },
    { label: "Message", key: "message" },
    { label: "Remarks", key: "remarks" },
    { label: "Status", key: "status" },
    { label: "Assignee", key: "assignee" },
    { label: "Last Update", key: "updatedAt" },
  ]

  const formattedCreateDates = item => {
    return moment(item?.createdAt).format("DD/MM/YYYY h:m:a")
  }
  const formattedUpdateDates = item => {
    return moment(item?.updatedAt).format("DD/MM/YYYY h:m:a")
  }
  const excelDownloadLeads = map(allLeadsExel, (item, key) => ({
    ...item,
    no: key + 1,
    createdAt: formattedCreateDates(item),
    client_name: item?.client?.name,
    client_phone: item?.client?.phone,
    client_whatsappNumber: item?.client?.whatsappNumber, // add other client data
    medium: item.medium,
    type: item.type,
    vehicle_name: item.vehicle?.name,
    // variant_name: item?.variant?.name,
    model_name: item?.model?.name,
    // ownership_status: item?.vehicle?.ownershipStatus,
    message: item.message,
    remarks: item.remarks,
    status: item.status,
    assignee: item?.assignee?.name,
    updatedAt: formattedUpdateDates(item),
  }))

  const handleMediumValues = value => {
    setMedium(value)
    updateURLParams("medium", value)
  }

  const handleTypeValues = value => {
    setTypeValue(value)
    updateURLParams("type", value)
  }

  useEffect(() => {
    if (location?.state?.sort) {
      setSort(location?.state?.sort)
    }
    if (location?.state?.medium) {
      setMedium(location?.state?.medium)
    }
    if (location?.state?.type) {
      setTypeValue(location?.state?.type)
    }

    setTotalItemLimit(location?.state?.limit || 10)

    if (location?.state?.client) {
      setClient(location?.state?.clientName)
      setClientId(location?.state?.client)
    }

    if (location?.state?.vehicle) {
      setVehicle(location?.state?.vehicleName)
      setVehicleId(location?.state?.vehicle)
    }

    if (location?.state?.staff) {
      setStaff(location?.state?.staffName)
      setStaffId(location?.state?.staff)
    }

    setFinalDateFormatted({
      ...finalDateFormatted,
      from: location?.state?.dateFrom,
      to: location?.state?.dateTo,
    })
  }, [location])

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={handleColumns()}
                  data={allLeadData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="pt-1">
                        <Col className=" col-12">
                          {map(handleStatusValue(), (head, key) => (
                            <>
                              <input
                                type="radio"
                                className="btn-check"
                                name={`btnradio${key}`}
                                id={head?.value}
                                autoComplete="off"
                                checked={sort === head.value}
                                onChange={() => listChange(head?.value)}
                              />
                              <label
                                className="btn btn-warning"
                                htmlFor={head?.value}
                                style={{
                                  borderRadius: 3,
                                  marginRight: 1.5,
                                }}
                              >
                                {head?.label}
                                <Badge
                                  className={`font-size-12 ms-2 ${
                                    sort === head.value
                                      ? "badge-soft-light"
                                      : "badge-soft-light"
                                  }`}
                                  pill
                                >
                                  {head?.count}
                                </Badge>
                              </label>
                            </>
                          ))}
                        </Col>
                      </Row>

                      <Row className="d-flex align-items-center justify-content-between pb-1">
                        <Col md="4">
                          <label className="my-2">Medium</label>&nbsp;
                          <div
                            className="btn-group mt-0 mt-xl-0 d-flex align-items-center justify-content-start"
                            role="group"
                            aria-label="Basic radio toggle button group"
                          >
                            {map(MediumOptions, (item, key) => (
                              <div key={key} className="mx-1">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio-medium"
                                  id={item.value + "medium"}
                                  autoComplete="off"
                                  checked={medium === item.value}
                                  value={item.value}
                                  onChange={e =>
                                    handleMediumValues(e.target.value)
                                  }
                                />
                                <label
                                  className="btn btn-outline-warning"
                                  htmlFor={item.value + "medium"}
                                >
                                  {item.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Col>
                        <Col md="4">
                          <label className="my-2">Type</label>&nbsp;
                          <div
                            className="btn-group mt-0 mt-xl-0 d-flex align-items-center justify-content-start"
                            role="group"
                            aria-label="Basic radio toggle button group"
                          >
                            {map(typeOptions, (item, key) => (
                              <div key={key} className="mx-1">
                                <input
                                  type="radio"
                                  className="btn-check "
                                  name="btnradio-type"
                                  id={item.value + "type"}
                                  autoComplete="off"
                                  checked={typeValue === item.value}
                                  value={item.value}
                                  onChange={e =>
                                    handleTypeValues(e.target.value)
                                  }
                                />
                                <label
                                  className="btn btn-outline-warning px-"
                                  htmlFor={item.value + "type"}
                                >
                                  {item.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Col>
                        <Col md="4">
                          {role === "admin" ||
                          role === "generalManager" ||
                          role === "managingDirecter" ||
                          role === "branchManager" ||
                          role === "enquiryManager" ? (
                            // role === "salesExecutive" ? (
                            <div
                              className={`${
                                layout === "list"
                                  ? "my-2"
                                  : "d-flex justify-content-end"
                              }`}
                            >
                              <div className=" p-1">
                                <Link to="/lead/create/new">
                                  <Button
                                    type="button"
                                    color="warning"
                                    className="btn-rounded w-100 mb-0 me-2"
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Lead
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <div className="vehicle-option-table d-flex align-items-center">
                        {layout === "table" ? (
                          headerOptions
                        ) : (
                          <div className="w-100 mb-2" />
                        )}
                        <Row className="mb-2">
                          <>
                            <Col className="col-2 d-flex align-items-start">
                              <div>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"table-view-option"}
                                >
                                  Table view
                                </UncontrolledTooltip>
                                <Button
                                  color={
                                    layout === "table" ? "warning" : "light"
                                  }
                                  className="btn-rounded ms-4 "
                                  id="table-view-option"
                                  onClick={() => {
                                    setLayout("table")
                                    localStorage.setItem("layout", "table")
                                  }}
                                >
                                  <PiTableFill />
                                </Button>
                              </div>
                              <div>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target={"list-view-option"}
                                >
                                  List view
                                </UncontrolledTooltip>
                                <Button
                                  color={
                                    layout === "list" ? "warning" : "light"
                                  }
                                  className="btn-rounded ms-2 position-relative"
                                  id="list-view-option"
                                  onClick={toggleTopDrawer}
                                >
                                  <FaList />
                                  {vehicle?.total ? (
                                    <span
                                      className="btn-rounded bg-danger font-size-10 position-absolute"
                                      style={{
                                        top: -6,
                                        right: -6,
                                        padding: 1,
                                        height: 20,
                                        width: 20,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {vehicle?.total}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Button>
                              </div>
                            </Col>
                          </>
                        </Row>
                      </div>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {allLeads?.length >= 1 ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p className="my-4 mx-3 ">No Data !</p>
                          )}
                          <div className="d-flex align-items-center justify-content-between px-4">
                            <div className="w-75">
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            </div>

                            <div>
                              {allLeads?.length >= 1 ? (
                                <ExcelDownload
                                  title="Lead Reports"
                                  headers={headers}
                                  excelDownloadData={excelDownloadLeads}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <SimpleBar style={{ height: "900px", boxShadow: "none" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={onDrawerClose}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <p className="m-0">More Options</p>
            </div>
            <hr className="my-0" />
            <div className="vehicle-option-sidebar">{headerOptions}</div>
          </div>
        </SimpleBar>
      </ReactDrawer>
    </React.Fragment>
  )
}

export default Leads

Leads.propTypes = {
  vehicleDetailsId: PropTypes.string,
}

const typeOptions = [
  { label: "All", value: "" },
  { label: "Sell", value: "sell" },
  { label: "Buy", value: "buy" },
  { label: "Dream Car", value: "dream_car" },
]

const MediumOptions = [
  { label: "All", value: "" },
  { label: "Social Media", value: "social" },
  { label: "Direct", value: "direct" },
  { label: "Call", value: "call" },
]
