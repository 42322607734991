/* Userss - This line cannot be edited or removed */
export const GET_MEMBERS = "GET_MEMBERS"
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS"
export const GET_MEMBERS_FAIL = "GET_MEMBERS_FAIL"

/* Users_DETAILS */
export const GET_MEMBERS_DETAILS = "GET_MEMBERS_DETAILS"
export const GET_MEMBERS_DETAILS_SUCCESS = "GET_MEMBERS_DETAILS_SUCCESS"
export const GET_MEMBERS_DETAILS_FAIL = "GET_MEMBERS_DETAILS_FAIL"

/* CREATE Users */
export const CREATE_MEMBERS = "CREATE_MEMBERS"
export const CREATE_MEMBERS_SUCCESS = "CREATE_MEMBERS_SUCCESS"
export const CREATE_MEMBERS_FAIL = "CREATE_MEMBERS_FAIL"

/* Edit Users */
export const UPDATE_MEMBERS = "UPDATE_MEMBERS"
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS"
export const UPDATE_MEMBER_FAIL = "UPDATE_MEMBER_FAIL"

/* Delete Users */
export const DELETE_MEMBERS = "DELETE_MEMBERS"
export const DELETE_MEMBERS_SUCCESS = "DELETE_MEMBERS_SUCCESS"
export const DELETE_MEMBERS_FAIL = "DELETE_MEMBERS_FAIL"

//USERS
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

export const CREATE_USERS = "CREATE_USERS"
export const CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS"
export const CREATE_USERS_FAIL = "CREATE_USERS_FAIL"

export const UPDATE_USERS = "UPDATE_USERS"
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS"
export const UPDATE_USERS_FAIL = "UPDATE_USERS_FAIL"

export const DELETE_USERS = "DELETE_USERS"
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS"
export const DELETE_USERS_FAIL = "DELETE_USERS_FAIL"
