import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from "reactstrap"
import { BiStore } from "react-icons/bi"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import useGetStore from "hooks/useGetStore"

const ProfileMenu = props => {
  const { user, currentStore } = useSelector(state => ({
    user: state.Login.user,
    currentStore: state?.Stores?.currentStoreDetails,
  }))

  // Declare a new state variable, which we'll call "menu"

  const [menu, setMenu] = useState(false)

  const [username, setUsername] = useState(user?.role)

  useEffect(() => {
    const role = user?.role || sessionStorage.getItem("role")
    if (role === "admin") {
      setUsername(role)
    } else {
      setUsername(role)
    }
  }, [user?.role, currentStore])

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(sessionStorage.getItem("authUser"))
        setUsername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(sessionStorage.getItem("authUser"))
        setUsername(obj.username)
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <Label className="m-0 p-0" style={{ textTransform: "capitalize" }}>
            <i
              className="bx bx-exit  text-warning"
              style={{ fontSize: 30, cursor: "pointer" }}
            />
          </Label>
          {/* <span>{storeName}</span>/ */}
          {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block" /> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* {isStoreAdmin && (
            <Link to="/my-store" className="dropdown-item">
              <BiStore className="me-2" />
              <span>{props.t("My Store")}</span>
            </Link>
          )} */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
          <Link
            to={{ pathname: "/logout", state: { isLocal: true } }}
            className="dropdown-item"
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout On This Device")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
