import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_DETAILS,
  GET_CATEGORIES_DETAILS_SUCCESS,
  GET_CATEGORIES_DETAILS_FAIL,
  CREATE_CATEGORIES,
  CREATE_CATEGORIES_SUCCESS,
  CREATE_CATEGORIES_FAIL,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORIES_FAIL,
  DELETE_CATEGORIES,
  DELETE_CATEGORIES_SUCCESS,
  DELETE_CATEGORIES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  categories: {
    categories: [],
    total: "",
    page: "",
  },
  categoriesDetails: {},
  error: {},
  loading: false,
}

const Categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
    case GET_CATEGORIES_DETAILS:
    case CREATE_CATEGORIES:
    case UPDATE_CATEGORIES:
    case DELETE_CATEGORIES:
      return {
        ...state,
        loading: true,
      }

    case GET_CATEGORIES_FAIL:
    case GET_CATEGORIES_DETAILS_FAIL:
    case CREATE_CATEGORIES_FAIL:
    case UPDATE_CATEGORIES_FAIL:
    case DELETE_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        error: {},
        loading: false,
      }

    case GET_CATEGORIES_DETAILS_SUCCESS:
      return {
        ...state,
        categoriesDetails: action.payload,
        error: {},
        loading: false,
      }
    case CREATE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          categories: [...state.categories.categories, action.payload],
          total: state.total.categories + 1,
        },
        categoriesDetails: action.payload,
        error: {},
        loading: false,
      }
    case UPDATE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          categories: state.categories.categories.map(category =>
            category._id === action.payload._id
              ? { ...category, ...action.payload }
              : category
          ),
        },
        categoriesDetails: action.payload,
        loading: false,
        error: {},
      }
    case DELETE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          categories: state.categories.categories.filter(
            category => category._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Categories
