import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CLIENTS,
  GET_CLIENT_DETAILS,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  GET_DISTRICTS,
  GET_STATES,
} from "./actionTypes"
import {
  getClientsFail,
  getClientsSuccess,
  getClientDetailsSuccess,
  getClientDetailsFail,
  createClientFail,
  createClientSuccess,
  updateClientSuccess,
  updateClientFail,
  deleteClientSuccess,
  deleteClientFail,
  getDistrictsFail,
  getStatesFail,
  getStatesSuccess,
  getDistrictsSuccess,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Client - This line cannot be edited or removed
function getClientsAPi({
  page,
  sort,
  limit,
  searchText,
  clientId,
  stateId,
  districtId,
}) {
  return get(
    `/client/admin/all/?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}&client=${clientId && clientId}&state=${
      stateId && stateId
    }&district=${districtId ? districtId : ""}`
  )
}

const getClientDetailsAPi = clientId => {
  return get(`/client/admin/${clientId}`)
}

const createClientApi = ({ client }) => {
  return post("/client/admin/new", client)
}

const updateClientApi = ({ client, clientId }) => {
  return ApiPut(`/client/admin/${clientId}`, client)
}

const deleteClientApi = ({ clientId }) => {
  return del(`/client/admin/${clientId}`)
}

function* fetchClients({ payload }) {
  try {
    const response = yield call(getClientsAPi, payload)
    yield put(getClientsSuccess(response))
  } catch (error) {
    yield put(getClientsFail(error))
  }
}

function* fetchClientDetails({ payload: clientId }) {
  try {
    const response = yield call(getClientDetailsAPi, clientId)
    yield put(getClientDetailsSuccess(response))
  } catch (error) {
    yield put(getClientDetailsFail(error))
  }
}

function* onCreateClient({ payload }) {
  try {
    const response = yield call(createClientApi, payload)

    yield put(createClientSuccess(response))
    payload?.onCloseClick()
    doneNotification("Client Created Successfully!")
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createClientFail(error))
  }
}

function* onUpdateClient({ payload }) {
  try {
    const response = yield call(updateClientApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Client Updated Successfully!",
        title: "",
      })
      yield put(updateClientSuccess(response))
      if (payload.history) {
        payload.history.push("/clients")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateClientFail(error.response.data))
  }
}

function* onDeleteClient({ payload }) {
  try {
    const response = yield call(deleteClientApi, payload)
    yield put(deleteClientSuccess(response))
    doneNotification("Client Deleted Successfully!")
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteClientFail(error?.response?.data))
  }
}

// State - This line cannot be edited or removed
function getStatesAPi() {
  return get(`/state/all/`)
}

function* fetchStates({ payload }) {
  try {
    const response = yield call(getStatesAPi, payload)
    yield put(getStatesSuccess(response))
  } catch (error) {
    console.log(error)

    yield put(getStatesFail(error))
  }
}

// District - This line cannot be edited or removed
function getDistrictsAPi(stateId) {
  // if (stateId) {
  return get(
    `/district/all/?state=${stateId ? stateId : "65719111257678ac26a84762"}`
  )
  // }
}

function* fetchDistricts({ payload }) {
  try {
    const response = yield call(getDistrictsAPi, payload)
    yield put(getDistrictsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getDistrictsFail(error))
  }
}

function* clientSaga() {
  yield takeEvery(GET_DISTRICTS, fetchDistricts)

  yield takeEvery(GET_STATES, fetchStates)

  yield takeEvery(GET_CLIENTS, fetchClients)
  yield takeEvery(GET_CLIENT_DETAILS, fetchClientDetails)
  yield takeEvery(CREATE_CLIENT, onCreateClient)
  yield takeEvery(UPDATE_CLIENT, onUpdateClient)
  yield takeEvery(DELETE_CLIENT, onDeleteClient)
}

export default clientSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
