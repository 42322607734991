import axios from "axios"
import { Notification } from "components/Common/Notification"
import {
  apiEndpointDev,
  apiEndpointLocal,
  apiEndpointProd,
} from "project.config"

//apply base url for axios
const API_URL_PROD = apiEndpointProd
const API_URL_DEV = apiEndpointDev
// const API_URL_LOCAL = apiEndpointLocal

const axiosApi = axios.create({
  baseURL: API_URL_PROD,
})

axiosApi.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem("token")
    const storeId = sessionStorage?.getItem("storeId")
    const role = sessionStorage?.getItem("role")

    if (token) {
      config.headers["Authorization"] =
        "Bearer " + sessionStorage.getItem("token")
    }

    const middlewareUrl = role === "admin" ? "admin" : "member"

    if (config && config.url && config.url.includes("/admin")) {
      const parts = config.url.split("/admin")
      config.url = `${parts[0]}/${middlewareUrl}${parts[1]}`
    }

    return config
  },
  function (error) {
    return error
  }
)

axiosApi.interceptors.response.use(
  response => {
    return response
  },
  err => {
    console.log(err?.response?.status)
    if (err?.response?.status === 401) {
      // sessionStorage.clear("token")
      // window.location.reload(false)
      Notification({
        type: "error",
        message: err?.response?.data?.detail,
        title: err?.response?.statusText,
      })
    } else {
      Notification({
        type: "error",
        message: err?.response?.data?.detail,
        title: err?.response?.statusText,
      })
    }
    return Promise.reject(err)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function ApiPut(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
