import {
  GET_SALES,
  GET_SALES_SUCCESS,
  GET_SALES_FAIL,
  GET_SALE_DETAILS,
  GET_SALE_DETAILS_SUCCESS,
  GET_SALE_DETAILS_FAIL,
  CREATE_SALE,
  CREATE_SALE_SUCCESS,
  CREATE_SALE_FAIL,
  UPDATE_SALE,
  UPDATE_SALE_SUCCESS,
  UPDATE_SALE_FAIL,
  DELETE_SALE_SUCCESS,
  DELETE_SALE_FAIL,
  DELETE_SALE,
} from "./actionTypes"

// Sale - This line cannot be edited or removed
export const getSales = (
  page,
  limit,
  searchText,
  clientId,
  vehicleId,
  sortByDate
) => ({
  type: GET_SALES,
  payload: { page, limit, searchText, clientId, vehicleId, sortByDate },
})

export const getSalesSuccess = sales => ({
  type: GET_SALES_SUCCESS,
  payload: sales,
})

export const getSalesFail = error => ({
  type: GET_SALES_FAIL,
  payload: error,
})

export const getSaleDetails = saleId => ({
  type: GET_SALE_DETAILS,
  payload: saleId,
})

export const getSaleDetailsSuccess = saleDetails => ({
  type: GET_SALE_DETAILS_SUCCESS,
  payload: saleDetails,
})

export const getSaleDetailsFail = error => ({
  type: GET_SALE_DETAILS_FAIL,
  payload: error,
})

export const createSale = (sale, onCloseClick) => ({
  type: CREATE_SALE,
  payload: { sale, onCloseClick },
})

export const createSaleSuccess = sale => ({
  type: CREATE_SALE_SUCCESS,
  payload: sale,
})

export const createSaleFail = error => ({
  type: CREATE_SALE_FAIL,
  payload: error,
})

export const getSaleDetail = saleId => ({
  type: GET_SALE_DETAILS,
  payload: saleId,
})

export const updateSale = (sale, saleId, onCloseClick) => (
  console.log(saleId),
  {
    type: UPDATE_SALE,
    payload: { sale, saleId, onCloseClick },
  }
)

export const updateSaleSuccess = sale => ({
  type: UPDATE_SALE_SUCCESS,
  payload: sale,
})

export const updateSaleFail = error => ({
  type: UPDATE_SALE_FAIL,
  payload: error,
})

export const deleteSale = (saleId, history) => ({
  type: DELETE_SALE,
  payload: { saleId, history },
})

export const deleteSaleSuccess = sale => ({
  type: DELETE_SALE_SUCCESS,
  payload: sale,
})

export const deleteSaleFail = error => ({
  type: DELETE_SALE_FAIL,
  payload: error,
})
