import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_LEADHISTORYS,
  GET_LEADHISTORY_DETAILS,
  CREATE_LEADHISTORY,
  UPDATE_LEADHISTORY,
  DELETE_LEADHISTORY,
  GET_LEADHISTORY_BY_LEAD,
} from "./actionTypes"
import {
  getLeadHistorysFail,
  getLeadHistorysSuccess,
  getLeadHistoryDetailsSuccess,
  getLeadHistoryDetailsFail,
  createLeadHistoryFail,
  createLeadHistorySuccess,
  updateLeadHistorySuccess,
  updateLeadHistoryFail,
  deleteLeadHistorySuccess,
  deleteLeadHistoryFail,
  getLeadHistoryByLeadSuccess,
  getLeadHistoryByLeadFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// LeadHistory - This line cannot be edited or removed
function getLeadHistorysAPi({ page, limit, searchText, leadId }) {
  return get(
    `/leadhistory/admin/all/?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}&lead=${leadId ? leadId : ""}`
  )
}

const getLeadHistoryDetailsAPi = leadhistoryId => {
  return get(`/leadhistory/admin/${leadhistoryId}`)
}

const createLeadHistoryApi = ({ leadhistory }) => {
  return post("/leadhistory/admin/new", leadhistory)
}

const updateLeadHistoryApi = ({ leadhistory, leadhistoryId }) => {
  return ApiPut(`/leadhistory/admin/${leadhistoryId}`, leadhistory)
}

const deleteLeadHistoryApi = ({ leadhistoryId }) => {
  return del(`/leadhistory/admin/${leadhistoryId}`)
}

const getLeadHistoryByLeadAPi = ({ leadId, limit }) => {
  return get(`/leadhistory/admin/${leadId}?limit=${limit ? limit : 10}`)
}

function* fetchLeadHistorys({ payload }) {
  try {
    const response = yield call(getLeadHistorysAPi, payload)
    yield put(getLeadHistorysSuccess(response))
  } catch (error) {
    yield put(getLeadHistorysFail(error))
  }
}

function* fetchLeadHistoryDetails({ payload: leadhistoryId }) {
  try {
    const response = yield call(getLeadHistoryDetailsAPi, leadhistoryId)
    yield put(getLeadHistoryDetailsSuccess(response))
  } catch (error) {
    yield put(getLeadHistoryDetailsFail(error))
  }
}

function* onCreateLeadHistory({ payload }) {
  try {
    const response = yield call(createLeadHistoryApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createLeadHistorySuccess(response))
      payload?.history?.push("/leadhistorys")
      doneNotification("LeadHistory Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createLeadHistoryFail(error))
  }
}

function* onUpdateLeadHistory({ payload }) {
  try {
    const response = yield call(updateLeadHistoryApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "LeadHistory Updated Successfully!",
        title: "",
      })
      yield put(updateLeadHistorySuccess(response))
      if (payload.history) {
        payload.history.push("/leadhistorys")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateLeadHistoryFail(error.response.data))
  }
}

function* onDeleteLeadHistory({ payload }) {
  try {
    const response = yield call(deleteLeadHistoryApi, LeadHistoryId)
    yield put(deleteLeadHistorySuccess(response))
    Notification({
      type: "success",
      message: "LeadHistory Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteLeadHistoryFail(error?.response?.data))
  }
}

function* fetchLeadHistoryByLead({ payload: leadhistoryId }) {
  try {
    const response = yield call(getLeadHistoryByLeadAPi, leadhistoryId)
    yield put(getLeadHistoryByLeadSuccess(response))
  } catch (error) {
    yield put(getLeadHistoryByLeadFail(error))
  }
}

function* leadhistorySaga() {
  yield takeEvery(GET_LEADHISTORYS, fetchLeadHistorys)
  yield takeEvery(GET_LEADHISTORY_DETAILS, fetchLeadHistoryDetails)
  yield takeEvery(CREATE_LEADHISTORY, onCreateLeadHistory)
  yield takeEvery(UPDATE_LEADHISTORY, onUpdateLeadHistory)
  yield takeEvery(DELETE_LEADHISTORY, onDeleteLeadHistory)
  yield takeEvery(GET_LEADHISTORY_BY_LEAD, fetchLeadHistoryByLead)
}

export default leadhistorySaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
