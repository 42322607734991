/* ExelDownloads - This line cannot be edited or removed */
export const GET_EXELDOWNLOAD_SALE = "GET_EXELDOWNLOAD_SALE"
export const GET_EXELDOWNLOAD_SALE_SUCCESS = "GET_EXELDOWNLOAD_SALE_SUCCESS"
export const GET_EXELDOWNLOAD_SALE_FAIL = "GET_EXELDOWNLOAD_SALE_FAIL"

/* ExelDownload_DETAILS */
export const GET_EXELDOWNLOAD_VEHICLE = "GET_EXELDOWNLOAD_VEHICLE"
export const GET_EXELDOWNLOAD_VEHICLE_SUCCESS =
  "GET_EXELDOWNLOAD_VEHICLE_SUCCESS"
export const GET_EXELDOWNLOAD_VEHICLE_FAIL = "GET_EXELDOWNLOAD_VEHICLE_FAIL"

export const GET_EXELDOWNLOAD_LEAD = "GET_EXELDOWNLOAD_LEAD"
export const GET_EXELDOWNLOAD_LEAD_SUCCESS = "GET_EXELDOWNLOAD_LEAD_SUCCESS"
export const GET_EXELDOWNLOAD_LEAD_FAIL = "GET_EXELDOWNLOAD_LEAD_FAIL"
