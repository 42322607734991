/* Brands - This line cannot be edited or removed */
export const GET_BRAND_SEARCH_SUCCESS = "GET_BRAND_SEARCH_SUCCESS"
export const GET_BRAND_SEARCH_FAIL = "GET_BRAND_SEARCH_FAIL"

export const GET_BRANDS = "GET_BRANDS"
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS"
export const GET_BRANDS_FAIL = "GET_BRANDS_FAIL"

/* Brand_DETAILS */
export const GET_BRAND_DETAILS = "GET_BRAND_DETAILS"
export const GET_BRAND_DETAILS_SUCCESS = "GET_BRAND_DETAILS_SUCCESS"
export const GET_BRAND_DETAILS_FAIL = "GET_BRAND_DETAILS_FAIL"

/* CREATE Brand */
export const CREATE_BRAND = "CREATE_BRAND"
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS"
export const CREATE_BRAND_FAIL = "CREATE_BRAND_FAIL"

/* Edit Brand */
export const UPDATE_BRAND = "UPDATE_BRAND"
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS"
export const UPDATE_BRAND_FAIL = "UPDATE_BRAND_FAIL"

/* Delete Brand */
export const DELETE_BRAND = "DELETE_BRAND"
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS"
export const DELETE_BRAND_FAIL = "DELETE_BRAND_FAIL"
