import React from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"

const VehicleChart = ({ vehicleChart }) => {
  const series = [
    {
      name: "Amount",
      data: Object.values(vehicleChart),
    },
  ]

  console.log(vehicleChart)

  const filteredArray = Object?.values(vehicleChart).filter(
    element => typeof element === "number"
  )
  const maxValue = Math.max(...filteredArray)

  const options = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 3,
      colors: ["transparent"],
    },

    colors: ["#f8971d"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: { title: { text: "Count" } },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return " " + val + " Count"
        },
      },
    },
  }

  return (
    <ReactApexChart options={options} series={series} type="bar" height={320} />
  )
}

export default VehicleChart

VehicleChart.propTypes = {
  vehicleChart: PropTypes.array,
}
