import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_DETAILS,
  GET_CATEGORIES_DETAILS_SUCCESS,
  GET_CATEGORIES_DETAILS_FAIL,
  CREATE_CATEGORIES,
  CREATE_CATEGORIES_SUCCESS,
  CREATE_CATEGORIES_FAIL,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORIES_FAIL,
  DELETE_CATEGORIES,
  DELETE_CATEGORIES_SUCCESS,
  DELETE_CATEGORIES_FAIL,
} from "./actionTypes"

export const getCategories = (page, limit, searchText) => ({
  type: GET_CATEGORIES,
  payload: { page, limit, searchText },
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})

export const getCategoriesDetails = categoriesId => ({
  type: GET_CATEGORIES_DETAILS,
  payload: categoriesId,
})

export const getCategoriesDetailsSuccess = categoriesDetails => ({
  type: GET_CATEGORIES_DETAILS_SUCCESS,
  payload: categoriesDetails,
})

export const getCategoriesDetailsFail = error => ({
  type: GET_CATEGORIES_DETAILS_FAIL,
  payload: error,
})

export const createCategories = (categories, history) => ({
  type: CREATE_CATEGORIES,
  payload: { categories, history },
})

export const createCategoriesSuccess = categories => ({
  type: CREATE_CATEGORIES_SUCCESS,
  payload: { categories },
})

export const createCategoriesFail = error => ({
  type: CREATE_CATEGORIES_FAIL,
  payload: error,
})

export const updateCategories = (categories, categoryId, history) => ({
  type: UPDATE_CATEGORIES,
  payload: { categories, categoryId, history },
})

export const updateCategoriesSuccess = categories => ({
  type: UPDATE_CATEGORIES_SUCCESS,
  payload: categories,
})

export const updateCategoriesFail = error => ({
  type: UPDATE_CATEGORIES_FAIL,
  payload: error,
})

export const deleteCategories = (categoriesId, history) => ({
  type: DELETE_CATEGORIES,
  payload: { categoriesId, history },
})

export const deleteCategoriesSuccess = categories => ({
  type: DELETE_CATEGORIES_SUCCESS,
  payload: categories,
})

export const deleteCategoriesFail = error => ({
  type: DELETE_CATEGORIES_FAIL,
  payload: error,
})
