import {
  GET_LEADHISTORYS,
  GET_LEADHISTORYS_SUCCESS,
  GET_LEADHISTORYS_FAIL,
  GET_LEADHISTORY_DETAILS,
  GET_LEADHISTORY_DETAILS_SUCCESS,
  GET_LEADHISTORY_DETAILS_FAIL,
  CREATE_LEADHISTORY,
  CREATE_LEADHISTORY_SUCCESS,
  CREATE_LEADHISTORY_FAIL,
  UPDATE_LEADHISTORY,
  UPDATE_LEADHISTORY_SUCCESS,
  UPDATE_LEADHISTORY_FAIL,
  DELETE_LEADHISTORY,
  DELETE_LEADHISTORY_SUCCESS,
  DELETE_LEADHISTORY_FAIL,
  GET_LEADHISTORY_BY_LEAD,
  GET_LEADHISTORY_BY_LEAD_FAIL,
  GET_LEADHISTORY_BY_LEAD_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  leadhistorys: {
    leadhistorys: [],
    total: "",
    page: "",
  },
  leadhistorysById: {},
  leadhistoryDetails: {},
  error: {},
  loading: false,
}

const LeadHistory = (state = INIT_STATE, action) => {
  switch (action.type) {
    // LeadHistory - This line cannot be edited or removed
    case GET_LEADHISTORYS:
    case GET_LEADHISTORY_DETAILS:
    case CREATE_LEADHISTORY:
    case UPDATE_LEADHISTORY:
    case DELETE_LEADHISTORY:
    case GET_LEADHISTORY_BY_LEAD:
      return {
        ...state,
        loading: true,
      }

    case DELETE_LEADHISTORY_FAIL:
    case GET_LEADHISTORYS_FAIL:
    case GET_LEADHISTORY_DETAILS_FAIL:
    case UPDATE_LEADHISTORY_FAIL:
    case CREATE_LEADHISTORY_FAIL:
    case GET_LEADHISTORY_BY_LEAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_LEADHISTORYS_SUCCESS:
      return {
        ...state,
        leadhistorys: action.payload,
        error: {},
        loading: false,
      }

    case GET_LEADHISTORY_DETAILS_SUCCESS:
      return {
        ...state,
        leadhistoryDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_LEADHISTORY_SUCCESS:
      return {
        ...state,
        leadhistorys: {
          ...state.leadhistorys,
          leadhistorys: [...state.leadhistorys.leadhistorys, action.payload],
          total: state.leadhistorys.total + 1,
        },
        leadhistoryDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_LEADHISTORY_SUCCESS:
      return {
        ...state,
        leadhistorys: {
          ...state.leadhistorys,
          leadhistorys: state.leadhistorys.leadhistorys.map(leadhistory =>
            leadhistory._id === action.payload._id
              ? { ...leadhistory, ...action.payload }
              : leadhistory
          ),
        },
        leadhistoryDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_LEADHISTORY_SUCCESS:
      return {
        ...state,
        leadhistorys: {
          ...state.leadhistorys,
          leadhistorys: state.leadhistorys.leadhistorys.filter(
            leadhistory => leadhistory._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }

    case GET_LEADHISTORY_BY_LEAD_SUCCESS:
      return {
        ...state,
        leadhistorysById: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default LeadHistory
