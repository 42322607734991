// categories
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

// categories_DETAILS
export const GET_CATEGORIES_DETAILS = "GET_CATEGORIES_DETAILS"
export const GET_CATEGORIES_DETAILS_SUCCESS = "GET_CATEGORIES_SUCCESS_DETAILS"
export const GET_CATEGORIES_DETAILS_FAIL = "GET_CATEGORIES_FAIL_DETAILS"

// categories_CREATE
export const CREATE_CATEGORIES = "CREATE_CATEGORIES"
export const CREATE_CATEGORIES_SUCCESS = "CREATE_CATEGORIES_SUCCESS"
export const CREATE_CATEGORIES_FAIL = "CREATE_CATEGORIES_FAIL"

// categories_EDIT
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES"
export const UPDATE_CATEGORIES_SUCCESS = "UPDATE_CATEGORIES_SUCCESS"
export const UPDATE_CATEGORIES_FAIL = "UPDATE_CATEGORIES_FAIL"

// categories_DELETE
export const DELETE_CATEGORIES = "DELETE_CATEGORIES_DETAILS"
export const DELETE_CATEGORIES_SUCCESS = "DELETE_CATEGORIES_SUCCESS"
export const DELETE_CATEGORIES_FAIL = "DELETE_CATEGORIES_FAIL"
