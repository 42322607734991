import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"
import moment from "moment"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

//actions

import "../../assets/scss/datatables.scss"
import { AvField, AvForm } from "availity-reactstrap-validation"
import DeleteModal from "components/Common/DeleteModal"
import { getLeadHistoryByLead } from "store/actions"
import CreateForm from "./Crud/Update/Update"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const AllLeadHistory = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const { AllLeadHistorys, total, loading } = useSelector(state => ({
    AllLeadHistorys: state.LeadHistory?.leadhistorysById,
    total: state.LeadHistory.leadHistory?.total,
    loading: state.Members.loading,
  }))

  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [page, setPage] = useState(1)

  //delete
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })
  const [formModal, setFormModal] = useState({ status: false, data: "" })

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteUser(isOpen?.id, history))
  }

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getLeadHistoryByLead(params?.id, totalItemLimit))
  }, [dispatch, params?.id, totalItemLimit])

  console.log(AllLeadHistorys)

  const columns = [
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },

    {
      dataField: "activity",
      text: "Activity",
      sort: true,
    },

    // {
    //   dataField: "action",
    //   text: "Action",
    // },
  ]

  const userData = map(AllLeadHistorys, item => ({
    ...item,

    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm A"),
    activity: <span style={{ textTransform: "" }}>{item?.activity}</span>,
  }))

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = () => {
    setUserList()
  }

  useEffect(() => {
    const currentLimit = localStorage.getItem("userLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("userLimit", e.target.value)
    }
  }

  const onCloseClick = () => {
    setFormModal({ status: false, data: "" })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <CreateForm
        show={formModal?.status}
        onCloseClick={onCloseClick}
        updateData={formModal?.data}
      />
      <Card>
        <CardBody>
          <div>
            <ToolkitProvider
              keyField="_id"
              columns={columns}
              data={userData}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                      <div>
                        <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
                          <div className="mx-1">
                            <span className="">
                              Show
                              <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                            </span>
                          </div>
                          <div className="" style={{ width: "50px" }}>
                            <AvField
                              name="pageLimit"
                              className="form-control"
                              value={totalItemLimit}
                              onChange={e => handlePageLimit(e)}
                              type="text"
                              required
                            />
                          </div>
                        </AvForm>
                      </div>
                    </div>
                    <div></div>
                  </div>

                  {loading ? (
                    <Spinner color="secondary" className="d-block m-auto" />
                  ) : (
                    <>
                      {AllLeadHistorys?.length ? (
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                // keyField={"id"}
                                key={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                // defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <p
                          className="my-4 mx-3 "
                          style={{ textTransform: "capitalize" }}
                        >
                          No Data!
                        </p>
                      )}
                      <MyPagination
                        totalPages={pages}
                        page={page}
                        setPage={setPage}
                      />
                    </>
                  )}
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default AllLeadHistory

AllLeadHistory.propTypes = {
  leadId: PropTypes.obj,
}
