import React from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import { Spinner } from "reactstrap"

const LeadAnalyticsChart = ({ leadChart }) => {
  // const series = [
  //   {
  //     name: "leads",
  //     data: Object?.values(leadChart),
  //   },
  // ]

  // const series = Object.keys(leadChart).map(type => ({
  //   name: type,
  //   data: leadChart[type],
  // }))

  const series = [
    {
      name: "Buy",
      type: "line",
      data: leadChart?.buy,
    },
    {
      name: "Sell",
      type: "line",
      data: leadChart?.sell,
    },
    {
      name: "Total",
      type: "area",
      data: leadChart?.total,
    },
  ]

  // const options = {
  //   chart: {
  //     stacked: false,
  //     toolbar: {
  //       show: false,
  //     },
  //     dropShadow: {
  //       enabled: !0,
  //       color: "#000",
  //       top: 18,
  //       left: 7,
  //       blur: 8,
  //       opacity: 0.2,
  //     },
  //   },
  //   stroke: {
  //     width: [0, 2, 4],
  //     curve: "smooth",
  //   },
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "50%",
  //     },
  //   },
  //   colors: ["#f46a6a", "#556ee6", "#34c38f"],

  //   fill: {
  //     opacity: [0.85, 0.25, 1],
  //     gradient: {
  //       inverseColors: false,
  //       shade: "light",
  //       type: "vertical",
  //       opacityFrom: 0.85,
  //       opacityTo: 0.55,
  //       stops: [0, 100, 100, 100],
  //     },
  //   },
  //   labels: [
  //     "01/01/2003",
  //     "02/01/2003",
  //     "03/01/2003",
  //     "04/01/2003",
  //     "05/01/2003",
  //     "06/01/2003",
  //     "07/01/2003",
  //     "08/01/2003",
  //     "09/01/2003",
  //     "10/01/2003",
  //     "11/01/2003",
  //   ],
  //   markers: {
  //     size: 0,
  //   },
  //   legend: {
  //     offsetY: 11,
  //   },
  //   xaxis: {
  //     type: "datetime",
  //   },
  //   yaxis: {
  //     title: {
  //       text: "Points",
  //     },
  //   },
  //   tooltip: {
  //     shared: true,
  //     intersect: false,
  //     y: {
  //       formatter: function (y) {
  //         if (typeof y !== "undefined") {
  //           return y.toFixed(0) + " points"
  //         }
  //         return y
  //       },
  //     },
  //   },
  //   grid: {
  //     borderColor: "#f1f1f1",
  //   },
  // }

  // const filteredArray = leadChart?.total?.filter(
  //   element => typeof element === "number"
  // )

  // const maxValue = Math?.max(...filteredArray)

  // if (leadChart?.total) {
  //   // Check if leadChart.total is an array
  //   if (Array.isArray(leadChart.total)) {
  //     const filteredArray = leadChart.total.filter(
  //       element => typeof element === "number"
  //     )

  //     // Check if the filteredArray is not empty before finding the max
  //     if (filteredArray.length > 0) {
  //       const maxValue = Math.max(...filteredArray)
  //       console.log("Max Value:", maxValue)
  //     } else {
  //       console.log("No numeric values in the array")
  //     }
  //   } else {
  //     console.log("leadChart.total is not an array")
  //   }
  // } else {
  //   console.log("leadChart or leadChart.total is undefined")
  // }

  // console.log(leadChart?.total?.filter(i => i))

  // const filteredArray = leadChart.total.filter(
  //   element => typeof element === "number"
  // )
  // const maxValue = Math.max(...filteredArray)

  const options = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["black", "red", "#fcce95"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    yaxis: { title: { text: "" } },
  }
  return (
    <>
      {series?.length >= 1 ? (
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height="320"
        />
      ) : (
        <>
          <Spinner type="grow" color="info" />
        </>
      )}
    </>
  )
}

export default LeadAnalyticsChart

LeadAnalyticsChart.propTypes = {
  leadChart: PropTypes.array,
}
