import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import SimpleBar from "simplebar-react"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"
import moment from "moment"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

//actions

import "../../assets/scss/datatables.scss"
import { AvField, AvForm } from "availity-reactstrap-validation"
import DeleteModal from "components/Common/DeleteModal"
import { defaultImg } from "assets/images"
import {
  deleteVehicle,
  getBrands,
  getExelDownloadVehicle,
  getModels,
  getVariants,
  getVehicles,
} from "store/actions"

import { FaList } from "react-icons/fa"
import { PiTableFill } from "react-icons/pi"
import ReactDrawer from "react-drawer"
import ExcelDownload from "hooks/useExcelDownloadButton"
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min"

const AllVehicles = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const role = sessionStorage?.getItem("role")
  const [sort, setSort] = useState("all")

  const [brandValue, setBrandValue] = useState("Select Brand...")
  const [brandId, setBrandId] = useState("")
  const [brandSearch, setBrandSearch] = useState("")

  const [modelValue, setModelValue] = useState("Select Model...")
  const [modelId, setModelValueId] = useState("")
  const [modelSearch, setModelSearch] = useState("")

  const [varientValue, setVarientValue] = useState("Select Variant...")
  const [varientId, setVarientId] = useState("")
  const [varientSearch, setVarientSearch] = useState("")

  const [oilType, setOilType] = useState("Select Fuel...")
  const [oilTypeValue, setOilTypeVlaue] = useState("")

  const [ownerShipStatus, setOwnerShipStatus] = useState("Select Ownership...")
  const [ownerShipStatusValue, setOwnerShipStatusVlaue] = useState("")

  function handlerOwnerShipStatusFinalValues(event) {
    setOwnerShipStatus(event.label)
    setOwnerShipStatusVlaue(event.value)
  }

  const {
    vehicle,
    loading,
    brand,
    model,
    varient,
    allExelVehiclesExel,
    total,
  } = useSelector(state => ({
    vehicle: state.Vehicle.vehicles?.vehicles,
    total: state.Vehicle.vehicles?.total,
    loading: state.Vehicle.loading,
    brand: state.Brand.brands?.brands,
    model: state.Model.models?.models,
    varient: state.Varient.variants?.variants,
    allExelVehiclesExel: state?.ExelDownload?.exeldownloadVehicle?.vehicles,
  }))

  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [pageVehicle, setPageVehicle] = useState(1)
  const [vehicleSearchText, setVehicleSearchText] = useState("")
  const [layout, setLayout] = useState("table")
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState("right")

  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  const updatedLocation = {
    ...location,
    state: {
      ...location.state,
      page: 1,
    },
  }

  const handleDateSearch = () => {
    if (
      finalDateFormated?.from?.length >= 1 &&
      finalDateFormated?.to?.length >= 1
    ) {
      return finalDateFormated
    } else {
      return {
        from: "",
        to: "",
      }
    }
  }

  //delete
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteVehicle(isOpen?.id, history))
  }
  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const vehiclePages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getVehicles(
        pageVehicle,
        totalItemLimit,
        vehicleSearchText,
        brandId,
        modelId,
        varientId,
        oilTypeValue,
        ownerShipStatusValue,
        sort,
        "",
        handleDateSearch()
      )
    )
  }, [
    dispatch,
    pageVehicle,
    totalItemLimit,
    vehicleSearchText,
    brandId,
    modelId,
    varientId,
    oilTypeValue,
    ownerShipStatusValue,
    sort,
    finalDateFormated,
  ])

  const columns = [
    {
      dataField: "image",
      text: "Image",
      sort: true,
      filterText: "",
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "vehicleNumber",
      text: "Number",
      sort: true,
    },
    {
      dataField: "model",
      text: "Model",
      sort: true,
    },
    {
      dataField: "brand",
      text: "Brand",
      sort: true,
    },
    {
      dataField: "variant.name",
      text: "Variant",
      sort: true,
    },
    {
      dataField: "fuelType",
      text: "Fuel",
      sort: true,
    },
    {
      dataField: "ownershipStatus",
      text: "Ownership",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Added on",
      sort: true,
    },

    {
      dataField: "action",
      text: "Action",
    },
  ]

  const columnHandler = () => {
    switch (sort) {
      case "booked":
        const isBookedColumn = [
          {
            dataField: "bookedDate",
            text: "Booked Date",
            sort: true,
          },
          {
            dataField: "bookedClient.name",
            text: "Booked Client",
            sort: true,
          },
          {
            dataField: "bookedBy.name",
            text: "Booked by",
            sort: true,
          },
          ...columns?.filter(
            i =>
              i.dataField !== "model" &&
              i.dataField !== "brand" &&
              i.dataField !== "image" &&
              i.dataField !== "fuelType"
          ),
        ]
        return isBookedColumn

      case "sold":
        const isSoldColumns = [
          {
            dataField: "soldDate",
            text: "Sold Date",
            sort: true,
          },
          {
            dataField: "soldClient.name",
            text: "Sold Client",
            sort: true,
          },
          {
            dataField: "soldBy.name",
            text: "Sold by",
            sort: true,
          },
          ...columns?.filter(
            i =>
              i.dataField !== "model" &&
              i.dataField !== "brand" &&
              i.dataField !== "image" &&
              i.dataField !== "fuelType"
          ),
        ]
        return isSoldColumns

      default:
        return columns
    }
  }

  const handleOwnerShip = ownerShipStatus => {
    switch (ownerShipStatus) {
      case "first_owner":
        return "First Owner"
      case "second_owner":
        return "Second Owner"
      case "third_owner":
        return "Third Owner"
    }
  }

  const userData = map(vehicle, (item, index) => ({
    ...item,
    no: index + 1,
    name:
      sort === "all" ? (
        <span>
          {item?.name}
          <br />
          <Badge
            className={"font-size-12 badge-soft-success me-2 mt-1"}
            color=""
            pill
          >
            {item?.isSold && "sold"}
          </Badge>
          <Badge className={"font-size-12 badge-soft-warning"} color="" pill>
            {item?.isBooked && "booked"}
          </Badge>
        </span>
      ) : (
        <span>{item?.name}</span>
      ),
    key: index,
    model: item?.model?.name,
    brand: item?.brand?.name,
    bookedDate: (
      <span>
        {moment(item?.bookedDate ? item?.bookedDate : "").format("DD-MM-YYYY")}
      </span>
    ),
    fuelType: (
      <span style={{ textTransform: "capitalize" }}>{item?.fuelType}</span>
    ),
    soldDate: (
      <span>
        {moment(item?.soldDate ? item?.soldDate : "").format("DD-MM-YYYY")}
      </span>
    ),
    ownershipStatus: <span>{handleOwnerShip(item?.ownershipStatus)}</span>,
    image: (
      <img
        className="d-block header-profile-user"
        style={{ width: "70px", height: "70px", objectFit: "contain" }}
        src={
          item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
        }
      />
    ),
    roleData: (
      <Badge
        className={
          item.isGuest
            ? "font-size-12 badge-soft-primary"
            : item.role == "admin"
            ? "font-size-12 badge-soft-secondary"
            : "font-size-12 badge-soft-success"
        }
        color=""
        pill
      >
        {item.isGuest ? "guest" : item.role}
      </Badge>
    ),
    createdAt: moment(item.createdAt).format("DD/MM/YYYY"),

    lastLoginData:
      item.lastLogin && moment(item.lastLogin).format("DD/MM/YYYY - h:mm A"),

    action: (
      <>
        <div>
          <Link to={`/vehicle/details/${item?._id}`}>
            <Button
              id={`view${index}`}
              type="button"
              color="white"
              className="ms-1 btn"
            >
              <i
                className="bx bx-show-alt me-2"
                style={{ color: "green", fontSize: "22px" }}
              />
            </Button>
          </Link>
          {role === "admin" ||
          role === "generalManager" ||
          role === "managingDirecter" ? (
            <Button
              id={`delete${index}`}
              type="button"
              color="white"
              className="ms-1 btn "
              onClick={() => {
                handleDelete(item?._id)
              }}
            >
              <i
                className="bx bx-trash me-2"
                style={{ color: "red", fontSize: "20px" }}
              />
            </Button>
          ) : (
            ""
          )}
        </div>
      </>
    ),
  }))

  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  const debounceVehicleSearch = debounce(
    value => setVehicleSearchText(value),
    600
  )

  useEffect(() => {
    const currentLimit = localStorage.getItem("userLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("userLimit", e.target.value)
    }
  }

  useEffect(() => {
    dispatch(getBrands("", "", "", brandSearch))
  }, [dispatch, brandSearch])

  const handleEnter = textEntered => {
    setBrandSearch(textEntered)
  }

  function handlerFinalValuesBrand(event) {
    setBrandValue(event.label)
    setBrandId(event.value)
    history.push(updatedLocation)
  }

  const brandOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (brand?.length >= 1 ? brand : [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getModels("", "", modelSearch, brandId))
  }, [dispatch, modelSearch, brandId])

  const handleEnters = textEntered => {
    setModelSearch(textEntered)
  }

  function handlerFinalValueModel(event) {
    setModelValue(event.label)
    setModelValueId(event.value)
    history.push(updatedLocation)
  }

  const modelOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (model?.length >= 1 ? model : [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results._id,
      })),
    },
  ]

  const handleEntered = textEntered => {
    setVarientSearch(textEntered)
  }

  function handleFinalValuesVarient(event) {
    setVarientValue(event.label)
    setVarientId(event.value)
    history.push(updatedLocation)
  }

  const varientOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (varient?.length >= 1 ? varient : [])?.map((results, index) => ({
        key: index,
        label: results?.name,
        value: results?._id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getVariants("", "", varientSearch, ""))
  }, [dispatch, varientSearch])

  useEffect(() => {
    dispatch(
      getExelDownloadVehicle(
        pageVehicle,
        totalItemLimit,
        vehicleSearchText,
        brandId,
        modelId,
        varientId,
        oilTypeValue,
        ownerShipStatusValue,
        sort,
        handleDateSearch()
      )
    )
  }, [
    dispatch,
    pageVehicle,
    totalItemLimit,
    vehicleSearchText,
    brandId,
    modelId,
    varientId,
    oilTypeValue,
    ownerShipStatusValue,
    sort,
    finalDateFormated,
  ])

  const toggleTopDrawer = () => {
    setPosition("right")
    setOpen(!open)
    setLayout("list")
    localStorage.setItem("layout", "list")
  }

  const onDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const newLayout = localStorage.getItem("layout")
    if (newLayout && newLayout !== undefined) {
      setLayout(newLayout || "table")
    }
  }, [])

  function handlerOilTypeFinalValues(event) {
    setOilType(event.label)
    setOilTypeVlaue(event.value)
    history.push(updatedLocation)
  }

  const handleVehicleSearch = value => {
    debounceVehicleSearch(value)
    history.push(updatedLocation)
  }

  const headerOptions = (
    <>
      {layout && (
        <div className={`headerOptionWrap p-1`}>
          <div className="headerOptionSubWrap  align-items-center flex-wrap ">
            <div className={`${layout === "list" ? "" : "d-flex"}`}></div>
            <div className={`${layout === "list" ? "" : "d-flex mt-0"}`}>
              <div className={`${layout === "list" ? " " : "py-2"}`}>
                <div className="search-box mb-2 d-inline-block ">
                  <label></label>
                  <div className="position-relative">
                    <AvForm>
                      <AvField
                        name="searchText"
                        placeholder="Search"
                        type="text"
                        onChange={e => handleVehicleSearch(e.target.value)}
                      />
                    </AvForm>
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>{" "}
              </div>
              <div className={`${layout === "list" ? " " : "py-1"}`}>
                <label></label>
                <AvForm className="form-horizontal limit-Wrapper mx-2 mb-3 d-flex align-items-center">
                  <div className="mx-1">
                    <span className="">
                      Show
                      <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                    </span>
                  </div>
                  <div className="" style={{ width: "50px" }}>
                    <AvField
                      name="pageLimit"
                      className="form-control"
                      value={totalItemLimit}
                      onChange={e => handlePageLimit(e)}
                      type="text"
                      required
                    />
                  </div>
                </AvForm>{" "}
              </div>
              <div className={layout === "list" ? "my-2" : "w-25 mx-2"}>
                {" "}
                <label>Brand</label>
                <Select
                  onInputChange={handleEnter}
                  value={brandValue}
                  placeholder={brandValue}
                  onChange={handlerFinalValuesBrand}
                  options={brandOptions}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />{" "}
              </div>

              <div className={layout === "list" ? "my-2 " : "w-25 mx-2"}>
                <label>Model</label>
                <Select
                  onInputChange={handleEnters}
                  value={modelValue}
                  placeholder={modelValue}
                  onChange={handlerFinalValueModel}
                  options={modelOptions}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />{" "}
              </div>
              <AvForm style={{ paddingTop: "-4px" }}>
                <label className="mt-0">Sort By Date</label>
                <div className={`d-flex date_wrapper `}>
                  <div className="d-flex align-items-start position-relative">
                    <AvField
                      name="dateRange"
                      type="date"
                      validate={{
                        dateRange: {
                          start: { value: -5, units: "years" },
                          end: { value: 5, units: "years" },
                        },
                      }}
                      onChange={e => {
                        setFinalDateFormated({
                          ...finalDateFormated,
                          from: e.target.value,
                        })
                        history.push(updatedLocation)
                      }}
                      value={finalDateFormated?.from}
                    />
                    <button
                      onClick={() => {
                        setFinalDateFormated({
                          ...finalDateFormated,
                          from: "",
                        })
                        history.push(updatedLocation)
                      }}
                      type="button"
                      className="btn btn-white editable-cancel date_close btn-sm"
                    >
                      <i className="mdi mdi-close text-danger "></i>
                    </button>
                  </div>
                  <div className="d-flex align-items-start position-relative">
                    <AvField
                      name="dateRange"
                      className="mx-1"
                      type="date"
                      validate={{
                        dateRange: {
                          start: { value: -5, units: "years" },
                          end: { value: 5, units: "years" },
                        },
                      }}
                      onChange={e =>
                        setFinalDateFormated({
                          ...finalDateFormated,
                          to: e.target.value,
                        })
                      }
                      value={finalDateFormated?.to}
                    />
                    <button
                      onClick={() =>
                        setFinalDateFormated({
                          ...finalDateFormated,
                          to: "",
                        })
                      }
                      type="button"
                      className="btn btn-white editable-cancel date_close btn-sm"
                    >
                      <i className="mdi mdi-close text-danger "></i>
                    </button>
                  </div>
                </div>
              </AvForm>
            </div>
            <div className={`${layout === "list" ? "" : "d-flex mt-0"}`}>
              <div className={layout === "list" ? "my-2" : "w-25 mx-2"}>
                <label>Variant</label>
                <Select
                  onInputChange={handleEntered}
                  value={varientValue}
                  placeholder={varientValue}
                  onChange={handleFinalValuesVarient}
                  options={varientOptions}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />{" "}
              </div>
              <div className={layout === "list" ? "my-2" : "w-25 mx-2"}>
                <label>Fuel Type</label>
                <Select
                  // onInputChange={handleEnters}
                  value={oilType}
                  placeholder={oilType}
                  onChange={handlerOilTypeFinalValues}
                  options={FUEL_TYPE}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />
              </div>
              <div className={layout === "list" ? "my-2" : "w-25"}>
                <label>Ownership</label>
                <Select
                  // onInputChange={handleEnters}
                  value={ownerShipStatus}
                  placeholder={ownerShipStatus}
                  onChange={handlerOwnerShipStatusFinalValues}
                  options={OWNER_SHIP_STATUS}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />
              </div>
              {role === "admin" ||
              role === "generalManager" ||
              role === "managingDirecter" ||
              role === "purchaseManager" ? (
                <div className="d-flex align-items-end justify-content px-1">
                  <Link to={{ pathname: `/vehicle/create`, state: "create" }}>
                    <Button color="warning" size="md">
                      <i className="mdi mdi-plus me-1" />
                      New Vehicle
                    </Button>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* <div className={`${layout === "list" ? "" : "d-flex"}`}>
              <div className={layout === "list" ? "my-2" : "w-25 mx-2"}>
                <label>Variant</label>
                <Select
                  onInputChange={handleEntered}
                  value={varientValue}
                  placeholder={varientValue}
                  onChange={handleFinalValuesVarient}
                  options={varientOptions}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />{" "}
              </div>
              <div className={layout === "list" ? "my-2" : "w-25 mx-2"}>
                <label>Fuel Type</label>
                <Select
                  // onInputChange={handleEnters}
                  value={oilType}
                  placeholder={oilType}
                  onChange={handlerOilTypeFinalValues}
                  options={FUEL_TYPE}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />
              </div>
              <div className={layout === "list" ? "my-2" : "w-25"}>
                <label>Ownership</label>
                <Select
                  // onInputChange={handleEnters}
                  value={ownerShipStatus}
                  placeholder={ownerShipStatus}
                  onChange={handlerOwnerShipStatusFinalValues}
                  options={OWNER_SHIP_STATUS}
                  classNamePrefix="select2-selection"
                  isLoading={loading}
                />
              </div>
              {role === "admin" ||
              role === "generalManager" ||
              role === "managingDirecter" ? (
                <div className="d-flex align-items-end justify-content px-1">
                  <Link to={"/vehicle/create"}>
                    <Button color="success" size="md">
                      <i className="mdi mdi-plus me-1" />
                      New Vehicle
                    </Button>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </div>
      )}
    </>
  )

  const headers = [
    { label: "No", key: "no" },
    { label: "Date", key: "createdAt" },
    { label: "Vehicle Name", key: "vehicle_name" },
    { label: "Vehicle Model", key: "vehicle_model" },
    { label: "Vehicle Variant", key: "vehicle_variant" },
    { label: "Vehicle Brand", key: "vehicle_brand" },
    { label: "Fuel Type", key: "fuel_type" },
    { label: "Purchase Year", key: "initialPurchaseYear" },
    { label: "Make Year", key: "makeYear" },
    { label: "Ownership Status", key: "ownershipStatus" },
    { label: "Sold Client", key: "sold_client" },
    { label: "Sold By", key: "sold_by" },
    { label: "Sold Date", key: "sold_date" },
    { label: "Booked Client", key: "booked_client" },
    { label: "Booked By", key: "booked_by" },
    { label: "Booked Date", key: "booked_date" },
  ]

  const formattedCreateDates = item => {
    return moment(item?.createdAt).format("DD/MM/YYYY h:m:a")
  }
  const soldDateFormatted = item => {
    return moment(item?.soldDate).format("DD/MM/YYYY ")
  }
  const bookedDateFormatted = item => {
    return moment(item?.bookedDate).format("DD/MM/YYYY")
  }

  const excelDownloadVehicles = map(allExelVehiclesExel, (item, key) => ({
    ...item,
    no: key + 1,
    createdAt: formattedCreateDates(item),
    vehicle_name: item?.vehicle?.name,
    vehicle_model: item?.model?.name,
    vehicle_variant: item?.variant?.name,
    vehicle_brand: item?.brand?.name,
    fuel_type: item.fuelType,
    initialPurchaseYear: item.initialPurchaseYear,
    makeYear: item.makeYear,
    ownershipStatus: item.ownershipStatus,
    sold_client: item.soldClient?.name,
    sold_by: item?.soldBy,
    sold_date: soldDateFormatted(item),
    booked_client: item?.bookedClient?.name,
    booked_by: item?.bookedBy,
    booked_date: bookedDateFormatted(item),
  }))

  const statusValues = [
    { label: "All", value: "all" },
    { label: "Booked", value: "booked" },
    { label: "Sold", value: "sold" },
  ]

  const listChange = sortValue => {
    setSort(sortValue)
    console.log(sortValue)
    history?.push({
      pathname: location?.pathname,
      search: `p=${pageVehicle}&s=${sortValue}`,
      state: {
        page: pageVehicle,
        sort: sortValue,
      },
    })
  }

  useEffect(() => {
    if (location?.state && location?.state?.sort) {
      setSort(location?.state?.sort)
    } else {
      setSort("all")
    }
  }, [location?.state?.sort])

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody className="position-relative">
              <Row>
                <Col className="mb-3 col-12">
                  {map(statusValues, (head, key) => (
                    <>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id={key + 1}
                        autoComplete="off"
                        checked={sort === head.value}
                        onChange={() => listChange(head?.value)}
                      />
                      <label
                        className="btn btn-warning"
                        htmlFor={key + 1}
                        style={{
                          borderRadius: 1,
                        }}
                      >
                        {head?.label}
                      </label>
                    </>
                  ))}
                </Col>
              </Row>
              {layout === "table" ? (
                headerOptions
              ) : (
                <div className="w-100 mb-2" />
              )}
              <Row className="mb-4">
                <>
                  <Col
                    className="col-2 d-flex align-items-start"
                    style={{ position: "absolute", top: 20, right: -30 }}
                  >
                    <div>
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"table-view-option"}
                      >
                        Table view
                      </UncontrolledTooltip>
                      <Button
                        color={layout === "table" ? "warning" : "light"}
                        className="btn-rounded ms-4 "
                        id="table-view-option"
                        onClick={() => {
                          setLayout("table")
                          localStorage.setItem("layout", "table")
                        }}
                      >
                        <PiTableFill
                          onClick={() => {
                            setLayout("table")
                            localStorage.setItem("layout", "table")
                          }}
                        />
                      </Button>
                    </div>
                    <div>
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"list-view-option"}
                      >
                        List view
                      </UncontrolledTooltip>
                      <Button
                        color={layout === "list" ? "warning" : "light"}
                        className="btn-rounded ms-2 position-relative"
                        id="list-view-option"
                        onClick={toggleTopDrawer}
                      >
                        <FaList />
                        {vehicle?.total ? (
                          <span
                            className="btn-rounded bg-danger font-size-10 position-absolute"
                            style={{
                              top: -6,
                              right: -6,
                              padding: 1,
                              height: 20,
                              width: 20,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {vehicle?.total}
                          </span>
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </Col>
                </>
              </Row>

              <div className="py-4">
                <ToolkitProvider
                  keyField="_id"
                  columns={columnHandler()}
                  data={userData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {vehicle?.length ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p
                              className="my-4 mx-3"
                              style={{ textTransform: "capitalize" }}
                            >
                              No Datas
                            </p>
                          )}
                          <div className="d-flex align-items-center justify-content-between px-4">
                            <div className="w-75">
                              <MyPagination
                                totalPages={vehiclePages}
                                page={pageVehicle}
                                setPage={setPageVehicle}
                              />
                            </div>
                            {/* {role === "admin" ||
                            role === "generalManager" ||
                            role === "managingDirector" ||
                            role === "purchaseManager" ? ( */}
                            <div>
                              {vehicle?.length >= 1 ? (
                                <ExcelDownload
                                  title="Vehicle Reports"
                                  headers={headers}
                                  excelDownloadData={excelDownloadVehicles}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            {/* ) : (
                              ""
                            )} */}
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <SimpleBar style={{ height: "900px", boxShadow: "none" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={onDrawerClose}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <p className="m-0">More Options</p>
            </div>
            <hr className="my-0" />
            <div className="vehicle-option-sidebar">{headerOptions}</div>
          </div>
        </SimpleBar>
      </ReactDrawer>
    </React.Fragment>
  )
}

export default AllVehicles
const FUEL_TYPE = [
  { label: "All", value: "" },
  {
    label: "Diesel",
    value: "diesel",
  },
  {
    label: "Petrol",
    value: "petrol",
  },
  {
    label: "Electric",
    value: "electric",
  },
  {
    label: "CNG",
    value: "cng",
  },
]

const OWNER_SHIP_STATUS = [
  { label: "All", value: "" },
  { label: "First Owner", value: "first_owner" },
  { label: "Second Owner", value: "second_owner" },
  { label: "Third Owner", value: "third_owner" },
]
