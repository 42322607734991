/* Clients - This line cannot be edited or removed */
export const GET_CLIENTS = "GET_CLIENTS"
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL"

/* Client_DETAILS */
export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS"
export const GET_CLIENT_DETAILS_SUCCESS = "GET_CLIENT_DETAILS_SUCCESS"
export const GET_CLIENT_DETAILS_FAIL = "GET_CLIENT_DETAILS_FAIL"

/* CREATE Client */
export const CREATE_CLIENT = "CREATE_CLIENT"
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS"
export const CREATE_CLIENT_FAIL = "CREATE_CLIENT_FAIL"

/* Edit Client */
export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS"
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL"

/* Delete Client */
export const DELETE_CLIENT = "DELETE_CLIENT"
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS"
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL"

export const GET_STATES = "GET_STATES"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"
export const GET_STATES_FAIL = "GET_STATES_FAIL"

export const GET_DISTRICTS = "GET_DISTRICTS"
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS"
export const GET_DISTRICTS_FAIL = "GET_DISTRICTS_FAIL"
