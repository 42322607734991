import {
  GET_ALL_FOLLOWUP,
  GET_ALL_FOLLOWUP_FAIL,
  GET_ALL_FOLLOWUP_SUCCESS,
  GET_FOLLOWUP_DETAILS,
  GET_FOLLOWUP_DETAILS_FAIL,
  GET_FOLLOWUP_DETAILS_SUCCESS,
  CREATE_FOLLOWUP,
  CREATE_FOLLOWUP_FAIL,
  CREATE_FOLLOWUP_SUCCESS,
  UPDATE_FOLLOWUP,
  UPDATE_FOLLOWUP_FAIL,
  UPDATE_FOLLOWUP_SUCCESS,
  DELETE_FOLLOWUP,
  DELETE_FOLLOWUP_SUCCESS,
  DELETE_FOLLOWUP_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  stateDetails: {},

  followUps: {
    followUp: [],
    total: "",
    page: "",
  },
  followUpDetails: {},
  error: {},
  loading: false,
}

const FollowUp = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Client - This line cannot be edited or removed
    case GET_ALL_FOLLOWUP:
    case GET_FOLLOWUP_DETAILS:
    case CREATE_FOLLOWUP:
    case UPDATE_FOLLOWUP:
    case DELETE_FOLLOWUP:
      return {
        ...state,
        loading: true,
      }

    case DELETE_FOLLOWUP_FAIL:
    case GET_ALL_FOLLOWUP_FAIL:
    case GET_FOLLOWUP_DETAILS_FAIL:
    case UPDATE_FOLLOWUP_FAIL:
    case CREATE_FOLLOWUP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ALL_FOLLOWUP_SUCCESS:
      return {
        ...state,
        followUps: action.payload,
        error: {},
        loading: false,
      }

    case GET_FOLLOWUP_DETAILS_SUCCESS:
      return {
        ...state,
        clientDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_FOLLOWUP_SUCCESS:
      return {
        ...state,
        followUps: {
          ...state.followUps,
          followUps: [action.payload, ...state.followUps.followUps],
          total: state.followUps.total + 1,
        },
        followUpDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_FOLLOWUP_SUCCESS:
      console.log(state?.followUps)
      console.log(action.payload)
      return {
        ...state,
        followUps: {
          ...state?.followUps,
          followUps: state?.followUps?.followUps?.filter(
            followUp => followUp?._id !== action?.payload?._id
          ),
        },
        followUpDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_FOLLOWUP_SUCCESS:
      return {
        ...state,
        followUps: {
          ...state.followUps,
          followUps: state.followUps.followUps.filter(
            client => client._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }

    // State - This line cannot be edited or removed

    default:
      return state
  }
}

export default FollowUp
