import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_LEADNOTES,
  GET_LEADNOTES_DETAILS,
  CREATE_LEADNOTES,
  UPDATE_LEADNOTES,
  DELETE_LEADNOTES,
} from "./actionTypes"
import {
  getLeadNotesFail,
  getLeadNotesSuccess,
  getLeadNotesDetailsSuccess,
  getLeadNotesDetailsFail,
  createLeadNotesFail,
  createLeadNotesSuccess,
  updateLeadNotesSuccess,
  updateLeadNotesFail,
  deleteLeadNotesSuccess,
  deleteLeadNotesFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// LeadNotes - This line cannot be edited or removed
function getLeadNotessAPi({ page, sort, limit, searchText, leadId }) {
  if (leadId) {
    return get(`/leadNote/admin/${leadId}`)
  } else {
    return get(
      `/leadNote/admin/all/?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText && searchText}`
    )
  }
}

const getLeadNotesDetailsAPi = leadnotesId => {
  return get(`/leadNote/admin/${leadnotesId}`)
}

const createLeadNotesApi = ({ leadnotes, leadId }) => {
  return post(`/leadNote/admin/new`, leadnotes)
}

const updateLeadNotesApi = ({ leadnotes, leadnotesId }) => {
  console.log("leadnotesId =>", leadnotesId)
  return ApiPut(`/leadNote/admin/${leadnotesId}`, leadnotes)
}

const deleteLeadNotesApi = ({ leadnotesId }) => {
  return del(`/leadNote/admin/${leadnotesId}`)
}

function* fetchLeadNotes({ payload }) {
  try {
    const response = yield call(getLeadNotessAPi, payload)
    yield put(getLeadNotesSuccess(response))
  } catch (error) {
    yield put(getLeadNotesFail(error))
  }
}

function* fetchLeadNotesDetails({ payload: leadnotesId }) {
  try {
    const response = yield call(getLeadNotesDetailsAPi, leadnotesId)
    yield put(getLeadNotesDetailsSuccess(response))
  } catch (error) {
    yield put(getLeadNotesDetailsFail(error))
  }
}

function* onCreateLeadNotes({ payload }) {
  try {
    const response = yield call(createLeadNotesApi, payload)

    yield put(createLeadNotesSuccess(response))
    payload?.onCloseClick()
    doneNotification("LeadNotes Created Successfully!")
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createLeadNotesFail(error))
  }
}

function* onUpdateLeadNotes({ payload }) {
  try {
    const response = yield call(updateLeadNotesApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "LeadNotes Updated Successfully!",
        title: "",
      })
      yield put(updateLeadNotesSuccess(response))
      if (payload.onCloseClick) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    console.log(error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateLeadNotesFail(error?.response?.data))
  }
}

function* onDeleteLeadNotes({ payload }) {
  try {
    const response = yield call(deleteLeadNotesApi, payload)
    if (response?._id) {
      yield put(deleteLeadNotesSuccess(response?._id))
      Notification({
        type: "success",
        message: "LeadNotes Deleted Successfully!",
        title: "",
      })
    }
  } catch (error) {
    console.log("title =>")
    console.log("data :", error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteLeadNotesFail(error?.response?.data))
  }
}

function* leadnotesSaga() {
  yield takeEvery(GET_LEADNOTES, fetchLeadNotes)
  yield takeEvery(GET_LEADNOTES_DETAILS, fetchLeadNotesDetails)
  yield takeEvery(CREATE_LEADNOTES, onCreateLeadNotes)
  yield takeEvery(UPDATE_LEADNOTES, onUpdateLeadNotes)
  yield takeEvery(DELETE_LEADNOTES, onDeleteLeadNotes)
}

export default leadnotesSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
