/* Varients - This line cannot be edited or removed */
export const GET_VARIANTS = "GET_VARIANTS"
export const GET_VARIANTS_SUCCESS = "GET_VARIANTS_SUCCESS"
export const GET_VARIANTS_FAIL = "GET_VARIANTS_FAIL"

/* Varient_DETAILS */
export const GET_VARIANT_DETAILS = "GET_VARIANT_DETAILS"
export const GET_VARIANT_DETAILS_SUCCESS = "GET_VARIANT_DETAILS_SUCCESS"
export const GET_VARIANT_DETAILS_FAIL = "GET_VARIANT_DETAILS_FAIL"

/* CREATE Varient */
export const CREATE_VARIANT = "CREATE_VARIANT"
export const CREATE_VARIANT_SUCCESS = "CREATE_VARIANT_SUCCESS"
export const CREATE_VARIANT_FAIL = "CREATE_VARIANT_FAIL"

/* Edit Varient */
export const UPDATE_VARIANT = "UPDATE_VARIANT"
export const UPDATE_VARIANT_SUCCESS = "UPDATE_VARIANT_SUCCESS"
export const UPDATE_VARIANT_FAIL = "UPDATE_VARIANT_FAIL"

/* Delete Varient */
export const DELETE_VARIANT = "DELETE_VARIANT"
export const DELETE_VARIANT_SUCCESS = "DELETE_VARIANT_SUCCESS"
export const DELETE_VARIANT_FAIL = "DELETE_VARIANT_FAIL"
