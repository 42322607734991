import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import {
  deleteClient,
  deleteMember,
  deleteUsers,
  getMembers,
  getUsers,
  getUserss,
} from "store/actions"
import UserModal from "./Crud/Modal"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const Members = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [userModal, setUserModal] = useState({ status: false, data: {} })
  const [totalListLimit, setTotalListLimit] = useState("10")
  const [searchText, setSearchText] = useState("")
  const [memberRole, setMemberRole] = useState("Select Roles...")
  const [memberRoleValue, setMemberValue] = useState("member")
  const [sortValues, setSortValues] = useState("member")

  const { loading, allUser } = useSelector(state => ({
    loading: state.Members?.loading,
    allUser: state.Members.users,
  }))

  const totalPages = Math.ceil(allUser?.total / 10)
  const pages = range(1, totalPages + 1)

  const sort = sortValues

  console.log(sort)

  useEffect(() => {
    dispatch(getUsers(page, totalListLimit, searchText, "", sort))
  }, [dispatch, page, totalListLimit, searchText, sort])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteMember(isOpen?.id))
  }

  console.log(isOpen)

  const columns = [
    {
      dataField: "id",
      text: "#",
    },

    {
      dataField: "createdAt",
      text: "Joined on",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "E-mail",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "memberRole",
      text: "Designation",
      sort: true,
    },

    {
      dataField: "action",
      text: "Action",
    },
  ]

  const debounceUserSearch = debounce(value => setSearchText(value), 600)

  const handlePageLimit = e => {
    console.log("count =>", e)
    setTotalListLimit(e)
  }

  const listChange = sortValue => {
    setSortValues(sortValue)
    console.log(sortValue)
    history?.push({
      pathname: location.pathname,
      search: `p=${page}&s=${sortValue}`,
      state: {
        page: page,
        sort: sortValue,
      },
    })
  }
  console.log(allUser)

  const handleRoleFormatted = role => {
    switch (role) {
      case "enquiryManager":
        return "Enquiry Manager"
      case "enquiryAnalyst":
        return "Enquiry Analyst"
      case "salesManager":
        return "Sales Manager"
      case "salesExecutive":
        return "Sales Executive"
      case "managingDirector":
        return "Managing Director"
      case "generalManager":
        return "General Manager"
      case "branchManager":
        return "Branch Manager"
      case "purchaseManager":
        return "Purchase Manager"
    }
  }

  const tableData = map(
    allUser?.users,
    (item, index) => (
      console.log(item),
      {
        ...item,
        id: index + 1,
        memberRole: (
          <span style={{ textTransform: "capitalize" }}>
            {handleRoleFormatted(item?.memberRole)}
          </span>
        ),
        email: <div style={{ whiteSpace: "break-spaces" }}>{item?.email}</div>,
        createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm a"),
        action: (
          <div>
            <Link
              to={`#`}
              className="btn-md"
              onClick={() => setUserModal({ status: true, data: item })}
            >
              <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
            </Link>
            <Button
              id={`Delete${index}`}
              type="button"
              color="white"
              className="ms-1 btn"
              onClick={() => {
                handleDelete(item?._id)
              }}
            >
              <i
                className="bx bx-trash me-2"
                style={{
                  color: "red",
                  marginBottom: "10px",
                  fontSize: "1.3rem",
                }}
              />
            </Button>
          </div>
        ),
      }
    )
  )

  const optionsRoleMember = [
    { label: "All", value: "member" },
    // { label: "Client", value: "client" },
    // { label: "Member", value: "member" },
    { label: "Enquiry Manager", value: "enquiryManager" },
    { label: "Enquiry Analyst", value: "enquiryAnalyst" },
    { label: "Sales Manager", value: "salesManager" },
    { label: "Sales Executive", value: "salesExecutive" },
    { label: "Managing Director", value: "managingDirector" },
    { label: "General Manager", value: "generalManager" },
    { label: "Branch Manager", value: "branchManager" },
    { label: "Purchase Manager", value: "purchaseManager" },
  ]

  // const handlerRoleFinalValues = event => {
  //   console.log(event)
  //   setMemberRole(event?.label)
  //   setMemberValue(event?.value)
  // }

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <UserModal
          onCloseClick={() => setUserModal({ status: false, data: {} })}
          show={userModal?.status}
          updateData={userModal?.data}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <div className="d-flex aling-items-center justify-content-between">
                      <div className="py-2">
                        {map(optionsRoleMember, (head, key) => (
                          <>
                            <input
                              type="radio"
                              className="btn-check"
                              name="btnradio"
                              id={key + 1}
                              autoComplete="off"
                              checked={sortValues === head.value}
                              onChange={() => listChange(head?.value)}
                            />
                            <label
                              className="btn btn-warning"
                              htmlFor={key + 1}
                              style={{
                                borderRadius: 1,
                              }}
                            >
                              {head?.label}
                            </label>
                          </>
                        ))}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex">
                        <div>
                          {" "}
                          <div>
                            {" "}
                            <div className="search-box mb-2 d-inline-block">
                              <div className="position-relative">
                                <AvForm>
                                  <AvField
                                    name="searchText"
                                    placeholder="Search"
                                    type="text"
                                    onChange={e =>
                                      debounceUserSearch(e.target.value)
                                    }
                                  />
                                </AvForm>
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {" "}
                          <div>
                            <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
                              <div className="mx-1">
                                <span className="">
                                  Show
                                  <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                                </span>
                              </div>
                              <div className="" style={{ width: "50px" }}>
                                <AvField
                                  name="pageLimit"
                                  className="form-control"
                                  value={totalListLimit}
                                  onChange={e =>
                                    handlePageLimit(e.target.value)
                                  }
                                  type="text"
                                  required
                                />
                              </div>
                            </AvForm>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          {" "}
                          <div className="">
                            <Button
                              type="button"
                              color="warning"
                              className="btn-rounded  mb-2 me-2"
                              onClick={() => setUserModal({ status: true })}
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Member
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {allUser?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Members !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Members
