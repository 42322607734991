import {
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  GET_MEMBERS_DETAILS,
  GET_MEMBERS_DETAILS_SUCCESS,
  GET_MEMBERS_DETAILS_FAIL,
  CREATE_MEMBERS,
  CREATE_MEMBERS_SUCCESS,
  CREATE_MEMBERS_FAIL,
  UPDATE_MEMBERS,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  DELETE_MEMBERS,
  DELETE_MEMBERS_SUCCESS,
  DELETE_MEMBERS_FAIL,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  CREATE_USERS,
  UPDATE_USERS,
  DELETE_USERS,
  CREATE_USERS_FAIL,
  UPDATE_USERS_FAIL,
  DELETE_USERS_FAIL,
  CREATE_USERS_SUCCESS,
  UPDATE_USERS_SUCCESS,
  DELETE_USERS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  members: {
    members: [],
    total: "",
    page: "",
  },
  memberDetails: {},
  error: {},
  loading: false,
  users: {
    users: [],
    total: "",
    page: "",
  },
}

const Members = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Users - This line cannot be edited or removed
    case GET_MEMBERS:
    case GET_MEMBERS_DETAILS:
    case CREATE_MEMBERS:
    case UPDATE_MEMBERS:
    case DELETE_MEMBERS:
    case GET_USERS:
    case CREATE_USERS:
    case UPDATE_USERS:
    case DELETE_USERS:
      return {
        ...state,
        loading: true,
      }
      Members
    case DELETE_MEMBERS_FAIL:
    case GET_MEMBERS_FAIL:
    case GET_MEMBERS_DETAILS_FAIL:
    case UPDATE_MEMBER_FAIL:
    case CREATE_MEMBERS_FAIL:
    case GET_USERS_FAIL:
    case CREATE_USERS_FAIL:
    case UPDATE_USERS_FAIL:
    case DELETE_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action?.payload,
        error: {},
        loading: false,
      }

    case GET_MEMBERS_DETAILS_SUCCESS:
      return {
        ...state,
        usersDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_MEMBERS_SUCCESS:
      return {
        ...state,
        members: {
          ...state?.members,
          members: [...state?.members?.members, action?.payload?.newMemberData],
          total: state.members.total + 1,
        },
        usersDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          users: state.users.users.map(user =>
            user._id === action.payload._id
              ? { ...user, ...action.payload }
              : user
          ),
        },
        usersDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_MEMBERS_SUCCESS:
      console.log(action)
      console.log(state?.users)
      return {
        ...state,
        users: {
          ...state?.users,
          users: state?.users?.users?.filter(
            users => users._id !== action?.payload?._id
          ),
        },
        error: {},
        loading: false,
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action?.payload,
        error: {},
        loading: false,
      }
    case CREATE_USERS_SUCCESS:
      return {
        ...state,
        members: action?.payload,
        error: {},
        loading: false,
      }
    case UPDATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      }
    case DELETE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      }

    default:
      return state
  }
}

export default Members
