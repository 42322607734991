import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"

// Import Routes all
import {
  superAdminRoutes,
  publicRoutes,
  commonRoute,
  managingDirectorRoutes,
  generalManagerRoutes,
  enquiryManagerRoutes,
  enquiryAnalystRoutes,
  salesExecutiveRoutes,
  salesManagerRoutes,
  branchManagerRoutes,
  purchaseManagerRoutes,
} from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/Pages.scss"
import { getProfile } from "store/actions"

const token = sessionStorage.getItem("token")
const role = sessionStorage.getItem("role")

const App = props => {
  const dispatch = useDispatch()

  const { user } = useSelector(state => ({
    user: state.Login.user,
  }))

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  function handleRoutesByRole() {
    // let Routes = commonRoute
    switch (role) {
      case "admin":
        return superAdminRoutes
      case "managingDirector":
        managingDirectorRoutes
      case "generalManager":
        return generalManagerRoutes
      case "enquiryManager":
        return enquiryManagerRoutes
      case "enquiryAnalyst":
        return enquiryAnalystRoutes
      case "salesExecutive":
        return salesExecutiveRoutes
      case "salesManager":
        return salesManagerRoutes
      case "branchManager":
        return branchManagerRoutes
      case "purchaseManager":
        return purchaseManagerRoutes
      default:
        return superAdminRoutes
    }
    return ""
  }

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {handleRoutesByRole()?.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
              token={user?.token ? user?.token : token}
            />
          ))}

          <Route render={() => <Redirect to={{ pathname: "/404" }} />} />
          {!token && (
            <Route render={() => <Redirect to={{ pathname: "/login" }} />} />
          )}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
