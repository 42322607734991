import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce, update } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { CardTitle, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap"

import Select from "react-select"
import MyRating from "components/Common/Rating"
import UseImageUploader from "components/imageUploader/useImageUploader"
import {
  createFollowUp,
  getDistricts,
  getStates,
  getUsers,
  updateFollowUp,
} from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"

const FollowUpModal = ({ show, onCloseClick, updateData, type }) => {
  const dispatch = useDispatch()
  const role = sessionStorage.getItem("role")
  const params = useParams()

  const { allDistricts, allState, allStaffs } = useSelector(state => ({
    allStaffs: state?.Members?.users?.users,
  }))

  const [Staff, setStaff] = useState("Select Staff...")
  const [StaffId, setStaffId] = useState("")
  const [StaffSearch, setStaffSearch] = useState("")
  const [staffRole, setStaffRole] = useState("member")
  const [status, setStatus] = useState("")
  const [statusValue, setStatusValue] = useState("")

  console.log(updateData?.countryCode)

  const handlerStatusFinalValue = event => {
    setStatus(event?.label)
    setStatusValue(event?.value)
  }
  console.log(updateData)

  useEffect(() => {
    setStatus(updateData?.status)
    setStatusValue(updateData?.status)
  }, [updateData])

  const statusValues = [
    { label: "All", value: "all" },
    { label: "Scheduled", value: "scheduled" },
    { label: "Pending", value: "pending" },
    { label: "Done", value: "done" },
  ]

  useEffect(() => {
    if (
      role === "admin" ||
      role === "enquiryManager" ||
      role === "enquiryAnalyst"
    ) {
      dispatch(getUsers("", "", StaffSearch, "", staffRole))
    }
  }, [dispatch, StaffSearch, staffRole])

  const staffOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allStaffs?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersStaffs = text => {
    setStaffSearch(text)
  }

  const handlerStaffsFinalValue = event => {
    setStaff(event?.label)
    setStaffId(event?.value)
  }

  useEffect(() => {
    setStaffId(updateData?.createdBy?.id)
    setStaff(updateData?.createdBy?.name)
  }, [updateData])

  const handleValidSubmit = (e, v) => {
    const followUpUpdate = {
      ...v,
      lead: params?.id,
      type: type,
      createdBy: StaffId,
      status: statusValue,
    }

    if (updateData?._id) {
      dispatch(updateFollowUp(followUpUpdate, updateData?._id, onCloseClick))
      onCloseClick()
    } else {
      console.log(v)
      const followUpCreate = {
        ...v,
        lead: params?.id,
        type: type,
        status: statusValue,
        createdBy: StaffId,
      }
      dispatch(createFollowUp(followUpCreate, onCloseClick))
      onCloseClick()
    }
  }

  const dater = dates => {
    moment(dates).format("MM/DD/YYYY")
    return dater
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle className="pb-3">
          {updateData?._id ? "Edit Follow Up" : "Add New Follow Up"}
        </CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <>
              <Col className="col-12">
                <label>
                  Title <span className="text-danger"> *</span>
                </label>
                <AvField
                  name="title"
                  type="text"
                  placeholder="Enter title... "
                  value={updateData?.title}
                  validate={{
                    required: {
                      value: false,
                      errorMessage: "title is required",
                    },
                  }}
                />
              </Col>
              <Col className="col-12 py-3">
                <div className="w-100 text-align-center">
                  <label>Created By</label>
                  <Select
                    onInputChange={handleEntersStaffs}
                    value={Staff}
                    placeholder={Staff}
                    onChange={handlerStaffsFinalValue}
                    options={staffOptions}
                    classNamePrefix="select2-selection"
                    isLoading={false}
                  />
                </div>
              </Col>
              <Col className=" mb-4" md={updateData?._id ? 6 : 12}>
                <label>Date</label>
                <AvField
                  name="date"
                  type="date"
                  placeholder="Enter Date"
                  value={dater}
                  validate={{
                    required: {
                      value: false,
                      errorMessage: "Date is required",
                    },
                  }}
                />
              </Col>

              {updateData?._id && (
                <Col className="col-6 mb-2">
                  <label>Status</label>
                  <Select
                    // onInputChange={handleEntersVehicle}
                    value={status}
                    placeholder={status}
                    onChange={handlerStatusFinalValue}
                    options={statusValues}
                    classNamePrefix="select2-selection"
                    isLoading={false}
                  />
                </Col>
              )}
            </>
          </Row>
          <Row>
            <Col className="col-12">
              <label>
                Message <span className="text-danger"> *</span>
              </label>
              <AvField
                name="message"
                type="textarea"
                placeholder="Enter message... "
                value={updateData?.message}
                style={{ minHeight: "100px" }}
                validate={{
                  required: {
                    value: false,
                    errorMessage: "phone number is required",
                  },
                }}
              />
            </Col>
          </Row>

          {!updateData?._id ? (
            <>
              <Row></Row>
            </>
          ) : (
            ""
          )}

          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-light ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-warning ms-2">
                {updateData?._id ? "Save" : "Create"}
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

FollowUpModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
  type: PropTypes.any,
}

export default FollowUpModal
