import {
  GET_LEADS,
  GET_LEADS_SUCCESS,
  GET_LEADS_FAIL,
  GET_LEAD_DETAILS,
  GET_LEAD_DETAILS_SUCCESS,
  GET_LEAD_DETAILS_FAIL,
  CREATE_LEAD,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD_FAIL,
  UPDATE_LEAD,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_FAIL,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAIL,
  DELETE_LEAD,
  GET_LEAD_STATUS_COUNT,
  GET_LEAD_STATUS_COUNT_SUCCESS,
  GET_LEAD_STATUS_COUNT_FAIL,
} from "./actionTypes"

// Lead - This line cannot be edited or removed
export const getLeads = (
  page,
  sort,
  limit,
  searchText,
  vehicleId,
  typeValue,
  clientId,
  medium,
  sortByDate,
  StaffId
) => ({
  type: GET_LEADS,
  payload: {
    page,
    sort,
    limit,
    searchText,
    vehicleId,
    typeValue,
    clientId,
    medium,
    sortByDate,
    StaffId,
  },
})

export const getLeadsSuccess = leads => ({
  type: GET_LEADS_SUCCESS,
  payload: leads,
})

export const getLeadsFail = error => ({
  type: GET_LEADS_FAIL,
  payload: error,
})

export const getLeadDetails = leadId => ({
  type: GET_LEAD_DETAILS,
  payload: leadId,
})

export const getLeadDetailsSuccess = leadDetails => ({
  type: GET_LEAD_DETAILS_SUCCESS,
  payload: leadDetails,
})

export const getLeadDetailsFail = error => ({
  type: GET_LEAD_DETAILS_FAIL,
  payload: error,
})

export const createLead = (lead, history) => ({
  type: CREATE_LEAD,
  payload: { lead, history },
})

export const createLeadSuccess = lead => ({
  type: CREATE_LEAD_SUCCESS,
  payload: lead,
})

export const createLeadFail = error => ({
  type: CREATE_LEAD_FAIL,
  payload: error,
})

export const getLeadDetail = leadId => ({
  type: GET_LEAD_DETAILS,
  payload: leadId,
})

export const updateLead = (lead, leadId, history, onClose, isStatus) => ({
  type: UPDATE_LEAD,
  payload: { lead, leadId, history, onClose, isStatus },
})

export const updateLeadSuccess = lead => ({
  type: UPDATE_LEAD_SUCCESS,
  payload: lead,
})

export const updateLeadFail = error => ({
  type: UPDATE_LEAD_FAIL,
  payload: error,
})

export const deleteLead = (leadId, history) => (
  console.log(leadId),
  {
    type: DELETE_LEAD,
    payload: { leadId, history },
  }
)

export const deleteLeadSuccess = lead => ({
  type: DELETE_LEAD_SUCCESS,
  payload: lead,
})

export const deleteLeadFail = error => ({
  type: DELETE_LEAD_FAIL,
  payload: error,
})

export const getLeadStatusCount = () => ({
  type: GET_LEAD_STATUS_COUNT,
  payload: "",
})
export const getLeadStatusCountSuccess = leadStatusCount => ({
  type: GET_LEAD_STATUS_COUNT_SUCCESS,
  payload: leadStatusCount,
})
export const getLeadStatusCountFail = error => ({
  type: GET_LEAD_STATUS_COUNT_FAIL,
  payload: error,
})
