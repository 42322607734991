import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce, update } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap"

import Select from "react-select"
import MyRating from "components/Common/Rating"
import UseImageUploader from "components/imageUploader/useImageUploader"
import Spinner from "components/Common/Spinner"
import { createBrand, updateBrand } from "store/actions"

const BrandCrudModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const [imagesPreview, setImagesPreview] = useState([])

  const { loading } = useSelector(state => ({
    loading: state.Brand.loading,
  }))

  const handleValidSubmit = (e, v) => {
    console.log(v)
    const updateBrandData = {
      ...v,
      logo: imagesPreview,
    }
    if (updateData?._id) {
      dispatch(updateBrand(updateBrandData, updateData?._id, ""))
      onCloseClick()
    } else {
      const createBrandData = {
        ...v,
        logo: imagesPreview,
      }
      dispatch(createBrand(createBrandData, onCloseClick))
      onCloseClick()
    }
  }

  useEffect(() => {
    if (updateData?._id) {
      setImagesPreview(updateData?.logo)
    }
  }, [updateData])

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>{updateData?._id ? "Edit Brand" : "Add Brand"}</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <>
              <Col className="col-12 mb-2">
                <AvField
                  name="name"
                  type="text"
                  // placeholder="Enter Name "
                  label="Name *"
                  value={updateData?.name ?? ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a name",
                    },
                  }}
                />
              </Col>
            </>
          </Row>
          <Row>
            <>
              <Col className="col-12 mb-2">
                <AvField
                  name="description"
                  type="textarea"
                  // placeholder="description "
                  label="Description"
                  value={updateData?.description ?? ""}
                />
              </Col>
            </>
          </Row>
          <Row>
            <UseImageUploader
              imageSize={4}
              setImagesPreview={setImagesPreview}
              imagesPreview={imagesPreview}
              uploadUrl={"/focusmotors"}
              isMultiple
            />
          </Row>

          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-light ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-warning ms-2">
                {loading && <Spinner />}
                {updateData?._id ? "Save" : "Create"}
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

BrandCrudModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default BrandCrudModal
