import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_SALES,
  GET_SALE_DETAILS,
  CREATE_SALE,
  UPDATE_SALE,
  DELETE_SALE,
} from "./actionTypes"
import {
  getSalesFail,
  getSalesSuccess,
  getSaleDetailsSuccess,
  getSaleDetailsFail,
  createSaleFail,
  createSaleSuccess,
  updateSaleSuccess,
  updateSaleFail,
  deleteSaleSuccess,
  deleteSaleFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Sale - This line cannot be edited or removed
function getSalesAPi({
  page,
  sort,
  limit,
  searchText,
  clientId,
  vehicleId,
  sortByDate,
}) {
  return get(
    `/sale/admin/all/?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}&client=${clientId && clientId}&vehicle=${
      vehicleId && vehicleId
    }&from=${sortByDate.from}&to=${sortByDate.to}`
  )
}

const getSaleDetailsAPi = saleId => {
  return get(`/sale/admin/${saleId}`)
}

const createSaleApi = ({ sale }) => {
  return post("/sale/admin/new", sale)
}

const updateSaleApi = ({ sale, saleId }) => {
  console.log(saleId)
  return ApiPut(`/sale/admin/${saleId}`, sale)
}

const deleteSaleApi = ({ saleId }) => {
  return del(`/sale/admin/${saleId}`)
}

function* fetchSales({ payload }) {
  try {
    const response = yield call(getSalesAPi, payload)
    yield put(getSalesSuccess(response))
  } catch (error) {
    yield put(getSalesFail(error))
  }
}

function* fetchSaleDetails({ payload: saleId }) {
  try {
    const response = yield call(getSaleDetailsAPi, saleId)
    yield put(getSaleDetailsSuccess(response))
  } catch (error) {
    yield put(getSaleDetailsFail(error))
  }
}

function* onCreateSale({ payload }) {
  console.log(payload)
  try {
    const response = yield call(createSaleApi, payload)

    yield put(createSaleSuccess(response))
    doneNotification("Sale Created Successfully!")
    payload?.onCloseClick()
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createSaleFail(error))
  }
}

function* onUpdateSale({ payload }) {
  try {
    const response = yield call(updateSaleApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Sale Updated Successfully!",
        title: "",
      })
      yield put(updateSaleSuccess(response))
      if (payload.onCloseClick) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateSaleFail(error.response.data))
  }
}

function* onDeleteSale({ payload: SaleId }) {
  try {
    const response = yield call(deleteSaleApi, SaleId)
    yield put(deleteSaleSuccess(response))
    Notification({
      type: "success",
      message: "Sale Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteSaleFail(error?.response?.data))
  }
}

function* saleSaga() {
  yield takeEvery(GET_SALES, fetchSales)
  yield takeEvery(GET_SALE_DETAILS, fetchSaleDetails)
  yield takeEvery(CREATE_SALE, onCreateSale)
  yield takeEvery(UPDATE_SALE, onUpdateSale)
  yield takeEvery(DELETE_SALE, onDeleteSale)
}

export default saleSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
