import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
  Badge,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"
import { PiNotepad } from "react-icons/pi"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions

import "../../assets/scss/datatables.scss"

import MyPagination from "components/Common/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import { GiTeamIdea } from "react-icons/gi"
import { GrTask } from "react-icons/gr"
import { BiSolidPhoneCall } from "react-icons/bi"
import { MdAddCall } from "react-icons/md"
import { MdNoteAdd } from "react-icons/md"
import { MdAddTask } from "react-icons/md"
import { IoIosAdd } from "react-icons/io"

import { deleteClient, getClients, getLeads, getUsers } from "store/actions"
import { getAllFollowUp, updateFollowUp } from "store/followUp/actions"
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import FollowUpModal from "./Crud/Modal"
import { useCopyText } from "hooks/useCopyText"

const FollowUpDataTables = () => {
  const role = sessionStorage.getItem("role")
  const dispatch = useDispatch()
  const history = useHistory()
  const { copyText } = useCopyText()

  const location = useLocation()
  const params = useParams()
  const [page, setPage] = useState(1)
  const [followUpModal, setFollowUpModal] = useState({
    status: false,
    data: {},
    value: "",
  })

  const [checkbox, setCheckbox] = useState("")

  const [searchText, setSearchText] = useState("")
  const [totalListLimit, setTotalListLimit] = useState(10)

  const [Staff, setStaff] = useState("Select Staff...")
  const [StaffId, setStaffId] = useState("")
  const [StaffSearch, setStaffSearch] = useState("")

  const [sort, setSort] = useState("scheduled")
  const [typeValue, setTypeValue] = useState(params?.id ? "all" : "call")
  const [staffRole, setStaffRole] = useState("member")

  const [client, setClient] = useState("Select Client...")
  const [clientId, setClientId] = useState("")
  const [clientSearch, setClientSearch] = useState("")

  const [leadValue, setLeadValue] = useState("Select Lead...")
  const [leadId, setLeadId] = useState("")
  const [leadSearch, setLeadSearch] = useState("")

  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  const { loading, allFollowUp, allStaffs, allClients, allCounts, allLeads } =
    useSelector(state => ({
      loading: state.FollowUp.loading,
      allFollowUp: state?.FollowUp?.followUps,
      allCounts: state?.FollowUp?.followUps?.statusCounts,
      allStaffs: state?.Members?.users?.users,
      allClients: state?.Client?.clients?.clients,
      allLeads: state?.Lead?.leads?.leads,
    }))

  const totalPages = Math.ceil(allFollowUp?.total / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    if (params?.id) {
      dispatch(
        getAllFollowUp(
          page,
          sort,
          totalListLimit,
          typeValue,
          StaffId,
          finalDateFormated,
          params?.id,
          clientId
        )
      )
    } else {
      dispatch(
        getAllFollowUp(
          page,
          sort,
          totalListLimit,
          typeValue,
          StaffId,
          finalDateFormated,
          leadId ? leadId : "",
          clientId
        )
      )
    }
  }, [
    dispatch,
    page,
    sort,
    totalListLimit,
    typeValue,
    StaffId,
    finalDateFormated,
    params?.id,
    leadValue,
    clientId,
  ])

  console.log(leadValue)

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteClient(isOpen?.id))
  }

  const handleFollowUpStatus = status => {
    switch (status) {
      case "scheduled":
        return "SCHEDULED"
      case "pending":
        return "PENDING"
      case "done":
        return "DONE"
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "Mark as Done",

      sort: true,
    },
    {
      dataField: "type",
      text: "",
      sort: true,
    },
    {
      dataField: "client",
      text: "Client",
      sort: true,
    },

    {
      dataField: "assignedDate",
      text: "Assigned Date",
    },
    {
      dataField: "assignee.name",
      text: "Assignee",
      sort: true,
    },

    {
      dataField: "message",
      text: "Message",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  useEffect(() => {
    dispatch(getLeads("", "", "", leadSearch, "", "", "", "", "", ""))
  }, [dispatch, leadSearch])

  const handleEntersLead = textEntered => {
    setLeadSearch(textEntered)
  }

  function handlerFinalValueLead(event) {
    setLeadValue(event.label)
    setLeadId(event.value)
  }

  const leadOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (allLeads?.length >= 1 ? allLeads : [])?.map(
        (results, index) => ({
          key: index,
          label: `${results.client?.name} ${results.vehicle?.name ? "-" : ""} ${
            results.vehicle?.name || ""
          }`,
          value: results._id,
        })
      ),
    },
  ]

  const handleCopy = text => {
    copyText(text)
  }
  useEffect(() => {
    // if (role === "admin" || role === "enquiryManager") {
    dispatch(getUsers("", "", StaffSearch, "", staffRole))
    // }
  }, [dispatch, StaffSearch, staffRole])

  const staffOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allStaffs?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersStaffs = text => {
    setStaffSearch(text)
  }

  const handlerStaffsFinalValue = event => {
    setStaff(event?.label)
    setStaffId(event?.value)
    setPage(1)
  }
  const handleFollowUpStatusColor = color => {
    switch (color) {
      case "scheduled":
        return "info"
      case "pending":
        return "warning"
      case "done":
        return "success"

      default:
        return "info"
    }
  }

  const handleFollowUpType = type => {
    switch (type) {
      case "call":
        return <BiSolidPhoneCall className="fs-3" />
      case "meeting":
        return <GiTeamIdea className="fs-3" />
      case "task":
        return <PiNotepad className="fs-3" />
      case "other":
        return ""

      default:
        return ""
    }
  }

  console.log(checkbox)

  const handleCheckbox = value => {
    setCheckbox(value)
    const updateStatus = {
      status: "done",
    }
    dispatch(updateFollowUp(updateStatus, value, ""))
  }

  const handleColumns = () => {
    if (role === "salesManager") {
      return columns?.filter(i => i.dataField !== "action")
    } else return columns
  }

  console.log(allFollowUp)

  const tableData = map(allFollowUp?.followUps, (item, index) => ({
    ...item,
    id:
      item?.status !== "done" ? (
        <AvForm>
          <UncontrolledTooltip placement="bottom" target="checkbox">
            Mark as Done
          </UncontrolledTooltip>
          <AvField
            id="checkbox"
            name="checkbox"
            type="checkbox"
            checked={checkbox}
            onChange={e => handleCheckbox(item?._id)}
            className="checkbox-custom"
          />
        </AvForm>
      ) : (
        ""
      ),

    message: (
      <div style={{ whiteSpace: "break-spaces" }}>
        {item?.message?.slice(0, 110)}
        {item?.message?.length > 110 ? " ..." : ""}
      </div>
    ),
    client: (
      <span>
        {item?.lead?.client?.name} <br />
        {item?.lead?.client?.phone}
      </span>
    ),
    mail: <span>{item?.lead?.client?.email}</span>,
    status: item?.status && (
      <Badge
        style={{ textTransform: "capitalize" }}
        className={
          "px-2 py-2 badge-soft-" + `${handleFollowUpStatusColor(item?.status)}`
        }
      >
        {" "}
        {handleFollowUpStatus(item?.status)}{" "}
      </Badge>
    ),
    type: item?.type && (
      <div
        onClick={() => handleCopy(item?.createdBy?.phone)}
        style={{ cursor: "pointer" }}
        title="copy"
      >
        {handleFollowUpType(item?.type)}
      </div>
    ),
    assignedDate: moment(item?.assignedDate).format("DD/MM/YYYY "),
    createdBy: item?.createdBy && (
      <div style={{ whiteSpace: "break-spaces" }}>
        {item?.createdBy?.name}({item?.createdBy?.memberRole})
      </div>
    ),
    action: (
      <div>
        <Button
          title="edit"
          id={`view${index}`}
          type="button"
          color="white"
          className="ms-1 btn"
          onClick={() =>
            setFollowUpModal({
              status: true,
              value: item?.type,
              data: item,
            })
          }
        >
          <i
            className="bx bx-edit me-2"
            style={{ color: "green", fontSize: "22px" }}
          />
        </Button>
        {/* {role === "admin" ||
        role === "generalManager" ||
        role === "managingDirecter" ? (
          <>
            <Button
              id={`Delete${index}`}
              type="button"
              color="white"
              className="ms-1 btn "
              onClick={() => {
                handleDelete(item._id)
              }}
            >
              <i
                className="bx bx-trash me-2"
                style={{
                  color: "red",
                  marginBottom: "10px",
                  fontSize: "1.3rem",
                }}
              />
            </Button>
          </>
        ) : (
          ""
        )} */}
      </div>
    ),
  }))

  const debounceClientSearch = debounce(value => setSearchText(value), 600)

  const handlePageLimit = count => {
    setTotalListLimit(count)
  }

  const statusValues = [
    { label: "All", value: "all", count: allCounts?.all },
    { label: "Scheduled", value: "scheduled", count: allCounts?.scheduled },
    { label: "Pending", value: "pending", count: allCounts?.pending },
    { label: "Done", value: "done", count: allCounts?.done },
  ]

  const handleStatusController = value => {
    switch (role) {
      case "admin":
        return value
      case "generalManager":
        return value
      case "managingDirecter":
        return value
      case "branchManager":
        return ""
    }
  }

  const listChangeFollowup = sortValue => {
    setSort(sortValue)
    console.log(sortValue)
    if (location?.pathname === "/dashboard") {
      return ""
    } else {
      history?.push({
        pathname: location.pathname,
        search: `p=${page}&s=${sortValue}`,
        state: {
          page: page,
          sort: handleStatusController(sortValue),
        },
      })
    }
  }

  useEffect(() => {
    if (params?.id) {
      setSort("all")
    } else {
      setSort("scheduled")
    }
  }, [params?.id])

  console.log(sort)

  useEffect(() => {
    dispatch(getClients("", "", clientSearch, "", ""))
  }, [dispatch, clientSearch])

  const clientOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allClients?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?._id,
      })),
    },
  ]

  const handleEntersClients = text => {
    setClientSearch(text)
  }

  const handlerClientFinalValue = event => {
    setClient(event?.label)
    setClientId(event?.value)
    setPage(1)
  }

  console.log(location?.pathname)

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onDeleteClick={handleDeleteEvent}
        />
        <FollowUpModal
          onCloseClick={() => setFollowUpModal({ status: false, data: {} })}
          show={followUpModal?.status}
          updateData={followUpModal?.data}
          type={followUpModal?.value}
          setSort={setSort}
        />
        <Col className="col-12">
          <Card style={{ minHeight: "245px" }}>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={handleColumns()}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {!params?.id && (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="mb-3 ">
                            {map(
                              statusValues,
                              (head, key) => (
                                console.log(head),
                                (
                                  <>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name={`btn-radio${key + 1}`}
                                      id={head?.value}
                                      autoComplete="off"
                                      checked={sort === head?.value}
                                      onChange={() =>
                                        listChangeFollowup(head?.value, key)
                                      }
                                    />
                                    <label
                                      className="btn btn-warning "
                                      htmlFor={head?.value}
                                      style={{
                                        borderRadius: 1,
                                      }}
                                    >
                                      {head?.label}
                                      <Badge
                                        className={`font-size-12 ms-2 ${
                                          sort === head.value
                                            ? "badge-soft-light"
                                            : "badge-soft-light"
                                        }`}
                                        pill
                                      >
                                        {head?.count}
                                      </Badge>
                                    </label>
                                  </>
                                )
                              )
                            )}
                          </div>
                          <div
                            className="btn-group mt-0 mt-xl-0 d-flex align-items-center justify-content-start"
                            role="group"
                            aria-label="Basic radio toggle button group"
                          >
                            {map(typeOptions, (item, key) => (
                              <div
                                key={key}
                                className="me-3 d-flex align-items-center"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name={`btn_radio${key}`}
                                  id={item?.value}
                                  autoComplete="off"
                                  checked={typeValue === item?.value}
                                  value={item?.value}
                                  onChange={e => setTypeValue(e.target.value)}
                                />
                                <label
                                  className="btn btn-outline-warning d-flex align-items-center justify-content-center"
                                  htmlFor={item?.value}
                                >
                                  {item?.icon}
                                  &nbsp;
                                  {item.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="d-flex  align-items-center">
                          <div className="mt-2">
                            <label></label>

                            <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center w-75">
                              <div className="mx-1">
                                <span className="">
                                  Show
                                  <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                                </span>
                              </div>
                              <div className="" style={{ width: "50px" }}>
                                <AvField
                                  name="pageLimit"
                                  className="form-control"
                                  value={totalListLimit}
                                  onChange={e =>
                                    handlePageLimit(e.target.value)
                                  }
                                  type="text"
                                  required
                                />
                              </div>
                            </AvForm>
                          </div>
                          {role === "admin" && (
                            <>
                              <div
                                className="w-20 text-align-center me-1 "
                                style={{ width: "21%" }}
                              >
                                <label>Staff</label>
                                <Select
                                  onInputChange={handleEntersStaffs}
                                  value={Staff}
                                  placeholder={Staff}
                                  onChange={handlerStaffsFinalValue}
                                  options={staffOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={loading}
                                />
                              </div>
                            </>
                          )}
                          <div
                            className="w-21 text-align-center"
                            style={{ width: "21%" }}
                          >
                            <label>Client</label>
                            <Select
                              onInputChange={handleEntersClients}
                              value={client}
                              placeholder={client}
                              onChange={handlerClientFinalValue}
                              options={clientOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                          <div
                            className="w-21 text-align-center mx-1"
                            style={{ width: "21%" }}
                          >
                            <label>Lead</label>
                            <Select
                              onInputChange={handleEntersLead}
                              value={leadValue}
                              placeholder={leadValue}
                              onChange={handlerFinalValueLead}
                              options={leadOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />{" "}
                          </div>
                          <div className="mx-1">
                            <AvForm style={{ marginTop: 8 }}>
                              <span className="mt-1">Sort By Date</span>
                              <div className={`d-flex date_wrapper `}>
                                <div className="d-flex align-items-start position-relative">
                                  <AvField
                                    name="dateRange"
                                    type="date"
                                    validate={{
                                      dateRange: {
                                        start: { value: -5, units: "years" },
                                        end: { value: 5, units: "years" },
                                      },
                                    }}
                                    onChange={e =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        from: e.target.value,
                                      })
                                    }
                                    value={finalDateFormated?.from}
                                  />
                                  <button
                                    onClick={() =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        from: "",
                                      })
                                    }
                                    type="button"
                                    className="btn btn-white editable-cancel date_close btn-sm"
                                  >
                                    <i className="mdi mdi-close text-danger "></i>
                                  </button>
                                </div>
                                <div className="d-flex align-items-start position-relative">
                                  <AvField
                                    name="dateRange"
                                    className="mx-1"
                                    type="date"
                                    validate={{
                                      dateRange: {
                                        start: { value: -5, units: "years" },
                                        end: { value: 5, units: "years" },
                                      },
                                    }}
                                    onChange={e =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        to: e.target.value,
                                      })
                                    }
                                    value={finalDateFormated?.to}
                                  />
                                  <button
                                    onClick={() =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        to: "",
                                      })
                                    }
                                    type="button"
                                    className="btn btn-white editable-cancel date_close btn-sm"
                                  >
                                    <i className="mdi mdi-close text-danger "></i>
                                  </button>
                                </div>
                              </div>
                            </AvForm>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="d-flex align-items-center justify-content-end py-3">
                      {/* <div className="d-flex w-100"> */}

                      <div>
                        {location?.pathname !== "/followUp/all" &&
                          (location?.pathname !== "/dashboard" ? (
                            <>
                              {typeOptionsCreate?.map((item, key) => (
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded me-2 text-align-center "
                                  onClick={() =>
                                    setFollowUpModal({
                                      status: true,
                                      value: item?.value,
                                    })
                                  }
                                  key={key}
                                >
                                  <span> {item?.icon}</span>
                                  <span className="text-align-center">
                                    {" "}
                                    {item?.label}
                                  </span>
                                </Button>
                              ))}
                            </>
                          ) : null)}
                      </div>
                    </div>

                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {allFollowUp?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                              title="followUp"
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Follow Ups !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FollowUpDataTables

const typeOptions = [
  // { label: "All", value: "alll", icon: "" },

  {
    label: "Call",
    value: "call",
    icon: <BiSolidPhoneCall style={{ fontSize: 14 }} />,
  },
  {
    label: "Meeting",
    value: "meeting",
    icon: <GiTeamIdea style={{ fontSize: 14 }} />,
  },
  {
    label: "Task",
    value: "task",
    icon: <PiNotepad style={{ fontSize: 14 }} />,
  },
  {
    label: "Other",
    value: "other",
    icon: <PiNotepad style={{ fontSize: 14 }} />,
  },
]

const typeOptionsCreate = [
  { label: "Call", value: "call", icon: <MdAddCall className="fs-5" /> },
  { label: "Meeting", value: "meeting", icon: <MdNoteAdd className="fs-5" /> },
  { label: "Task", value: "task", icon: <MdAddTask className="fs-5" /> },
  { label: "Other", value: "other", icon: <IoIosAdd className="fs-5" /> },
]
