import React, { useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Dropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import avatar from "assets/images/other/people.jpeg"
import { IoCloseSharp } from "react-icons/io5"

function Profile({ show, onCloseClick, state, setState }) {
  const { profileDetails } = useSelector(state => ({
    profileDetails: state.Profile.profile,
  }))

  return (
    <div>
      <Modal
        isOpen={show}
        toggle={onCloseClick}
        centered={true}
        className="bg-orange"
      >
        <ModalBody className="p-4">
          <div className="d-flex align-items-center justify-content-end">
            <IoCloseSharp
              style={{ cursor: "pointer" }}
              className="text-danger font-size-18"
              onClick={() => setState(false)}
            />
          </div>
          <div className="media">
            <div className="me-3">
              <img
                src={avatar}
                alt=""
                style={{ objectFit: "contain" }}
                className="avatar-sm rounded-circle img-thumbnail"
              />
            </div>
            <div className="media-body">
              <div className="media">
                <div className="media-body">
                  <div className="text-muted">
                    <h5 className="mb-1">{profileDetails?.name}</h5>
                    <p className="mb-0" style={{ textTransform: "capitalize" }}>
                      {profileDetails?.role === "member"
                        ? profileDetails?.memberRole
                        : profileDetails?.role}
                    </p>
                  </div>
                </div>
              </div>

              <hr />

              <Row>
                {profileDetails?.phone && (
                  <Col xl={6}>
                    <div className="d-flex align-items-center">
                      <p className="text-muted text-truncate mb-2">
                        <i className="bx bx-phone text-secondary pe-2" />
                        {profileDetails?.phone}
                      </p>
                    </div>
                  </Col>
                )}
                {profileDetails?.email && (
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <p className="text-muted text-truncate mb-2">
                        <i className="bx bx-mail-send text-primary pe-2" />
                        {profileDetails?.email}
                      </p>
                    </div>
                  </div>
                )}
              </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Profile

Profile.propTypes = {
  onCloseClick: PropTypes.bool,
  show: PropTypes.any,
  state: PropTypes.any,
  setState: PropTypes.any,
}
