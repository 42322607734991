import {
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  GET_MEMBERS_DETAILS,
  GET_MEMBERS_DETAILS_SUCCESS,
  GET_MEMBERS_DETAILS_FAIL,
  CREATE_MEMBERS,
  CREATE_MEMBERS_SUCCESS,
  CREATE_MEMBERS_FAIL,
  UPDATE_MEMBERS,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  DELETE_MEMBERS_SUCCESS,
  DELETE_MEMBERS_FAIL,
  DELETE_MEMBERS,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  CREATE_USERS,
  CREATE_USERS_SUCCESS,
  CREATE_USERS_FAIL,
  UPDATE_USERS,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FAIL,
  DELETE_USERS,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
} from "./actionTypes"

// Users - This line cannot be edited or removed
export const getMembers = (page, limit, searchText, handleRoles, sort) => (
  console.log(sort),
  console.log(handleRoles),
  {
    type: GET_MEMBERS,
    payload: { page, limit, searchText, handleRoles, sort },
  }
)

export const getMembersSuccess = members => ({
  type: GET_MEMBERS_SUCCESS,
  payload: members,
})

export const getMembersFail = error => ({
  type: GET_MEMBERS_FAIL,
  payload: error,
})

export const getMemberDetails = usersId => ({
  type: GET_MEMBERS_DETAILS,
  payload: usersId,
})

export const getMemberDetailsSuccess = usersDetails => ({
  type: GET_MEMBERS_DETAILS_SUCCESS,
  payload: usersDetails,
})

export const getMemberDetailsFail = error => ({
  type: GET_MEMBERS_DETAILS_FAIL,
  payload: error,
})

export const createMember = (users, history) => ({
  type: CREATE_MEMBERS,
  payload: { users, history },
})

export const createMemberSuccess = users => ({
  type: CREATE_MEMBERS_SUCCESS,
  payload: users,
})

export const createMemberFail = error => ({
  type: CREATE_MEMBERS_FAIL,
  payload: error,
})

// export const getUsersDetail = usersId => ({
//   type: GET_MEMBERS_DETAILS,
//   payload: usersId,
// })

export const updateMember = (users, usersId, onCloseClick) => (
  console.log(usersId),
  {
    type: UPDATE_MEMBERS,
    payload: { users, usersId, onCloseClick },
  }
)

export const updateMemberSuccess = users => ({
  type: UPDATE_MEMBER_SUCCESS,
  payload: users,
})

export const updateMemberFail = error => ({
  type: UPDATE_MEMBER_FAIL,
  payload: error,
})

export const deleteMember = (usersId, history) => (
  console.log(usersId),
  {
    type: DELETE_MEMBERS,
    payload: { usersId, history },
  }
)

export const deleteMemberSuccess = usersId => ({
  type: DELETE_MEMBERS_SUCCESS,
  payload: usersId,
})

export const deleteMemberFail = error => ({
  type: DELETE_MEMBERS_FAIL,
  payload: error,
})

// user
export const getUsers = (page, limit, searchText, handleRoles, sort) => ({
  type: GET_USERS,
  payload: { page, limit, searchText, handleRoles, sort },
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const createUsers = (datas, onCloseClick) => ({
  type: CREATE_USERS,
  payload: { datas, onCloseClick },
})

export const createUsersSuccess = users => ({
  type: CREATE_USERS_SUCCESS,
  payload: users,
})

export const createUsersFail = error => ({
  type: CREATE_USERS_FAIL,
  payload: error,
})

export const updateUsers = (datas, onCloseClick) => ({
  type: UPDATE_USERS,
  payload: { datas, onCloseClick },
})

export const updateUsersSuccess = users => ({
  type: UPDATE_USERS_SUCCESS,
  payload: users,
})

export const updateUsersFail = error => ({
  type: UPDATE_USERS_FAIL,
  payload: error,
})

export const deleteUsers = (userId, onCloseClick) => (
  console.log(userId),
  {
    type: DELETE_USERS,
    payload: { userId, onCloseClick },
  }
)

export const deleteUsersSuccess = users => ({
  type: DELETE_USERS_SUCCESS,
  payload: users,
})

export const deleteUsersFail = error => ({
  type: DELETE_USERS_FAIL,
  payload: error,
})
