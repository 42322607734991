import React, { useEffect } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

//actions
import MetaTag from "components/Common/Meta-tag"
import Form from "./Form"
import { useDispatch } from "react-redux"
import {
  getBrands,
  getClients,
  getModels,
  getVariants,
  getVehicleDetail,
} from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const UpdateVehicles = () => {
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(getVehicleDetail(params.id))
  }, [params.id])

  return (
    <React.Fragment>
      <MetaTag title={"Update Vehicles"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard / Vehicle" breadcrumbItem="Update" />
        <Container>
          <Form updateData={true} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdateVehicles
