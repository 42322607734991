import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import FocusMotors from "../pages/Focus-Dashboard/index"

//404
import Pages404 from "pages/error/pages-404"
import Clients from "pages/Client"

import Lead from "pages/Lead/index"
import VehicleDetail from "pages/Vehicle/SingleView"
import Vehicles from "pages/Vehicle"
import Create from "pages/Vehicle/Crud/Update/Create"
import UpdateVehicles from "pages/Vehicle/Crud/Update/Update"
import LeadDetail from "pages/Lead/SingleView/index"
import UpdateClient from "pages/Client/Crud/Update"
import CreateClient from "pages/Client/Crud/Create"
import ClientDetails from "pages/Client/SingleView"
import CreateLeads from "pages/Lead/Create-update/Create"
import UpdateLeads from "pages/Lead/Create-update/Update"
import Varients from "pages/Varient"
import LeadHistory from "pages/Lead-History"
import LeadNotes from "pages/Lead-Notes"
import Sales from "pages/Sale"
import UserPasswordPage from "pages/Authentication/userPassword"
import Members from "pages/Members"
import Users from "pages/AllUsers"
import ResetPasswordPage from "pages/Authentication/resetPassword "
import Category from "pages/category"
import Categories from "pages/Categories"
import CreateCategories from "pages/Categories/Crud/Create"
import UpdateCategories from "pages/Categories/Crud/Update"
import FollowUp from "pages/followUp"
import MatchingList from "pages/matchingList"
import MatchingListDetails from "pages/matchingList/SingleView"
import Privacy from "pages/Privacy"

const commonRoute = [
  { path: "/dashboard", component: Dashboard },

  { path: "/logout", component: Logout },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]
const role = sessionStorage.getItem("role")

const leadList = [
  // lead
  { path: "/lead/all", component: Lead },
  { path: "/lead-details/:id", component: LeadDetail },
  { path: "/lead/create/new", component: CreateLeads },
  { path: "/lead/update/:id", component: UpdateLeads },
]
const clientList = [
  // Client
  { path: "/client/all", component: Clients },
  { path: "/client/update/:id", component: UpdateClient },
  { path: "/client/create/new", component: CreateClient },
  { path: "/client/details/:id", component: ClientDetails },
]

const followUpList = [
  // followUp
  { path: "/followUp/all", component: FollowUp },
  { path: "/client/update/:id", component: UpdateClient },
  { path: "/client/create/new", component: CreateClient },
  { path: "/client/details/:id", component: ClientDetails },
]

const MatchingLists = [
  // followUp
  { path: "/matching-list/all", component: MatchingList },
  { path: "/matching-list/update/:id", component: UpdateClient },
  { path: "/matching-list/create/new", component: CreateClient },
  { path: "/matching-list/details/:id", component: MatchingListDetails },
]

const vehicleList = [
  // vehicles
  { path: "/vehicle/all", component: Vehicles },
  { path: "/vehicle/details/:id", component: VehicleDetail },
  { path: "/vehicle/create", component: Create },
  { path: "/vehicle/update/:id", component: UpdateVehicles },
]

const superAdminRoutes = [
  ...leadList,
  ...clientList,
  ...vehicleList,
  ...followUpList,
  ...MatchingLists,
  // { path: "/stockUpdate", component: UpdateProductStock },

  // lead history
  { path: "/lead/lead-history/all", component: LeadHistory },

  // lead notes
  { path: "/lead/lead-notes/all", component: LeadNotes },

  //focus dashboard
  { path: "/focus-motors", component: FocusMotors },

  // brands
  // { path: "/brand/all", component: Brands },

  //varient
  // { path: "/varient/all", component: Varients },

  // members
  { path: "/member/all", component: Members },

  // // users
  { path: "/user/all", component: Users },

  // // models
  // { path: "/model/all", component: Models },
  { path: "/category", component: Category },

  // sale
  { path: "/sale/all", component: Sales },

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  //Category-All
  { path: "/categories", component: Categories },
  { path: "/categories/create", component: CreateCategories },
  { path: "/categories/update/:id", component: UpdateCategories },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const managingDirectorRoutes = [
  ...leadList,
  ...vehicleList,
  ...clientList,
  ...followUpList,

  { path: "/sale/all", component: Sales },
  // users
  { path: "/user/all", component: Users },

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const generalManagerRoutes = managingDirectorRoutes

const enquiryManagerRoutes = [
  { path: "/dashboard", component: Dashboard },
  ...clientList,
  ...leadList,
  ...followUpList,

  //Dashboard

  { path: "/vehicle/all", component: Vehicles },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const enquiryAnalystRoutes = [
  ...leadList,
  // vehicles
  { path: "/vehicle/all", component: Vehicles },
  { path: "/vehicle/details/:id", component: VehicleDetail },
  ...followUpList,

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]
const salesExecutiveRoutes = [
  ...leadList,
  ...vehicleList,
  ...clientList,
  ...followUpList,

  { path: "/sale/all", component: Sales },

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]
const salesManagerRoutes = [
  ...leadList,
  ...vehicleList,
  ...followUpList,

  { path: "/client/all", component: Clients },

  { path: "/sale/all", component: Sales },

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const branchManagerRoutes = [
  ...leadList,
  ...vehicleList,

  ...followUpList,

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const purchaseManagerRoutes = [
  ...vehicleList,

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/privacy", component: Privacy },
  { path: "/password/initialize/:id", component: UserPasswordPage },
  { path: "/password/reset/:id", component: ResetPasswordPage },
  { path: "/register", component: Register },
  { path: "/404", component: Pages404 },
]

export {
  publicRoutes,
  superAdminRoutes,
  commonRoute,
  managingDirectorRoutes,
  salesExecutiveRoutes,
  salesManagerRoutes,
  enquiryAnalystRoutes,
  enquiryManagerRoutes,
  generalManagerRoutes,
  branchManagerRoutes,
  purchaseManagerRoutes,
}
