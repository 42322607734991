import MetaTag from "components/Common/Meta-tag"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Button, Card, CardBody, Container } from "reactstrap"
import Form from "./Form"
import { AvForm } from "availity-reactstrap-validation"
import Spinner from "components/Common/Spinner"
import { useDispatch, useSelector } from "react-redux"
import { getLeadDetails, updateLead } from "store/actions"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"

function UpdateLeads() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const { LeadDetails, loading } = useSelector(state => ({
    LeadDetails: state.Lead.leadDetails,
    loading: state?.Lead?.loading,
  }))

  const [vehicle, setVehicle] = useState("Select Vehicle...")
  const [vehicleId, setVehicleId] = useState("")
  const [vehicleSearch, setVehicleSearch] = useState("")
  const [status, setStatus] = useState("Select Status...")
  const [statusValue, setStatusValue] = useState("")
  const [client, setClient] = useState("Select Client...")
  const [clientId, setClientId] = useState("")
  const [clientSearch, setClientSearch] = useState("")
  const [type, setType] = useState("Select...")
  const [typeValue, setTypeValue] = useState("")
  const [medium, setMedium] = useState("Select...")
  const [mediumValue, setMediumValue] = useState("")
  const [member, setMember] = useState("Select member...")
  const [memberId, setMemberId] = useState("")
  const [memberSearch, setMemberSearch] = useState("")
  const [country, setCountry] = useState("+91")

  const [brandValue, setBrandValue] = useState("Select Brand...")
  const [brandId, setBrandId] = useState("")
  const [brandSearch, setBrandSearch] = useState("")

  const [modelValue, setModelValue] = useState("Select Model...")
  const [modelId, setModelValueId] = useState("")
  const [modelSearch, setModelSearch] = useState("")

  const [varientValue, setVarientValue] = useState("Select Variant...")
  const [varientId, setVarientId] = useState("")
  const [varientSearch, setVarientSearch] = useState("")

  const allStates = {
    vehicle,
    setVehicle,
    vehicleId,
    setVehicleId,
    vehicleSearch,
    setVehicleSearch,
    status,
    setStatus,
    statusValue,
    setStatusValue,
    client,
    setClient,
    clientId,
    setClientId,
    clientSearch,
    setClientSearch,
    type,
    setType,
    typeValue,
    setTypeValue,
    medium,
    setMedium,
    mediumValue,
    setMediumValue,
    member,
    setMember,
    memberId,
    setMemberId,
    memberSearch,
    setMemberSearch,
    country,
    setCountry,
    brandValue,
    setBrandValue,
    brandId,
    setBrandId,
    brandSearch,
    setBrandSearch,
    modelValue,
    setModelValue,
    modelId,
    setModelValueId,
    modelSearch,
    setModelSearch,
    varientValue,
    setVarientValue,
    varientId,
    setVarientId,
    varientSearch,
    setVarientSearch,
  }
  useEffect(() => {
    dispatch(getLeadDetails(params?.id))
  }, [dispatch, params?.id])

  const handleValidSubmit = (e, v) => {
    const lead = {
      ...v,
      client: clientId ? clientId : "",
      medium: mediumValue,
      leadType: typeValue,
      vehicle: vehicleId,
      status: statusValue,
      assignee: memberId,
      countryCode: country,
    }
    console.log(lead)
    dispatch(updateLead(lead, params?.id, history))
  }

  return (
    <div>
      <div>
        <MetaTag title="Update Lead" />

        <div className="page-content">
          <Breadcrumbs title="Dashboard" breadcrumbItem=" Update Lead" />
          <Container fluid>
            <div className="container-fluid">
              {/* <AvForm>
                <Form isUpdate={true} states={allStates} />
              </AvForm> */}
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => handleValidSubmit(e, v)}
              >
                <Card className="w-75">
                  <CardBody>
                    <Form isUpdate={LeadDetails} states={allStates} />
                    <div className="py-3 d-flex aling-items-center justify-content-end">
                      {clientId ? (
                        <Button color="warning" type="submit" className="px-5">
                          {loading && <Spinner />} Update
                        </Button>
                      ) : (
                        <Button
                          color="warning"
                          type="submit"
                          className="px-5"
                          disabled
                        >
                          {loading && <Spinner />} Update
                        </Button>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </AvForm>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default UpdateLeads
