import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import AdminDashboard from "./AdminDashboard"
import { getProfile } from "store/actions"

const Dashboard = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => ({
    user: state.Login.user,
  }))
  const role = sessionStorage.getItem("role")

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  if (user?.role ? user?.role : "" || role) {
    return <AdminDashboard />
  } else if (
    user?.role === "admin" ||
    role === "admin" ||
    role === "managingDirector" ||
    role === "generalManager"
  ) {
    return <AdminDashboard />
  }
}

export default Dashboard
