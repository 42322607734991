/* LeadHistorys - This line cannot be edited or removed */
export const GET_LEADHISTORYS = "GET_LEADHISTORYS"
export const GET_LEADHISTORYS_SUCCESS = "GET_LEADHISTORYS_SUCCESS"
export const GET_LEADHISTORYS_FAIL = "GET_LEADHISTORYS_FAIL"

/* LeadHistory_DETAILS */
export const GET_LEADHISTORY_DETAILS = "GET_LEADHISTORY_DETAILS"
export const GET_LEADHISTORY_DETAILS_SUCCESS = "GET_LEADHISTORY_DETAILS_SUCCESS"
export const GET_LEADHISTORY_DETAILS_FAIL = "GET_LEADHISTORY_DETAILS_FAIL"

/* CREATE LeadHistory */
export const CREATE_LEADHISTORY = "CREATE_LEADHISTORY"
export const CREATE_LEADHISTORY_SUCCESS = "CREATE_LEADHISTORY_SUCCESS"
export const CREATE_LEADHISTORY_FAIL = "CREATE_LEADHISTORY_FAIL"

/* Edit LeadHistory */
export const UPDATE_LEADHISTORY = "UPDATE_LEADHISTORY"
export const UPDATE_LEADHISTORY_SUCCESS = "UPDATE_LEADHISTORY_SUCCESS"
export const UPDATE_LEADHISTORY_FAIL = "UPDATE_LEADHISTORY_FAIL"

/* Delete LeadHistory */
export const DELETE_LEADHISTORY = "DELETE_LEADHISTORY"
export const DELETE_LEADHISTORY_SUCCESS = "DELETE_LEADHISTORY_SUCCESS"
export const DELETE_LEADHISTORY_FAIL = "DELETE_LEADHISTORY_FAIL"

export const GET_LEADHISTORY_BY_LEAD = "GET_LEADHISTORY_BY_LEAD"
export const GET_LEADHISTORY_BY_LEAD_SUCCESS = "GET_LEADHISTORY_BY_LEAD_SUCCESS"
export const GET_LEADHISTORY_BY_LEAD_FAIL = "GET_LEADHISTORY_BY_LEAD_FAIL"
