import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DataTables from "./DataTables"
import MetaTag from "components/Common/Meta-tag"

function Users() {
  return (
    <>
      <MetaTag title={"All Users"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Users" />
        <Container fluid>
          <div className="container-fluid">
            <DataTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Users
